import { Card, CardMedia, LinearProgress } from '@mui/material'

import './styles.css'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'

export const MonitoringPage = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [url, setUrl] = useState<string>('')
  const apiClient = new ApiClient()

  const handleLoad = () => {
    setLoading(false)
  }

  const getOrganizationDashboard = async () => {
    setLoading(true)
    try {
      const dashboard = await apiClient.getOrganizationDashboard()
      setUrl(dashboard)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getOrganizationDashboard()
  }, [])

  return (
    <Card
      sx={{
        height: '100%',

        border: 'none',
        boxShadow: 'none',
      }}
    >
      {loading && <LinearProgress />}
      <CardMedia
        className="MonitoringPage"
        component="iframe"
        height="100%"
        width="100%"
        src={url}
        sx={{
          border: 'none',
          boxShadow: 'none',
          visibility: loading ? 'hidden' : 'visible',
        }}
        onLoad={handleLoad}
      />
    </Card>
  )
}

export default MonitoringPage
