import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'

const useFetchPlatformServiceConfiguration = (uuid?: string) => {
  const [platformServiceConfiguration, setPlatformServiceConfiguration] =
    useState<PlatformServiceConfigurationSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchPlatformServiceConfiguration = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getPlatformServiceConfiguration(uuid)
          setPlatformServiceConfiguration(data)
        } catch (e) {
          console.error('Ошибка при получении репозитория:', e)
        }
      }
    }

    fetchPlatformServiceConfiguration()
  }, [uuid])

  return platformServiceConfiguration
}

export default useFetchPlatformServiceConfiguration
