import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  url: null,
  webhook: {
    channel: null,
    text: null,
    username: null,
  },
}

export const MattermostWebhookActionForm = () => {
  return new MobxReactForm({ fields }, { plugins })
}

export default new MobxReactForm({ fields }, { plugins })
