import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from 'components/CustomButton'
import { KeycloakGroupMembersTable } from './KeycloakGroupMembersTable'
import GitlabGroupMembersTable from './GitlabGroupMembersTable'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import useFetchTeam from 'hooks/useFetchTeam'

export const TeamMembersPanel = () => {
  const { uuid } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [synced, setSynced] = useState<boolean>(false)

  const apiClient = new ApiClient()
  const team = useFetchTeam(uuid || '')

  const onClick = async () => {
    setFailed(false)
    setSynced(false)
    setLoading(true)
    try {
      await apiClient.syncGitlabGroupMembers(uuid || '')
      setSynced(true)
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <UserInteractionCustom
        name="synchronize"
        description="Ручная синхронизация"
        helperText={`Синхронизировать пользователей в группе "${team?.name}" Gitlab с пользователями в группе "${team?.name}" Keycloak вручную. Если пользователь не существует в Gitlab, он будет предварительно создан. Отсутствующие в группе "${name}" пользователи будут добавлены с правами "Guest". Автоматическая синхронизация запускается по расписанию раз в 5 минут.`}
      >
        <CustomButton
          onClick={onClick}
          loading={loading}
          sx={{ width: '200px' }}
        >
          Синхронизировать
        </CustomButton>
      </UserInteractionCustom>
      <KeycloakGroupMembersTable />
      <GitlabGroupMembersTable />
      <CustomSnackbarComponent
        opened={synced}
        setOpened={setSynced}
        message="Синхронизация завершена успешно"
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
}

export default TeamMembersPanel
