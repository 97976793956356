import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'

export type CustomSelectComponentUUIDOption = {
  label: string
  uuid?: string
}

export type CustomSelectProps = {
  title?: string
  options?: CustomSelectComponentUUIDOption[]
  height?: string
  width?: string
  // ToDo: remove allValues
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
  error?: boolean
}

export const CustomSelectComponentUUID = (props: CustomSelectProps) => {
  const [value, setValue] = useState<CustomSelectComponentUUIDOption | null>(
    null
  )

  const [options, setOptions] = useState<CustomSelectComponentUUIDOption[]>([])

  useEffect(() => {
    try {
      const o: CustomSelectComponentUUIDOption[] = []

      if (props.options) {
        o.push(...props.options)
        setOptions(o)
      }
    } catch (e) {
      console.error(e)
    }
  }, [props.options])

  useEffect(() => {
    try {
      setValue(
        options?.find((option) => option.uuid === props.selected) || null
      )
    } catch (e) {
      console.error(e)
    }
  }, [options, props.selected])

  return (
    <>
      <Autocomplete
        value={value}
        disabled={props.disabled}
        size="small"
        options={options}
        noOptionsText="не найдено"
        onChange={(
          _event: any,
          newValue: CustomSelectComponentUUIDOption | null
        ) => {
          props.setSelected(newValue?.uuid || '')
        }}
        isOptionEqualToValue={(option, value) =>
          option.label === value.label && option.uuid === value.uuid
        }
        sx={{
          fontSize: '15px',
          width: props.width || 360,
        }}
        renderInput={(params: any) => (
          <TextField {...params} label={props.title} error={props.error} />
        )}
      />
    </>
  )
}
