import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
  CustomSelectProps,
} from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'
import { ServiceTemplate } from 'types/Managers/ServiceTemplates'
import { ApiClient } from 'api/ApiClient'

export interface ServiceTemplateSelectProps extends CustomSelectProps {}

export const ServiceTemplateSelect = (props: ServiceTemplateSelectProps) => {
  const [serviceTemplates, setServiceTemplates] = useState<ServiceTemplate[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getServiceTemplates = async () => {
      try {
        const data = await apiClient.getServiceTemplates()
        setServiceTemplates(data)
      } catch (e) {
        console.log(e)
      }
    }

    getServiceTemplates()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    serviceTemplates?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default ServiceTemplateSelect
