import React from 'react'

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import colorConfigs from 'configs/colorConfigs'
import { RootState } from 'redux/store'
import { RouteType } from 'routes/config'

type Props = {
  item: RouteType
}

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState)

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      sx={{
        '&: hover': {
          backgroundColor: colorConfigs.sidebar.hoverBg,
        },
        backgroundColor:
          appState === item.state ? colorConfigs.sidebar.activeBg : 'unset',
        paddingLeft: '0px',
        height: '50px',
        borderRadius: '5px',
        marginTop: '1px',
      }}
    >
      <ListItemIcon
        sx={{
          color: colorConfigs.sidebar.color,
          paddingLeft: '20px',
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            sx={{ fontWeight: 'medium', fontSize: '0.875rem' }}
            component={'span'}
          >
            {item.sidebarProps.displayText}
          </Typography>
        }
      />
    </ListItemButton>
  ) : null
}

export default SidebarItem
