import { ComponentPage } from 'components/ComponentPage'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'
import ActionsPanel from './ActionsPanel/ActionsPanel'
import { useParams } from 'react-router-dom'
import useFetchServiceTemplate from 'hooks/useFetchServiceTemplate'

export const ServiceTemplatePage = () => {
  const { uuid } = useParams()
  const serviceTemplate = useFetchServiceTemplate(uuid)

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel serviceTemplate={serviceTemplate} />,
    },
    {
      name: 'Actions',
      index: 1,
      children: <ActionsPanel serviceTemplate={serviceTemplate} />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default ServiceTemplatePage
