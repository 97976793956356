import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'
import { ApiClient } from 'api/ApiClient'
import { ComponentCard } from 'components/ComponentCard'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const [platformServiceConfiguration, setPlatformServiceConfiguration] =
    useState<PlatformServiceConfigurationSpec>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getPlatformServiceConfiguration = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getPlatformServiceConfiguration(uuid)
          setPlatformServiceConfiguration(data)
        } catch (e) {
          console.log(e)
        }
      }
    }

    getPlatformServiceConfiguration()
  }, [uuid])

  return (
    <ComponentCard
      name={platformServiceConfiguration?.name}
      description={platformServiceConfiguration?.description}
      fullPage
      options={[
        {
          name: 'Владелец',
          value: `${platformServiceConfiguration?.ownerRef?.name}`,
        },
        {
          name: 'Шаблон',
          value: `${platformServiceConfiguration?.platformServiceTemplateRef?.name}`,
        },
      ]}
    />
  )
}

export default DescriptionPanel
