import '@fontsource/public-sans'
import { CssBaseline } from '@mui/material'
import { deleteAuthType, setAuth } from 'api/Auth'
import {
  checkExistLocalUserToken,
  checkExpiredLocalAuthToken,
  deleteLocalAuthToken,
} from 'api/LocalAccounts'
import { checkExistOidcToken } from 'api/OidcAccounts'
import { checkAuth, deleteProfile, getAuthType } from 'api/utils'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { AuthProvider } from 'react-oidc-context'
import { Provider } from 'react-redux'
import App from './App'
import './index.css'
import { LoginForm } from './pages/LoginForm/LoginForm'
import { store } from './redux/store'
import reportWebVitals from './reportWebVitals'

const OIDC_CONFIG_AUTHORITY = window.env.REACT_APP_OIDC_CONFIG_AUTHORITY || ''
const OIDC_CONFIG_CLIENT_ID = window.env.REACT_APP_OIDC_CONFIG_CLIENT_ID || ''
const OIDC_CONFIG_REDIRECT_URI =
  window.env.REACT_APP_OIDC_CONFIG_REDIRECT_URI || ''

const onSigninCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname)
}

const oidcConfig = {
  authority: OIDC_CONFIG_AUTHORITY,
  client_id: OIDC_CONFIG_CLIENT_ID,
  redirect_uri: OIDC_CONFIG_REDIRECT_URI,
  post_logout_redirect_uri: `${OIDC_CONFIG_REDIRECT_URI}`,
  onSigninCallback: onSigninCallback,
}

function Index() {
  const handleLocalAuth = () => {
    if (!checkExpiredLocalAuthToken()) {
      return (
        <>
          <CssBaseline />
          <App />
        </>
      )
    } else {
      setAuth(false)
      deleteProfile()
      deleteAuthType()
      deleteLocalAuthToken()
      window.location.reload()
      return <></>
    }
  }

  return (
    <React.StrictMode>
      <Provider store={store}>
        {!checkAuth() &&
          !checkExistLocalUserToken() &&
          !checkExistOidcToken() && <LoginForm />}
        {checkAuth() && getAuthType() === 'local' && handleLocalAuth()}
        {checkAuth() && getAuthType() === 'oidc' && (
          <AuthProvider {...oidcConfig}>
            <CssBaseline />
            <App />
          </AuthProvider>
        )}
      </Provider>
    </React.StrictMode>
  )
}

// eslint-disable-next-line
const container = document.getElementById('root')!
const root = createRoot(container)
root.render(<Index />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
