import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from 'components/CustomButton'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomEntity } from 'types/Managers/CustomEntities'
import useFetchCustomEntity from 'hooks/useFetchCustomEntity'
import { NothingFoundPage } from '@edp/core-components-frontend'

export const CustomEntitiesPage = () => {
  const [customEntities, setCustomEntities] = useState<CustomEntity[]>()
  const [filteredCustomEntities, setFilteredCustomEntities] =
    useState<CustomEntity[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [customEntityToDelete, setCustomEntityToDelete] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getCustomEntities = async () => {
    setLoading(true)

    try {
      const data = await apiClient.getCustomEntities()
      setCustomEntities(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomEntities()
  }, [])

  useEffect(() => {
    const hc: CustomEntity[] = []

    if (customEntities && owner !== '') {
      for (const customEntity of customEntities) {
        if (customEntity.owner === owner) {
          hc.push(customEntity)
        }
      }
      setFilteredCustomEntities(hc)
    }

    if (owner === '') {
      setFilteredCustomEntities(customEntities)
    }
  }, [customEntities, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setCustomEntityToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteCustomEntity(customEntityToDelete).then(() => {
        setDeleted(true)
        getCustomEntities()
        setDeletionWindowOpened(false)
      })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const customEntityToDeleteSpec = useFetchCustomEntity(customEntityToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/components/custom-entities/register'}
          >
            Зарегистрировать
          </CustomButton>
        </Box>
      </Grid>
      {filteredCustomEntities?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте зарегистрировать новый пользовательский компонент, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredCustomEntities?.map((customEntity, index) => (
          <ComponentCard
            key={index}
            name={customEntity.name}
            uuid={customEntity.uuid}
            description={customEntity.description}
            options={[
              {
                name: 'Идентификатор',
                value: customEntity.slug,
              },
              {
                name: 'Владелец',
                value: customEntity.ownerRef?.name,
              },
              {
                name: 'Тип',
                value: customEntity.type,
                disabled: !customEntity.type,
              },
              {
                name: 'URL',
                value: customEntity.url,
                disabled: !customEntity.url,
              },
            ]}
            linkOpen={`/components/custom-entities/${customEntity.uuid}`}
            linkUpdate={`/components/custom-entities/update/${customEntity.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление пользовательского компонента'}
          text={`Пользовательский компонент ${customEntityToDeleteSpec?.name} будет удален без возможности восстановления.`}
          spec={customEntityToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Пользовательский компонент ${customEntityToDeleteSpec?.name} удален`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении пользовательского компонента ${customEntityToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default CustomEntitiesPage
