import { ApiClient } from 'api/ApiClient'
import { ComponentCard } from 'components/ComponentCard'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PlatformServiceInstanceSpec } from 'types/Managers/PlatformServiceInstances'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const [platformServiceInstance, setPlatformServiceInstance] =
    useState<PlatformServiceInstanceSpec>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getPlatformServiceInstances = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getPlatformServiceInstance(uuid)
          setPlatformServiceInstance(data)
        } catch (e) {
          console.log(e)
        }
      }
    }

    getPlatformServiceInstances()
  }, [uuid])

  return (
    <ComponentCard
      name={platformServiceInstance?.name}
      uuid={platformServiceInstance?.uuid}
      description={platformServiceInstance?.description}
      fullPage
      options={[
        {
          name: 'Владелец',
          value: platformServiceInstance?.ownerRef?.name,
        },
        {
          name: 'Окружение',
          value: platformServiceInstance?.environmentRef?.name,
        },
        {
          name: 'Шаблон',
          value: platformServiceInstance?.platformServiceTemplateRef?.name,
        },
        {
          name: 'Конфигурация',
          value: platformServiceInstance?.platformServiceConfigurationRef?.name,
        },
      ]}
    />
  )
}

export default DescriptionPanel
