import React, { useEffect, useState } from 'react'

import { getAuthType } from 'api/utils'
import {
  AuthType,
  ChangePasswordResponse,
  ChangePasswordSpec,
} from 'types/LocalAccounts'
import { Alert, Box, Modal, TextField } from '@mui/material'
import { CustomButton } from 'components/CustomButton'
import { ApiClient } from 'api/ApiClient'
import './style.css'

type ChangePasswordModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  updatePassword: boolean
  setUpdatePassword: React.Dispatch<React.SetStateAction<boolean>>
  incorrectCurrentPassword: boolean
  setIncorrectCurrentPassword: React.Dispatch<React.SetStateAction<boolean>>
  errorChangePasswordMessage: string
  setErrorChangePasswordMessage: React.Dispatch<React.SetStateAction<string>>
  errorChangePassword: boolean
  setErrorChangePassword: React.Dispatch<React.SetStateAction<boolean>>
}

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const authType = getAuthType()

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('')
  const [newPasswordNotMatch, setNewPasswordNotMatch] = useState<boolean>(true)

  useEffect(() => {
    if (newPassword === repeatNewPassword) {
      setNewPasswordNotMatch(false)
    } else {
      setNewPasswordNotMatch(true)
    }
  }, [repeatNewPassword])

  const handleChangePassword = async () => {
    if (
      !newPasswordNotMatch &&
      newPassword !== '' &&
      repeatNewPassword !== ''
    ) {
      const apiClient = new ApiClient()

      const spec: ChangePasswordSpec = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      }

      props.setUpdatePassword(false)
      const result = (await apiClient.changePassword(
        spec
      )) as ChangePasswordResponse

      if (result.error !== undefined) {
        if (result.error === 'incorrect current password') {
          props.setIncorrectCurrentPassword(true)
          return
        }

        props.setErrorChangePasswordMessage(result.error)
        props.setErrorChangePassword(true)
        return
      }

      props.setIncorrectCurrentPassword(false)
      props.setUpdatePassword(true)
    }
  }

  return (
    <>
      {authType === AuthType.Local && (
        <Modal
          open={props.open}
          onClose={() => {
            props.setOpen(false)
          }}
        >
          <Box className="boxStyleChangePassword">
            <h1 style={{ marginBottom: '-0.5em' }}>Смена пароля</h1>
            <TextField
              required
              id="currentpassword"
              type="password"
              label="Текущий пароль"
              helperText="Введите текущий пароль"
              fullWidth
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
            {repeatNewPassword !== '' &&
              newPassword !== '' &&
              newPasswordNotMatch && (
                <Alert severity="error" style={{ height: '3.5em' }}>
                  Пароли не совпадают
                </Alert>
              )}

            <TextField
              required
              id="newpassword"
              type="password"
              label="Новый пароль"
              helperText="Введите новый пароль"
              fullWidth
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
            <TextField
              required
              id="repeatnewpassword"
              type="password"
              label="Повторите новый пароль"
              helperText="Повторите новый пароль"
              fullWidth
              value={repeatNewPassword}
              onChange={(event) => setRepeatNewPassword(event.target.value)}
            />
            <CustomButton
              onClick={() => {
                handleChangePassword()
              }}
            >
              Обновить пароль
            </CustomButton>
          </Box>
        </Modal>
      )}
    </>
  )
}

export default ChangePasswordModal
