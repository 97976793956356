import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import useFetchCustomEntity from 'hooks/useFetchCustomEntity'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const customEntity = useFetchCustomEntity(uuid)

  return (
    <ComponentCard
      name={customEntity?.name}
      description={customEntity?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: customEntity?.slug,
        },
        {
          name: 'Владелец',
          value: customEntity?.ownerRef?.name,
        },
        {
          name: 'Тип',
          value: customEntity?.type,
          disabled: !customEntity?.type,
        },
        {
          name: 'URL',
          value: customEntity?.url,
          disabled: !customEntity?.url,
        },
      ]}
    />
  )
}

export default DescriptionPanel
