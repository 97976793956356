import { CardHeader, Typography } from '@mui/material'
import colorConfigs from 'configs/colorConfigs'

export type CustomCardHeaderProps = {
  height?: string
  title?: string
  description?: string
  fullPage?: boolean
}

function trimDescription(text?: string, length?: number) {
  if (!text || !length) {
    return
  }

  if (text.length > length) {
    return text.substring(0, length) + '...'
  }

  return text
}

export const CustomCardHeader = (props: CustomCardHeaderProps) => {
  return (
    <CardHeader
      style={{
        height: props.height || '100px',
        alignItems: 'start',
        backgroundColor: colorConfigs.sidebar.activeBg,
        color: colorConfigs.sidebar.color,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
      }}
      title={props.title}
      subheader={
        <Typography
          component="div"
          variant="subtitle2"
          sx={{
            color: colorConfigs.sidebar.color,
            fontSize: '12px',
          }}
        >
          {props.fullPage
            ? trimDescription(props.description, 330)
            : trimDescription(props.description, 90)}
        </Typography>
      }
    />
  )
}

export default CustomCardHeader
