import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const header = {
  name: null,
  value: null,
}

const fields = {
  url: null,
  method: null,
  payload: null,
  headers: {
    value: [],
    options: {
      structure: header,
    },
  },
}

export const CustomWebhookActionForm = () => {
  return new MobxReactForm({ fields }, { plugins })
}

export default new MobxReactForm({ fields }, { plugins })
