import { useEffect } from 'react'
import { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { NodeResources } from 'types/resources'
import { useParams } from 'react-router-dom'
import { ResourcesChart } from './ResourcesChart'
import prettyBytes from 'pretty-bytes'
import './styles.css'

export const ResourcesPanel = () => {
  const { uuid } = useParams()

  const [availableCpuLimits, setAvailableCpuLimits] = useState<number>(1)
  const [availableCpuRequests, setAvailableCpuRequests] = useState<number>(1)
  const [availableMemoryLimits, setAvailableMemoryLimits] = useState<number>(1)
  const [availableMemoryRequests, setAvailableMemoryRequests] =
    useState<number>(1)
  const [allocatableCpu, setAllocatableCpu] = useState<number>(1)
  const [allocatableMemory, setAllocatableMemory] = useState<number>(1)
  const [cpuLimits, setCpuLimits] = useState<number>(0)
  const [cpuRequests, setCpuRequests] = useState<number>(0)
  const [memoryLimits, setMemoryLimits] = useState<number>(0)
  const [memoryRequests, setMemoryRequests] = useState<number>(0)

  const [nodesResources, setNodesResources] = useState<NodeResources[]>()

  const apiClient = new ApiClient()

  const getClusterResources = async () => {
    try {
      const resources = await apiClient.getClusterResources(uuid || '')
      setNodesResources(resources)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getClusterResources()
  }, [uuid])

  useEffect(() => {
    let am: number = 1
    let ac: number = 1
    let cl: number = 0
    let cr: number = 0
    let ml: number = 0
    let mr: number = 0

    if (nodesResources) {
      for (const node of nodesResources) {
        am += node.allocatable.memory
        ac += node.allocatable.cpu
        cl += node.limits.cpu
        cr += node.requests.cpu
        ml += node.limits.mem
        mr += node.requests.mem
      }
    }

    setAllocatableCpu(ac)
    setAllocatableMemory(am)
    setCpuLimits(cl)
    setCpuRequests(cr)
    setMemoryLimits(ml)
    setMemoryRequests(mr)
  }, [nodesResources])

  useEffect(() => {
    setAvailableCpuRequests(allocatableCpu - cpuRequests)
    setAvailableMemoryRequests(allocatableMemory - memoryRequests)
    setAvailableCpuLimits(allocatableCpu - cpuLimits)
    setAvailableMemoryLimits(allocatableMemory - memoryLimits)
  }, [
    allocatableCpu,
    allocatableMemory,
    cpuLimits,
    memoryLimits,
    cpuRequests,
    memoryRequests,
  ])

  return (
    <Grid className="Resources-Grid">
      <Grid
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          component="div"
          variant="body1"
          sx={{
            fontWeight: '600',
            width: '500px',
          }}
        >
          CPU
        </Typography>
        <Typography
          component="div"
          variant="caption"
          sx={{
            width: '500px',
          }}
        >
          Всего в кластере: {allocatableCpu}m
        </Typography>
        <ResourcesChart
          resource="CPU (requests)"
          allocated={cpuRequests}
          available={availableCpuRequests}
        />
        <ResourcesChart
          resource="CPU (limits)"
          allocated={cpuLimits}
          available={availableCpuLimits}
        />
      </Grid>
      <Grid
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: '600',
            width: '500px',
          }}
        >
          RAM
        </Typography>
        <Typography
          component="div"
          variant="caption"
          sx={{
            width: '500px',
          }}
        >
          Всего в кластере: {prettyBytes(allocatableMemory, { binary: true })}
        </Typography>
        <ResourcesChart
          resource="RAM (requests)"
          allocated={memoryRequests}
          available={availableMemoryRequests}
        />

        <ResourcesChart
          resource="RAM (limits)"
          allocated={memoryLimits}
          available={availableMemoryLimits}
        />
      </Grid>
    </Grid>
  )
}

export default ResourcesPanel
