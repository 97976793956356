import { useState } from 'react'
import { Card, CardMedia, LinearProgress } from '@mui/material'

const DOMAIN = window.env.REACT_APP_DOMAIN || ''

export const StatusPage = () => {
  const [loading, setLoading] = useState(true)

  const handleLoad = () => {
    setLoading(false)
  }

  const url = `https://status-page.${DOMAIN}`

  return (
    <Card
      sx={{
        height: '100%',

        border: 'none',
        boxShadow: 'none',
      }}
    >
      {loading && <LinearProgress />}
      <CardMedia
        component="iframe"
        height="100%"
        src={url}
        sx={{
          visibility: loading ? 'hidden' : 'visible',
          border: 'none',
          boxShadow: 'none',
        }}
        onLoad={handleLoad}
      />
    </Card>
  )
}

export default StatusPage
