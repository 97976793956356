import { CardMedia, LinearProgress } from '@mui/material'
import useFetchKafkaCluster from 'hooks/useFetchKafkaCluster'
import { useParams } from 'react-router-dom'
import './styles.css'
import { useState } from 'react'
import { NothingFoundPage } from '@edp/core-components-frontend'

export const GrafanaPanel = () => {
  const { uuid } = useParams()
  const kafkaCluster = useFetchKafkaCluster(uuid)

  const [loading, setLoading] = useState<boolean>(true)

  const handleLoad = () => {
    setLoading(false)
  }

  return (
    <>
      {loading && <LinearProgress />}
      {!kafkaCluster?.integrations?.grafana?.dashboard && !loading && (
        <NothingFoundPage text="Grafana dashboard не найден. Попробуйте добавить его на странице редактирования кластера Kafka." />
      )}
      <CardMedia
        className="KafkaCluster-GrafanaPanel-CardMedia"
        component="iframe"
        height="100%"
        width="100%"
        src={kafkaCluster?.integrations?.grafana?.dashboard}
        sx={{
          border: 'none',
          boxShadow: 'none',
          visibility: loading ? 'hidden' : 'visible',
        }}
        onLoad={handleLoad}
      />
    </>
  )
}

export default GrafanaPanel
