import { Typography } from '@mui/material'
import { Entry, Ring } from '../utils/types'
import { TechRadarLegendLink } from './TechRadarLegendLink'
import { TechRadarLegendProps } from './types'
import './styles.css'

type TechRadarLegendRingProps = {
  ring: Ring
  entries: Entry[]
  onEntryMouseEnter?: TechRadarLegendProps['onEntryMouseEnter']
  onEntryMouseLeave?: TechRadarLegendProps['onEntryMouseEnter']
}

export const TechRadarLegendRing = ({
  ring,
  entries,
  onEntryMouseEnter,
  onEntryMouseLeave,
}: TechRadarLegendRingProps) => {
  return (
    <div
      data-testid="radar-ring"
      key={ring.id}
      className="TechRadar-RadarLegend-Ring"
    >
      <h3
        className="TechRadar-RadarLegend-RingHeading"
        style={{ color: ring.color }}
        data-testid="radar-legend-heading"
      >
        {ring.name}
      </h3>
      {entries.length === 0 ? (
        <Typography paragraph className="TechRadar-RadarLegend-RingEmpty">
          -
        </Typography>
      ) : (
        <ol className="TechRadar-RadarLegend-RingList">
          {entries.map((entry) => (
            <li
              key={entry.id}
              value={(entry.index || 0) + 1}
              onMouseEnter={
                onEntryMouseEnter && (() => onEntryMouseEnter(entry))
              }
              onMouseLeave={
                onEntryMouseLeave && (() => onEntryMouseLeave(entry))
              }
            >
              <TechRadarLegendLink
                url={entry.url}
                title={entry.title}
                description={entry.description}
                active={entry.active}
                links={entry.links ?? []}
                timeline={entry.timeline ?? []}
              />
            </li>
          ))}
        </ol>
      )}
    </div>
  )
}

export default TechRadarLegendRing
