import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Typography } from '@mui/material'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import { HelmChartRegistry } from 'types/Managers/HelmCharts'
import { ApiClient } from 'api/ApiClient'
import HelmChartStore from './store'
import UserInteractionInfraServiceSelect from 'components/UserInteraction/UserInteractionInfraServiceSelect'
import {
  INFRA_SERVICE_HARBOR,
  INFRA_SERVICE_NEXUS,
} from 'types/Managers/InfraServices'
import UserInteractionInfraServiceTypeSelect from 'components/UserInteraction/UserInteractionInfraServiceTypeSelect'

export interface BasicInfoProps {
  disabled: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BasicInfo = observer((props: BasicInfoProps) => {
  const [helmCharts, setHelmCharts] = useState<string[]>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const getInfraService = async () => {
      try {
        if (
          HelmChartStore.$('registryEngine').value &&
          HelmChartStore.$('registryName').value
        ) {
          const data = await apiClient.getInfraService(
            HelmChartStore.$('registryEngine').value,
            HelmChartStore.$('registryName').value
          )

          HelmChartStore.$('registry').set(data.id)
        }
      } catch (e) {
        console.log(e)
      }
    }
    getInfraService()
  }, [
    HelmChartStore.$('registryEngine').value,
    HelmChartStore.$('registryName').value,
  ])

  useEffect(() => {
    const getHelmChartsFromRegistry = async () => {
      setHelmCharts([])

      try {
        if (
          HelmChartStore.$('registryEngine').value &&
          HelmChartStore.$('registryName').value &&
          HelmChartStore.$('registryURL').value
        ) {
          const helmChartRegistry: HelmChartRegistry = {
            engine: HelmChartStore.$('registryEngine').value,
            name: HelmChartStore.$('registryName').value,
            URL: HelmChartStore.$('registryURL').value,
          }

          const data = await apiClient.getHelmChartsFromRegistry(
            helmChartRegistry
          )

          setHelmCharts(data)
        }
      } catch (e) {
        console.log(e)
      }
    }
    getHelmChartsFromRegistry()
  }, [
    HelmChartStore.$('registryEngine').value,
    HelmChartStore.$('registryName').value,
    HelmChartStore.$('registryURL').value,
  ])

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая Helm chart."
        error={!HelmChartStore.$('owner').isValid}
        selected={HelmChartStore.$('owner').value}
        setSelected={(value) => HelmChartStore.$('owner').set(value)}
      />
      <UserInteractionInfraServiceTypeSelect
        name="registryEngine"
        description="Тип хранилища артефактов"
        helperText="Тип хранилища артефактов в котором хранится Helm chart."
        filter={[INFRA_SERVICE_NEXUS, INFRA_SERVICE_HARBOR]}
        selected={HelmChartStore.$('registryEngine').value}
        setSelected={(value) => HelmChartStore.$('registryEngine').set(value)}
      />
      <UserInteractionInfraServiceSelect
        name="registryName"
        type={HelmChartStore.$('registryEngine').value}
        description="Хранилище артефактов"
        helperText="Инстанс хранилища артефактов в котором хранится Helm chart."
        selected={HelmChartStore.$('registryName').value}
        setSelected={(value) => HelmChartStore.$('registryName').set(value)}
      />
      <UserInteractionTextField
        name="registryURL"
        description="URL репозитория"
        helperText="URL репозитория в котором хранится Helm chart без схемы (формат 'registry.example.com')."
        {...HelmChartStore.$('registryURL').bind()}
      />
      <UserInteractionCustomSelect
        name="name"
        description="Helm chart"
        helperText="Название Helm chart в репозитории."
        options={helmCharts || []}
        error={!HelmChartStore.$('name').isValid}
        selected={HelmChartStore.$('name').value}
        setSelected={(value) => HelmChartStore.$('name').set(value)}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание Helm chart."
        multiline
        rows={6}
        height={'150px'}
        {...HelmChartStore.$('description').bind()}
      />
    </>
  )
})

export default BasicInfo
