import { KubernetesObject } from '@kubernetes/client-node'
import { IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import ManifestDialog from './ManifestDialog'
import { BsFiletypeYml } from 'react-icons/bs'

interface ManifestButtonProps {
  manifest: KubernetesObject
}

export const ManifestButton = (props: ManifestButtonProps) => {
  const [opened, setOpened] = useState<boolean>(false)
  const handleOpen = () => setOpened(true)

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ':hover': {
            bgcolor: 'transparent',
          },
        }}
      >
        <Tooltip arrow title={`Посмотреть YAML манифест`} placement="top">
          <div>
            <BsFiletypeYml />
          </div>
        </Tooltip>
      </IconButton>
      <ManifestDialog
        opened={opened}
        setOpened={setOpened}
        manifest={props.manifest}
      />
    </>
  )
}

export default ManifestButton
