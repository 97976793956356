import { Box, Grid, Paper, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from 'components/CustomButton'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SignaturePublicKey } from 'types/signature'

export const CosignPanel = () => {
  const { uuid } = useParams()
  const [signaturePublicKey, setSignaturePublicKey] =
    useState<SignaturePublicKey>()

  const [successUpdatePrivateSignatureKey, setSuccessUpdatePrivateSignature] =
    useState<boolean>(false)
  const [errorUpdatePrivateSignatureKey, setErrorUpdatePrivateSignature] =
    useState<boolean>(false)
  const [loadingUpdatePrivateSignatureKey, setLoadingUpdatePrivateSignature] =
    useState<boolean>(false)
  const [updatePrivateSignatureKeyError, setUpdatePrivateSignatureKeyError] =
    useState<string>('')

  const apiClient = new ApiClient()

  const updateSignaturePrivateKey = async () => {
    if (uuid) {
      setLoadingUpdatePrivateSignature(true)
      await apiClient
        .updateSignaturePrivateKey(uuid)
        .then(() => {
          setLoadingUpdatePrivateSignature(false)
          setSuccessUpdatePrivateSignature(true)
        })
        .catch((e) => {
          setUpdatePrivateSignatureKeyError(e)
          setErrorUpdatePrivateSignature(true)
          setLoadingUpdatePrivateSignature(false)
        })
    }
  }

  useEffect(() => {
    const getPublicKey = async () => {
      if (uuid) {
        await apiClient.getSignaturePublicKey(uuid).then((res) => {
          setSignaturePublicKey(res)
        })
      }
    }

    getPublicKey()
  }, [successUpdatePrivateSignatureKey])

  return (
    <>
      <Grid container>
        <Grid item>
          <Paper
            sx={{
              padding: '30px',
            }}
          >
            {' '}
            <Box
              style={{
                display: 'grid',
              }}
            >
              <Typography variant="h6">Cosign signature key</Typography>
              <Typography sx={{ 'margin-top': '1em' }}>
                <b>Время создания:</b> {signaturePublicKey?.creation_time}
              </Typography>
              <Typography sx={{ 'margin-top': '1em' }}>
                <b>Тип:</b> {signaturePublicKey?.type}
              </Typography>
              <pre>{signaturePublicKey?.public_key}</pre>
              <CustomButton
                onClick={() => {
                  updateSignaturePrivateKey()
                }}
                loading={loadingUpdatePrivateSignatureKey}
              >
                Перегенировать ключ
              </CustomButton>
              {successUpdatePrivateSignatureKey && (
                <CustomSnackbarComponent
                  opened={successUpdatePrivateSignatureKey}
                  setOpened={setSuccessUpdatePrivateSignature}
                  message={'Ключ успешно обновлен, образы переподписаны'}
                  severity="success"
                />
              )}
              {errorUpdatePrivateSignatureKey && (
                <CustomSnackbarComponent
                  opened={errorUpdatePrivateSignatureKey}
                  setOpened={setErrorUpdatePrivateSignature}
                  message={`При обновлении ключа произошла ошибка. ${updatePrivateSignatureKeyError}`}
                  severity="error"
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default CosignPanel
