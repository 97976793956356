import type { Quadrant, Ring, Entry } from '../utils/types'
import TechRadarBubble from '../TechRadarBubble/TechRadarBubble'
import TechRadarEntry from '../TechRadarEntry/TechRadarEntry'
import TechRadarFooter from '../TechRadarFooter/TechRadarFooter'
import TechRadarGrid from '../TechRadarGrid/TechRadarGrid'
import TechRadarLegend from '../TechRadarLegend/TechRadarLegend'

export type Props = {
  width: number
  height: number
  radius: number
  rings: Ring[]
  quadrants: Quadrant[]
  entries: Entry[]
  activeEntry?: Entry
  onEntryMouseEnter?: (entry: Entry) => void
  onEntryMouseLeave?: (entry: Entry) => void
}

// A component that draws the radar circle.
const TechRadarPlot = (props: Props): JSX.Element => {
  const {
    width,
    height,
    radius,
    quadrants,
    rings,
    entries,
    activeEntry,
    onEntryMouseEnter,
    onEntryMouseLeave,
  } = props

  return (
    <g data-testid="radar-plot">
      <TechRadarLegend
        quadrants={quadrants}
        rings={rings}
        entries={entries}
        onEntryMouseEnter={
          onEntryMouseEnter && ((entry) => onEntryMouseEnter(entry))
        }
        onEntryMouseLeave={
          onEntryMouseLeave && ((entry) => onEntryMouseLeave(entry))
        }
      />
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        <TechRadarGrid radius={radius} rings={rings} />
        <TechRadarFooter x={-0.5 * width} y={0.49 * height} />
        {entries.map((entry) => (
          <TechRadarEntry
            key={entry.id}
            x={entry.x || 0}
            y={entry.y || 0}
            color={entry.color || ''}
            value={(entry?.index || 0) + 1}
            url={entry.url}
            links={entry.links}
            description={entry.description}
            moved={entry.moved}
            title={entry.title}
            timeline={entry.timeline}
            onMouseEnter={onEntryMouseEnter && (() => onEntryMouseEnter(entry))}
            onMouseLeave={onEntryMouseLeave && (() => onEntryMouseLeave(entry))}
          />
        ))}
        <TechRadarBubble
          visible={!!activeEntry}
          text={activeEntry?.title || ''}
          x={activeEntry?.x || 0}
          y={activeEntry?.y || 0}
        />
      </g>
    </g>
  )
}

export default TechRadarPlot
