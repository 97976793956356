export enum OperationStatus {
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export enum OperationAction {
  Create = 'create',
  Get = 'get',
  Update = 'update',
  Delete = 'delete',
  Sync = 'sync',
}

export enum Controllers {
  Kubernetes = 'Kubernetes',
  Keycloak = 'Keycloak',
  Vault = 'Vault',
  Harbor = 'Harbor',
  Grafana = 'Grafana',
  GitLab = 'GitLab',
  DefectDojo = 'DefectDojo',
  ArgoCD = 'ArgoCD',
  SonarQube = 'SonarQube',
  Prometheus = 'Prometheus',
  StatusPage = 'StatusPage',
  Polaris = 'Polaris',
}

export enum Managers {
  Team = 'Team',
  Service = 'Service',
  Environment = 'Environment',
}

export interface OperationSpec {
  uuid: string
  status: OperationStatus
  entity: string
  name: string
  action: OperationAction
  manager: Managers
  controller: Controllers
  resource: string
  spec: object
  retriable: boolean
  user?: string
  error?: string
  createdAt?: Date
}
