import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  name: {
    value: null,
    rules: 'required|string|between:3,64',
  },
  slug: {
    value: null,
    rules: 'required|string|between:3,64|regex:/^[a-z][a-z0-9-]*$/',
  },
  description: null,
  owner: {
    value: null,
    rules: 'required',
  },
  templateRepository: {
    value: null,
    rules: 'required',
  },
  groups: [],
  actions: [],
}

export default new MobxReactForm({ fields }, { plugins })
