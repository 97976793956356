import { ComponentPage } from 'components/ComponentPage'
import { GitlabPanel } from './GitlabPanel/GitlabPanel'
import { ActionType } from 'types/Managers/Actions'
import { useParams } from 'react-router-dom'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'
import useFetchAction from 'hooks/useFetchAction'

export const ActionPage = () => {
  const { uuid } = useParams()
  const action = useFetchAction(uuid)

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Pipelines',
      index: 1,
      children: <GitlabPanel />,
      hidden: action?.type !== ActionType.GitlabPipeline,
    },
  ]
  return <ComponentPage panels={panels} />
}

export default ActionPage
