import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import { Divider, Typography } from '@mui/material'
import { MdDeleteOutline } from 'react-icons/md'
import { MdOutlineChangeCircle } from 'react-icons/md'
import { IoMdOpen } from 'react-icons/io'
import { Link as RouterLink } from 'react-router-dom'
import './styles.css'
import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'

type Option = {
  name?: string
  value?: string
  disabled?: boolean
  isUrl?: boolean
  externalUrl?: boolean
  url?: string
}

type ComponentCardProps = {
  uuid?: string
  name?: string
  options?: Option[]
  linkOpen?: string
  linkUpdate?: string
  handleDelete?: (name: string, type?: string) => void
  description?: string
  fullPage?: boolean | false
  deleteByName?: boolean
}

export const ComponentCard = (props: ComponentCardProps) => {
  const wrapperHandleDeleteButton = async () => {
    if (props.handleDelete && !props.deleteByName) {
      props.handleDelete(String(props.uuid))
    }

    if (props.handleDelete && props.deleteByName) {
      props.handleDelete(String(props.name))
    }
  }

  return (
    <Card
      variant={props.fullPage ? 'elevation' : 'outlined'}
      sx={{
        minWidth: 275,
        minHeight: 260,
        display: 'grid',
        boxShadow: '1',
      }}
    >
      <CustomCardHeader
        height="100px"
        title={props.name}
        description={props.description}
        fullPage={props.fullPage}
      />
      <CardContent
        sx={{
          minHeight: 110,
          display: 'grid',
          alignContent: 'center',
          backgroundColor: '#FFFFFF',
        }}
      >
        {props.options?.map((option) =>
          option.disabled ? (
            <></>
          ) : (
            <Typography component="div" variant="caption" key={option.name}>
              {option.isUrl ? (
                <>
                  {option.name}:{' '}
                  {option.externalUrl ? (
                    <RouterLink
                      className="ComponentCard-Link"
                      to={option.url || ''}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {option.value}
                    </RouterLink>
                  ) : (
                    <RouterLink
                      className="ComponentCard-Link"
                      to={option.url || ''}
                    >
                      {option.value}
                    </RouterLink>
                  )}
                </>
              ) : (
                `${option.name}: ${option.value}`
              )}
            </Typography>
          )
        )}
      </CardContent>
      <Divider />
      {props.fullPage ? (
        <></>
      ) : (
        <CardActions
          sx={{
            justifyContent: 'space-evenly',
            alignSelf: 'end',
            height: 50,
            backgroundColor: '#FFFFFF',
          }}
        >
          {props.linkOpen ? (
            <Button
              size="small"
              color="inherit"
              component={RouterLink}
              to={props.linkOpen}
              variant="text"
              startIcon={<IoMdOpen />}
            >
              Открыть
            </Button>
          ) : (
            <></>
          )}
          {props.linkUpdate ? (
            <Button
              size="small"
              color="inherit"
              component={RouterLink}
              to={props.linkUpdate}
              variant="text"
              startIcon={<MdOutlineChangeCircle />}
            >
              Изменить
            </Button>
          ) : (
            <></>
          )}
          {props.handleDelete ? (
            <Button
              size="small"
              color="inherit"
              onClick={wrapperHandleDeleteButton}
              variant="text"
              startIcon={<MdDeleteOutline />}
            >
              Удалить
            </Button>
          ) : (
            <></>
          )}
        </CardActions>
      )}
    </Card>
  )
}
