import { Divider, Typography } from '@mui/material'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import ServiceStore from './store'
import { observer } from 'mobx-react-lite'
import './styles.css'

export interface IntegrationsProps {
  disabled: boolean
}

const Integrations = observer((props: IntegrationsProps) => {
  return (
    <>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Интеграции
      </Typography>
      <UserInteractionSwitch
        name="defectdojo"
        description="DefectDojo"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в DefectDojo для сервиса. Будет создан продукт "${
          ServiceStore.$('name').value
        }".`}
        checked={ServiceStore.$('integrations.defectdojo.enabled').value}
        onChange={(e) => {
          ServiceStore.$('integrations.defectdojo.enabled').set(
            e.target.checked
          )
        }}
      />
      <UserInteractionSwitch
        name="sonarqube"
        description="SonarQube"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в SonarQube для сервиса. Будет создан проект "${
          ServiceStore.$('name').value
        }".`}
        checked={ServiceStore.$('integrations.sonarqube.enabled').value}
        onChange={(e) => {
          ServiceStore.$('integrations.sonarqube.enabled').set(e.target.checked)
        }}
      />
    </>
  )
})

export default Integrations
