import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Chip } from '@mui/material'
import { Tooltip } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import { V1ContainerStatus, V1Pod } from '@kubernetes/client-node'
import ManifestButton from './ManifestButton'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { DeleteButton } from './DeleteButton'
import { LogsButton } from './LogsButton'

export enum PodPhaseColors {
  Pending = 'info',
  Running = 'success',
  Succeed = 'success',
  Failed = 'error',
  Unknown = 'warning',
}

export interface PodsTableProps {
  pods: V1Pod[]
  cluster: string
}

export const PodsTable = (props: PodsTableProps) => {
  const columns: GridColDef[] = [
    {
      field: 'manifest',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return <ManifestButton manifest={params.row.pod} />
      },
    },
    {
      field: 'logs',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return <LogsButton pod={params.row.pod} cluster={props.cluster} />
      },
    },
    {
      field: 'delete',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return <DeleteButton pod={params.row.pod} cluster={props.cluster} />
      },
    },
    {
      field: 'name',
      headerName: 'Название',
      minWidth: 200,
    },
    {
      field: 'phase',
      headerName: 'Фаза',
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
          <Chip
            label={params.row.phase}
            size="small"
            variant="outlined"
            color={
              PodPhaseColors[params.row.phase as keyof typeof PodPhaseColors]
            }
          />
        </div>
      ),
    },
    {
      field: 'namespace',
      headerName: 'Namespace',
      minWidth: 230,
    },
    {
      field: 'containers',
      headerName: 'Контейнеры',
      minWidth: 300,
      renderCell: (params) => (
        <div>
          {params.row.containers?.map((container: V1ContainerStatus) => (
            <div>
              {container.state?.waiting ? (
                <Tooltip
                  title={`waiting: ${container.state?.waiting.reason}`}
                  arrow
                  placement="top"
                >
                  <CancelIcon
                    style={{ color: 'red', verticalAlign: 'middle' }}
                  />
                </Tooltip>
              ) : (
                ''
              )}
              {container.state?.terminated ? (
                <Tooltip
                  title={`terminated: ${container.state?.terminated.reason}`}
                  arrow
                  placement="top"
                >
                  <StopCircleIcon
                    style={{ color: 'blue', verticalAlign: 'middle' }}
                  />
                </Tooltip>
              ) : (
                ''
              )}
              <span style={{ color: '#90EE90' }}>
                {container.state?.running ? (
                  <Tooltip title={`running`} arrow placement="top">
                    <CheckCircleIcon
                      style={{ color: 'green', verticalAlign: 'middle' }}
                    />
                  </Tooltip>
                ) : (
                  ''
                )}
              </span>
              <span style={{ color: '#757575' }}>
                <Tooltip
                  title={`Docker образ: ${container.image}`}
                  arrow
                  placement="top"
                >
                  <LocalOfferIcon style={{ verticalAlign: 'middle' }} />
                </Tooltip>
              </span>
              {` ${container.name}`}
              {` (${container.restartCount} restarts)`}
            </div>
          ))}
        </div>
      ),
    },
  ]

  const rows = props.pods.map((pod: V1Pod) => {
    return {
      name: pod.metadata?.name,
      phase: pod.status?.phase,
      namespace: pod.metadata?.namespace,
      containers: pod.status?.containerStatuses,
      pod: pod,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.name}
      columns={columns}
      rows={rows}
      pageSize={8}
      rowsPerPageOptions={[8]}
      autoPageSize
      autoHeight={true}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      onCellClick={() => {}}
      getRowHeight={() => 'auto'}
    />
  )
}
