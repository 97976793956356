import { Box, Grid, TextField } from '@mui/material'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { useState } from 'react'
import { CredentialsReqSpec, CredentialsType } from 'types/Credentials'
import { CustomButton } from 'components/CustomButton'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { ResponseStatusText } from 'common/responseStatus'
import CredentialsTypeSelect from 'components/CredentialsTypeSelect/CredentialsTypeSelect'

export const AddCredentialsPanel = () => {
  const apiClient = new ApiClient()

  const [credentialsType, setCredentialsType] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [owner, setOwner] = useState<string>('')
  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [secretText, setSecretText] = useState<string>('')

  const create = async () => {
    if (!name || !owner) {
      setErrorMessage('Все поля должны быть заполнены!')
      setFailed(true)
      return
    }

    if (
      credentialsType === CredentialsType.LOGIN_AND_PASSWORD &&
      (!login || !password)
    ) {
      setErrorMessage('Все поля должны быть заполнены!')
      setFailed(true)
      return
    }

    if (
      (credentialsType === CredentialsType.SSH ||
        credentialsType === CredentialsType.SSH_WITH_PASSWORD) &&
      !secretText
    ) {
      setErrorMessage('Все поля должны быть заполнены!')
      setFailed(true)
      return
    }

    if (credentialsType === CredentialsType.SSH_WITH_PASSWORD && !password) {
      setErrorMessage('Все поля должны быть заполнены!')
      setFailed(true)
      return
    }

    try {
      setLoading(true)

      const secretTextBase64 = Buffer.from(secretText, 'binary').toString(
        'base64'
      )
      const spec: CredentialsReqSpec = {
        name: name,
        owner: owner,
        description: description,
        type: credentialsType,
        login: login,
        password: password,
        secretText: secretTextBase64,
      }

      await apiClient
        .createCredentials(spec)
        .then(async (data: ResponseStatusText) => {
          setLoading(false)

          if (data.status === 'success') {
            setCreated(true)

            setCredentialsType('')
            setName('')
            setDescription('')
            setOwner('')
            setLogin('')
            setPassword('')
            setSecretText('')
          } else {
            setFailed(true)
            if (data.error) {
              setErrorMessage(data.error)
            } else {
              setErrorMessage('unknown')
            }
          }
        })
    } catch (e) {
      setLoading(false)
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('unknown')
      }
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'grid',
              flexDirection: 'column',
              justifyContent: 'left',
              width: '100%',
              gap: 2,
            }}
          >
            <Grid item xs={12}>
              <CredentialsTypeSelect
                title="Выберите тип"
                selected={credentialsType}
                setSelected={setCredentialsType}
              />
              <Box
                sx={{
                  display: 'grid',
                  flexDirection: 'column',
                  justifyContent: 'left',
                  width: '100%',
                  'margin-top': '2em',
                  gap: 2,
                }}
              >
                {credentialsType !== '' && (
                  <>
                    <TextField
                      required
                      id="name"
                      type="text"
                      label="Название учетных данных"
                      helperText="Введите название учетных данных"
                      defaultValue=""
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      sx={{ width: 360 }}
                    />
                    <TextField
                      id="description"
                      type="text"
                      label="Краткое описание"
                      helperText="Введите краткое описание"
                      defaultValue=""
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      sx={{ width: 360 }}
                    />
                    <TeamSelect
                      title="Владелец"
                      selected={owner}
                      setSelected={setOwner}
                    />
                  </>
                )}
                {credentialsType === CredentialsType.LOGIN_AND_PASSWORD && (
                  <>
                    <TextField
                      required
                      id="login"
                      type="text"
                      label="Логин"
                      helperText="Введите логин"
                      defaultValue=""
                      value={login}
                      onChange={(event) => setLogin(event.target.value)}
                      sx={{ width: 360 }}
                    />
                    <TextField
                      required
                      id="password"
                      type="password"
                      label="Пароль"
                      helperText="Введите пароль"
                      defaultValue=""
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      sx={{ width: 360 }}
                    />
                  </>
                )}
                {(credentialsType === CredentialsType.SSH ||
                  credentialsType === CredentialsType.SSH_WITH_PASSWORD) && (
                  <>
                    <TextField
                      required
                      id="ssh_key"
                      type="password"
                      label="SSH ключ"
                      helperText="Введите приватную часть ssh ключа"
                      defaultValue=""
                      multiline
                      minRows={5}
                      value={secretText}
                      onChange={(event) => setSecretText(event.target.value)}
                      sx={{ width: '50em' }}
                    />
                    {credentialsType === CredentialsType.SSH_WITH_PASSWORD && (
                      <>
                        <TextField
                          required
                          id="password"
                          label="Пароль"
                          helperText="Введите пароль ключа"
                          defaultValue=""
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          sx={{ width: 360 }}
                        />
                      </>
                    )}
                  </>
                )}
                {credentialsType !== '' && (
                  <CustomButton
                    sx={{ height: '40px', width: 360 }}
                    loading={loading}
                    onClick={create}
                  >
                    Создать
                  </CustomButton>
                )}
              </Box>
            </Grid>
            <CustomSnackbarComponent
              opened={created}
              setOpened={setCreated}
              message="Новые учетные данные добавлены"
              severity="success"
            />
            <CustomSnackbarComponent
              opened={failed}
              setOpened={setFailed}
              message={`Произошла ошибка: ${errorMessage}`}
              severity="error"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
