import { useEffect, useState } from 'react'

import { ComponentPage } from 'components/ComponentPage'
import GitSecretsPanel from './GitSecretsPanel/GitSecretsPanel'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'
import { RepositorySpec } from 'types/Managers/Repositories'
import { GitMirrorPanel } from './GitMirrorPanel/GitMirrorPanel'
import { OperationsPanel } from 'components/OperationsPanel/OperationsPanel'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'

export const RepositoryPage = () => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const [repository, setRepository] = useState<RepositorySpec>()

  const getRepository = async () => {
    try {
      if (uuid) {
        const data = await apiClient.getRepository(uuid)
        setRepository(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRepository()
  }, [])

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Секреты в git',
      index: 1,
      children: <GitSecretsPanel />,
    },
    {
      name: 'Настройки зеркалирования',
      index: 2,
      children: <GitMirrorPanel />,
      hidden: !repository?.RepositoryMirroring.enabled,
    },
    {
      name: 'Операции',
      index: 3,
      children: <OperationsPanel entity={uuid || ''} />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default RepositoryPage
