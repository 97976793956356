import {
  AiFillEnvironment,
  AiOutlineBranches,
  AiOutlineCheckCircle,
  AiOutlineCluster,
  AiOutlineCode,
} from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { CiCircleList } from 'react-icons/ci'
import { FaRegBuilding } from 'react-icons/fa'
import { GiPlatform } from 'react-icons/gi'
import { ImMap2, ImUsers } from 'react-icons/im'
import { IoLogoWebComponent } from 'react-icons/io5'
import { TbRadar } from 'react-icons/tb'
import { VscDebugStart } from 'react-icons/vsc'
import {
  MdOutlineDashboardCustomize,
  //  MdOutlinePolicy,
  MdOutlineSettings,
} from 'react-icons/md'
import { RiTeamFill } from 'react-icons/ri'
import { SiApachekafka, SiHelm, SiKubernetes } from 'react-icons/si'
import { TbComponents, TbTemplate } from 'react-icons/tb'
import { SlDocs } from 'react-icons/sl'

import { RouteType } from './config'

// import { AiOutlineSecurityScan } from 'react-icons/ai'
import { FaInfinity } from 'react-icons/fa'
import { MdMonitor, MdOutlineSecurity } from 'react-icons/md'
import { SiSwagger } from 'react-icons/si'
import { SlOrganization } from 'react-icons/sl'
import { TbHeartRateMonitor } from 'react-icons/tb'
import { VscSymbolNamespace } from 'react-icons/vsc'

import ExplorePage from '../pages/explore/ExplorePage'
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage'

import ActionsPage from 'pages/components/Actions/ActionsPage'
import CustomEntitiesPage from 'pages/components/CustomEntities/CustomEntitiesPage'
import KafkaClustersPage from 'pages/platformServices/Kafka/KafkaClustersPage'
import EnvironmentsPage from '../pages/components/Environments/EnvironmentsPage'
import HelmChartsPage from '../pages/components/HelmCharts/HelmChartsPage'
import RepositoriesPage from '../pages/components/Repositories/RepositoriesPage'
import ServicesPage from '../pages/components/Services/ServicesPage'
import ClustersPage from '../pages/kubernetes/Clusters/ClustersPage'
import TeamsPage from '../pages/organization/Teams/TeamsPage'
import PlatformServiceConfigurationsPage from '../pages/platformServices/PlatformServiceConfigurations/PlatformServiceConfigurationsPage'
import PlatformServiceInstancesPage from '../pages/platformServices/PlatformServiceInstances/PlatformServiceInstancesPage'
import PlatformServiceTemplatesPage from '../pages/platformServices/PlatformServiceTemplates/PlatformServiceTemplatesPage'
import ServiceTemplatesPage from '../pages/components/ServiceTemplates/ServiceTemplatesPage'
import KubernetesNamespacesPage from '../pages/kubernetes/KubernetesNamespaces/KubernetesNamespacesPage'

import HelmChartPage from 'pages/components/HelmCharts/HelmChartPage'
import EnvironmentPage from '../pages/components/Environments/EnvironmentPage'
import RepositoryPage from '../pages/components/Repositories/RepositoryPage'
import ServicePage from '../pages/components/Services/ServicePage'
import ClusterPage from '../pages/kubernetes/Clusters/ClusterPage'
import TeamPage from '../pages/organization/Teams/TeamPage'
import PlatformServiceConfigurationPage from '../pages/platformServices/PlatformServiceConfigurations/PlatformServiceConfigurationPage'
import PlatformServiceInstancePage from '../pages/platformServices/PlatformServiceInstances/PlatformServiceInstancePage'
import PlatformServiceTemplatePage from '../pages/platformServices/PlatformServiceTemplates/PlatformServiceTemplatePage'
import CustomEntityPage from 'pages/components/CustomEntities/CustomEntityPage'
import ServiceTemplatePage from '../pages/components/ServiceTemplates/ServiceTemplatePage'
import ActionPage from '../pages/components/Actions/ActionPage'
import KubernetesNamespacePage from '../pages/kubernetes/KubernetesNamespaces/KubernetesNamespacePage'

import CreateKafkaClusterPage from 'pages/platformServices/Kafka/CreateKafkaClusterPage'
import CreatePlatformServiceInstancePage from 'pages/platformServices/PlatformServiceInstances/CreatePlatformServiceInstancePage'
import CreateEnvironmentPage from '../pages/components/Environments/CreateEnvironmentPage'
import CreateHelmChartPage from '../pages/components/HelmCharts/CreateHelmChartPage'
import CreateRepositoryPage from '../pages/components/Repositories/CreateRepositoryPage'
import CreateServicePage from '../pages/components/Services/CreateServicePage'
import CreateClusterPage from '../pages/kubernetes/Clusters/CreateClusterPage'
import CreateTeamPage from '../pages/organization/Teams/CreateTeamPage'
import CreatePlatformServiceConfigurationPage from '../pages/platformServices/PlatformServiceConfigurations/CreatePlatformServiceConfigurationPage'
import CreatePlatformServiceTemplatePage from '../pages/platformServices/PlatformServiceTemplates/CreatePlatformServiceTemplatePage'
import CreateCustomEntityPage from 'pages/components/CustomEntities/CreateCustomEntityPage'
import CreateActionPage from 'pages/components/Actions/CreateActionPage'
import CreateServiceTemplatePage from 'pages/components/ServiceTemplates/CreateServiceTemplatePage'
import CreateKubernetesNamespacePage from '../pages/kubernetes/KubernetesNamespaces/CreateKubernetesNamespacePage'

import UpdateEnvironmentPage from 'pages/components/Environments/UpdateEnvironmentPage'
import UpdateHelmChartPage from 'pages/components/HelmCharts/UpdateHelmChartPage'
import UpdateRepositoryPage from 'pages/components/Repositories/UpdateRepositoryPage'
import UpdateServicePage from 'pages/components/Services/UpdateServicePage'
import UpdateClusterPage from 'pages/kubernetes/Clusters/UpdateClusterPage'
import UpdateTeamPage from 'pages/organization/Teams/UpdateTeamPage'
import UpdateKafkaClusterPage from 'pages/platformServices/Kafka/UpdateKafkaClusterPage'
import UpdatePlatformServiceConfigurationPage from 'pages/platformServices/PlatformServiceConfigurations/UpdatePlatformServiceConfigurationPage'
import UpdatePlatformServiceInstancePage from 'pages/platformServices/PlatformServiceInstances/UpdatePlatformServiceInstancePage'
import UpdatePlatformServiceTemplatePage from 'pages/platformServices/PlatformServiceTemplates/UpdatePlatformServiceTemplatePage'
import UpdateCustomEntityPage from 'pages/components/CustomEntities/UpdateCustomEntityPage'
import UpdateActionPage from 'pages/components/Actions/UpdateActionPage'
import UpdateServiceTemplatePage from 'pages/components/ServiceTemplates/UpdateServiceTemplatePage'
import UpdateKubernetesNamespacePage from '../pages/kubernetes/KubernetesNamespaces/UpdateKubernetesNamespacePage'

import ComponentsLayout from 'components/Layouts/ComponentsLayout'
import KubernetesLayout from 'components/Layouts/KubernetesLayout'
import OrganizationLayout from 'components/Layouts/OrganizationLayout'
import PlatformServicesLayout from 'components/Layouts/PlatformServicesLayout'
import SettingsLayout from 'components/Layouts/SettingsLayout'

// import Kyverno from 'pages/kubernetes/Kyverno/Kyverno'
// import KyvernoClusterPolicies from 'pages/kubernetes/Kyverno/KyvernoClusterPolicies'
// import KyvernoClusterPolicyPage from 'pages/kubernetes/Kyverno/KyvernoClusterPolicyPage'
import MonitoringPage from 'pages/organization/MonitoringPage/MonitoringPage'
import Structure from 'pages/organization/Structure/Structure'
import CredentialsPage from 'pages/settings/CredentialsPage/CredentialsPage'
import InfrastructureServicesPage from 'pages/settings/InfrastructureServices/InfrastructureServicesPage'
import OperationsPage from 'pages/settings/OperationsPage/OperationsPage'
import ProfilePage from 'pages/settings/ProfilePage/ProfilePage'
import { RBACPage } from 'pages/settings/rbac/RBACPage'
// import { PolarisPage } from '../pages/PolarisPage'
import { SwaggerPage } from '../pages/settings/SwaggerPage'
import { TechRadarPage } from 'pages/organization/TechRadarPage/TechRadarPage'
import KafkaClusterPage from 'pages/platformServices/Kafka/KafkaClusterPage'
import { DocsPage } from 'pages/DocsPage'
import { StatusPage } from 'pages/organization/StatusPage'

const routes: RouteType[] = [
  {
    index: true,
    element: <ExplorePage />,
    state: 'home',
    path: '/',
  },
  {
    path: '/components',
    element: <ComponentsLayout />,
    state: 'components',
    sidebarProps: {
      displayText: 'Компоненты',
      icon: <IoLogoWebComponent />,
    },
    topbarProps: {
      breadcrumbs: 'Компоненты',
      accessible: false,
    },
    child: [
      {
        path: '/components/services',
        element: <ServicesPage />,
        state: 'components.services',
        sidebarProps: {
          displayText: 'Сервисы',
          icon: <AiOutlineCode />,
        },
        topbarProps: {
          breadcrumbs: 'Сервисы',
        },
      },
      {
        path: '/components/services/create',
        element: <CreateServicePage />,
        state: 'components.services',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/services/update',
        element: <NotFoundPage />,
        state: 'components.services',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/services/update/:uuid',
        element: <UpdateServicePage />,
        state: 'components.services',
        topbarProps: {
          breadcrumbs: 'Обновить',
          nameFromParams: true,
          entity: 'Service',
        },
      },
      {
        path: '/components/services/:uuid',
        element: <ServicePage />,
        state: 'components.services',
        topbarProps: {
          nameFromParams: true,
          entity: 'Service',
        },
      },
      {
        path: '/components/environments',
        element: <EnvironmentsPage />,
        state: 'components.environments',
        sidebarProps: {
          displayText: 'Окружения',
          icon: <AiFillEnvironment />,
        },
        topbarProps: {
          breadcrumbs: 'Окружения',
        },
      },
      {
        path: '/components/environments/create',
        element: <CreateEnvironmentPage />,
        state: 'components.environments',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/environments/update',
        element: <NotFoundPage />,
        state: 'components.environments',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/environments/update/:uuid',
        element: <UpdateEnvironmentPage />,
        state: 'components.environments',
        topbarProps: {
          nameFromParams: true,
          entity: 'Environment',
        },
      },
      {
        path: '/components/environments/:uuid',
        element: <EnvironmentPage />,
        state: 'components.environments',
        topbarProps: {
          nameFromParams: true,
          entity: 'Environment',
        },
      },
      {
        path: '/components/repositories',
        element: <RepositoriesPage />,
        state: 'components.repositories',
        sidebarProps: {
          displayText: 'Репозитории',
          icon: <AiOutlineBranches />,
        },
        topbarProps: {
          breadcrumbs: 'Репозитории',
        },
      },
      {
        path: '/components/repositories/register',
        element: <CreateRepositoryPage />,
        state: 'components.repositories',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/components/repositories/update',
        element: <NotFoundPage />,
        state: 'components.repositories',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/repositories/update/:uuid',
        element: <UpdateRepositoryPage />,
        state: 'components.repositories',
        topbarProps: {
          breadcrumbs: 'Обновить',
          nameFromParams: true,
          entity: 'Repository',
        },
      },
      {
        path: '/components/repositories/:uuid',
        element: <RepositoryPage />,
        state: 'components.repositories',
        topbarProps: {
          nameFromParams: true,
          entity: 'Repository',
        },
      },
      {
        path: '/components/helm-charts',
        element: <HelmChartsPage />,
        state: 'components.helm-charts',
        sidebarProps: {
          displayText: 'Helm charts',
          icon: <SiHelm />,
        },
        topbarProps: {
          breadcrumbs: 'Helm charts',
        },
      },
      {
        path: '/components/helm-charts/register',
        element: <CreateHelmChartPage />,
        state: 'components.helm-charts',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/components/helm-charts/update',
        element: <NotFoundPage />,
        state: 'components.helm-charts',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/helm-charts/update/:uuid',
        element: <UpdateHelmChartPage />,
        state: 'components.helm-charts',
        topbarProps: {
          breadcrumbs: 'Обновить',
          nameFromParams: true,
          entity: 'HelmChart',
        },
      },
      {
        path: '/components/helm-charts/:uuid',
        element: <HelmChartPage />,
        state: 'components.helm-charts',
        topbarProps: {
          nameFromParams: true,
          entity: 'HelmChart',
        },
      },
      {
        path: '/components/custom-entities',
        element: <CustomEntitiesPage />,
        state: 'components.custom-entities',
        sidebarProps: {
          displayText: 'Custom компоненты',
          icon: <MdOutlineDashboardCustomize />,
        },
        topbarProps: {
          breadcrumbs: 'Custom компоненты',
        },
      },
      {
        path: '/components/custom-entities/register',
        element: <CreateCustomEntityPage />,
        state: 'components.custom-entities',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/components/custom-entities/update',
        element: <NotFoundPage />,
        state: 'components.custom-entities',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/custom-entities/update/:uuid',
        element: <UpdateCustomEntityPage />,
        state: 'components.custom-entities',
        topbarProps: {
          nameFromParams: true,
          entity: 'CustomEntity',
        },
      },
      {
        path: '/components/custom-entities/:uuid',
        element: <CustomEntityPage />,
        state: 'components.custom-entities',
        topbarProps: {
          nameFromParams: true,
          entity: 'CustomEntity',
        },
      },
      {
        path: '/components/actions',
        element: <ActionsPage />,
        state: 'components.actions',
        sidebarProps: {
          displayText: 'Actions',
          icon: <VscDebugStart />,
        },
        topbarProps: {
          breadcrumbs: 'Actions',
        },
      },
      {
        path: '/components/actions/create',
        element: <CreateActionPage />,
        state: 'components.actions',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/actions/update',
        element: <NotFoundPage />,
        state: 'components.actions',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/actions/update/:uuid',
        element: <UpdateActionPage />,
        state: 'components.actions',
        topbarProps: {
          nameFromParams: true,
          entity: 'Action',
        },
      },
      {
        path: '/components/actions/:uuid',
        element: <ActionPage />,
        state: 'components.actions',
        topbarProps: {
          nameFromParams: true,
          entity: 'Action',
        },
      },
      {
        path: '/components/service-templates',
        element: <ServiceTemplatesPage />,
        state: 'components.service-templates',
        sidebarProps: {
          displayText: 'Шаблоны сервисов',
          icon: <TbTemplate />,
        },
        topbarProps: {
          breadcrumbs: 'Шаблоны сервисов',
        },
      },
      {
        path: '/components/service-templates/create',
        element: <CreateServiceTemplatePage />,
        state: 'components.service-templates',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/service-templates/update',
        element: <NotFoundPage />,
        state: 'components.service-templates',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/service-templates/update/:uuid',
        element: <UpdateServiceTemplatePage />,
        state: 'components.service-templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'ServiceTemplate',
        },
      },
      {
        path: '/components/service-templates/:uuid',
        element: <ServiceTemplatePage />,
        state: 'components.service-templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'ServiceTemplate',
        },
      },
    ],
  },
  {
    path: '/kubernetes',
    element: <KubernetesLayout />,
    state: 'kubernetes',
    sidebarProps: {
      displayText: 'Kubernetes',
      icon: <SiKubernetes />,
    },
    topbarProps: {
      breadcrumbs: 'Kubernetes',
      accessible: false,
    },
    child: [
      {
        path: '/kubernetes/clusters',
        element: <ClustersPage />,
        state: 'kubernetes.clusters',
        sidebarProps: {
          displayText: 'Кластеры',
          icon: <AiOutlineCluster />,
        },
        topbarProps: {
          breadcrumbs: 'Кластеры',
        },
      },
      {
        path: '/kubernetes/clusters/register',
        element: <CreateClusterPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/kubernetes/clusters/update',
        element: <NotFoundPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/kubernetes/clusters/update/:uuid',
        element: <UpdateClusterPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          nameFromParams: true,
          entity: 'Cluster',
        },
      },
      {
        path: '/kubernetes/clusters/:uuid',
        element: <ClusterPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          nameFromParams: true,
          entity: 'Cluster',
        },
      },
      {
        path: '/kubernetes/namespaces',
        element: <KubernetesNamespacesPage />,
        state: 'kubernetes.namespaces',
        sidebarProps: {
          displayText: 'Namespaces',
          icon: <VscSymbolNamespace />,
        },
        topbarProps: {
          breadcrumbs: 'Namespaces',
        },
      },
      {
        path: '/kubernetes/namespaces/register',
        element: <CreateKubernetesNamespacePage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/kubernetes/namespaces/update',
        element: <NotFoundPage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/kubernetes/namespaces/update/:uuid',
        element: <UpdateKubernetesNamespacePage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          nameFromParams: true,
          entity: 'KubernetesNamespace',
        },
      },
      {
        path: '/kubernetes/namespaces/:uuid',
        element: <KubernetesNamespacePage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          nameFromParams: true,
          entity: 'KubernetesNamespace',
        },
      },
      /**
      {
        path: '/kubernetes/kyverno',
        element: <Kyverno />,
        state: 'kubernetes.kyverno',
        sidebarProps: {
          displayText: 'Kyverno',
          icon: <MdOutlinePolicy />,
        },
        topbarProps: {
          breadcrumbs: 'Kyverno',
        },
      },
      {
        path: '/kubernetes/kyverno/clusterpolicies',
        element: <KyvernoClusterPolicies />,
        state: 'kubernetes.kyverno',
        topbarProps: {
          breadcrumbs: 'Cluster Policies',
        },
      },
      {
        path: '/kubernetes/kyverno/clusterpolicies/:name',
        element: <KyvernoClusterPolicyPage />,
        state: 'kubernetes.kyverno',
        topbarProps: {
          nameFromParams: true,
        },
      },
      */
    ],
  },
  {
    path: '/platform-services',
    element: <PlatformServicesLayout />,
    state: 'platform-services',
    sidebarProps: {
      displayText: 'Платформенные сервисы',
      icon: <GiPlatform />,
    },
    topbarProps: {
      breadcrumbs: 'Платформенные сервисы',
      accessible: false,
    },
    child: [
      {
        path: '/platform-services/templates',
        element: <PlatformServiceTemplatesPage />,
        state: 'platform-services.templates',
        sidebarProps: {
          displayText: 'Шаблоны',
          icon: <TbTemplate />,
        },
        topbarProps: {
          breadcrumbs: 'Шаблоны',
        },
      },
      {
        path: '/platform-services/templates/create',
        element: <CreatePlatformServiceTemplatePage />,
        state: 'platform-services.templates',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/platform-services/templates/update',
        element: <NotFoundPage />,
        state: 'platform-services.template',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/platform-services/templates/update/:uuid',
        element: <UpdatePlatformServiceTemplatePage />,
        state: 'platform-services.templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceTemplate',
        },
      },
      {
        path: '/platform-services/templates/:uuid',
        element: <PlatformServiceTemplatePage />,
        state: 'platform-services.templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceTemplate',
        },
      },
      {
        path: '/platform-services/configurations',
        element: <PlatformServiceConfigurationsPage />,
        state: 'platform-services.configurations',
        sidebarProps: {
          displayText: 'Конфигурации',
          icon: <CiCircleList />,
        },
        topbarProps: {
          breadcrumbs: 'Конфигурации',
        },
      },
      {
        path: '/platform-services/configurations/create',
        element: <CreatePlatformServiceConfigurationPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/platform-services/configurations/update',
        element: <NotFoundPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/platform-services/configurations/update/:uuid',
        element: <UpdatePlatformServiceConfigurationPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceConfiguration',
        },
      },
      {
        path: '/platform-services/configurations/:uuid',
        element: <PlatformServiceConfigurationPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceConfiguration',
        },
      },
      {
        path: '/platform-services/instances',
        element: <PlatformServiceInstancesPage />,
        state: 'platform-services.instances',
        sidebarProps: {
          displayText: 'Инстансы',
          icon: <TbComponents />,
        },
        topbarProps: {
          breadcrumbs: 'Инстансы',
        },
      },
      {
        path: '/platform-services/instances/create',
        element: <CreatePlatformServiceInstancePage />,
        state: 'platform-services.instances',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/platform-services/instances/update',
        element: <NotFoundPage />,
        state: 'platform-services.instances',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/platform-services/instances/update/:uuid',
        element: <UpdatePlatformServiceInstancePage />,
        state: 'platform-services.instances',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceInstance',
        },
      },
      {
        path: '/platform-services/instances/:uuid',
        element: <PlatformServiceInstancePage />,
        state: 'platform-services.instances',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceInstance',
        },
      },
      {
        path: '/platform-services/kafka',
        element: <NotFoundPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          breadcrumbs: 'Kafka',
          accessible: false,
        },
      },
      {
        path: '/platform-services/kafka/clusters',
        element: <KafkaClustersPage />,
        state: 'platform-services.kafka',
        sidebarProps: {
          displayText: 'Kafka',
          icon: <SiApachekafka />,
        },
        topbarProps: {
          breadcrumbs: 'Кластеры',
        },
      },
      {
        path: '/platform-services/kafka/clusters/:uuid',
        element: <KafkaClusterPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          entity: 'KafkaCluster',
          nameFromParams: true,
        },
      },
      {
        path: '/platform-services/kafka/clusters/create',
        element: <CreateKafkaClusterPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/platform-services/kafka/clusters/update/:uuid',
        element: <UpdateKafkaClusterPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          breadcrumbs: 'Обновить',
          nameFromParams: true,
        },
      },
    ],
  },
  {
    path: '/organization',
    element: <OrganizationLayout />,
    state: 'organization',
    sidebarProps: {
      displayText: 'Организация',
      icon: <FaRegBuilding />,
    },
    topbarProps: {
      breadcrumbs: 'Организация',
      accessible: false,
    },
    child: [
      {
        path: '/organization/structure',
        element: <Structure />,
        state: 'organization.structure',
        sidebarProps: {
          displayText: 'Оргструктура',
          icon: <SlOrganization />,
        },
        topbarProps: {
          breadcrumbs: 'Оргструктура',
        },
      },
      {
        path: '/organization/monitoring',
        element: <MonitoringPage />,
        state: 'organization.monitoring',
        sidebarProps: {
          displayText: 'Мониторинг',
          icon: <TbHeartRateMonitor />,
        },
        topbarProps: {
          breadcrumbs: 'Мониторинг',
        },
      },
      {
        path: '/organization/tech-radar',
        element: <TechRadarPage />,
        state: 'organization.tech-radar',
        sidebarProps: {
          displayText: 'Техрадар',
          icon: <TbRadar />,
        },
        topbarProps: {
          breadcrumbs: 'Технологический радар',
        },
      },
      {
        path: '/organization/status-page',
        element: <StatusPage />,
        state: 'organization.status-page',
        sidebarProps: {
          displayText: 'Status page',
          icon: <AiOutlineCheckCircle />,
        },
        topbarProps: {
          breadcrumbs: 'Status page',
        },
      },
      {
        path: '/organization/teams',
        element: <TeamsPage />,
        state: 'organization.teams',
        sidebarProps: {
          displayText: 'Команды',
          icon: <RiTeamFill />,
        },
        topbarProps: {
          breadcrumbs: 'Команды',
        },
      },
      {
        path: '/organization/teams/:uuid',
        element: <TeamPage />,
        state: 'organization.teams',
        topbarProps: {
          nameFromParams: true,
          entity: 'Team',
        },
      },
      {
        path: '/organization/teams/create',
        element: <CreateTeamPage />,
        state: 'organization.teams',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/organization/teams/update',
        element: <NotFoundPage />,
        state: 'organization.teams',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/organization/teams/update/:uuid',
        element: <UpdateTeamPage />,
        state: 'organization.teams',
        topbarProps: {
          nameFromParams: true,
          entity: 'Team',
        },
      },
    ],
  },
  {
    path: '/settings',
    element: <SettingsLayout />,
    state: 'settings',
    sidebarProps: {
      displayText: 'Настройки',
      icon: <MdOutlineSettings />,
    },
    topbarProps: {
      breadcrumbs: 'Настройки',
      accessible: false,
    },
    child: [
      {
        path: '/settings/rbac',
        element: <RBACPage />,
        state: 'settings.rbac',
        sidebarProps: {
          displayText: 'Управление доступом',
          icon: <ImUsers />,
        },
        topbarProps: {
          breadcrumbs: 'Управление доступом',
        },
      },
      {
        path: '/settings/operations',
        element: <OperationsPage />,
        state: 'settings.operations',
        sidebarProps: {
          displayText: 'Операции',
          icon: <FaInfinity />,
        },
        topbarProps: {
          breadcrumbs: 'Операции',
        },
      },
      {
        path: '/settings/credentials',
        element: <CredentialsPage />,
        state: 'settings.credentials',
        sidebarProps: {
          displayText: 'Учетные данные',
          icon: <MdOutlineSecurity />,
        },
        topbarProps: {
          breadcrumbs: 'Учетные данные',
        },
      },
      {
        path: '/settings/infra-services',
        element: <InfrastructureServicesPage />,
        state: 'settings.infra-services',
        sidebarProps: {
          displayText: 'Инфраструктурные сервисы',
          icon: <MdMonitor />,
        },
        topbarProps: {
          breadcrumbs: 'Инфраструктурные сервисы',
        },
      },
      {
        path: '/settings/api',
        element: <SwaggerPage />,
        state: 'setting.api',
        sidebarProps: {
          displayText: 'API',
          icon: <SiSwagger />,
        },
        topbarProps: {
          breadcrumbs: 'API',
        },
      },
      {
        path: '/settings/profile',
        element: <ProfilePage />,
        state: 'settings.profile',
        sidebarProps: {
          displayText: 'Профиль',
          icon: <CgProfile />,
        },
        topbarProps: {
          breadcrumbs: 'Профиль',
        },
      },
    ],
  },
  {
    path: '/explore',
    element: <ExplorePage />,
    state: 'explore',
    sidebarProps: {
      displayText: 'Экосистема',
      icon: <ImMap2 />,
    },
    topbarProps: {
      breadcrumbs: 'Экосистема',
    },
  },
  {
    path: '/docs',
    element: <DocsPage />,
    state: 'docs',
    sidebarProps: {
      displayText: 'Документация',
      icon: <SlDocs />,
    },
    topbarProps: {
      breadcrumbs: 'Документация',
    },
  },
  /**
  {
    path: '/polaris',
    element: <PolarisPage />,
    state: 'polaris',
    sidebarProps: {
      displayText: 'Polaris',
      icon: <AiOutlineSecurityScan />,
    },
    topbarProps: {
      breadcrumbs: 'Polaris',
    },
  },
  */
  {
    path: '/*',
    element: <NotFoundPage />,
    state: '404',
  },
]

export default routes
