import { Outlet } from 'react-router-dom'

const PlatformServicesLayout = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default PlatformServicesLayout
