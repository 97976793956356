import { ComponentPage } from 'components/ComponentPage'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'

export const CustomEntityPage = () => {
  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default CustomEntityPage
