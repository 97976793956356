import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { Action } from 'types/Managers/Actions'

const useFetchAction = (uuid?: string) => {
  const [action, setAction] = useState<Action>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchAction = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getAction(uuid)
          setAction(data)
        } catch (e) {
          console.error('Ошибка при получении данных:', e)
        }
      }
    }

    fetchAction()
  }, [uuid])

  return action
}

export default useFetchAction
