import { useState } from 'react'
import { CustomEntity } from 'types/Managers/CustomEntities'
import { ApiClient } from 'api/ApiClient'
import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import BasicInfo from './EditCustomEntityPage/BasicInfo'
import { useNavigate } from 'react-router-dom'

export const CreateCustomEntityPage = () => {
  const navigate = useNavigate()
  const [customEntity, setCustomEntity] = useState<CustomEntity>({
    uuid: '',
    name: '',
    slug: '',
    description: '',
    owner: '',
    type: '',
    url: '',
  })

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const handleCreate = async () => {
    setCreated(false)
    if (!customEntity.name || !customEntity.owner) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)

    try {
      const spec: CustomEntity = {
        uuid: customEntity.uuid,
        name: customEntity.name,
        description: customEntity.description,
        slug: customEntity.slug,
        type: customEntity.type,
        owner: customEntity.owner,
        url: customEntity.url,
      }

      await apiClient.createCustomEntity(spec)

      setCreated(true)
      navigate('/components/custom-entities')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createdMessage={`Пользовательский компонент ${customEntity.name} зарегистрирован.`}
      createButtonText="Зарегистрировать"
    >
      <BasicInfo
        customEntity={customEntity}
        setCustomEntity={setCustomEntity}
        disabled={false}
      />
    </CreateComponentPage>
  )
}
export default CreateCustomEntityPage
