import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import useFetchCluster from 'hooks/useFetchCluster'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const cluster = useFetchCluster(uuid)

  return (
    <ComponentCard
      name={cluster?.name}
      description={cluster?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: `${cluster?.slug}`,
        },
        {
          name: 'Владелец',
          value: cluster?.ownerRef?.name,
        },
        {
          name: 'URL',
          value: cluster?.address,
        },
      ]}
    />
  )
}

export default DescriptionPanel
