export const AlfastrahKafkaTopicAccountAccessTypes: string[] = [
  'read',
  'write',
  'read-write',
  'all',
]

export const AlfastrahKafkaTopicAccountSeparators: string[] = ['.', '-']
export const AlfastrahKafkaEnvironments: string[] = ['test', 'prod']
export const AlfastrahBlocks = [
  {
    label: 'Инфраструктура (ИТ)',
    uuid: 'i',
  },
  {
    label: 'ОПЕРУ',
    uuid: 'o',
  },
  {
    label: 'Маркетинг (Digital)',
    uuid: 'd',
  },
  {
    label: 'Розница',
    uuid: 'r',
  },
  {
    label: 'Корпоративный',
    uuid: 'c',
  },
  {
    label: 'Авиа',
    uuid: 'a',
  },
  {
    label: 'Медицина',
    uuid: 'm',
  },
  {
    label: 'Финансы',
    uuid: 'f',
  },
  {
    label: 'HR',
    uuid: 'h',
  },
]

export interface AlfastrahKafkaTopicAccount {
  login: string
  access: string
  ip?: string
  create?: boolean
}

export interface AlfastrahKafkaTopic {
  block: string
  topic: string
  environment: string
  description: string
  entity: string
  accounts: AlfastrahKafkaTopicAccount[]
  separator?: string
  partition?: number
  time?: number
  messages?: number
  subscribers?: number
  size?: number
  comments?: string
}
