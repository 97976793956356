import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { LinkProps } from 'react-router-dom'
import colorConfigs from 'configs/colorConfigs'

export interface CustomButtonProps extends ButtonProps {
  loading?: boolean
  component?: React.ForwardRefExoticComponent<
    LinkProps & React.RefAttributes<HTMLAnchorElement>
  >
  to?: string
  children: React.ReactNode
}

export const CustomButton = (props: CustomButtonProps) => {
  const { loading, ...otherProps } = props

  return (
    <Button
      variant="contained"
      disabled={loading}
      to={props.to}
      size="small"
      style={{
        minHeight: '40px',
        minWidth: '100px',
        backgroundColor: colorConfigs.sidebar.activeBg,
        color: colorConfigs.sidebar.color,
      }}
      {...otherProps}
    >
      {loading ? (
        <CircularProgress size="25px" color="inherit" />
      ) : (
        props.children
      )}
    </Button>
  )
}

export default CustomButton
