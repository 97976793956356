import { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Role } from 'types/rbac/role'
import SettingsIcon from '@mui/icons-material/Settings'
import EditRole, { RoleActions } from './EditRole'

export interface DeleteButtonProps {
  uuid: string
  name: string
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface UpdateButtonProps {
  role: Role
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface RolesTableProps {
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export const RolesTable = (props: RolesTableProps) => {
  const [roles, setRoles] = useState<Role[]>([])
  const apiClient = new ApiClient()

  const DeleteButton = (props: DeleteButtonProps) => {
    const deleteRole = async () => {
      props.setFailed(false)
      props.setErrorMessage('')

      try {
        await apiClient.deleteRole(props.uuid)
        getRoles()
      } catch (e) {
        props.setFailed(true)

        if (e instanceof Error) {
          props.setErrorMessage(e.message)
        } else {
          props.setErrorMessage('Unknown error')
        }
      }
    }

    return (
      <IconButton onClick={deleteRole}>
        <Tooltip arrow title={`Удалить роль "${props.name}"`} placement="top">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    )
  }

  const UpdateButton = (props: UpdateButtonProps) => {
    const [opened, setOpened] = useState<boolean>(false)

    const handleOpen = () => setOpened(true)

    return (
      <>
        <IconButton onClick={handleOpen}>
          <Tooltip
            arrow
            title={`Изменить роль "${props.role.name}"`}
            placement="top"
          >
            <SettingsIcon />
          </Tooltip>
        </IconButton>
        <EditRole
          action={RoleActions.Update}
          uuid={props.role.uuid}
          opened={opened}
          setOpened={setOpened}
          created={props.created}
          setCreated={props.setCreated}
          updated={props.updated}
          setUpdated={props.setUpdated}
          failed={props.failed}
          setFailed={props.setFailed}
          errorMessage={props.errorMessage}
          setErrorMessage={props.setErrorMessage}
        />
      </>
    )
  }

  const getRoles = async () => {
    try {
      const data = await apiClient.getRoles()
      setRoles(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRoles()
  }, [props.created, props.updated])

  const columns: GridColDef[] = [
    {
      field: 'update',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return (
          <UpdateButton
            role={params.row.role}
            created={props.created}
            setCreated={props.setCreated}
            updated={props.updated}
            setUpdated={props.setUpdated}
            failed={props.failed}
            setFailed={props.setFailed}
            errorMessage={props.errorMessage}
            setErrorMessage={props.setErrorMessage}
          />
        )
      },
    },
    {
      field: 'delete',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return (
          <DeleteButton
            uuid={params.row.uuid}
            name={params.row.name}
            failed={props.failed}
            setFailed={props.setFailed}
            errorMessage={props.errorMessage}
            setErrorMessage={props.setErrorMessage}
          />
        )
      },
    },
    {
      field: 'name',
      headerName: 'Название',
      minWidth: 500,
    },
  ]

  const rows = roles.map((role: Role) => {
    return {
      uuid: role.uuid,
      name: role.name,
      role: role,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.uuid}
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoPageSize
      autoHeight={true}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
      components={{ BaseButton: DeleteButton }}
    />
  )
}
