import Alert from '@mui/material/Alert'
import { useAsync } from 'react-use'
import { getElapsedTime } from '../../utils'
import { LinearProgress, Link } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { getStatusIconType } from './Icons'
import { GitlabMergeRequest } from 'types/Controllers/Gitlab'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'

export const DenseTable = ({ mergeRequests }: any) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params) => (
        <Link href={params.row.web_url} target="_blank" rel="noreferrer">
          {params.row.id}
        </Link>
      ),
    },
    {
      field: 'state',
      headerName: 'Статус',
      renderCell: (params) => getStatusIconType(params.row.state),
    },
    { field: 'author', headerName: 'Автор' },
    { field: 'title', headerName: 'Название', minWidth: 200 },
    { field: 'created_at', headerName: 'Создан', minWidth: 150 },
  ]

  const rows = mergeRequests?.data?.map((mergeRequest: GitlabMergeRequest) => {
    return {
      id: mergeRequest.id,
      state: mergeRequest.state,
      author: mergeRequest.author.username,
      title: mergeRequest.title,
      web_url: mergeRequest.web_url,
      created_at: getElapsedTime(mergeRequest.created_at),
    }
  })

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
    />
  )
}

export const MergeRequestsTable = () => {
  const apiClient = new ApiClient()
  const { uuid } = useParams()

  const { value, loading, error } = useAsync(async (): Promise<
    GitlabMergeRequest[]
  > => {
    const gitlabObj = await apiClient.getMergeRequestsSummary(uuid || '')
    const data = gitlabObj?.getMergeRequestsData
    const renderData: any = { data }
    return renderData
  }, [])

  if (loading) {
    return <LinearProgress />
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return <DenseTable mergeRequests={value || []} />
}
