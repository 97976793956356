import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'

export async function createPlatformServiceConfiguration(
  this: ApiClient,
  spec: PlatformServiceConfigurationSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/platform-services/configurations`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await this.handleResponse(response)
}

export async function getPlatformServiceConfigurations(
  this: ApiClient
): Promise<PlatformServiceConfigurationSpec[]> {
  const url = `${this.serverUrl}/platform-services/configurations`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getPlatformServiceConfigurationsBy(
  this: ApiClient,
  filterName: string,
  filterValue: string
): Promise<PlatformServiceConfigurationSpec[]> {
  const url = `${this.serverUrl}/platform-services/configurations?filterName=${filterName}&filterValue=${filterValue}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getPlatformServiceConfiguration(
  this: ApiClient,
  uuid: string
): Promise<PlatformServiceConfigurationSpec> {
  const url = `${this.serverUrl}/platform-services/configurations/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updatePlatformServiceConfiguration(
  this: ApiClient,
  spec: PlatformServiceConfigurationSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/platform-services/configurations/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function deletePlatformServiceConfiguration(
  this: ApiClient,
  uuid: string
) {
  const url = `${this.serverUrl}/platform-services/configurations/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
