import React, { forwardRef } from 'react'

import { HelpOutline } from '@mui/icons-material'
import { Grid, Typography, Tooltip, TextField } from '@mui/material'
import './styles.css'
import { CHANGE_ME_PLACEHOLDER } from 'types/other'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'

export interface UserInteractionTextFieldProps {
  name: string
  description: string
  helperText: string
  value: string | number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  multiline?: boolean
  rows?: number
  height?: string
  disabled?: boolean
  type?: string
  label?: string
  error?: boolean
  errorMessage?: string
  removeButton?: boolean
  handleRemove?: () => void
}

const UserInteractionTextField = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: UserInteractionTextFieldProps, _ref) => {
    return (
      <Grid className="UserInteraction-Grid-Remove">
        <Grid className="UserInteraction-Grid">
          <Grid className="UserInteraction-Description">
            <Typography variant="body2" fontSize={'15px'}>
              {props.description}
            </Typography>
            <Tooltip arrow title={props.helperText} placement="top">
              <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
            </Tooltip>
          </Grid>
          <TextField
            name={props.name}
            className={`UserInteraction-TextField`}
            value={props.value}
            type={props.type}
            label={props.label}
            error={props.error || props.value === CHANGE_ME_PLACEHOLDER}
            helperText={props.errorMessage}
            onChange={props.onChange}
            required={props.required || false}
            multiline={props.multiline || false}
            rows={props.rows || 1}
            disabled={props.disabled}
            sx={{
              '& .MuiInputBase-root': {
                height: `${props.height || '40px'}`,
              },
            }}
          />
        </Grid>
        {props.removeButton && (
          <UserInteractionRemoveButton handleRemove={props.handleRemove} />
        )}
      </Grid>
    )
  }
)

UserInteractionTextField.displayName = 'UserInteractionTextField'

export default UserInteractionTextField
