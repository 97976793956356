import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import useFetchRepository from 'hooks/useFetchRepository'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const repository = useFetchRepository(uuid)

  return (
    <ComponentCard
      name={repository?.name}
      description={repository?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: `${repository?.slug}`,
        },
        {
          name: 'Владелец',
          value: repository?.ownerRef?.name,
          isUrl: true,
          externalUrl: false,
          url: `/organization/teams/${repository?.ownerRef?.uuid}`,
        },
        {
          name: 'Gitlab URL',
          value: repository?.slug,
          url: repository?.url,
          isUrl: true,
          externalUrl: true,
        },
        {
          name: 'Является шаблоном',
          value: repository?.isTemplate ? 'да' : 'нет',
        },
        {
          name: 'Настроено зеркалирование',
          value: repository?.RepositoryMirroring.enabled ? 'да' : 'нет',
        },
      ]}
    />
  )
}

export default DescriptionPanel
