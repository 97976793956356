import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { ServiceTemplate } from 'types/Managers/ServiceTemplates'

const useFetchServiceTemplate = (uuid?: string) => {
  const [service, setServiceTemplate] = useState<ServiceTemplate>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchServiceTemplate = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getServiceTemplate(uuid)
          setServiceTemplate(data)
        } catch (e) {
          console.error('Ошибка при получении данных:', e)
        }
      }
    }

    fetchServiceTemplate()
  }, [uuid])

  return service
}

export default useFetchServiceTemplate
