import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from 'components/CustomButton'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { DeletionWindow } from 'components/DeletionWindow'
import { PlatformServiceInstanceSpec } from 'types/Managers/PlatformServiceInstances'
import useFetchPlatformServiceInstance from 'hooks/useFetchPlatformServiceInstance'
import { NothingFoundPage } from '@edp/core-components-frontend'

export const PlatformServiceInstancesPage = () => {
  const [platformServiceInstances, setPlatformServiceInstances] =
    useState<PlatformServiceInstanceSpec[]>()
  const [
    filteredPlatformServiceInstances,
    setFilteredPlatformServiceInstances,
  ] = useState<PlatformServiceInstanceSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [platformServiceInstanceToDelete, setPlatformServiceInstanceToDelete] =
    useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()
  const getPlatformServiceInstances = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getPlatformServiceInstances()
      setPlatformServiceInstances(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPlatformServiceInstances()
  }, [])

  useEffect(() => {
    const e: PlatformServiceInstanceSpec[] = []

    if (platformServiceInstances && owner !== '') {
      for (const platformServiceInstance of platformServiceInstances) {
        if (platformServiceInstance.owner === owner) {
          e.push(platformServiceInstance)
        }
      }
      setFilteredPlatformServiceInstances(e)
    }

    if (owner === '') {
      setFilteredPlatformServiceInstances(platformServiceInstances)
    }
  }, [platformServiceInstances, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setPlatformServiceInstanceToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient
        .deletePlatformServiceInstance(platformServiceInstanceToDelete)
        .then(() => {
          setDeleted(true)
          getPlatformServiceInstances()
          setDeletionWindowOpened(false)
        })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const platformServiceInstanceToDeleteSpec = useFetchPlatformServiceInstance(
    platformServiceInstanceToDelete
  )

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/platform-services/instances/create'}
          >
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {filteredPlatformServiceInstances?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новый инстанс платформенного сервиса, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredPlatformServiceInstances?.map(
          (platformServiceInstance, index) => (
            <ComponentCard
              key={index}
              name={platformServiceInstance.name}
              uuid={platformServiceInstance.uuid}
              description={platformServiceInstance.description}
              options={[
                {
                  name: 'Владелец',
                  value: platformServiceInstance.ownerRef?.name,
                },
                {
                  name: 'Окружение',
                  value: platformServiceInstance.environmentRef?.name,
                },
                {
                  name: 'Шаблон',
                  value:
                    platformServiceInstance.platformServiceTemplateRef?.name,
                },
                {
                  name: 'Конфигурация',
                  value:
                    platformServiceInstance.platformServiceConfigurationRef
                      ?.name,
                },
              ]}
              linkOpen={`/platform-services/instances/${platformServiceInstance.uuid}`}
              linkUpdate={`/platform-services/instances/update/${platformServiceInstance.uuid}`}
              handleDelete={handleOpenDeletionWindow}
            />
          )
        )}
        <DeletionWindow
          title={'Удаление инстанса'}
          text={`Инстанс платформенного сервиса ${platformServiceInstanceToDeleteSpec?.name} будет удален без возможности восстановления.`}
          spec={platformServiceInstanceToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Инстанс платформенного сервиса ${platformServiceInstanceToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении инстанса платформенного сервиса ${platformServiceInstanceToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default PlatformServiceInstancesPage
