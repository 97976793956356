import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { ServiceInstanceInfoSpec } from 'types/service'
import { ServiceTemplate } from 'types/Managers/ServiceTemplates'

export async function createServiceTemplate(
  this: ApiClient,
  serviceTemplate: ServiceTemplate
): Promise<JSON> {
  const url = `${this.serverUrl}/service-templates`

  const body = JSON.stringify(serviceTemplate)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })
  return await this.handleResponse(response)
}

export async function getServiceTemplates(
  this: ApiClient
): Promise<ServiceTemplate[]> {
  const url = `${this.serverUrl}/service-templates`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceTemplatesBy(
  this: ApiClient,
  filterName: string,
  filterValue: string
): Promise<ServiceTemplate[]> {
  const url = `${this.serverUrl}/service-templates?filterName=${filterName}&filterValue=${filterValue}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceTemplate(
  this: ApiClient,
  uuid: string
): Promise<ServiceTemplate> {
  const url = `${this.serverUrl}/service-templates/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updateServiceTemplate(
  this: ApiClient,
  spec: ServiceTemplate
): Promise<JSON> {
  const url = `${this.serverUrl}/service-templates/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getServiceTemplateInstances(
  this: ApiClient,
  uuid: string
): Promise<Array<ServiceInstanceInfoSpec>> {
  const url = `${this.serverUrl}/service-templates/${uuid}/instances/info`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function deleteServiceTemplate(
  this: ApiClient,
  uuid: string
): Promise<ServiceTemplate> {
  const url = `${this.serverUrl}/service-templates/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
