import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectProps,
  CustomSelectComponentUUIDOption,
} from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'
import { ApiClient } from 'api/ApiClient'
import { EnvironmentSpec } from 'types/Managers/Environments'

export interface EnvironmentSelectProps extends CustomSelectProps {}

export const EnvironmentSelect = (props: EnvironmentSelectProps) => {
  const [environments, setEnvironments] = useState<EnvironmentSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getEnvironments = async () => {
      try {
        const data = await apiClient.getEnvironments()
        setEnvironments(data)
      } catch (e) {
        console.log(e)
      }
    }

    getEnvironments()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    environments?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []
  return <CustomSelectComponentUUID options={options} {...props} />
}

export default EnvironmentSelect
