import { useRef, useLayoutEffect } from 'react'
import './styles.css'

export type TechRadarBubbleProps = {
  visible: boolean
  text: string
  x: number
  y: number
}

const TechRadarBubble = (props: TechRadarBubbleProps): JSX.Element => {
  const { visible, text } = props

  const textElem = useRef<SVGTextElement>(null)
  const svgElem = useRef<SVGGElement>(null)
  const rectElem = useRef<SVGRectElement>(null)
  const pathElem = useRef<SVGPathElement>(null)

  const updatePosition = () => {
    if (textElem.current) {
      const { x, y } = props
      const bbox = textElem.current.getBBox()
      const marginX = 5
      const marginY = 4

      if (svgElem.current) {
        svgElem.current.setAttribute(
          'transform',
          `translate(${x - bbox.width / 2}, ${y - bbox.height - marginY})`
        )
      }

      if (rectElem.current) {
        rectElem.current.setAttribute('x', String(-marginX))
        rectElem.current.setAttribute('y', String(-bbox.height))
        rectElem.current.setAttribute('width', String(bbox.width + 2 * marginX))
        rectElem.current.setAttribute('height', String(bbox.height + marginY))
      }

      if (pathElem.current) {
        pathElem.current.setAttribute(
          'transform',
          `translate(${bbox.width / 2 - marginX}, ${marginY - 1})`
        )
      }
    }
  }

  useLayoutEffect(() => {
    updatePosition()
  })

  return (
    <g
      ref={svgElem}
      x={0}
      y={0}
      className={
        visible
          ? 'TechRadar-RadarBubble-VisibleBubble'
          : 'TechRadar-RadarBubble-Bubble'
      }
      data-testid="radar-bubble"
    >
      <rect
        ref={rectElem}
        rx={4}
        ry={4}
        className={'TechRadar-RadarBubble-Background'}
      />
      <text ref={textElem} className={'TechRadar-RadarBubble-Text'}>
        {text}
      </text>
      <path
        ref={pathElem}
        d="M 0,0 10,0 5,8 z"
        className={'TechRadar-RadarBubble-Background'}
      />
    </g>
  )
}

export default TechRadarBubble
