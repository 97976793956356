import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { PlatformServiceInstanceSpec } from 'types/Managers/PlatformServiceInstances'

const useFetchPlatformServiceInstance = (uuid?: string) => {
  const [platformServiceInstance, setPlatformServiceInstance] =
    useState<PlatformServiceInstanceSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchPlatformServiceInstance = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getPlatformServiceInstance(uuid)
          setPlatformServiceInstance(data)
        } catch (e) {
          console.error('Ошибка при получении репозитория:', e)
        }
      }
    }

    fetchPlatformServiceInstance()
  }, [uuid])

  return platformServiceInstance
}

export default useFetchPlatformServiceInstance
