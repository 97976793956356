import CustomPieChart from 'components/CustomPieChart/CustomPieChart'

export type ResourcesChartProps = {
  resource: string
  allocated: number
  available: number
}

export const ResourcesChart = (props: ResourcesChartProps) => {
  return (
    <CustomPieChart
      data={[
        {
          name: `Доступно ${props.resource}`,
          value: props.available,
          color: '#0CC078',
        },
        {
          name: `Выделено ${props.resource}`,
          value: props.allocated,
          color: '#FB6962',
        },
      ]}
      resource={props.resource}
    />
  )
}
