import React, { forwardRef } from 'react'

import { HelpOutline } from '@mui/icons-material'
import { Grid, Typography, Tooltip, Switch } from '@mui/material'
import './styles.css'

export interface UserInteractionSwitchProps {
  name: string
  description: string
  helperText: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const UserInteractionSwitch = forwardRef(
  (props: UserInteractionSwitchProps, _ref) => {
    return (
      <Grid className="UserInteraction-Grid">
        <Grid className="UserInteraction-Description">
          <Typography variant="body2" fontSize={'15px'}>
            {props.description}
          </Typography>
          <Tooltip arrow title={props.helperText} placement="top">
            <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
          </Tooltip>
        </Grid>
        <Grid className="UserInteraction-Grid-Switch">
          <Switch
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled}
            size="small"
            sx={{
              marginLeft: '-5px',
            }}
          />
        </Grid>
      </Grid>
    )
  }
)

UserInteractionSwitch.displayName = 'UserInteractionSwitch'

export default UserInteractionSwitch
