import { useEffect, useState } from 'react'

import { ApiClient } from 'api/ApiClient'

import BasicInfo from './EditPlatformServiceInstancePage/BasicInfo'
import { PlatformServiceInstanceSpec } from 'types/Managers/PlatformServiceInstances'
import UpdateComponentPage from 'components/UpdateComponentPage/UpdateComponentPage'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export const UpdatePlatformServiceInstancePage = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()

  const [platformServiceInstance, setPlatformServiceInstance] =
    useState<PlatformServiceInstanceSpec>({
      uuid: '',
      name: '',
      owner: '',
      description: '',
      environment: '',
      cluster: '',
      platformServiceTemplate: '',
      platformServiceConfiguration: '',
    })

  const [updated, setUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const getPlatformServiceInstance = async () => {
    try {
      const data = await apiClient.getPlatformServiceInstance(uuid || '')
      setPlatformServiceInstance(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPlatformServiceInstance()
  }, [])

  const handleUpdate = async () => {
    setUpdated(false)
    if (
      !platformServiceInstance.name ||
      !platformServiceInstance.environment ||
      !platformServiceInstance.platformServiceTemplate ||
      !platformServiceInstance.platformServiceConfiguration
    ) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)
    try {
      const spec: PlatformServiceInstanceSpec = {
        uuid: platformServiceInstance.uuid,
        name: platformServiceInstance.name,
        description: platformServiceInstance.description,
        owner: platformServiceInstance.owner,
        environment: platformServiceInstance.environment,
        cluster: platformServiceInstance.cluster,
        platformServiceTemplate:
          platformServiceInstance.platformServiceTemplate,
        platformServiceConfiguration:
          platformServiceInstance.platformServiceConfiguration,
      }

      await apiClient.updatePlatformServiceInstance(spec)
      setUpdated(true)
      navigate('/platform-services/instances')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <UpdateComponentPage
      handleUpdate={handleUpdate}
      loading={loading}
      updated={updated}
      setUpdated={setUpdated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      updatedMessage={`Инстанс ${platformServiceInstance.name} обновлен`}
    >
      <BasicInfo
        platformServiceInstance={platformServiceInstance}
        setPlatformServiceInstance={setPlatformServiceInstance}
        disabled={true}
      />
    </UpdateComponentPage>
  )
}

export default UpdatePlatformServiceInstancePage
