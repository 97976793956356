import React from 'react'

import {
  Alert,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { HarborInfo } from 'types/Managers/InfraServices'

export interface harborInfoCardProps {
  status: string
  error: string
  info: HarborInfo
}

export const HarborInfoCard = (props: harborInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <LinearProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'warning' && (
        <Alert severity="warning">
          Подключение установлено, но токен некорректный
        </Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}
      {(props.status === 'success' || props.status === 'warning') && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Auth mode:</b> {props.info.auth_mode}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Harbor version:</b> {props.info.harbor_version}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Self registration:</b> {String(props.info.self_registration)}
            </ListItemText>
          </ListItem>
          {props.status === 'success' && (
            <>
              <ListItem>
                <ListItemText>
                  <b>External url:</b>{' '}
                  <a href={props.info.external_url}>
                    {props.info.external_url}
                  </a>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Has ca root:</b> {String(props.info.has_ca_root)}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Notification enable:</b>{' '}
                  {String(props.info.notification_enable)}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Project creation restriction:</b>{' '}
                  {props.info.project_creation_restriction}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Read only:</b> {String(props.info.read_only)}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Registry storage provider name:</b>{' '}
                  {props.info.registry_storage_provider_name}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>With chartmuseum:</b> {String(props.info.with_chartmuseum)}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>With notary:</b> {String(props.info.with_notary)}
                </ListItemText>
              </ListItem>
            </>
          )}
        </List>
      )}
    </Paper>
  )
}
