import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import ReactFlow, { addEdge, ConnectionLineType, MiniMap } from 'reactflow'
import { Edge } from 'react-flow-renderer'
import { Node as ReactFlowRendererNode } from 'react-flow-renderer'
import dagre from 'dagre'
import 'reactflow/dist/style.css'
import { ApiClient } from 'api/ApiClient'
import { TeamSpec } from '@edp/types'
import { useNavigate } from 'react-router-dom'
import './styles.css'

const position = { x: 0, y: 0 }
const edgeType = 'simplebezier'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeWidth = 200
const nodeHeight = 100

const getLayoutedElements = (nodes: any, edges: any, direction = 'TB') => {
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = 'top'
    node.sourcePosition = 'bottom'

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }

    return node
  })

  return { nodes, edges }
}

const Structure = () => {
  const [teams, setTeams] = useState<TeamSpec[]>([])
  const [initialNodes, setInitialNodes] = useState<ReactFlowRendererNode[]>([])
  const [initialEdges, setInitialEdges] = useState<Edge[]>([])
  const ref = useRef<HTMLDivElement | null>(null)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const navigate = useNavigate()

  const renderNodeLabel = (team: TeamSpec) => (
    <div>
      <a
        href={`/organization/teams/${team.uuid}`}
        onClick={(event) => handleLinkClick(event, team.uuid)}
        style={{
          color: '#333238',
          background: '#F8F8FE',
          textDecoration: 'none',
        }}
      >
        {team.name}
      </a>
    </div>
  )

  const handleLinkClick = (event: React.MouseEvent, uuid: string) => {
    event.preventDefault()
    navigate(`/organization/teams/${uuid}`)
  }

  useLayoutEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
    setHeight(ref.current ? ref.current.offsetHeight : 0)
  }, [ref.current])

  const apiClient = new ApiClient()

  useEffect(() => {
    const getTeams = async () => {
      try {
        const data = await apiClient.getTeams()
        setTeams(data)
      } catch (e) {
        console.log(e)
      }
    }
    getTeams()
  }, [])

  useEffect(() => {
    const edges: Edge[] = []
    const nodes: ReactFlowRendererNode[] = []

    for (const team of teams) {
      nodes.push({
        id: team.name,
        data: {
          label: renderNodeLabel(team),
        },
        position,
        className: 'custom',
      })

      for (const parentTeam of teams) {
        if (team.parentTeam === parentTeam.uuid) {
          edges.push({
            id: `${parentTeam.name}-${team.name}`,
            source: parentTeam.name,
            target: team.name,
            type: edgeType,
          })
        }
      }
    }

    setInitialNodes(nodes)
    setInitialEdges(edges)
  }, [teams])

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges
  )

  const [nodes, setNodes] = useState<ReactFlowRendererNode[]>([])
  const [edges, setEdges] = useState<Edge[]>([])

  useEffect(() => {
    setNodes(layoutedNodes)
    setEdges(layoutedEdges)
  }, [layoutedNodes, layoutedEdges])

  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds: any) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  )

  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      <div style={{ height: height, width: width }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onConnect={onConnect}
          nodesDraggable={false}
          nodesFocusable={false}
          edgesFocusable={false}
          elementsSelectable={true}
          fitView
        >
          <MiniMap />
        </ReactFlow>
      </div>
    </div>
  )
}

export default Structure
