import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { highlight, languages } from 'prismjs'
import React from 'react'
import Editor from 'react-simple-code-editor'

import 'prismjs/components/prism-bash'
import 'prismjs/themes/prism.css'
import './styles.css'
import { GitlabVariable } from 'types/index'

export type PipelineVariablesProps = {
  variables: GitlabVariable[]
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}

export const PipelineVariables = (props: PipelineVariablesProps) => {
  const handleClose = () => props.setOpened(false)

  return (
    <Dialog open={props.opened} onClose={handleClose}>
      <DialogTitle>
        Переменные pipeline
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <Typography
          component="div"
          variant="caption"
          sx={{ paddingBottom: '5px' }}
        >
          {
            'Переменные окружения, которые будут переданы в pipeline развертывания.'
          }
        </Typography>
        {props.variables ? (
          <>
            <Editor
              disabled
              name={'variables'}
              className="PipelineVariables-HighlightedInput-Editor"
              value={props.variables
                .map((variable) => `${variable.key}="${variable.value}"`)
                .join('\n')}
              onValueChange={() => {}}
              highlight={(value) =>
                highlight(value, languages.bash, 'language-bash')
              }
              padding={10}
            />
          </>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PipelineVariables
