import React from 'react'
import { Typography } from '@mui/material'

import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import { ClusterSpec } from '@edp/types'
import UserInteractionPasswordTextField from 'components/UserInteraction/UserInteractionPasswordTextField'

export interface BasicInfoProps {
  cluster: ClusterSpec
  setCluster: React.Dispatch<React.SetStateAction<ClusterSpec>>
  disabled: boolean
}

export const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setCluster((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setOwner: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setCluster((prevState) => ({
      ...prevState,
      owner: value instanceof Function ? value(prevState.owner) : value,
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название Kubernetes кластера."
        value={props.cluster.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор кластера в инфраструктурных сервисах. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        value={props.cluster.slug || ''}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание Kubernetes кластера."
        value={props.cluster.description}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая Kubernetes кластером."
        selected={props.cluster.owner}
        setSelected={setOwner}
      />
      <UserInteractionTextField
        name="address"
        description="API URL"
        helperText="API URL Kubernetes кластера. Указывается в формате https://<ip | domain>:<port>."
        value={props.cluster.address}
        onChange={handleChange}
      />
      <UserInteractionPasswordTextField
        name="token"
        description="Токен"
        helperText="Токен для доступа к Kubernetes кластеру."
        value={props.cluster.token}
        onChange={handleChange}
      />
    </>
  )
}

export default BasicInfo
