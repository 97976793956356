import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { KubernetesNamespaceSpec } from 'types/Managers/KubernetesNamespaces'

export async function createKubernetesNamespace(
  this: ApiClient,
  spec: KubernetesNamespaceSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/kubernetes/namespaces`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getKubernetesNamespaces(
  this: ApiClient
): Promise<KubernetesNamespaceSpec[]> {
  const url = `${this.serverUrl}/kubernetes/namespaces`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getKubernetesNamespace(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/kubernetes/namespaces/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updateKubernetesNamespace(
  this: ApiClient,
  spec: KubernetesNamespaceSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/kubernetes/namespaces/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function deleteKubernetesNamespace(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/kubernetes/namespaces/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
