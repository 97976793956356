import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  ref: {
    value: null,
    rules: 'required',
    options: {
      validateOnChange: true,
    },
  },
  tag: {
    value: null,
  },
  environment: {
    value: null,
  },
  variables: [],
}

export default new MobxReactForm({ fields }, { plugins })
