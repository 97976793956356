import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { observer } from 'mobx-react-lite'
import { ActionVariables } from 'types/Managers/Actions'
import { RepositoryVariable } from 'types/Managers/Repositories'
import ServiceStore from '../store'
import { runInAction } from 'mobx'
import { SiGitlab } from 'react-icons/si'
import { Tooltip, Typography } from '@mui/material'
import './styles.css'

export interface GitlabActionProps {
  actionVariables: ActionVariables
  index: number
  serviceSlug: string
}

export const GitlabPipelineActionLogo = () => {
  return (
    <Tooltip
      arrow
      title={
        'Gitlab pipeline action запускает pipeline в репозитории action с подстановкой переменных, значение которых вы можете задать ниже.'
      }
      placement="top"
    >
      <div>
        <SiGitlab className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const GitlabMRActionLogo = () => {
  return (
    <Tooltip
      arrow
      title={
        'Gitlab MR action создает merge request в целевой репозиторий и добавляет туда файлы из репозитория action с подстановкой переменных, значение которых вы можете задать ниже.'
      }
      placement="top"
    >
      <div>
        <SiGitlab className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const GitlabAction = observer((props: GitlabActionProps) => {
  const handleChangeActionsVariables = (
    index: number,
    variablesIndex: number,
    value: string
  ) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const v = actionsVariables[index].variables as RepositoryVariable[]
      v[variablesIndex].value = value
      actionsVariables[index].variables = v

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  return (
    <>
      <Typography
        variant="subtitle2"
        paddingTop="15px"
        className="Typography-Subtitle2"
      >
        Переменные
      </Typography>
      {props.actionVariables.variables &&
        (props.actionVariables.variables as RepositoryVariable[]).map(
          (variable, variablesIndex) => (
            <UserInteractionTwoTextFields
              key={`action-${props.actionVariables.action.name}-variable-${variablesIndex}`}
              index={variablesIndex}
              first={variable.key}
              second={variable.value || ''}
              helperText={variable.description}
              firstLabel={'Название'}
              secondLabel={'Значение'}
              handleChangeSecond={(e) =>
                handleChangeActionsVariables(
                  props.index,
                  variablesIndex,
                  e.target.value
                )
              }
              firstDisabled
            />
          )
        )}
    </>
  )
})
