import React from 'react'

import { ComponentPage } from 'components/ComponentPage'
import { AddInfraServicePanel } from './panels/AddInfraServicePanel'
import { ListInfraServicePanel } from './panels/ListInfraServicePanel'

const InfrastructureServices = () => {
  const panels = [
    {
      name: 'Список инфраструктурных сервисов',
      index: 0,
      children: <ListInfraServicePanel />,
    },
    {
      name: 'Добавить инфраструктурный сервис',
      index: 1,
      children: <AddInfraServicePanel />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default InfrastructureServices
