import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { ClusterSpec } from '@edp/types'

const useFetchCluster = (uuid?: string) => {
  const [cluster, setCluster] = useState<ClusterSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchCluster = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getCluster(uuid)
          setCluster(data)
        } catch (e) {
          console.error('Ошибка при получении кластера:', e)
        }
      }
    }

    fetchCluster()
  }, [uuid])

  return cluster
}

export default useFetchCluster
