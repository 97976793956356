import type { EntrySnapshot } from '../utils/types'
import { Typography, Box } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AdjustIcon from '@mui/icons-material/Adjust'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { v4 as uuidv4 } from 'uuid'
import { MovedState } from 'types/TechRadar'

export type TechRadarTimelineProps = {
  timeline?: EntrySnapshot[]
}

const DateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short',
}

function DataGridTitle() {
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10px',
        marginBottom: '10px',
      }}
    >
      <Typography
        variant="body1"
        component="div"
        sx={{
          fontWeight: '600',
          textAlign: 'center',
        }}
      >
        История
      </Typography>
    </Box>
  )
}

export const TechRadarTimeline = (
  props: TechRadarTimelineProps
): JSX.Element => {
  const { timeline } = props

  const columns: GridColDef[] = [
    {
      field: 'moved',
      headerName: 'Изменение',
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.moved === MovedState.Up ? <ArrowUpwardIcon /> : ''}
            {params.row.moved === MovedState.Down ? <ArrowDownwardIcon /> : ''}
            {params.row.moved === MovedState.NoChange ? <AdjustIcon /> : ''}
          </>
        )
      },
    },
    {
      field: 'ring',
      headerName: 'Cтатус',
      minWidth: 100,
    },
    {
      field: 'date',
      headerName: 'Дата',
      minWidth: 150,
    },
    {
      field: 'description',
      headerName: 'Описание',
      minWidth: 300,
    },
  ]

  const rows = timeline?.map((timeEntry: EntrySnapshot) => {
    return {
      moved: timeEntry.moved,
      ring: timeEntry.ring.name,
      date: timeEntry.date.toLocaleString('ru-RU', DateOptions),
      description: timeEntry.description,
    }
  })

  return (
    <DataGrid
      getRowId={() => uuidv4()}
      columns={columns}
      rows={rows || []}
      pageSize={4}
      rowsPerPageOptions={[4]}
      autoHeight={true}
      autoPageSize
      getRowHeight={() => 'auto'}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      components={{ Toolbar: DataGridTitle }}
    />
  )
}

export default TechRadarTimeline
