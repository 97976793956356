import { MdOutlineWebhook } from 'react-icons/md'
import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ActionVariables, CustomWebhookVariables } from 'types/Managers/Actions'

import './styles.css'
import CustomWebhookAction from 'pages/components/Actions/CustomWebhookAction/CustomWebhookAction'
import { CustomWebhookActionForm } from 'pages/components/Actions/CustomWebhookAction/store'
import { reaction, runInAction } from 'mobx'
import ServiceStore from '../store'
import { useEffect } from 'react'

export interface CustomWebhookProps {
  actionVariables: ActionVariables
  index: number
}

export const CustomWebhookLogo = () => {
  return (
    <Tooltip
      arrow
      title={'Custom webhook action отправляет webhook на указанный URL.'}
      placement="top"
    >
      <div>
        <MdOutlineWebhook className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const CustomWebhook = observer((props: CustomWebhookProps) => {
  const form = CustomWebhookActionForm()
  form.set(props.actionVariables.variables)

  const setActionsVariables = (index: number) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const webhook: CustomWebhookVariables = {
        url: form.$('url').value,
        method: form.$('method').value,
        payload: form.$('payload').value,
        headers: form.$('headers').value,
      }

      actionsVariables[index].variables = webhook

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  useEffect(() => {
    const disposer = reaction(
      () => ({
        webhook: form.$changed,
      }),
      () => {
        setActionsVariables(props.index)
      }
    )

    return () => disposer()
  }, [form, props.index])

  return <CustomWebhookAction form={form} />
})
