import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'

export async function getOrganizationDashboard(
  this: ApiClient
): Promise<string> {
  const url = `${this.serverUrl}/organization/dashboard`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
