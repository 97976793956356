import { jwtDecode } from 'jwt-decode'
import { ProfileSpec } from 'types/LocalAccounts'
import { getProfile, setProfile } from './utils'

export function checkExistLocalUserToken(): boolean {
  const token = sessionStorage.getItem(`local.user`)

  if (token === null) {
    return false
  } else {
    return true
  }
}

export function setLocalAuthProfile(jwt: string) {
  const decoded = jwtDecode(jwt) as ProfileSpec
  setProfile(decoded)
}

// Local auth token
export function setLocalAuthToken(token: string) {
  localStorage.setItem('local.user', token)
  setLocalAuthProfile(token)
}

export function getLocalAuthToken() {
  return localStorage.getItem('local.user')
}

export function deleteLocalAuthToken() {
  localStorage.removeItem('local.user')
}

export function checkExpiredLocalAuthToken(): boolean {
  const profile = getProfile()
  const now = Math.floor(Date.now() / 1000)

  if (profile.exp) {
    if (profile.exp < now) {
      return true
    }
    return false
  }

  return false
}
