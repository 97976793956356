import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { KubernetesNamespaceSpec } from 'types/Managers/KubernetesNamespaces'

const useFetchKubernetesNamespace = (uuid?: string) => {
  const [kubernetesNamespace, setKubernetesNamespace] =
    useState<KubernetesNamespaceSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchKubernetesNamespace = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getKubernetesNamespace(uuid)
          setKubernetesNamespace(data)
        } catch (e) {
          console.error('Ошибка при получении данных:', e)
        }
      }
    }

    fetchKubernetesNamespace()
  }, [uuid])

  return kubernetesNamespace
}

export default useFetchKubernetesNamespace
