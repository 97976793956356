import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { KafkaClusterSpec } from 'types/Managers/KafkaClusters'

const useFetchKafkaCluster = (uuid?: string) => {
  const [kafkaCluster, setKafkaCluster] = useState<KafkaClusterSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchKafkaCluster = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getKafkaCluster(uuid)
          setKafkaCluster(data)
        } catch (e) {
          console.error('Ошибка при получении кластера Kafka:', e)
        }
      }
    }

    fetchKafkaCluster()
  }, [uuid])

  return kafkaCluster
}

export default useFetchKafkaCluster
