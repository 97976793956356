export enum GitLeaksLockStatus {
  NotFound = 'NotFound',
  Pending = 'Pending',
  Running = 'Running',
}

export enum GitSecretStatus {
  Unfixed = 'Не исправлено',
  Fixed = 'Исправлено',
  FalsePositive = 'Ложное',
}

export interface GitSecretScanStatus {
  status: string
}

export interface GitSecretsReqSpec {
  uuid: string
  wait: boolean
}

export interface GitSecretStatusUpdateReqSpec {
  id: number
  status: string
}

export interface GitSecretSpec {
  id?: number
  status: string
  repository?: number
  repositoryUrl: string
  secret: GitLeaksProblem
}

export interface GitLeaksProblem {
  Description: string
  StartLine: number
  EndLine: number
  StartColumn: number
  EndColumn: number
  Match: string
  Secret: string
  File: string
  SymlinkFile: string
  Commit: string
  Entropy: string
  Author: string
  Email: string
  Date: string
  Message: string
  Tags: Array<string>
  RuleID: string
  Fingerprint: string
}

export interface GitLeaksLockSpec {
  repositoryId: number
  status: string
  process: number
  createdTime?: Date
  updatedAt?: Date
}
