import { observer } from 'mobx-react-lite'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { IconButton, Typography } from '@mui/material'
import './styles.css'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import { runInAction } from 'mobx'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Form from 'mobx-react-form'
import {
  AlfastrahBlocks,
  AlfastrahKafkaEnvironments,
  AlfastrahKafkaTopicAccount,
  AlfastrahKafkaTopicAccountAccessTypes,
  AlfastrahKafkaTopicAccountSeparators,
} from 'types/Clients/alfastrah'
import UserInteractionCustomSelectUUID from 'components/UserInteraction/UserInteractionCustomSelectUUID'
import { useEffect } from 'react'

interface KafkaTopicActionProps {
  form: Form
  validate: boolean
}

export const KafkaTopicAction = observer((props: KafkaTopicActionProps) => {
  const handleAddAccountsField = () => {
    let a: AlfastrahKafkaTopicAccount[] = [...props.form.$('accounts').value]

    runInAction(() => {
      const emptyAccount: AlfastrahKafkaTopicAccount = {
        login: '',
        access: 'read',
        ip: '',
        create: true,
      }
      a = [...a, emptyAccount]
      props.form.$('accounts').set(a)
    })
  }

  const handleRemoveAccountsField = () => {
    if (props.form.$('accounts').value.length > 1) {
      runInAction(() => {
        const a: AlfastrahKafkaTopicAccount[] = [
          ...props.form.$('accounts').value,
        ]
        a.pop()
        props.form.$('accounts').set(a)
      })
    }
  }

  const handleChangeAccountsLogin = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const a = [...props.form.$('accounts').value]
      a[index].login = event.target.value
      props.form.$('accounts').set(a)
    })
  }

  const handleChangeAccountsAccess = (index: number, event: any) => {
    runInAction(() => {
      const a = [...props.form.$('accounts').value]
      a[index].access = event
      props.form.$('accounts').set(a)
    })
  }

  const handleChangeAccountsIP = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const a = [...props.form.$('accounts').value]
      a[index].ip = event.target.value
      props.form.$('accounts').set(a)
    })
  }

  const changeTopicName = async () => {
    try {
      props.form
        .$('topic')
        .set(
          `${props.form.$('block').value}${props.form.$('separator').value}${
            props.form.$('description').value
          }${props.form.$('separator').value}${props.form.$('entity').value}${
            props.form.$('separator').value
          }${props.form.$('environment').value}`
        )
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    changeTopicName()
  }, [
    props.form.$('block').value,
    props.form.$('separator').value,
    props.form.$('description').value,
    props.form.$('entity').value,
    props.form.$('environment').value,
  ])

  return (
    <>
      <Typography
        variant="subtitle2"
        className="Typography-Subtitle2"
        paddingTop="15px"
      >
        Конфигурация Kafka топика
      </Typography>
      <Typography
        variant="subtitle2"
        paddingTop="15px"
        className="Typography-Subtitle2"
      >
        Обязательные параметры
      </Typography>
      <UserInteractionCustomSelectUUID
        name="block"
        options={AlfastrahBlocks}
        description="Блок"
        helperText="К какому блоку будет относиться топик."
        error={props.validate ? !props.form.$('block').isValid : false}
        selected={props.form.$('block').value}
        setSelected={(value) => props.form.$('block').set(value)}
      />
      <UserInteractionTextField
        name="description"
        description="Описание продукта/функции"
        helperText="Краткое описание того, что/кто/откуда/куда. Например 1c-boss-test-data, означающий передачу из 1С в Босс тестовых данных."
        error={props.validate ? !props.form.$('description').isValid : false}
        {...props.form.$('description').bind()}
      />
      <UserInteractionTextField
        name="entity"
        description="Публикуемая сущность"
        helperText="Публикуемая сущность. Например, json, avro и т.д."
        error={props.validate ? !props.form.$('entity').isValid : false}
        {...props.form.$('entity').bind()}
      />
      <UserInteractionCustomSelect
        name="environment"
        options={AlfastrahKafkaEnvironments}
        description="Окружение"
        helperText="Среда, где необходимо создать топик. Prod - продуктив, test - тестовая среда, находящаяся в Я.Облаке."
        error={props.validate ? !props.form.$('environment').isValid : false}
        selected={props.form.$('environment').value}
        setSelected={(value) => props.form.$('environment').set(value)}
      />
      <UserInteractionTextField
        name="topic"
        disabled
        description="Название топика"
        helperText="Итоговое название топика."
        value={props.form.$('topic').value}
      />
      <Typography
        variant="subtitle2"
        paddingTop="15px"
        className="Typography-Subtitle2"
      >
        Аккаунты
      </Typography>
      {props.form.$('accounts').value &&
        props.form
          .$('accounts')
          .value.map((account: AlfastrahKafkaTopicAccount, index: number) => (
            <div key={`kafka-topic-account-${index}`}>
              <UserInteractionTextField
                key={`kafka-topic-account-login-${index}`}
                name={`kafka-topic-account-login-${index}`}
                description={'Название аккаунта'}
                helperText={
                  'Название аккаунта. Поле обязательно для заполнения.'
                }
                value={account.login}
                onChange={(event) => handleChangeAccountsLogin(index, event)}
              />
              <UserInteractionCustomSelect
                key={`kafka-topic-account-access-${index}`}
                name={`kafka-topic-account-access-${index}`}
                options={AlfastrahKafkaTopicAccountAccessTypes}
                description="Уровень доступа"
                helperText="Уровень доступа."
                selected={account.access}
                setSelected={(event) =>
                  handleChangeAccountsAccess(index, event)
                }
              />
              <UserInteractionTextField
                key={`kafka-topic-account-ip-${index}`}
                name={`kafka-topic-account-ip-${index}`}
                description={'IP-адрес'}
                helperText={
                  'IP-адрес. Если поле останется пустым, то доступ к топику не будет ограничен.'
                }
                value={account.ip || ''}
                onChange={(event) => handleChangeAccountsIP(index, event)}
              />
            </div>
          ))}
      <IconButton aria-label="add" onClick={handleAddAccountsField}>
        <AddIcon />
      </IconButton>
      <IconButton aria-label="remove" onClick={handleRemoveAccountsField}>
        <RemoveIcon />
      </IconButton>
      <Typography
        variant="subtitle2"
        paddingTop="15px"
        className="Typography-Subtitle2"
      >
        Опциональные параметры
      </Typography>
      <UserInteractionCustomSelect
        name="separator"
        options={AlfastrahKafkaTopicAccountSeparators}
        description="Сепаратор"
        helperText="Каким сепаратором будут разделены опции для формирования названия топика. Значение по умолчанию: '-'."
        error={!props.form.$('separator').isValid}
        selected={props.form.$('separator').value}
        setSelected={(value) => props.form.$('separator').set(value)}
      />
      <UserInteractionTextField
        name="partition"
        description="Количество партиций"
        helperText="По умолчанию выставляется максимально возможное исходя из конфигурации серверов Kafka. Количество партиций влияет на надёжность и производительность топика, но с увеличением количества партиций повышается риск нарушения очередности, либо повторной доставки сообщения. Максимальные значения: prod - до 6, test - до 10"
        error={!props.form.$('partition').isValid}
        {...props.form.$('partition').bind()}
      />
      <UserInteractionTextField
        name="time"
        description="Срок хранения"
        helperText="Срок хранения данных для топика (по умолчанию 3 дня). Указывается в днях. Значение от 1 до 180."
        error={!props.form.$('time').isValid}
        {...props.form.$('time').bind()}
      />
      <UserInteractionTextField
        name="messages"
        description="Сообщений в день"
        helperText="Количество сообщений в день передаваемых через топик (с учётом прогноза роста нагрузки на 1 год вперёд). Например, сейчас ожидается, что за день будет проходить 1 миллион сообщений, но за год нагрузка возрастёт в два раза, то есть прогноз по количеству сообщений через год – 2 миллиона в день -> в запросе указывается 2 миллиона. Значение от 1 до 100000000"
        error={!props.form.$('messages').isValid}
        {...props.form.$('messages').bind()}
      />
      <UserInteractionTextField
        name="subscribers"
        description="Подписчиков на топик"
        helperText="Примерное количество подписчиков на топик. Значение от 1 до 100"
        error={!props.form.$('subscribers').isValid}
        {...props.form.$('subscribers').bind()}
      />
      <UserInteractionTextField
        name="size"
        description="Размер одного сообщения"
        helperText="Средний размер одного сообщения в Кб (для расчёта объёма данных для резервирования под топик). Максимальное значение 3072"
        error={!props.form.$('size').isValid}
        {...props.form.$('size').bind()}
      />
      <UserInteractionTextField
        name="comments"
        description="Комментарий"
        helperText="Укажите в комментарии ваш email."
        multiline
        rows={6}
        height={'150px'}
        {...props.form.$('comments').bind()}
      />
    </>
  )
})

export default KafkaTopicAction
