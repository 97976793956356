import { LinearProgress, Link } from '@mui/material'
import Alert from '@mui/material/Alert'
import { useAsync } from 'react-use'
import { getElapsedTime } from '../../utils'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'
import { GitlabIssueObject } from 'types/Controllers/Gitlab'

export const DenseTable = ({
  issuesObjects,
}: {
  issuesObjects: GitlabIssueObject[]
}) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params) => (
        <Link href={params.row.web_url} target="_blank" rel="noreferrer">
          {params.row.id}
        </Link>
      ),
    },
    { field: 'status', headerName: 'Статус' },
    { field: 'author', headerName: 'Автор' },
    { field: 'title', headerName: 'Название', minWidth: 200 },
    { field: 'created_at', headerName: 'Создана', minWidth: 150 },
  ]

  const rows = issuesObjects?.map((issue: GitlabIssueObject) => {
    return {
      id: issue.id,
      status: issue.state,
      author: issue.author.username,
      title: issue.title,
      web_url: issue.web_url,
      created_at: getElapsedTime(issue.created_at),
    }
  })

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
    />
  )
}

export const IssuesTable = () => {
  const apiClient = new ApiClient()
  const { uuid } = useParams()

  const { value, loading, error } = useAsync(async (): Promise<{
    data: GitlabIssueObject[]
  }> => {
    const gitlabIssuesObject = await apiClient.getIssuesSummary(uuid || '')
    const data = gitlabIssuesObject?.getIssuesData
    const renderData: any = { data }

    return renderData
  }, [])

  if (loading) {
    return <LinearProgress />
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return <DenseTable issuesObjects={value?.data || []} />
}
