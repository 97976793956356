import {
  CustomSelectComponent,
  CustomSelectProps,
} from '../CustomSelectComponent/CustomSelectComponent'
import { CredentialsType } from 'types/Credentials'

export interface CredentialsTypeSelectProps extends CustomSelectProps {}

export const CredentialsTypeSelect = (props: CredentialsTypeSelectProps) => {
  const options = [
    CredentialsType.LOGIN_AND_PASSWORD,
    CredentialsType.SSH,
    CredentialsType.SSH_WITH_PASSWORD,
  ]

  return <CustomSelectComponent options={options} {...props} />
}

export default CredentialsTypeSelect
