import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectProps,
  CustomSelectComponentUUIDOption,
} from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'
import { ClusterSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'

export interface ClusterSelectProps extends CustomSelectProps {}

export const ClusterSelect = (props: ClusterSelectProps) => {
  const [clusters, setClusters] = useState<ClusterSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getClusters = async () => {
      try {
        const data = await apiClient.getClusters()
        setClusters(data)
      } catch (e) {
        console.log(e)
      }
    }

    getClusters()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    clusters?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default ClusterSelect
