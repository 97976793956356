import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { EnvironmentSpec } from 'types/Managers/Environments'

const useFetchEnvironment = (uuid?: string) => {
  const [environment, setEnvironment] = useState<EnvironmentSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchEnvironment = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getEnvironment(uuid)
          setEnvironment(data)
        } catch (e) {
          console.error('Ошибка при получении окружения:', e)
        }
      }
    }

    fetchEnvironment()
  }, [uuid])

  return environment
}

export default useFetchEnvironment
