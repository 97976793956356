import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { RepositorySpec } from 'types/Managers/Repositories'

const useFetchRepository = (uuid?: string) => {
  const [repository, setRepository] = useState<RepositorySpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchRepository = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getRepository(uuid)
          setRepository(data)
        } catch (e) {
          console.error('Ошибка при получении данных:', e)
        }
      }
    }

    fetchRepository()
  }, [uuid])

  return repository
}

export default useFetchRepository
