export interface InfraServiceConfigurationSpec {
  name: string
  description?: string
  type: string
  url: string
  token: string
  username?: string
  namespace?: string
  startPortRange?: number
  endPortRange?: number
  labelK8S?: string
  roleID?: string
  secretID?: string
}

export const enum EntityAction {
  Create,
  Update,
}
