import { Box, Grid } from '@mui/material'
import { ServiceTemplate } from 'types/Managers/ServiceTemplates'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from 'components/CustomButton'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import useFetchServiceTemplate from 'hooks/useFetchServiceTemplate'
import { NothingFoundPage } from '@edp/core-components-frontend'

export const ServiceTemplatesPage = () => {
  const [serviceTemplates, setServiceTemplates] = useState<ServiceTemplate[]>(
    []
  )
  const [filteredServiceTemplates, setFilteredServiceTemplates] = useState<
    ServiceTemplate[]
  >([])
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [serviceTemplateToDelete, setServiceTemplateToDelete] =
    useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getServiceTemplates = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getServiceTemplates()
      setServiceTemplates(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getServiceTemplates()
  }, [])

  useEffect(() => {
    const s: ServiceTemplate[] = []

    if (serviceTemplates && owner !== '') {
      for (const serviceTemplate of serviceTemplates) {
        if (serviceTemplate.owner === owner) {
          s.push(serviceTemplate)
        }
      }
      setFilteredServiceTemplates(s)
    }

    if (owner === '') {
      setFilteredServiceTemplates(serviceTemplates)
    }
  }, [serviceTemplates, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setServiceTemplateToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient
        .deleteServiceTemplate(serviceTemplateToDelete)
        .then(() => {
          setDeleted(true)
          getServiceTemplates()
          setDeletionWindowOpened(false)
        })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const serviceTemplateToDeleteSpec = useFetchServiceTemplate(
    serviceTemplateToDelete
  )

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/components/service-templates/create'}
          >
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {filteredServiceTemplates?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новый шаблон сервиса, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredServiceTemplates?.map((serviceTemplate, index) => (
          <ComponentCard
            key={index}
            name={serviceTemplate.name}
            uuid={serviceTemplate.uuid}
            description={serviceTemplate.description}
            options={[
              {
                name: 'Идентификатор',
                value: serviceTemplate.slug,
              },
              {
                name: 'Владелец',
                value: serviceTemplate.ownerRef?.name,
                isUrl: true,
                externalUrl: false,
                url: `/organization/teams/${serviceTemplate.ownerRef?.uuid}`,
              },
              {
                name: 'Шаблонный репозиторий в EDP',
                isUrl: true,
                value: serviceTemplate.templateRepositoryRef?.name,
                url: `/components/repositories/${serviceTemplate.templateRepositoryRef?.uuid}`,
                externalUrl: false,
              },
              {
                name: 'Шаблонный репозиторий в Gitlab',
                isUrl: true,
                value: serviceTemplate.templateRepositoryRef?.name,
                url: `${serviceTemplate.templateRepositoryRef?.url}`,
                externalUrl: true,
              },
            ]}
            linkOpen={`/components/service-templates/${serviceTemplate.uuid}`}
            linkUpdate={`/components/service-templates/update/${serviceTemplate.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление шаблона сервиса'}
          text={`Шаблон сервиса ${serviceTemplateToDeleteSpec?.name} будет удален.`}
          spec={serviceTemplateToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Шаблон сервиса ${serviceTemplateToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении шаблона сервиса ${serviceTemplateToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default ServiceTemplatesPage
