import { TeamSpec } from '@edp/types'
import { AlfastrahKafkaTopic } from 'types/Clients/alfastrah'
import { KrakendEndpointSpec } from 'types/Controllers/Krakend'
import { MattermostPost, MattermostWebhook } from 'types/Controllers/Mattermost'
import { GitlabVariable } from '..'
import { RepositorySpec, RepositoryVariable } from './Repositories'

export enum ActionType {
  GitlabPipeline = 'Gitlab pipeline',
  GitlabMR = 'Gitlab MR',
  GitlabGroupLevelVariables = 'Gitlab group-level variables',
  VaultSecret = 'Vault secret',
  KrakendEndpoint = 'KrakenD endpoint',
  KafkaTopic = 'Kafka topic',
  MattermostPost = 'Mattermost post',
  MattermostWebhook = 'Mattermost webhook',
  CustomWebhook = 'Custom webhook',
}

export interface ActionVariables {
  action: Action
  variables?:
    | RepositoryVariable[]
    | VaultSecretVariables
    | KrakendEndpointSpec
    | AlfastrahKafkaTopic
    | GitlabGroupLevelVariables
    | MattermostPostVariables
    | MattermostWebhookVariables
    | CustomWebhookVariables
}

export const CustomWebhookMethods = ['POST', 'PATH', 'PUT', 'DELETE', 'GET']

export interface CustomWebhookHeader {
  name: string
  value: string
}

export interface CustomWebhookVariables {
  url: string
  method: string
  payload: string
  headers: CustomWebhookHeader[]
}

export interface MattermostWebhookVariables {
  url: string
  webhook: MattermostWebhook
}

export interface GitlabGroupLevelVariables {
  group: string
  groupLevelVariables: GitlabVariable[]
}

export interface MattermostPostVariables {
  instance: string
  post: MattermostPost
}

export interface VaultSecretVariables {
  path: string
  name: string
  data: {
    key: string
    value: string
  }[]
}

export interface Action {
  uuid?: string
  type: ActionType
  enabled: boolean
  name: string
  slug: string
  description: string
  owner: string
  repository?: string
  targetRepository?: string
  sourceBranch?: string
  targetBranch?: string
  targetFile?: string
  ownerRef?: TeamSpec
  repositoryRef?: RepositorySpec
  targetRepositoryRef?: RepositorySpec
  vault?: {
    secret?: VaultSecretVariables
  }
  krakend?: {
    endpoint?: KrakendEndpointSpec
  }
  kafka?: {
    topic?: AlfastrahKafkaTopic
  }
  gitlab?: {
    groupLevelVariables?: GitlabGroupLevelVariables
  }
  mattermost?: {
    post?: MattermostPostVariables
    webhook?: MattermostWebhookVariables
  }
  customWebhook?: CustomWebhookVariables
}
