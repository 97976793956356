const colorConfigs = {
  sidebar: {
    logobg: '#041233',
    bg: '#F8F8FE',
    color: '#333238',
    hoverBg: '#E7E7ED',
    activeBg: '#E7E7ED',
  },
  topbar: {
    bg: '#fff',
    color: '#000',
  },
  mainBg: '#fff',
}

export default colorConfigs
