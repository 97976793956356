import React from 'react'
import { Typography } from '@mui/material'

import UserInteractionSlider from 'components/UserInteraction/UserInteractionSlider'
import { TeamSpec } from '@edp/types'
import prettyBytes from 'pretty-bytes'

export interface ResourcesProps {
  disabled: boolean
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

export const Resources = (props: ResourcesProps) => {
  const magicNumber16MegabytesToBytes = 16777216
  const maxCpu = 100000
  const maxMemory = 137438953472

  function valueLabelFormatCpu(value: number) {
    return `${value}m`
  }

  function valueLabelFormatMemory(value: number) {
    return prettyBytes(value, {
      binary: true,
    })
  }

  const marksCpu = [
    {
      value: 0,
      label: '0',
    },
    {
      value: maxCpu,
      label: valueLabelFormatCpu(maxCpu),
    },
  ]

  const marksMemory = [
    {
      value: 0,
      label: '0',
    },
    {
      value: maxMemory,
      label: valueLabelFormatMemory(maxMemory),
    },
  ]

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Ресурсы Kubernetes
      </Typography>
      <UserInteractionSlider
        description="CPU"
        helperText="Ядра CPU, выделяемые команде в кластерах Kubernetes."
        name="cpu"
        min={0}
        step={100}
        max={maxCpu}
        marks={marksCpu}
        valueLabelFormat={valueLabelFormatCpu}
        value={props.team.quotas.kubernetes.limitsCpu || 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            quotas: {
              ...props.team.quotas,
              kubernetes: {
                ...props.team.quotas.kubernetes,
                limitsCpu: Number(event.target.value),
              },
            },
          })
        }
      />
      <UserInteractionSlider
        description="Memory"
        helperText="Оперативная память, выделяемая команде в кластерах Kubernetes."
        name="memory"
        min={0}
        step={magicNumber16MegabytesToBytes}
        max={maxMemory}
        marks={marksMemory}
        valueLabelFormat={valueLabelFormatMemory}
        value={props.team.quotas.kubernetes.limitsMemory || 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            quotas: {
              ...props.team.quotas,
              kubernetes: {
                ...props.team.quotas.kubernetes,
                limitsMemory: Number(event.target.value),
              },
            },
          })
        }
      />
    </>
  )
}

export default Resources
