import './styles.css'

export type TechRadarFooterProps = {
  x: number
  y: number
}

const TechRadarFooter = (props: TechRadarFooterProps): JSX.Element => {
  const { x, y } = props

  return (
    <text
      data-testid="radar-footer"
      transform={`translate(${x}, ${y})`}
      className={'TechRadar-RadarFooter-Text'}
    >
      {'▲ перемещен вверх\u00a0\u00a0\u00a0\u00a0\u00a0▼ перемещен вниз'}
    </text>
  )
}

export default TechRadarFooter
