import { Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { runInAction } from 'mobx'
import Form from 'mobx-react-form'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { GitlabGroup, GitlabVariable } from 'types/index'
import './styles.css'

interface GitlabGroupLevelVariablesActionProps {
  form: Form
  validate: boolean
  disabled?: boolean
}

export const GitlabGroupLevelVariablesAction = observer(
  (props: GitlabGroupLevelVariablesActionProps) => {
    const [gitlabGroups, setGitlabGroups] = useState<GitlabGroup[]>([])

    const apiClient = new ApiClient()

    const getGitlabGroups = async () => {
      try {
        const groups = await apiClient.getGitlabGroups()
        setGitlabGroups(groups)
      } catch (e) {
        console.error('Ошибка при получении данных:', e)
      }
    }

    useEffect(() => {
      getGitlabGroups()
    }, [])

    const handleAddGroupLevelVariables = () => {
      let v: GitlabVariable[] = [...props.form.$('groupLevelVariables').value]

      runInAction(() => {
        const emptyGroupLevelVariable: GitlabVariable = {
          key: '',
          value: '',
        }
        v = [...v, emptyGroupLevelVariable]
        props.form.$('groupLevelVariables').set(v)
      })
    }

    const handleRemoveGroupLevelVariables = (index: number) => {
      runInAction(() => {
        const v: GitlabVariable[] = [
          ...props.form.$('groupLevelVariables').value,
        ]
        v.splice(index, 1)
        props.form.$('groupLevelVariables').set(v)
      })
    }

    const handleChangeGroupLevelVariablesKey = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      runInAction(() => {
        const a = [...props.form.$('groupLevelVariables').value]
        a[index].key = event.target.value
        props.form.$('groupLevelVariables').set(a)
      })
    }

    const handleChangeGroupLevelVariablesValue = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      runInAction(() => {
        const a = [...props.form.$('groupLevelVariables').value]
        a[index].value = event.target.value
        props.form.$('groupLevelVariables').set(a)
      })
    }

    return (
      <>
        <Typography
          variant="subtitle2"
          className="Typography-Subtitle2"
          paddingTop="15px"
        >
          Gitlab group-level variables
        </Typography>
        <UserInteractionCustomSelect
          name="group"
          disabled={props.disabled}
          description="Группа репозиториев"
          helperText="Группа репозиториев в Gitlab в которой будут созданы group-level переменные."
          options={gitlabGroups.map((g) => g.full_path)}
          selected={props.form.$('group').value}
          setSelected={(value) => props.form.$('group').set(value)}
        />
        <div>
          <Typography
            variant="subtitle2"
            className="Typography-Subtitle2"
            paddingTop="30px"
            paddingBottom="15px"
          >
            Переменные
          </Typography>
          {props.form.$('groupLevelVariables').value &&
            props.form
              .$('groupLevelVariables')
              .value.map((variable: GitlabVariable, index: number) => (
                <div key={`group-level-variable-${index}`}>
                  <UserInteractionTwoTextFields
                    key={`gitlab-project-level-variable-${index}`}
                    index={index}
                    first={variable.key}
                    second={variable.value}
                    firstLabel={'Название'}
                    secondLabel={'Значение'}
                    helperText={variable.description}
                    removeButton
                    handleRemove={() => handleRemoveGroupLevelVariables(index)}
                    handleChangeFirst={(event) =>
                      handleChangeGroupLevelVariablesKey(index, event)
                    }
                    handleChangeSecond={(event) =>
                      handleChangeGroupLevelVariablesValue(index, event)
                    }
                  />
                </div>
              ))}
          <UserInteractionAddButton handleAdd={handleAddGroupLevelVariables} />
        </div>
      </>
    )
  }
)

export default GitlabGroupLevelVariablesAction
