import { SiApachekafka } from 'react-icons/si'
import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ActionVariables } from 'types/Managers/Actions'
import './styles.css'
import KafkaTopicAction from 'pages/components/Actions/KafkaTopicAction/KafkaTopicAction'
import { CreateKafkaTopicActionForm } from 'pages/components/Actions/KafkaTopicAction/store'
import { reaction, runInAction } from 'mobx'
import ServiceStore from '../store'
import { useEffect } from 'react'
import { AlfastrahKafkaTopic } from 'types/Clients/alfastrah'

export interface KafkaTopicProps {
  actionVariables: ActionVariables
  index: number
}

export const KafkaTopicLogo = () => {
  return (
    <Tooltip
      arrow
      title={'Kafka topic action создает заявку в SD на создание топика'}
      placement="top"
    >
      <div>
        <SiApachekafka className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const KafkaTopic = observer((props: KafkaTopicProps) => {
  const form = CreateKafkaTopicActionForm()
  form.set(props.actionVariables.variables)

  const setActionsVariables = (index: number) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const topic: AlfastrahKafkaTopic = {
        block: form.$('block').value,
        topic: form.$('topic').value,
        environment: form.$('environment').value,
        description: form.$('description').value,
        entity: form.$('entity').value,
        accounts: form.$('accounts').value || [],
        separator: form.$('separator').value,
        partition: form.$('partition').value,
        time: form.$('time').value,
        messages: form.$('messages').value,
        subscribers: form.$('subscribers').value,
        size: form.$('size').value,
        comments: form.$('comments').value,
      }

      actionsVariables[index].variables = topic

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  useEffect(() => {
    const disposer = reaction(
      () => ({
        topic: form.$changed,
      }),
      () => {
        setActionsVariables(props.index)
      }
    )

    return () => disposer()
  }, [form, props.index])

  return <KafkaTopicAction form={form} validate={true} />
})
