import CustomPieChart from 'components/CustomPieChart/CustomPieChart'
import './styles.css'

export type ResourcesChartDropdownProps = {
  resource: string
  loading?: boolean
  overall: {
    name: string
    value: number
    color: string
  }[]
}

export const ResourcesChartDropdown = (props: ResourcesChartDropdownProps) => {
  return (
    <CustomPieChart
      data={props.overall}
      resource={props.resource}
      loading={props.loading}
    />
  )
}
