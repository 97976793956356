import { LinearProgress } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { NothingFoundPage } from '@edp/core-components-frontend'

export interface ReadmePanelProps {
  uuid: string
}

export const ReadmePanel = (props: ReadmePanelProps) => {
  const [markdown, setMarkdown] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const apiClient = new ApiClient()

  useEffect(() => {
    const getServiceReadme = async () => {
      try {
        setLoading(true)
        const data = await apiClient.getServiceReadme(props.uuid)
        setMarkdown(data)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    getServiceReadme()
  }, [])

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && !markdown && (
        <NothingFoundPage text="Попробуйте добавить файл README.md в репозиторий сервиса в ветку по умолчанию." />
      )}
      {!loading && markdown && (
        <Markdown
          remarkPlugins={[[remarkGfm]]}
          // eslint-disable-next-line react/no-children-prop
          children={markdown}
          components={{
            code(props) {
              // eslint-disable-next-line react/prop-types
              const { children, className, ...rest } = props
              const match = /language-(\w+)/.exec(className || '')
              const language = match ? match[1] : 'sh'
              return (
                <SyntaxHighlighter
                  {...rest}
                  PreTag="div"
                  // eslint-disable-next-line react/no-children-prop
                  children={String(children).replace(/\n$/, '')}
                  language={language}
                  style={prism}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  ref={(_ref) => {}}
                />
              )
            },
          }}
        />
      )}
    </>
  )
}

export default ReadmePanel
