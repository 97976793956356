import { Grid } from '@mui/material'
import { ContributorsCard, LanguagesCard } from './components'
import Deploy from '../Deploy/Deploy'
import Build from '../Build/Build'
import CreateTag from '../CreateTag/CreateTag'
import { PipelinesCard } from './components/PipelinesTable/PipelinesCard'
import { TagsCard } from './components/TagsTable/TagsCard'
import { MergeRequestsCard } from './components/MergeRequestsTable/MergeRequestsCard'
import { IssuesCard } from './components/IssuesTable/IssuesCard'

export const GitlabPanel = () => (
  <>
    <Grid container className="Grid-Panel">
      <Grid item>
        <CreateTag />
      </Grid>
      <Grid item>
        <Build />
      </Grid>
      <Grid item>
        <Deploy />
      </Grid>
    </Grid>
    <Grid container spacing={6} direction="row" alignItems="stretch">
      <Grid item sm={12} md={6}>
        <ContributorsCard />
      </Grid>
      <Grid item sm={12} md={6}>
        <LanguagesCard />
      </Grid>
      <Grid item md={12}>
        <PipelinesCard />
      </Grid>
      <Grid item md={12}>
        <TagsCard />
      </Grid>
      <Grid item md={12}>
        <MergeRequestsCard />
      </Grid>
      <Grid item md={12}>
        <IssuesCard />
      </Grid>
    </Grid>
  </>
)
