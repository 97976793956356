import React from 'react'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

import { SelectChangeEvent } from '@mui/material'

export type CustomSelectProps = {
  title?: string
  options?: string[]
  height?: string
  width?: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
  error?: boolean
  loading?: boolean
}

export const CustomSelectComponent = (props: CustomSelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    props.setSelected(event.target.value)
  }

  return (
    <FormControl
      size="small"
      sx={{
        width: props.width || 360,
      }}
    >
      <InputLabel id="select-helper-label">{props.title}</InputLabel>
      <Select
        value={props.selected}
        onChange={handleChange}
        label={props.title}
        disabled={props.disabled}
        error={props.error}
        sx={{
          fontSize: '15px',
        }}
      >
        {props.loading ? (
          <MenuItem>
            <CircularProgress size={20} />
          </MenuItem>
        ) : (
          props.options?.map((option, index) => (
            <MenuItem
              value={option}
              key={index}
              sx={{
                fontSize: '15px',
              }}
            >
              {option}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
}
