import { ComponentPage } from 'components/ComponentPage'
import { OperationsPanel } from 'components/OperationsPanel/OperationsPanel'
import { useParams } from 'react-router-dom'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'
import { OpensearchPanel } from './OpensearchPanel/OpensearchPanel'

export const EnvironmentPage = () => {
  const { uuid } = useParams()

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Opensearch',
      index: 1,
      children: <OpensearchPanel />,
    },
    {
      name: 'Операции',
      index: 2,
      children: <OperationsPanel entity={uuid || ''} />,
    },
  ]

  return <ComponentPage panels={panels} />
}
export default EnvironmentPage
