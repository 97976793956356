import { Grid } from '@mui/material'
import { PipelinesCard } from './PipelinesCard'

export const GitlabPanel = () => (
  <Grid container spacing={6} direction="row" alignItems="stretch">
    <Grid item md={12}>
      <PipelinesCard />
    </Grid>
  </Grid>
)
