import Alert from '@mui/material/Alert'
import { useAsync } from 'react-use'
import { getDuration, getElapsedTime } from './utils'
import { LinearProgress, Link, Chip } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'
import { GitlabPipelineObject } from 'types/Controllers/Gitlab'

export enum PipelineStatusColors {
  pending = 'info',
  running = 'primary',
  success = 'success',
  failed = 'error',
  canceled = 'primary',
  skipped = 'primary',
  manual = 'primary',
  scheduled = 'info',
}

export const DenseTable = ({ pipelineObjects }: any) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 40,
      renderCell: (params) => (
        <Link href={params.row.web_url} target="_blank" rel="noreferrer">
          {params.row.id}
        </Link>
      ),
    },
    {
      field: 'status',
      headerName: 'Статус',
      minWidth: 150,
      renderCell: (params) => (
        <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
          <Chip
            label={params.row.status}
            size="small"
            variant="outlined"
            sx={{
              width: '100px',
            }}
            color={
              PipelineStatusColors[
                params.row.status as keyof typeof PipelineStatusColors
              ]
            }
          />
        </div>
      ),
    },
    { field: 'ref', headerName: 'Ref', minWidth: 250 },
    { field: 'duration', headerName: 'Длительность', minWidth: 150 },
    { field: 'created_at', headerName: 'Создан', minWidth: 150 },
  ]

  const rows = pipelineObjects.data.map(
    (pipelineObject: GitlabPipelineObject) => {
      return {
        id: pipelineObject.id,
        status: pipelineObject.status,
        ref: pipelineObject.ref,
        web_url: pipelineObject.web_url,
        created_at: getElapsedTime(pipelineObject.created_at),
        duration: getDuration(
          pipelineObject.created_at,
          pipelineObject.updated_at
        ),
      }
    }
  )

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
    />
  )
}

export const PipelinesTable = () => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const { value, loading, error } = useAsync(async (): Promise<
    GitlabPipelineObject[]
  > => {
    const gitlabObj = await apiClient.getActionPipelinesSummary(uuid || '')
    const data = gitlabObj?.getPipelinesData
    const renderData: any = { data }

    return renderData
  }, [])

  if (loading) {
    return <LinearProgress />
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return <DenseTable pipelineObjects={value || []} />
}
