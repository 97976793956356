import React from 'react'
import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { V1Pod } from '@kubernetes/client-node'
import { ApiClient } from 'api/ApiClient'
import { highlight, languages } from 'prismjs'
import Editor from 'react-simple-code-editor'

import './styles.css'
import 'prismjs/components/prism-log'
import 'prismjs/themes/prism.css'

type LogsDialogProps = {
  pod: V1Pod
  cluster: string
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const LogsDialog = (props: LogsDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const handleClose = () => props.setOpened(false)

  let logsInterval: NodeJS.Timer
  const [isSubscribeToLogs, setIsSubscribeToLogs] = useState(true)
  const [logs, setLogs] = useState<string>('')

  useEffect(() => {
    const apiClient = new ApiClient()

    const getLogs = async () => {
      setLoading(true)
      if (props.opened) {
        try {
          const raw = await apiClient.getPodLogs(props.cluster, props.pod)
          if (raw.podStatus === 'Unknown') {
            setIsSubscribeToLogs(false)
          } else {
            if (raw.logs) {
              setLogs(raw.logs)
            }
          }
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }
    }

    if (isSubscribeToLogs) {
      logsInterval = setInterval(() => {
        getLogs()
      }, 5000)
    }

    return () => clearInterval(logsInterval)
  }, [isSubscribeToLogs, props.opened, props.cluster])

  return (
    <Dialog
      open={props.opened}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: '900px' } }}
    >
      <DialogTitle>
        {props.pod.metadata?.name}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {loading && <div className="LogsDialog-Loading">...</div>}
      </DialogTitle>

      <DialogContent>
        {isSubscribeToLogs ? (
          <div style={{ width: 750 }}>
            <Editor
              disabled
              style={{ width: 750 }}
              name={'logs'}
              className="LogsDialog-HighlightedInput-Editor"
              value={logs.length > 0 ? logs : '...'}
              onValueChange={() => {}}
              highlight={(value) =>
                highlight(value, languages.log, 'language-log')
              }
              padding={10}
            />
          </div>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default LogsDialog
