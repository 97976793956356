import { observer } from 'mobx-react-lite'
import { Divider, Typography } from '@mui/material'
import ServiceTemplateStore from './store'
import './styles.css'
import { runInAction } from 'mobx'
import { useState, useEffect } from 'react'
import { GitlabGroup } from 'types/index'
import { ApiClient } from 'api/ApiClient'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'

const Groups = observer(() => {
  const [gitlabGroups, setGitlabGroups] = useState<GitlabGroup[]>([])

  const apiClient = new ApiClient()

  const getGitlabGroups = async () => {
    try {
      const groups = await apiClient.getGitlabGroups()
      setGitlabGroups(groups)
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    getGitlabGroups()
  }, [])

  const handleAddGroup = () => {
    let newGroups: string[] = [...ServiceTemplateStore.$('groups').value]

    runInAction(() => {
      newGroups = [...newGroups, '']
      ServiceTemplateStore.$('groups').set(newGroups)
    })
  }

  const handleRemoveGroup = (index: number) => {
    runInAction(() => {
      const newGroups: string[] = [...ServiceTemplateStore.$('groups').value]
      newGroups.splice(index, 1)
      ServiceTemplateStore.$('groups').set(newGroups)
    })
  }

  const handleChangeGroups = (
    index: number,
    value: React.SetStateAction<string>
  ) => {
    runInAction(() => {
      const newActions = [...ServiceTemplateStore.$('groups').value]
      newActions[index] = value
      ServiceTemplateStore.$('groups').set(newActions)
    })
  }

  return (
    <div>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Группы в Gitlab
      </Typography>
      {ServiceTemplateStore.$('groups').value &&
        ServiceTemplateStore.$('groups').value.map(
          (group: string, index: number) => (
            <UserInteractionCustomSelect
              name="gitlabGroup"
              description="Группа репозиториев"
              helperText="Группа репозиториев в Gitlab в которой возможно создать сервис из шаблона."
              removeButton
              handleRemove={() => handleRemoveGroup(index)}
              options={gitlabGroups.map((g) => g.full_path)}
              selected={group}
              setSelected={(value) => handleChangeGroups(index, value)}
            />
          )
        )}
      <UserInteractionAddButton handleAdd={handleAddGroup} />
    </div>
  )
})

export default Groups
