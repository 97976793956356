export interface TechRadarRing {
  id: string
  name: string
  color: string
}
export interface TechRadarQuadrant {
  id: string
  name: string
}

export interface TechRadarEntryLink {
  url: string
  title: string
}

export interface TechRadarEntry {
  key: string
  id: string
  quadrant: string
  title: string
  url?: string
  timeline: Array<TechRadarEntrySnapshot>
  description?: string
  links?: Array<TechRadarEntryLink>
}

export interface TechRadarEntrySnapshot {
  date: Date
  ringId: string
  description?: string
  moved?: MovedState
}

export enum MovedState {
  Down = -1,
  NoChange = 0,
  Up = 1,
}

export interface TechRadarData {
  quadrants: TechRadarQuadrant[]
  rings: TechRadarRing[]
  entries: TechRadarEntry[]
}
