import { observer } from 'mobx-react-lite'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import { Typography } from '@mui/material'
import KubernetesNamespaceStore from './store'
import './styles.css'
import UserInteractionEnvironmentSelect from 'components/UserInteraction/UserInteractionEnvironmentSelect'

export interface BasicInfoProps {
  disabled: boolean
}

const BasicInfo = observer((props: BasicInfoProps) => {
  return (
    <>
      <Typography variant="body1" className="Typography-Body1">
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название namespace."
        error={!KubernetesNamespaceStore.$('name').isValid}
        {...KubernetesNamespaceStore.$('name').bind()}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор namespace. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        disabled={props.disabled}
        error={!KubernetesNamespaceStore.$('slug').isValid}
        {...KubernetesNamespaceStore.$('slug').bind()}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание namespace."
        multiline
        rows={6}
        height={'150px'}
        {...KubernetesNamespaceStore.$('description').bind()}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая namespace."
        error={!KubernetesNamespaceStore.$('owner').isValid}
        selected={KubernetesNamespaceStore.$('owner').value}
        setSelected={(value) => KubernetesNamespaceStore.$('owner').set(value)}
      />
      <UserInteractionEnvironmentSelect
        name="environment"
        description="Окружение"
        helperText="Окружение, к которому принадлежит namespace"
        error={!KubernetesNamespaceStore.$('environment').isValid}
        selected={KubernetesNamespaceStore.$('environment').value}
        setSelected={(value) =>
          KubernetesNamespaceStore.$('environment').set(value)
        }
      />
    </>
  )
})

export default BasicInfo
