import { ApiClient } from 'api/ApiClient'
import {
  CustomSelectComponent,
  CustomSelectProps,
} from 'components/CustomSelectComponent/CustomSelectComponent'
import { useEffect, useState } from 'react'

export interface InfraServiceSelectProps extends CustomSelectProps {
  type: string
}

export const InfraServiceSelect = (props: InfraServiceSelectProps) => {
  const apiClient = new ApiClient()
  const [options, setOptions] = useState<Array<string>>([])

  useEffect(() => {
    const getInfraServices = async () => {
      if (props.type) {
        try {
          const data = await apiClient.getInfraServicesByType(props.type)
          const infraServices: string[] = []

          for (const infraService of data) {
            infraServices.push(infraService.name)
          }

          setOptions(infraServices)
        } catch (e) {
          console.error('Ошибка при получении данных:', e)
        }
      }
    }

    getInfraServices()
  }, [props.type])

  return <CustomSelectComponent options={options} {...props} />
}

export default InfraServiceSelect
