import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { GitlabTag } from 'types/index'
import { useParams } from 'react-router-dom'
import { Alert, Chip, LinearProgress, Link, Tooltip } from '@mui/material'
import { getElapsedTime } from '../../utils'
import { useAsync } from 'react-use'

export const DenseTable = ({ tags }: any) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Тег',
      minWidth: 200,
      renderCell: (params) => (
        <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
          <Tooltip arrow title={params.row.message} placement="top">
            <Chip
              label={params.row.name}
              size="small"
              variant="outlined"
              color={'success'}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'commit_short_id',
      headerName: 'Коммит',
      minWidth: 100,
      renderCell: (params) => (
        <Link href={params.row.commit_web_url} target="_blank" rel="noreferrer">
          {params.row.commit_short_id}
        </Link>
      ),
    },
    {
      field: 'commit_author',
      headerName: 'Автор коммита',
      minWidth: 150,
    },
    {
      field: 'commit_message',
      headerName: 'Сообщение коммита',
      minWidth: 400,
    },
    { field: 'commit_date', headerName: 'Коммит создан', minWidth: 200 },
  ]

  const rows = tags.map((tag: GitlabTag) => {
    return {
      name: tag.name,
      message: tag.message,
      commit_short_id: tag.commit.short_id,
      commit_web_url: tag.commit.web_url,
      commit_author: tag.commit.author_name,
      commit_message: tag.commit.message,
      commit_date: getElapsedTime(tag.commit.created_at),
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.name}
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
    />
  )
}

export const TagsTable = () => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const { value, loading, error } = useAsync(async (): Promise<GitlabTag[]> => {
    const tags = await apiClient.getServiceRepositoryTags(uuid || '')
    return tags
  }, [])

  if (loading) {
    return <LinearProgress />
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return <DenseTable tags={value || []} />
}
