import { ComponentCard } from 'components/ComponentCard'
import { Action } from 'types/Managers/Actions'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { ServiceTemplate } from 'types/Managers/ServiceTemplates'

interface DescriptionPanelProps {
  serviceTemplate?: ServiceTemplate
}

export const DescriptionPanel = (props: DescriptionPanelProps) => {
  const [serviceTemplateActions, setServiceTemplateActions] = useState<
    Action[]
  >([])
  const apiClient = new ApiClient()

  const fetchActions = async () => {
    try {
      if (props.serviceTemplate && props.serviceTemplate.actions) {
        let filteredActions: Action[] = []

        const actions = await apiClient.getActions()

        for (const serviceTemplateAction of props.serviceTemplate.actions) {
          const filteredAction = actions.find(
            (action) => action.uuid === serviceTemplateAction
          )
          if (filteredAction) {
            filteredActions = [...filteredActions, filteredAction]
          }

          setServiceTemplateActions(filteredActions)
        }
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchActions()
  }, [props.serviceTemplate])

  return (
    <ComponentCard
      name={props.serviceTemplate?.name}
      description={props.serviceTemplate?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: props.serviceTemplate?.slug,
        },
        {
          name: 'Владелец',
          value: props.serviceTemplate?.ownerRef?.name,
          isUrl: true,
          externalUrl: false,
          url: `/organization/teams/${props.serviceTemplate?.ownerRef?.uuid}`,
        },
        {
          name: 'Шаблонный репозиторий в EDP',
          isUrl: true,
          value: props.serviceTemplate?.templateRepositoryRef?.name,
          url: `/components/repositories/${props.serviceTemplate?.templateRepositoryRef?.uuid}`,
          externalUrl: false,
        },
        {
          name: 'Шаблонный репозиторий в Gitlab',
          isUrl: true,
          value: props.serviceTemplate?.templateRepositoryRef?.name,
          url: `${props.serviceTemplate?.templateRepositoryRef?.url}`,
          externalUrl: true,
        },
        ...serviceTemplateActions.map((action, index) => ({
          name: `Action ${index + 1}`,
          value: action.name,
          isUrl: true,
          externalUrl: false,
          url: `/components/actions/${action.uuid}`,
        })),
      ]}
    />
  )
}

export default DescriptionPanel
