import {
  Alert,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { GitlabInfo } from 'types/Managers/InfraServices'

export interface gitlabInfoCardProps {
  status: string
  error: string
  info: GitlabInfo
}

export const GitlabInfoCard = (props: gitlabInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <LinearProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}

      {props.status === 'success' && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Version:</b> {props.info.version}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Revision:</b> {props.info.revision}
            </ListItemText>
          </ListItem>
        </List>
      )}
    </Paper>
  )
}
