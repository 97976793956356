import { useState } from 'react'

import { ApiClient } from 'api/ApiClient'

import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'
import BasicInfo from './EditPlatformServiceConfigurationPage/BasicInfo'
import { useNavigate } from 'react-router-dom'

export const CreatePlatformServiceConfigurationPage = () => {
  const navigate = useNavigate()
  const [platformServiceConfiguration, setPlatformServiceConfiguration] =
    useState<PlatformServiceConfigurationSpec>({
      uuid: '',
      name: '',
      owner: '',
      description: '',
      platformServiceTemplate: '',
      helmValues: '',
    })

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const handleCreate = async () => {
    setCreated(false)
    if (!platformServiceConfiguration.name) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)
    try {
      const helmValuesEncoded = Buffer.from(
        platformServiceConfiguration.helmValues,
        'binary'
      ).toString('base64')

      const spec: PlatformServiceConfigurationSpec = {
        uuid: platformServiceConfiguration.uuid,
        name: platformServiceConfiguration.name,
        description: platformServiceConfiguration.description,
        owner: platformServiceConfiguration.owner,
        platformServiceTemplate:
          platformServiceConfiguration.platformServiceTemplate,
        helmValues: helmValuesEncoded,
      }

      await apiClient.createPlatformServiceConfiguration(spec)
      setCreated(true)
      navigate('/platform-services/configurations')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createdMessage={`Конфигурация ${platformServiceConfiguration.name} создана`}
    >
      <BasicInfo
        platformServiceConfiguration={platformServiceConfiguration}
        setPlatformServiceConfiguration={setPlatformServiceConfiguration}
        disabled={false}
      />
    </CreateComponentPage>
  )
}

export default CreatePlatformServiceConfigurationPage
