import LinkIcon from '@mui/icons-material/Link'
import { isValidUrl } from '../utils/components'
import type { EntrySnapshot } from '../utils/types'
import { Link } from 'react-router-dom'
import { TechRadarTimeline } from '../TechRadarTimeline/TechRadarTimeline'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'

export type TechRadarDescriptionProps = {
  open: boolean
  onClose: () => void
  title: string
  description: string
  timeline?: EntrySnapshot[]
  url?: string
  links?: Array<{ url: string; title: string }>
}

export const TechRadarDescription = (
  props: TechRadarDescriptionProps
): JSX.Element => {
  function showDialogActions(
    url: string | undefined,
    links: Array<{ url: string; title: string }> | undefined
  ): boolean {
    return isValidUrl(url) || Boolean(links && links.length > 0)
  }

  const { open, onClose, title, description, timeline, url, links } = props

  return (
    <Dialog
      data-testid="radar-description"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: '700px' } }}
    >
      <DialogTitle data-testid="radar-description-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          component="div"
          variant="caption"
          sx={{ paddingBottom: '10px' }}
        >
          {description}
        </Typography>
        <TechRadarTimeline timeline={timeline} />
      </DialogContent>
      {showDialogActions(url, links) && (
        <DialogActions>
          {links?.map((link) => (
            <Button
              component={Link}
              to={link.url}
              onClick={onClose}
              color="primary"
              startIcon={<LinkIcon />}
              key={link.url}
            >
              {link.title}
            </Button>
          ))}
          {isValidUrl(url) && (
            <Button
              component={Link}
              to={url}
              onClick={onClose}
              color="primary"
              startIcon={<LinkIcon />}
              key={url}
            >
              Узнать больше
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default TechRadarDescription
