import { Card, Grid, LinearProgress } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'
import { EnvironmentSelect } from 'components/EnvironmentSelect'
import { NothingFoundPage } from '@edp/core-components-frontend'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { VaultEnvironmentSecret } from 'types/Controllers/Vault'
import { SecretsTable } from './SecretsTable'
import './styles.css'

export const SecretsPanel = () => {
  const { uuid } = useParams()
  const [vaultEnvironmentSecrets, setVaultEnvironmentSecrets] = useState<
    VaultEnvironmentSecret[]
  >([])
  const apiClient = new ApiClient()

  const [loading, setLoading] = useState<boolean>(false)
  const [environment, setEnvironment] = useState<string>('')

  useEffect(() => {
    const getSecrets = async () => {
      try {
        if (environment) {
          setLoading(true)

          const v: VaultEnvironmentSecret[] = []
          const data = await apiClient.getServiceSecrets(uuid || '')

          for (const secret of data) {
            if (secret.environment == environment) {
              v.push(secret)
            }
          }
          setVaultEnvironmentSecrets(v)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    getSecrets()
  }, [uuid, environment])

  return (
    <>
      <Grid container className="Grid-Panel">
        <Grid item>
          <EnvironmentSelect
            selected={environment}
            setSelected={setEnvironment}
            width={'280px'}
            title="Окружение"
          />
        </Grid>
      </Grid>
      {loading !== false ? <LinearProgress /> : <></>}
      {!loading && vaultEnvironmentSecrets.length == 0 && (
        <NothingFoundPage
          text={
            'Попробуйте выбрать одно из окружений, для которых добавлены секреты в HashiCorp Vault.'
          }
        />
      )}
      {!loading && vaultEnvironmentSecrets.length > 0 && (
        <Card style={{ minHeight: '200px' }}>
          <CustomCardHeader
            height="80px"
            title="Секреты"
            description={`Секреты в HashiCorp Vault`}
            fullPage={false}
          />
          <SecretsTable vaultEnvironmentSecrets={vaultEnvironmentSecrets} />
        </Card>
      )}
    </>
  )
}

export default SecretsPanel
