import { ComponentPage } from 'components/ComponentPage'
import { HelmValuesPanel } from './HelmValuesPanel/HelmValuesPanel'
import DescriptionPanel from './DescriptionPanel/DecsriptionPanel'

export const PlatformServiceConfigurationPage = () => {
  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Helm values',
      index: 1,
      children: <HelmValuesPanel />,
    },
  ]

  return <ComponentPage panels={panels} />
}
export default PlatformServiceConfigurationPage
