import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const account = {
  login: null,
  access: 'read',
  ip: null,
  create: true,
}

const fields = {
  block: {
    value: null,
    rules: 'required',
    options: {
      validateOnChange: true,
    },
  },
  environment: {
    value: null,
    rules: 'required',
    options: {
      validateOnChange: true,
    },
  },
  description: {
    value: null,
    rules: 'required',
    options: {
      validateOnChange: true,
    },
  },
  entity: {
    value: null,
    rules: 'required',
    options: {
      validateOnChange: true,
    },
  },
  topic: null,
  accounts: {
    value: [
      {
        login: null,
        access: 'read',
        ip: null,
        create: true,
      },
    ],
    options: {
      structure: account,
    },
  },
  separator: '-',
  partition: {
    value: null,
    rules: 'integer|max:10',
    options: {
      validateOnChange: true,
    },
  },
  time: {
    value: null,
    rules: 'integer|min:1|max:180',
    options: {
      validateOnChange: true,
    },
  },
  messages: {
    value: null,
    rules: 'integer|min:1|max:100000000',
    options: {
      validateOnChange: true,
    },
  },
  subscribers: {
    value: null,
    rules: 'integer|min:1|max:100',
    options: {
      validateOnChange: true,
    },
  },
  size: {
    value: null,
    rules: 'integer|min:1|max:3072',
    options: {
      validateOnChange: true,
    },
  },
  comments: null,
}

export const CreateKafkaTopicActionForm = () => {
  return new MobxReactForm({ fields }, { plugins })
}

export default new MobxReactForm({ fields }, { plugins })
