import React from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import './styles.css'
import CloseIcon from '@mui/icons-material/Close'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs'
import YAML from 'yaml'
import 'prismjs/components/prism-yaml'
import 'prismjs/themes/prism.css'
import './styles.css'
import { KubernetesObject } from '@kubernetes/client-node'

export type ManifestDialogProps = {
  manifest: KubernetesObject
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}

export const ManifestDialog = (props: ManifestDialogProps) => {
  const handleClose = () => props.setOpened(false)

  const manifest = new YAML.Document()
  manifest.contents = props.manifest

  return (
    <Dialog
      open={props.opened}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: '900px' } }}
    >
      <DialogTitle>
        {props.manifest.metadata?.name}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        {props.manifest ? (
          <>
            <Editor
              disabled
              name={'manifest'}
              className="ManifestDialog-HighlightedInput-Editor"
              value={manifest.toString()}
              onValueChange={() => {}}
              highlight={(value) =>
                highlight(value, languages.yaml, 'language-yaml')
              }
              padding={10}
            />
          </>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ManifestDialog
