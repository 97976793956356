import {
  Alert,
  Box,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { setAuth, setAuthType } from 'api/Auth'
import { setLocalAuthToken } from 'api/LocalAccounts'
import { CustomButton } from 'components/CustomButton'
import { useEffect, useState } from 'react'
import { AuthType, LoginResponse, LoginSpec } from 'types/LocalAccounts'

export const LoginForm = () => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [alert, setAlert] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<string>('')

  const handleOidcAuth = () => {
    setAuthType(AuthType.OIDC)
    setAuth(true)
    window.location.reload()
  }

  const handleLocalAuth = async () => {
    setAuthType(AuthType.Local)

    const apiClient = new ApiClient()
    try {
      const config: LoginSpec = {
        username: username,
        password: password,
      }
      const data = (await apiClient.login(config)) as unknown as LoginResponse
      console.log(data)

      if (data.error) {
        if (data.error === 'unauthorized') {
          setLoginError(data.error)
          console.log(loginError)
          return
        }
      }
      setLocalAuthToken(data.token)
      setAuth(true)
      window.location.reload()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (loginError === 'unauthorized') {
      setAlert(true)
    }
  }, [loginError])

  return (
    <Container>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'left',
          alignItems: 'center',
          width: '100%',
          marginTop: '2em',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'center',
            width: '300px',
            marginTop: '2em',
            gap: 2,
          }}
        >
          <Typography variant="h5">Авторизация</Typography>
          {alert && <Alert severity="error">Проверьте логин и пароль</Alert>}
          <TextField
            id="login"
            type="text"
            label="логин"
            fullWidth
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            id="password"
            type="password"
            label="пароль"
            fullWidth
            onChange={(event) => setPassword(event.target.value)}
          />
          <CustomButton
            type="submit"
            fullWidth
            onClick={() => handleLocalAuth()}
          >
            Войти
          </CustomButton>
          <CustomButton fullWidth onClick={() => handleOidcAuth()}>
            Keycloak
          </CustomButton>
        </Box>
      </Box>
    </Container>
  )
}

export default LoginForm
