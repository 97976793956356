import { CardMedia } from '@mui/material'
import { useParams } from 'react-router-dom'

import './styles.css'
import useFetchTeam from 'hooks/useFetchTeam'

export const MonitoringPanel = () => {
  const { uuid } = useParams()
  const team = useFetchTeam(uuid)

  return (
    <>
      <CardMedia
        className="Teams-MonitoringPanel"
        component="iframe"
        height="100%"
        width="100%"
        src={team?.integrations.grafana?.dashboard}
        sx={{
          border: 'none',
          boxShadow: 'none',
        }}
      />
    </>
  )
}

export default MonitoringPanel
