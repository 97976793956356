import { OpensearchDashboardsInfo } from 'types/Managers/InfraServices'
import {
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'

export interface opensearchDashboardsInfoCardProps {
  status: string
  error: string
  info: OpensearchDashboardsInfo
}

export const OpensearchDashboardsInfoCard = (
  props: opensearchDashboardsInfoCardProps
) => {
  return (
    <Paper>
      {props.status === 'loading' && <CircularProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'warning' && (
        <Alert severity="warning">
          Подключение установлено, но токен некорректный
        </Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}
      {props.status === 'success' && props.info !== undefined && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Has api access:</b> {String(props.info.has_api_access)}
            </ListItemText>
          </ListItem>
        </List>
      )}
    </Paper>
  )
}
