import React from 'react'

import { Typography } from '@mui/material'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionPlatformServiceTemplateSelect from 'components/UserInteraction/UserInteractionPlatformServiceTemplateSelect'
import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'
import UserInteractionHighlightedInput from 'components/UserInteraction/UserInteractionHighlightedInput'

export interface BasicInfoProps {
  platformServiceConfiguration: PlatformServiceConfigurationSpec
  setPlatformServiceConfiguration: React.Dispatch<
    React.SetStateAction<PlatformServiceConfigurationSpec>
  >
  disabled: boolean
}

export const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setPlatformServiceConfiguration((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setOwner: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setPlatformServiceConfiguration((prevState) => ({
      ...prevState,
      owner: value instanceof Function ? value(prevState.owner) : value,
    }))
  }

  const setHelmValues: React.Dispatch<React.SetStateAction<string>> = (
    value
  ) => {
    props.setPlatformServiceConfiguration((prevState) => ({
      ...prevState,
      helmValues:
        value instanceof Function ? value(prevState.helmValues) : value,
    }))
  }

  const setPlatformServiceTemplate: React.Dispatch<
    React.SetStateAction<string>
  > = (value) => {
    props.setPlatformServiceConfiguration((prevState) => ({
      ...prevState,
      platformServiceTemplate:
        value instanceof Function
          ? value(prevState.platformServiceTemplate)
          : value,
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название конфигурации платформенного сервиса."
        value={props.platformServiceConfiguration.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание конфигурации платформенного сервиса."
        value={props.platformServiceConfiguration.description}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая конфигурацией платформенного сервиса."
        selected={props.platformServiceConfiguration.owner}
        setSelected={setOwner}
      />
      <UserInteractionPlatformServiceTemplateSelect
        name="platformServiceTemplate"
        description="Шаблон"
        helperText="Шаблон платформенного сервиса для которого создается конфигурация."
        selected={props.platformServiceConfiguration.platformServiceTemplate}
        setSelected={setPlatformServiceTemplate}
      />
      <UserInteractionHighlightedInput
        name="helmValues"
        language="yaml"
        description="Конфигурация"
        helperText="Helm Values в формате yaml."
        value={props.platformServiceConfiguration.helmValues}
        setValue={setHelmValues}
        minHeight={'150px'}
      />
    </>
  )
}

export default BasicInfo
