import { ComponentPage } from 'components/ComponentPage'
import PermissionsPanel from './permissionsPanel/PermissionsPanel'
import RoleBindingsPanel from './roleBindingsPanel/RoleBindingsPanel'
import RolesPanel from './rolesPanel/RolesPanel'

export const RBACPage = () => {
  const panels = [
    {
      name: 'Политики',
      index: 0,
      children: <PermissionsPanel />,
    },
    {
      name: 'Роли',
      index: 1,
      children: <RolesPanel />,
    },
    {
      name: 'Привязки ролей',
      index: 2,
      children: <RoleBindingsPanel />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default RBACPage
