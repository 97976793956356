import { observer } from 'mobx-react-lite'

import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import BasicInfo from './BasicInfo'
import { Action, ActionType } from 'types/Managers/Actions'
import { EditComponentPage } from 'components/EditComponentPage'
import { EntityAction } from 'types/entities'

import ActionStore from './store'
import KrakendEndpointActionStore from '../KrakendEndpointAction/store'
import KafkaTopicActionStore from '../KafkaTopicAction/store'
import GitlabGroupLevelVariablesActionStore from '../GitlabGroupLevelVariablesAction/store'
import MattermostPostActionStore from '../MattermostPostAction/store'
import MattermostWebhookActionStore from '../MattermostWebhookAction/store'
import CustomWebhookActionStore from '../CustomWebhookAction/store'

export interface EditActionPageProps {
  action: EntityAction
  type?: ActionType
}

export const EditActionPage = observer((props: EditActionPageProps) => {
  const { uuid } = useParams()
  const navigate = useNavigate()

  const [edited, setEdited] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)

  const apiClient = new ApiClient()

  const fetchData = async () => {
    try {
      if (uuid) {
        const data = await apiClient.getAction(uuid)
        ActionStore.set(data)

        if (data.type == ActionType.KrakendEndpoint && data.krakend) {
          KrakendEndpointActionStore.set(data.krakend.endpoint)
        }

        if (data.type == ActionType.KafkaTopic && data.kafka) {
          KafkaTopicActionStore.set(data.kafka.topic)
        }

        if (data.type == ActionType.GitlabGroupLevelVariables && data.gitlab) {
          GitlabGroupLevelVariablesActionStore.set(
            data.gitlab.groupLevelVariables
          )
        }

        if (data.type == ActionType.MattermostPost && data.mattermost) {
          MattermostPostActionStore.set(data.mattermost.post)
        }

        if (data.type == ActionType.MattermostWebhook && data.mattermost) {
          MattermostWebhookActionStore.set(data.mattermost.webhook)
        }
        if (data.type == ActionType.CustomWebhook && data.customWebhook) {
          CustomWebhookActionStore.set(data.customWebhook)
        }
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    ActionStore.reset()
    fetchData()
  }, [])

  const handleEdit = async () => {
    setEdited(false)
    setFailed(false)
    setErrorMessage('')
    setLoading(true)

    try {
      await ActionStore.validate().then(({ isValid }) => {
        if (!isValid) {
          setFailed(true)
          const e = new Error('Все обязательные поля должны быть заполнены')
          return Promise.reject(e)
        }
      })

      const action: Action = {
        name: ActionStore.$('name').value,
        slug: ActionStore.$('slug').value,
        type: ActionStore.$('type').value,
        description: ActionStore.$('description').value,
        enabled: ActionStore.$('enabled').value,
        owner: ActionStore.$('owner').value,
        repository: ActionStore.$('repository').value,
        targetRepository: ActionStore.$('targetRepository').value,
        sourceBranch: ActionStore.$('sourceBranch').value,
        targetBranch: ActionStore.$('targetBranch').value,
        targetFile: ActionStore.$('targetFile').value,
        vault: ActionStore.$('vault').value,
        krakend: {
          endpoint: {
            endpoint: KrakendEndpointActionStore.$('endpoint').value,
            method: KrakendEndpointActionStore.$('method').value,
            output_encoding:
              KrakendEndpointActionStore.$('output_encoding').value,
            timeout: KrakendEndpointActionStore.$('timeout').value,
            cache_ttl: KrakendEndpointActionStore.$('cache_ttl').value,
            extra_config: {
              'auth/validator': {
                alg: KrakendEndpointActionStore.$(
                  'extra_config.auth/validator.alg'
                ).value,
                jwk_url: KrakendEndpointActionStore.$(
                  'extra_config.auth/validator.jwk_url'
                ).value,
                disable_jwk_security: KrakendEndpointActionStore.$(
                  'extra_config.auth/validator.disable_jwk_security'
                ).value,
              },
            },
            input_headers: KrakendEndpointActionStore.$('input_headers').value,
            backend: [
              {
                url_pattern: KrakendEndpointActionStore.$(
                  'backend[0].url_pattern'
                ).value,
                encoding: KrakendEndpointActionStore.$('backend[0].encoding')
                  .value,
                sd: KrakendEndpointActionStore.$('backend[0].sd').value,
                method: KrakendEndpointActionStore.$('method').value,
                host: KrakendEndpointActionStore.$('backend[0].host').value,
                disable_host_sanitize: KrakendEndpointActionStore.$(
                  'backend[0].disable_host_sanitize'
                ).value,
              },
            ],
          },
        },
        kafka: {
          topic: {
            block: KafkaTopicActionStore.$('block').value,
            topic: KafkaTopicActionStore.$('topic').value,
            environment: KafkaTopicActionStore.$('environment').value,
            description: KafkaTopicActionStore.$('description').value,
            entity: KafkaTopicActionStore.$('entity').value,
            accounts: KafkaTopicActionStore.$('accounts').value || [],
            separator: KafkaTopicActionStore.$('separator').value,
            partition: KafkaTopicActionStore.$('partition').value,
            time: KafkaTopicActionStore.$('time').value,
            messages: KafkaTopicActionStore.$('messages').value,
            subscribers: KafkaTopicActionStore.$('subscribers').value,
            size: KafkaTopicActionStore.$('size').value,
            comments: KafkaTopicActionStore.$('comments').value,
          },
        },
        gitlab: {
          groupLevelVariables: {
            group: GitlabGroupLevelVariablesActionStore.$('group').value,
            groupLevelVariables: GitlabGroupLevelVariablesActionStore.$(
              'groupLevelVariables'
            ).value,
          },
        },
        mattermost: {
          post: {
            instance: MattermostPostActionStore.$('instance').value,
            post: {
              channel_id: MattermostPostActionStore.$('post.channel_id').value,
              message: MattermostPostActionStore.$('post.message').value,
            },
          },
          webhook: {
            url: MattermostWebhookActionStore.$('url').value,
            webhook: {
              channel: MattermostWebhookActionStore.$('webhook.channel').value,
              text: MattermostWebhookActionStore.$('webhook.text').value,
              username:
                MattermostWebhookActionStore.$('webhook.username').value,
            },
          },
        },
        customWebhook: {
          url: CustomWebhookActionStore.$('url').value,
          method: CustomWebhookActionStore.$('method').value,
          payload: CustomWebhookActionStore.$('payload').value,
          headers: CustomWebhookActionStore.$('headers').value,
        },
      }

      if (props.action === EntityAction.Create) {
        await apiClient.createAction(action)
      }

      if (props.action === EntityAction.Update) {
        action.uuid = uuid
        await apiClient.updateAction(action)
      }

      setEdited(true)
      navigate('/components/actions')
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
    } finally {
      setLoading(false)
    }
  }

  let editedMessage

  if (props.action === EntityAction.Create) {
    editedMessage = 'Action создан'
  }

  if (props.action === EntityAction.Update) {
    editedMessage = 'Action обновлен'
  }

  return (
    <>
      <EditComponentPage
        action={props.action}
        handleEdit={handleEdit}
        loading={loading}
        edited={edited}
        setEdited={setEdited}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        editedMessage={editedMessage}
      >
        <BasicInfo />
      </EditComponentPage>
    </>
  )
})

export default EditActionPage
