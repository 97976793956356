import { V1Pod } from '@kubernetes/client-node'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import { RxActivityLog } from 'react-icons/rx'
import LogsDialog from './LogsDialog'
import { Tooltip } from '@mui/material'

export interface LogsButtonProps {
  pod: V1Pod
  cluster: string
}

export const LogsButton = (props: LogsButtonProps) => {
  const [opened, setOpened] = useState<boolean>(false)
  const handleOpen = () => setOpened(true)

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ':hover': {
            bgcolor: 'transparent',
          },
        }}
      >
        <Tooltip arrow title={`Посмотреть логи`} placement="top">
          <div>
            <RxActivityLog />
          </div>
        </Tooltip>
      </IconButton>
      <LogsDialog
        opened={opened}
        setOpened={setOpened}
        pod={props.pod}
        cluster={props.cluster}
      />
    </>
  )
}
