import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { TeamSpec } from '@edp/types'

const useFetchTeam = (uuid?: string) => {
  const [team, setTeam] = useState<TeamSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchTeam = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getTeam(uuid)
          setTeam(data)
        } catch (e) {
          console.error('Ошибка при получении команды:', e)
        }
      }
    }

    fetchTeam()
  }, [uuid])

  return team
}

export default useFetchTeam
