import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { OperationSpec } from 'types/Managers/Operations'

export async function getOperations(
  this: ApiClient,
  entity?: string
): Promise<OperationSpec[]> {
  let url: string

  if (entity) {
    url = `${this.serverUrl}/operations?filterName=entity&filterValue=${entity}`
  } else {
    url = `${this.serverUrl}/operations`
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getOperation(
  this: ApiClient,
  uuid: string
): Promise<OperationSpec> {
  const url = `${this.serverUrl}/operations/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function retryOperation(
  this: ApiClient,
  uuid: string
): Promise<OperationSpec> {
  const url = `${this.serverUrl}/operations/${uuid}`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
