import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'
import { useEffect, useState } from 'react'
import { IoMdOpen } from 'react-icons/io'
import { useParams } from 'react-router-dom'

export const DefectDojoCard = () => {
  const { uuid } = useParams()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [productUrl, setProductUrl] = useState<string>('')
  const [productFindingsTotal, setProductFindingsTotal] = useState<number>()
  const [productFindingsCritical, setProductFindingsCritical] =
    useState<number>()
  const [productFindingsHigh, setProductFindingsHigh] = useState<number>()
  const [productFindingsMedium, setProductFindingsMedium] = useState<number>()
  const [productFindingsLow, setProductFindingsLow] = useState<number>()
  const [productFindingsInformational, setProductFindingsInformational] =
    useState<number>()

  useEffect(() => {
    setLoading(true)
    setError('')

    const getDefectDojoSummary = async () => {
      try {
        const apiClient = new ApiClient()

        const data = await apiClient.getDefectDojoSummary(uuid || '')
        setProductUrl(data.url || '')
        setProductFindingsTotal(data.findings_count)
        setProductFindingsCritical(data.findings_critical)
        setProductFindingsHigh(data.findings_high)
        setProductFindingsMedium(data.findings_medium)
        setProductFindingsLow(data.findings_low)
        setProductFindingsInformational(data.findings_informational)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError('unknown')
        }
      } finally {
        setLoading(false)
      }
    }
    getDefectDojoSummary()
  }, [uuid])

  return (
    <Card style={{ height: '400px', minWidth: '550px', boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="DefectDojo"
        description="Основная статистика по уязвимостям сервиса из DefectDojo"
        fullPage={false}
      />
      {loading && <LinearProgress />}
      {!loading && (
        <>
          <CardContent>
            <Grid item xs={12}>
              <Card
                style={{ backgroundColor: '#041233' }}
                sx={{
                  width: '100%',
                  height: '100px',
                  fontSize: 22,
                  lineHeight: 0.5,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: 105,
                  color: '#FFFFFF',
                }}
              >
                <p>{productFindingsTotal}</p>
                <p>Всего уязвимостей</p>
              </Card>
            </Grid>
            <Grid
              item
              xs
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto auto auto',
                justifyContent: 'center',
                gap: '5px',
                padding: '10px',
              }}
            >
              <Card
                style={{ backgroundColor: '#3579BD' }}
                sx={{
                  width: '100px',
                  height: '100px',
                  fontSize: 22,
                  lineHeight: 0.5,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  position: 'relative',
                }}
              >
                <p>{productFindingsInformational}</p>
                <p>Info</p>
              </Card>
              <Card
                style={{ backgroundColor: '#36B37E' }}
                sx={{
                  width: '100px',
                  height: '100px',
                  fontSize: 22,
                  lineHeight: 0.5,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  position: 'relative',
                }}
              >
                <p>{productFindingsLow}</p>
                <p>Low</p>
              </Card>
              <Card
                style={{ backgroundColor: '#E3AB19' }}
                sx={{
                  width: '100px',
                  height: '100px',
                  fontSize: 22,
                  lineHeight: 0.5,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  position: 'relative',
                }}
              >
                <p>{productFindingsMedium}</p>
                <p>Medium</p>
              </Card>
              <Card
                style={{ backgroundColor: '#D5791E' }}
                sx={{
                  width: '100px',
                  height: '100px',
                  fontSize: 22,
                  lineHeight: 0.5,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  position: 'relative',
                }}
              >
                <p>{productFindingsHigh}</p>
                <p>High</p>
              </Card>
              <Card
                style={{ backgroundColor: '#DD350D' }}
                sx={{
                  width: '100px',
                  height: '100px',
                  fontSize: 22,
                  lineHeight: 0.5,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  position: 'relative',
                }}
              >
                <p>{productFindingsCritical}</p>
                <p>Critical</p>
              </Card>
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              height: '70px',
              justifyContent: 'right',
              alignItems: 'end',
            }}
          >
            <Button
              color="primary"
              disabled={!productUrl}
              href={productUrl}
              target="_blank"
              startIcon={<IoMdOpen />}
            >
              Открыть
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  )
}

export default DefectDojoCard
