import React from 'react'

import {
  Alert,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { ArgoCDInfo } from 'types/Managers/InfraServices'

export interface argocdInfoCardProps {
  status: string
  error: string
  info: ArgoCDInfo
}

export const ArgocdInfoCard = (props: argocdInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <LinearProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'warning' && (
        <Alert severity="warning">
          Подключение установлено, но токен некорректный
        </Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}

      {(props.status === 'success' || props.status === 'warning') && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Version:</b> {props.info.Version}
            </ListItemText>
          </ListItem>
          {props.status === 'success' && (
            <>
              <ListItem>
                <ListItemText>
                  <b>Git commit:</b> {props.info.GitCommit}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>GO version:</b> {props.info.GoVersion}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Compiler:</b> {props.info.Compiler}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Platform:</b> {props.info.Platform}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Kustomize version:</b> {props.info.KustomizeVersion}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Helm version:</b> {props.info.HelmVersion}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <b>Jsonnet version:</b> {props.info.JsonnetVersion}
                </ListItemText>
              </ListItem>
            </>
          )}
        </List>
      )}
    </Paper>
  )
}
