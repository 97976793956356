import React from 'react'
import { useState, useEffect } from 'react'

import { ApiClient } from 'api/ApiClient'

import { Typography } from '@mui/material'

import UserInteractionSlider from 'components/UserInteraction/UserInteractionSlider'
import { EnvironmentSpec } from 'types/Managers/Environments'

export interface ResourcesProps {
  disabled: boolean
  environment: EnvironmentSpec
  setEnvironment: React.Dispatch<React.SetStateAction<EnvironmentSpec>>
}

export const Resources = (props: ResourcesProps) => {
  const magicNumber16MegabytesToBytes = 16777216
  const [allMemory, setAllMemory] = useState<number>(0)
  const [allCpu, setAllCpu] = useState<number>(0)

  const [allocatedCpu, setAllocatedCpu] = useState<number>(0)
  const [allocatedMemory, setAllocatedMemory] = useState<number>(0)

  useEffect(() => {
    const apiClient = new ApiClient()

    if (props.environment.owner !== '') {
      const getAllResources = async () => {
        try {
          const data = await apiClient.getTeam(props.environment.owner)
          setAllCpu(data.quotas.kubernetes.limitsCpu || 0)
          setAllMemory(data.quotas.kubernetes.limitsMemory || 0)
        } catch (e) {
          console.log(e)
        }
      }

      getAllResources()
    }
  }, [props.environment.owner])

  useEffect(() => {
    const apiClient = new ApiClient()

    if (props.environment.owner !== '') {
      const getQuotas = async () => {
        let am = 0
        let ac = 0

        try {
          const environments = await apiClient.getEnvironmentsBy(
            'owner',
            props.environment.owner
          )

          for (const environment of environments) {
            if (environment.uuid !== props.environment.uuid) {
              am += environment.quotas.kubernetes.limitsMemory || 0
              ac += environment.quotas.kubernetes.limitsCpu || 0
            }
          }
        } catch (e) {
          console.log(e)
        }
        setAllocatedMemory(am)
        setAllocatedCpu(ac)
      }

      getQuotas()
    }
  }, [props.environment.uuid, props.environment.owner])

  const availableMemory: number = allMemory - allocatedMemory
  const availableCpu: number = allCpu - allocatedCpu

  function valueLabelFormatCpu(value: number) {
    return `${value}m`
  }

  function valueLabelFormatMemory(value: number) {
    return `${value / 1024 / 1024}Mi`
  }

  const marksCpu = [
    {
      value: 0,
      label: '0',
    },
  ]

  availableCpu &&
    marksCpu.push({
      value: availableCpu - (availableCpu % 10),
      label: valueLabelFormatCpu(availableCpu - (availableCpu % 10)),
    })

  const marksMemory = [
    {
      value: 0,
      label: '0',
    },
  ]

  availableMemory &&
    marksMemory.push({
      value:
        availableMemory - (availableMemory % magicNumber16MegabytesToBytes),
      label: valueLabelFormatMemory(
        availableMemory - (availableMemory % magicNumber16MegabytesToBytes)
      ),
    })

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Ресурсы Kubernetes
      </Typography>
      <UserInteractionSlider
        description="CPU"
        disabled={props.disabled}
        helperText="Ядра CPU, выделяемые на окружение в кластере Kubernetes."
        name="cpu"
        min={0}
        step={10}
        max={availableCpu - (availableCpu % 10)}
        marks={marksCpu}
        valueLabelFormat={valueLabelFormatCpu}
        value={props.environment.quotas.kubernetes.limitsCpu || 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setEnvironment({
            ...props.environment,
            quotas: {
              ...props.environment.quotas,
              kubernetes: {
                ...props.environment.quotas.kubernetes,
                limitsCpu: Number(event.target.value),
              },
            },
          })
        }
      />
      <UserInteractionSlider
        description="Memory"
        disabled={props.disabled}
        helperText="Оперативная память, выделяемая на окружение в кластере Kubernetes."
        name="memory"
        min={0}
        step={magicNumber16MegabytesToBytes}
        max={
          availableMemory - (availableMemory % magicNumber16MegabytesToBytes)
        }
        marks={marksMemory}
        valueLabelFormat={valueLabelFormatMemory}
        value={props.environment.quotas.kubernetes.limitsMemory || 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setEnvironment({
            ...props.environment,
            quotas: {
              ...props.environment.quotas,
              kubernetes: {
                ...props.environment.quotas.kubernetes,
                limitsMemory: Number(event.target.value),
              },
            },
          })
        }
      />
    </>
  )
}

export default Resources
