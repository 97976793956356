import { Divider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import ServiceStore from './store'
import './styles.css'

export const Grafana = observer(() => {
  return (
    <>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Grafana
      </Typography>
      <UserInteractionTextField
        name="grafana-dashboard"
        description="Ссылка на dashboard"
        helperText="Ссылка на Grafana dashboard сервиса."
        {...ServiceStore.$('integrations.grafana.dashboard').bind()}
      />
    </>
  )
})

export default Grafana
