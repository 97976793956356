import { User } from 'oidc-client-ts'
import { ProfileSpec } from 'types/LocalAccounts'
import { setProfile } from './utils'

const OIDC_CONFIG_AUTHORITY = window.env.REACT_APP_OIDC_CONFIG_AUTHORITY || ''
const OIDC_CONFIG_CLIENT_ID = window.env.REACT_APP_OIDC_CONFIG_CLIENT_ID || ''

export function checkExistOidcToken(): boolean {
  const token = localStorage.getItem(
    `oidc.user:${OIDC_CONFIG_AUTHORITY}:${OIDC_CONFIG_CLIENT_ID}`
  )

  if (token === null) {
    return false
  } else {
    return true
  }
}

export function setOidcProfile() {
  const userInfo: User | null = getOIDCUserFromSessionStorage()
  if (userInfo === null) {
    return
  }

  setProfile(JSON.parse(JSON.stringify(userInfo.profile)) as ProfileSpec)
}

export function getOIDCUserFromSessionStorage(): User | null {
  const config = {
    authority: OIDC_CONFIG_AUTHORITY,
    client_id: OIDC_CONFIG_CLIENT_ID,
  }

  const userInfoRaw = sessionStorage.getItem(
    `oidc.user:${config.authority}:${config.client_id}`
  )
  if (userInfoRaw === null) {
    return null
  }

  const userInfo: User = JSON.parse(userInfoRaw)
  return userInfo
}
