import { useEffect, useState } from 'react'

import { ApiClient } from 'api/ApiClient'

import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'
import BasicInfo from './EditPlatformServiceConfigurationPage/BasicInfo'
import UpdateComponentPage from 'components/UpdateComponentPage/UpdateComponentPage'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export const UpdatePlatformServiceConfigurationPage = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()

  const [platformServiceConfiguration, setPlatformServiceConfiguration] =
    useState<PlatformServiceConfigurationSpec>({
      uuid: '',
      name: '',
      owner: '',
      description: '',
      platformServiceTemplate: '',
      helmValues: '',
    })

  const getPlatformServiceConfiguration = async () => {
    try {
      const data = await apiClient.getPlatformServiceConfiguration(uuid || '')
      setPlatformServiceConfiguration(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPlatformServiceConfiguration()
  }, [])

  const [updated, setUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const handleUpdate = async () => {
    setUpdated(false)
    if (!platformServiceConfiguration.name) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)
    try {
      const helmValuesEncoded = Buffer.from(
        platformServiceConfiguration.helmValues,
        'binary'
      ).toString('base64')

      const spec: PlatformServiceConfigurationSpec = {
        uuid: platformServiceConfiguration.uuid,
        name: platformServiceConfiguration.name,
        description: platformServiceConfiguration.description,
        owner: platformServiceConfiguration.owner,
        platformServiceTemplate:
          platformServiceConfiguration.platformServiceTemplate,
        helmValues: helmValuesEncoded,
      }

      await apiClient.updatePlatformServiceConfiguration(spec)
      setUpdated(true)
      navigate('/platform-services/configurations')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <UpdateComponentPage
      handleUpdate={handleUpdate}
      loading={loading}
      updated={updated}
      setUpdated={setUpdated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      updatedMessage={`Конфигурация ${platformServiceConfiguration.name} обновлена`}
    >
      <BasicInfo
        platformServiceConfiguration={platformServiceConfiguration}
        setPlatformServiceConfiguration={setPlatformServiceConfiguration}
        disabled={true}
      />
    </UpdateComponentPage>
  )
}

export default UpdatePlatformServiceConfigurationPage
