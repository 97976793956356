import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { CustomButton } from 'components/CustomButton'
import { Link } from 'react-router-dom'

export const NotFoundPage = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <img
        src={'/static/images/marvin.png'}
        alt="nothing-found"
        style={{ width: '300px' }}
      />
      <Typography variant="h1" style={{ color: theme.palette.primary.main }}>
        404
      </Typography>
      <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
        Страница не найдена
      </Typography>
      <CustomButton sx={{ marginTop: '15px' }} component={Link} to={'/'}>
        Вернуться на главную
      </CustomButton>
    </Box>
  )
}

export default NotFoundPage
