import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { CustomEntity } from 'types/Managers/CustomEntities'

const useFetchCustomEntity = (uuid?: string) => {
  const [customEntity, setCustomEntity] = useState<CustomEntity>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchCustomEntity = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getCustomEntity(uuid)
          setCustomEntity(data)
        } catch (e) {
          console.error('Ошибка при получении пользовательского компонента:', e)
        }
      }
    }

    fetchCustomEntity()
  }, [uuid])

  return customEntity
}

export default useFetchCustomEntity
