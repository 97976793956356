import React, { useState } from 'react'

import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  CardContent,
  Input,
} from '@mui/material'
import { CustomButton } from 'components/CustomButton'
import { useAuth } from 'react-oidc-context'
import { AuthType, ProfileSpec } from 'types/LocalAccounts'
import { deleteProfile, getAuthType, getProfile } from 'api/utils'
import { deleteAuthType, setAuth } from 'api/Auth'
import { deleteLocalAuthToken, getLocalAuthToken } from 'api/LocalAccounts'
import { setOidcProfile } from 'api/OidcAccounts'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import ChangePasswordModal from 'pages/settings/ProfilePage/ChangePasswordModal/ChangePassword'

const ProfilePage = () => {
  // Change password
  const [openChangePasswordModal, setOpenChangePasswordModal] =
    React.useState<boolean>(false)
  const [successUpdatePassword, setSuccessUpdatePassword] =
    useState<boolean>(false)
  const [incorrectCurrentPassword, setIncorrectCurrentPassword] =
    useState<boolean>(false)
  const [errorChangePasswordMessage, setErrorChangePasswordMessage] =
    useState<string>('')
  const [errorChangePassword, setErrorChangePassword] = useState<boolean>(false)

  const authType = getAuthType()
  let auth: any

  if (authType === 'oidc') {
    auth = useAuth()
    setOidcProfile()
  }

  const profile: ProfileSpec = getProfile()

  const groups: Array<string> = profile.groups as Array<string>

  const handleLogout = async () => {
    setAuth(false)
    deleteProfile()

    if (authType === AuthType.OIDC) {
      deleteAuthType()

      void auth.signoutRedirect()
      void auth.removeUser()
    }

    if (authType === AuthType.Local) {
      deleteAuthType()
      deleteLocalAuthToken()
      window.location.reload()
    }
  }

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '10px',
          }}
        >
          <Typography variant="h5">{profile.name}</Typography>
        </Box>
        <Divider sx={{ marginBottom: '20px' }} />
        <Grid
          sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
        >
          <Grid>
            <Typography sx={{ padding: '0 0 20px 20px' }}>
              <Avatar sx={{ width: '5em', height: '5em' }} aria-label="recipe">
                <Typography sx={{ 'font-size': '3em' }}>
                  {String(profile.given_name).substring(0, 1)}
                  {String(profile.family_name).substring(0, 1)}
                </Typography>
              </Avatar>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <List sx={{ padding: '0 20px 0 20px' }}>
              <ListItem disablePadding>
                <span style={{ padding: '5px' }}>
                  <b>Username:</b> {profile.preferred_username}
                </span>
              </ListItem>
              <ListItem disablePadding>
                <span style={{ padding: '5px' }}>
                  <b>Email:</b> {profile.email}
                </span>
              </ListItem>
              <ListItem disablePadding>
                <span style={{ padding: '5px' }}>
                  <b>Тип авторизации:</b> {authType}
                </span>
              </ListItem>
              {authType === AuthType.Local && (
                <ListItem disablePadding>
                  <span style={{ padding: '5px' }}>
                    <b>Токен: </b>
                    <Input
                      disabled
                      multiline
                      defaultValue={getLocalAuthToken()}
                      style={{ width: '400px' }}
                    />
                  </span>
                </ListItem>
              )}
              <ListItem disablePadding>
                <span style={{ padding: '5px' }}>
                  <b>Группы:</b>
                  <ul>
                    {groups.map((group) => {
                      return (
                        <li>
                          <span style={{ padding: '5px' }}>{group}</span>
                        </li>
                      )
                    })}
                  </ul>
                </span>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item xs={8} sx={{ float: 'right', paddingBottom: '10px' }}>
          {authType === AuthType.Local && (
            <>
              <ChangePasswordModal
                open={openChangePasswordModal}
                setOpen={setOpenChangePasswordModal}
                updatePassword={successUpdatePassword}
                setUpdatePassword={setSuccessUpdatePassword}
                incorrectCurrentPassword={incorrectCurrentPassword}
                setIncorrectCurrentPassword={setIncorrectCurrentPassword}
                errorChangePassword={errorChangePassword}
                setErrorChangePassword={setErrorChangePassword}
                errorChangePasswordMessage={errorChangePasswordMessage}
                setErrorChangePasswordMessage={setErrorChangePasswordMessage}
              />
              <CustomSnackbarComponent
                opened={successUpdatePassword}
                setOpened={setSuccessUpdatePassword}
                message={'Пароль успешно обновлен'}
                severity="success"
              />
              <CustomSnackbarComponent
                opened={incorrectCurrentPassword}
                setOpened={setIncorrectCurrentPassword}
                message={'Текущий пароль указан неверно'}
                severity="error"
              />
              <CustomSnackbarComponent
                opened={errorChangePassword}
                setOpened={setErrorChangePassword}
                message={`При обновлении пароля произошла ошибка. ${errorChangePassword}`}
                severity="error"
              />
              <CustomButton
                onClick={() => {
                  setOpenChangePasswordModal(true)
                }}
                style={{
                  minHeight: '40px',
                  minWidth: '100px',
                  marginRight: '15px',
                }}
              >
                Сменить пароль
              </CustomButton>
            </>
          )}
          <CustomButton onClick={() => handleLogout()}>Выйти</CustomButton>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProfilePage
