import { Quadrant, Ring } from '../utils/types'
import { TechRadarLegendRing } from './TechRadarLegendRing'
import { TechRadarLegendProps, Segments } from './types'
import { getSegment } from './utils'
import './styles.css'

type TechRadarLegendQuadrantProps = {
  segments: Segments
  quadrant: Quadrant
  rings: Ring[]
  onEntryMouseEnter: TechRadarLegendProps['onEntryMouseEnter']
  onEntryMouseLeave: TechRadarLegendProps['onEntryMouseLeave']
}

export const TechRadarLegendQuadrant = ({
  segments,
  quadrant,
  rings,
  onEntryMouseEnter,
  onEntryMouseLeave,
}: TechRadarLegendQuadrantProps) => {
  return (
    <foreignObject
      key={quadrant.id}
      x={quadrant.legendX}
      y={quadrant.legendY}
      width={quadrant.legendWidth}
      height={quadrant.legendHeight}
      data-testid="radar-quadrant"
    >
      <div className={'TechRadar-RadarLegend-Quadrant'}>
        <h2 className={'TechRadar-RadarLegend-QuadrantHeading'}>
          {quadrant.name}
        </h2>
        <div className={'TechRadar-RadarLegend-Rings'}>
          {rings.map((ring) => (
            <TechRadarLegendRing
              key={ring.id}
              ring={ring}
              entries={getSegment(segments, quadrant, ring)}
              onEntryMouseEnter={onEntryMouseEnter}
              onEntryMouseLeave={onEntryMouseLeave}
            />
          ))}
        </div>
      </div>
    </foreignObject>
  )
}

export default TechRadarLegendQuadrant
