import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { CustomEntity } from 'types/Managers/CustomEntities'

export async function createCustomEntity(
  this: ApiClient,
  spec: CustomEntity
): Promise<JSON> {
  const url = `${this.serverUrl}/custom-entities`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getCustomEntities(
  this: ApiClient
): Promise<CustomEntity[]> {
  const url = `${this.serverUrl}/custom-entities`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getCustomEntitiesBy(
  this: ApiClient,
  filterName: string,
  filterValue: string
): Promise<CustomEntity[]> {
  const url = `${this.serverUrl}/custom-entities?filterName=${filterName}&filterValue=${filterValue}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getCustomEntity(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/custom-entities/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updateCustomEntity(
  this: ApiClient,
  spec: CustomEntity
): Promise<JSON> {
  const url = `${this.serverUrl}/custom-entities/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function deleteCustomEntity(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/custom-entities/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
