import { useParams } from 'react-router-dom'
import useFetchEnvironment from 'hooks/useFetchEnvironment'
import { ComponentCard } from 'components/ComponentCard'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const environment = useFetchEnvironment(uuid)

  return (
    <ComponentCard
      name={environment?.name}
      description={environment?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: `${environment?.slug}`,
        },
        {
          name: 'Владелец',
          value: environment?.ownerRef?.name,
        },
        {
          name: 'Kubernetes кластер',
          value: environment?.clusterRef?.name,
        },
      ]}
    />
  )
}

export default DescriptionPanel
