import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { RoleBinding } from 'types/rbac/roleBinding'

export async function createRoleBinding(
  this: ApiClient,
  spec: RoleBinding
): Promise<JSON> {
  const url = `${this.serverUrl}/rbac/role-bindings`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getRoleBindings(this: ApiClient): Promise<any> {
  const url = `${this.serverUrl}/rbac/role-bindings`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getRoleBinding(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/rbac/role-bindings/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updateRoleBinding(
  this: ApiClient,
  spec: RoleBinding
): Promise<JSON> {
  const url = `${this.serverUrl}/rbac/role-bindings/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function deleteRoleBinding(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/rbac/role-bindings/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
