import { ComponentPage } from 'components/ComponentPage'

export const KubernetesNamespacePage = () => {
  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <></>,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default KubernetesNamespacePage
