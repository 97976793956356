import { Grid } from '@mui/material'
import { CustomButton } from 'components/CustomButton'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { useState } from 'react'
import EditKafkaTopic, { KafkaTopicActions } from './EditKafkaTopic'
import { KafkaTopicsTable } from './KafkaTopicsTable'

export const KafkaTopicsPanel = () => {
  const [opened, setOpened] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleOpen = () => setOpened(true)

  return (
    <>
      <Grid className="KafkaTopicsPanel-CreateButton">
        <CustomButton onClick={handleOpen}>Создать</CustomButton>
      </Grid>
      <EditKafkaTopic
        name={''}
        opened={opened}
        setOpened={setOpened}
        created={created}
        setCreated={setCreated}
        updated={updated}
        setUpdated={setUpdated}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        action={KafkaTopicActions.Create}
      />
      <KafkaTopicsTable
        updated={updated}
        setUpdated={setUpdated}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        created={created}
        setCreated={setCreated}
      />
      <CustomSnackbarComponent
        opened={created}
        setOpened={setCreated}
        message="Топик создан"
        severity="success"
      />
      <CustomSnackbarComponent
        opened={updated}
        setOpened={setUpdated}
        message="Топик обновлен"
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
}

export default KafkaTopicsPanel
