export enum AuthType {
  Local = 'local',
  OIDC = 'oidc',
  unknown = 'unknown',
}

export interface LoginSpec {
  username: string
  password: string
}

export interface ChangePasswordSpec {
  currentPassword: string
  newPassword: string
}

export interface ChangePasswordResponse {
  status?: string
  error?: string
}

export interface LoginResponse {
  token: string
  error?: string
}

export interface ProfileSpec {
  email_verified: boolean
  name: string
  groups: Array<string>
  preferred_username: string
  given_name: string
  family_name: string
  email: string
  iat?: number
  exp?: number
}
