import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  type: {
    value: null,
    rules: 'required',
  },
  enabled: true,
  name: {
    value: null,
    rules: 'required|string|between:3,64',
  },
  slug: {
    value: null,
    rules: 'required|string|between:3,64|regex:/^[a-z][a-z0-9-]*$/',
  },
  owner: {
    value: null,
    rules: 'required',
  },
  description: null,
  repository: null,
  targetRepository: null,
  sourceBranch: null,
  targetBranch: null,
  targetFile: null,
  vault: {
    secret: {
      name: null,
      path: null,
      data: [],
    },
  },
}

export default new MobxReactForm({ fields }, { plugins })
