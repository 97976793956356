import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from '@mui/material'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { runInAction } from 'mobx'
import Form from 'mobx-react-form'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { BsFiletypeJson } from 'react-icons/bs'
import {
  KrakendEndpointBackendEncodings,
  KrakendEndpointExtraConfigAlgs,
  KrakendEndpointMethods,
  KrakendEndpointOutputEncoindgs,
  KrakendEndpointSds,
  KrakendEndpointSpec,
} from 'types/Controllers/Krakend'
import KrakendJson from './KrakendJson'
import './styles.css'

interface KrakendEndpointActionProps {
  form: Form
}

export const KrakendEndpointAction = observer(
  (props: KrakendEndpointActionProps) => {
    const [krakendJsonOpened, setKrakendJsonOpened] = useState<boolean>(false)

    const handleAddInputHeaders = () => {
      let h: string[] = [...props.form.$('input_headers').value]

      runInAction(() => {
        h = [...h, '']
        props.form.$('input_headers').set(h)
      })
    }

    const handleRemoveInputHeaders = (index: number) => {
      runInAction(() => {
        const h: string[] = [...props.form.$('input_headers').value]
        h.splice(index, 1)
        props.form.$('input_headers').set(h)
      })
    }

    const handleChangeInputHeaders = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      runInAction(() => {
        const h = [...props.form.$('input_headers').value]
        h[index] = event.target.value
        props.form.$('input_headers').set(h)
      })
    }

    const endpoint: KrakendEndpointSpec = {
      endpoint: props.form.$('endpoint').value,
      method: props.form.$('method').value,
      output_encoding: props.form.$('output_encoding').value,
      timeout: props.form.$('timeout').value,
      cache_ttl: props.form.$('cache_ttl').value,
      extra_config: {
        'auth/validator': {
          alg: props.form.$('extra_config.auth/validator.alg').value,
          jwk_url: props.form.$('extra_config.auth/validator.jwk_url').value,
          disable_jwk_security: props.form.$(
            'extra_config.auth/validator.disable_jwk_security'
          ).value,
        },
      },
      input_headers: props.form.$('input_headers').value,
      backend: [
        {
          url_pattern: props.form.$('backend[0].url_pattern').value,
          encoding: props.form.$('backend[0].encoding').value,
          sd: props.form.$('backend[0].sd').value,
          method: props.form.$('method').value,
          host: props.form.$('backend[0].host').value,
          disable_host_sanitize: props.form.$(
            'backend[0].disable_host_sanitize'
          ).value,
        },
      ],
    }

    return (
      <>
        <Typography
          variant="subtitle2"
          className="Typography-Subtitle2"
          paddingTop="15px"
        >
          Конфигурация KrakenD endpoint
        </Typography>
        <UserInteractionTextField
          name="endpoint"
          description="endpoint"
          helperText="endpoint."
          error={!props.form.$('endpoint').isValid}
          {...props.form.$('endpoint').bind()}
        />
        <UserInteractionCustomSelect
          name="method"
          options={KrakendEndpointMethods}
          description="method"
          helperText="method."
          error={!props.form.$('method').isValid}
          selected={props.form.$('method').value}
          setSelected={(value) => props.form.$('method').set(value)}
        />
        <UserInteractionCustomSelect
          name="output_encoding"
          options={KrakendEndpointOutputEncoindgs}
          description="output_encoding"
          helperText="output_encoding."
          error={!props.form.$('output_encoding').isValid}
          selected={props.form.$('output_encoding').value}
          setSelected={(value) => props.form.$('output_encoding').set(value)}
        />
        <UserInteractionTextField
          name="timeout"
          description="timeout"
          helperText="timeout."
          error={!props.form.$('timeout').isValid}
          {...props.form.$('timeout').bind()}
        />
        <UserInteractionTextField
          name="cache_ttl"
          description="cache_ttl"
          helperText="cache_ttl."
          error={!props.form.$('cache_ttl').isValid}
          {...props.form.$('cache_ttl').bind()}
        />
        <Typography
          variant="subtitle2"
          paddingTop="30px"
          className="Typography-Subtitle2"
        >
          input_headers
        </Typography>
        {props.form.$('input_headers').value &&
          props.form
            .$('input_headers')
            .value.map((header: string, index: number) => (
              <UserInteractionTextField
                name={`header_${index + 1}`}
                description={`Header ${index + 1}`}
                helperText="input_headers."
                key={`input-headers-${index}`}
                value={header}
                removeButton
                handleRemove={() => handleRemoveInputHeaders(index)}
                onChange={(event) => handleChangeInputHeaders(index, event)}
              />
            ))}
        <UserInteractionAddButton handleAdd={handleAddInputHeaders} />
        <Typography
          variant="subtitle2"
          paddingTop="30px"
          className="Typography-Subtitle2"
        >
          extra_config
        </Typography>
        <Accordion
          key={`accordion-auth-validator`}
          elevation={0}
          sx={{
            width: '670px',
            '&::before': {
              visibility: 'hidden',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              padding: '0px',
            }}
          >
            <Typography variant="subtitle2">{`"auth/validator"`}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '0px',
            }}
          >
            <UserInteractionCustomSelect
              name="alg"
              options={KrakendEndpointExtraConfigAlgs}
              description="alg"
              helperText="alg."
              error={!props.form.$('extra_config.auth/validator.alg').isValid}
              selected={props.form.$('extra_config.auth/validator.alg').value}
              setSelected={(value) =>
                props.form.$('extra_config.auth/validator.alg').set(value)
              }
            />
            <UserInteractionTextField
              name="jwk_url"
              description="jwk_url"
              helperText="jwk_url."
              error={
                !props.form.$('extra_config.auth/validator.jwk_url').isValid
              }
              {...props.form.$('extra_config.auth/validator.jwk_url').bind()}
            />
            <UserInteractionSwitch
              name="disable_jwk_security"
              description="disable_jwk_security"
              helperText={`disable_jwk_security.`}
              checked={
                props.form.$('extra_config.auth/validator.disable_jwk_security')
                  .value
              }
              onChange={(e) => {
                props.form
                  .$('extra_config.auth/validator.disable_jwk_security')
                  .set(e.target.checked)
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Typography
          variant="subtitle2"
          paddingTop="30px"
          className="Typography-Subtitle2"
        >
          backend
        </Typography>
        <UserInteractionTextField
          name="url_pattern"
          description="url_pattern"
          helperText="url_pattern."
          error={!props.form.$('backend[0].url_pattern').isValid}
          {...props.form.$('backend[0].url_pattern').bind()}
        />
        <UserInteractionCustomSelect
          name="encoding"
          options={KrakendEndpointBackendEncodings}
          description="encoding"
          helperText="encoding."
          error={!props.form.$('backend[0].encoding').isValid}
          selected={props.form.$('backend[0].encoding').value}
          setSelected={(value) =>
            props.form.$('backend[0].encoding').set(value)
          }
        />
        <UserInteractionCustomSelect
          name="sd"
          options={KrakendEndpointSds}
          description="sd"
          helperText="sd."
          error={!props.form.$('backend[0].sd').isValid}
          selected={props.form.$('backend[0].sd').value}
          setSelected={(value) => props.form.$('backend[0].sd').set(value)}
        />
        <UserInteractionCustomSelect
          name="method"
          options={KrakendEndpointMethods}
          description="methods"
          helperText="methods."
          error={!props.form.$('method').isValid}
          selected={props.form.$('method').value}
          setSelected={(value) => props.form.$('method').set(value)}
        />
        <UserInteractionTextField
          name="host"
          description="host"
          helperText="host."
          error={!props.form.$('backend[0].host[0]').isValid}
          {...props.form.$('backend[0].host[0]').bind()}
        />
        <UserInteractionSwitch
          name="disable_host_sanitize"
          description="disable_host_sanitize"
          helperText={`disable_host_sanitize.`}
          checked={props.form.$('backend[0].disable_host_sanitize').value}
          onChange={(e) => {
            props.form
              .$('backend[0].disable_host_sanitize')
              .set(e.target.checked)
          }}
        />
        <UserInteractionCustom
          name="krakendJson"
          description="JSON конфигурация"
          helperText="Итоговая конфигурация добавляемого endpoint в JSON формате."
        >
          <IconButton
            onClick={() => setKrakendJsonOpened(true)}
            sx={{ marginLeft: '-6px' }}
          >
            <BsFiletypeJson style={{ color: '#757575' }} />
          </IconButton>
          <KrakendJson
            endpoint={endpoint}
            opened={krakendJsonOpened}
            setOpened={setKrakendJsonOpened}
          />
        </UserInteractionCustom>
      </>
    )
  }
)

export default KrakendEndpointAction
