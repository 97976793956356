import { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { RoleBinding } from 'types/rbac/roleBinding'
import SettingsIcon from '@mui/icons-material/Settings'
import EditRoleBinding, { RoleBindingActions } from './EditRoleBinding'

export interface DeleteButtonProps {
  uuid: string
  name: string
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface UpdateButtonProps {
  roleBinding: RoleBinding
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface RoleBindingsTableProps {
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export const RoleBindingsTable = (props: RoleBindingsTableProps) => {
  const [roleBindings, setRoleBindings] = useState<RoleBinding[]>([])
  const apiClient = new ApiClient()

  const DeleteButton = (props: DeleteButtonProps) => {
    const deleteRoleBinding = async () => {
      props.setFailed(false)
      props.setErrorMessage('')

      try {
        await apiClient.deleteRoleBinding(props.uuid)
        getRoleBindings()
      } catch (e) {
        props.setFailed(true)

        if (e instanceof Error) {
          props.setErrorMessage(e.message)
        } else {
          props.setErrorMessage('Unknown error')
        }
      }
    }

    return (
      <IconButton onClick={deleteRoleBinding}>
        <Tooltip
          arrow
          title={`Удалить привязку роли "${props.name}"`}
          placement="top"
        >
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    )
  }

  const UpdateButton = (props: UpdateButtonProps) => {
    const [opened, setOpened] = useState<boolean>(false)

    const handleOpen = () => setOpened(true)

    return (
      <>
        <IconButton onClick={handleOpen}>
          <Tooltip
            arrow
            title={`Обновить привязку роли "${props.roleBinding.name}"`}
            placement="top"
          >
            <SettingsIcon />
          </Tooltip>
        </IconButton>
        <EditRoleBinding
          action={RoleBindingActions.Update}
          uuid={props.roleBinding.uuid}
          opened={opened}
          setOpened={setOpened}
          updated={props.updated}
          setUpdated={props.setUpdated}
          failed={props.failed}
          setFailed={props.setFailed}
          errorMessage={props.errorMessage}
          setErrorMessage={props.setErrorMessage}
          created={props.created}
          setCreated={props.setCreated}
        />
      </>
    )
  }

  const getRoleBindings = async () => {
    try {
      const data = await apiClient.getRoleBindings()
      setRoleBindings(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRoleBindings()
  }, [props.created, props.updated])

  const columns: GridColDef[] = [
    {
      field: 'update',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return (
          <UpdateButton
            roleBinding={params.row.roleBinding}
            created={props.created}
            setCreated={props.setCreated}
            updated={props.updated}
            setUpdated={props.setUpdated}
            failed={props.failed}
            setFailed={props.setFailed}
            errorMessage={props.errorMessage}
            setErrorMessage={props.setErrorMessage}
          />
        )
      },
    },
    {
      field: 'delete',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return (
          <DeleteButton
            uuid={params.row.uuid}
            name={params.row.name}
            failed={props.failed}
            setFailed={props.setFailed}
            errorMessage={props.errorMessage}
            setErrorMessage={props.setErrorMessage}
          />
        )
      },
    },
    {
      field: 'name',
      headerName: 'Название',
      minWidth: 300,
    },
    {
      field: 'role',
      headerName: 'Роль',
      minWidth: 300,
    },
    {
      field: 'subject',
      headerName: 'Субъект доступа',
      minWidth: 300,
    },
  ]

  const rows = roleBindings.map((roleBinding: RoleBinding) => {
    return {
      uuid: roleBinding.uuid,
      name: roleBinding.name,
      role: roleBinding.roleRef?.name,
      subject: roleBinding.subjectRef?.name,
      roleBinding: roleBinding,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.uuid}
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoPageSize
      autoHeight={true}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
      components={{ BaseButton: DeleteButton }}
    />
  )
}
