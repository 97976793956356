import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from 'components/CustomButton'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { EnvironmentSpec } from 'types/Managers/Environments'
import useFetchEnvironment from 'hooks/useFetchEnvironment'
import { NothingFoundPage } from '@edp/core-components-frontend'

export const EnvironmentsPage = () => {
  const [environments, setEnvironments] = useState<EnvironmentSpec[]>()
  const [filteredEnvironments, setFilteredEnvironments] =
    useState<EnvironmentSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [environmentToDelete, setEnvironmentToDelete] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()
  const getEnvironments = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getEnvironments()
      setEnvironments(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getEnvironments()
  }, [])

  useEffect(() => {
    const e: EnvironmentSpec[] = []

    if (environments && owner !== '') {
      for (const environment of environments) {
        if (environment.owner === owner) {
          e.push(environment)
        }
      }
      setFilteredEnvironments(e)
    }

    if (owner === '') {
      setFilteredEnvironments(environments)
    }
  }, [environments, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setEnvironmentToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteEnvironment(environmentToDelete).then(() => {
        setDeleted(true)
        getEnvironments()
        setDeletionWindowOpened(false)
      })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const environmentToDeleteSpec = useFetchEnvironment(environmentToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton component={Link} to={'/components/environments/create'}>
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {filteredEnvironments?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новое окружение, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {filteredEnvironments?.map((environment, index) => (
          <ComponentCard
            key={index}
            name={environment.name}
            uuid={environment.uuid}
            description={environment.description}
            options={[
              {
                name: 'Идентификатор',
                value: `${environment.slug}`,
              },
              {
                name: 'Владелец',
                value: environment.ownerRef?.name,
              },
              {
                name: 'Kubernetes кластер',
                value: environment.clusterRef?.name,
              },
            ]}
            linkOpen={`/components/environments/${environment.uuid}`}
            linkUpdate={`/components/environments/update/${environment.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление окружения'}
          text={`Окружение ${environmentToDeleteSpec?.name} будет удалено без возможности восстановления.`}
          additionalText={`Будут удалены ресурсы окружения в инфраструктурных сервисах, отмеченные при выборе интеграций во время создания окружения.`}
          spec={environmentToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Окружение ${environmentToDeleteSpec?.name} удалено.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении окружения ${environmentToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default EnvironmentsPage
