import { AuthType } from 'types/LocalAccounts'

export function setAuth(auth: boolean) {
  localStorage.setItem('auth', String(auth))
}

export function setAuthType(authType: AuthType) {
  localStorage.setItem('authType', authType)
}

export function deleteAuthType() {
  localStorage.removeItem('authType')
}
