import { useState } from 'react'

import { ApiClient } from 'api/ApiClient'
import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import BasicInfo from './EditPlatformServiceTemplatePage/BasicInfo'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'
import { useNavigate } from 'react-router-dom'

export const CreatePlatformServiceTemplatePage = () => {
  const navigate = useNavigate()
  const [platformServiceTemplate, setPlatformServiceTemplate] =
    useState<PlatformServiceTemplateSpec>({
      uuid: '',
      name: '',
      owner: '',
      description: '',
      helmRepo: '',
      helmChart: '',
      helmVersion: '',
    })

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const apiClient = new ApiClient()

  const handleCreate = async () => {
    setLoading(true)
    setFailed(false)
    setCreated(false)
    setErrorMessage('')

    try {
      const spec: PlatformServiceTemplateSpec = {
        uuid: platformServiceTemplate.uuid,
        name: platformServiceTemplate.name,
        owner: platformServiceTemplate.owner,
        description: platformServiceTemplate.description,
        helmRepo: platformServiceTemplate.helmRepo,
        helmChart: platformServiceTemplate.helmChart,
        helmVersion: platformServiceTemplate.helmVersion,
      }

      await apiClient.createPlatformServiceTemplate(spec)

      setCreated(true)
      navigate('/platform-services/templates')
    } catch (e) {
      if (e instanceof Error) {
        setFailed(true)
        setErrorMessage(e.message)
      } else {
        setFailed(true)
        setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createdMessage={`Шаблон платформенного сервиса ${platformServiceTemplate.name} создан`}
    >
      <BasicInfo
        platformServiceTemplate={platformServiceTemplate}
        setPlatformServiceTemplate={setPlatformServiceTemplate}
        disabled={false}
      />
    </CreateComponentPage>
  )
}

export default CreatePlatformServiceTemplatePage
