import React, { useState } from 'react'
import { Typography } from '@mui/material'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import { TeamSpec } from '@edp/types'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { GitlabAccessLevels } from 'types/index'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'

export interface IntegrationsProps {
  disabled: boolean
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

export const Integrations = (props: IntegrationsProps) => {
  const [nexusDockerHTTP, setNexusDockerHTTP] = useState<number>(0)

  const getNexusDockerHTTP = () => {
    if (props.team.integrations.nexus) {
      if (props.disabled && (props.team.integrations.nexus.enabled || false)) {
        return String(props.team.integrations.nexus.docker?.httpPort)
      }
    }

    return String(nexusDockerHTTP)
  }

  const setGitlabAccessLevel: React.Dispatch<React.SetStateAction<string>> = (
    value
  ) => {
    props.setTeam(() => ({
      ...props.team,
      integrations: {
        ...props.team.integrations,
        gitlab: {
          ...props.team.integrations.gitlab,
          syncMembers: {
            ...props.team.integrations.gitlab.syncMembers,
            accessLevel:
              value instanceof Function
                ? value(
                    props.team.integrations.gitlab.syncMembers?.accessLevel ||
                      ''
                  )
                : value,
          },
        },
      },
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Интеграции
      </Typography>
      <UserInteractionSwitch
        name="keycloak"
        description="Keycloak"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в Keycloak для команды. Будут созданы Group "${props.team.slug}" и Realm role "${props.team.slug}". Если в Keycloak уже существует группа с названием "${props.team.slug}" оставьте чекбокс отжатым и данная группа будет импортирована.`}
        checked={props.team.integrations.keycloak.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              keycloak: {
                ...props.team.integrations.keycloak,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="harbor"
        description="Harbor"
        helperText={`Включить создание ресурсов в Harbor для команды. Будут созданы OIDC группа "${props.team.slug}" и приватный проект "${props.team.slug}".`}
        checked={props.team.integrations.harbor.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              harbor: {
                ...props.team.integrations.harbor,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="vault"
        description="HashiCorp Vault"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в HashiCorp Vault для команды. Будут созданы Secret "${props.team.slug}", Policy "${props.team.slug}", Group "${props.team.slug}", Group Alias "${props.team.slug}", Transition key ${props.team.slug}.`}
        checked={props.team.integrations.vault.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              vault: {
                ...props.team.integrations.vault,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="argocd"
        description="Argo CD"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в Argo CD для команды. Будет создан проект "${props.team.slug}".`}
        checked={props.team.integrations.argocd?.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              argocd: {
                ...props.team.integrations.argocd,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="defectdojo"
        description="DefectDojo"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в DefectDojo для команды. Будет создана группа "${props.team.slug}".`}
        checked={props.team.integrations.defectdojo.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              defectdojo: {
                ...props.team.integrations.defectdojo,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <Typography
        variant="body2"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Grafana
      </Typography>
      <UserInteractionSwitch
        name="grafana"
        description="Создание ресурсов"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в Grafana для команды. Будет создана организация "${props.team.slug}".`}
        checked={props.team.integrations.grafana.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              grafana: {
                ...props.team.integrations.grafana,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionTextField
        name="grafana-dashboard"
        description="Dashboard URL"
        helperText="Ссылка на Grafana dashboard команды."
        value={props.team.integrations.grafana?.dashboard || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              grafana: {
                ...props.team.integrations.grafana,
                dashboard: event.target.value,
              },
            },
          })
        }
      />
      <Typography
        variant="body2"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Nexus
      </Typography>
      <UserInteractionSwitch
        name="nexus"
        description="Создание ресурсов"
        helperText={`Включить создание ресурсов в Nexus для команды.`}
        checked={props.team.integrations.nexus?.enabled || false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              nexus: {
                ...props.team.integrations.nexus,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      {props.team.integrations.nexus?.enabled ? (
        <>
          <UserInteractionSwitch
            name="nexus-kubernetes"
            description="Проксирование в Kubernetes"
            disabled={props.disabled}
            helperText={`Включить создание Kubernetes ресурсов для проксирования трафика в Docker репозиторий команды. Будут созданы ресурсы Ingress и Service.`}
            checked={
              props.team.integrations.nexus?.kubernetes?.enabled || false
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setTeam({
                ...props.team,
                integrations: {
                  ...props.team.integrations,
                  nexus: {
                    ...props.team.integrations.nexus,
                    kubernetes: {
                      ...props.team.integrations.nexus.kubernetes,
                      enabled: event.target.checked,
                    },
                  },
                },
              })
            }
          />
          <UserInteractionSwitch
            name="SelectNexusPorts"
            description="Автоматическое назначение портов"
            disabled={props.disabled}
            helperText="Включить автоматическое назначение портов для Docker репозитория."
            checked={
              props.team.integrations.nexus?.docker?.autoGeneratePort || false
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setTeam({
                ...props.team,
                integrations: {
                  ...props.team.integrations,
                  nexus: {
                    ...props.team.integrations.nexus,
                    docker: {
                      autoGeneratePort: event.target.checked,
                    },
                  },
                },
              })
            }}
          />
          {(!props.team.integrations.nexus?.docker?.autoGeneratePort ||
            false) &&
            !props.disabled && (
              <>
                <UserInteractionTextField
                  name={'NexusDockerHTTP'}
                  description={'HTTP порт Docker репозитория'}
                  disabled={props.disabled}
                  helperText={'HTTP порт Docker репозитория.'}
                  value={getNexusDockerHTTP()}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(event.target.value)) {
                      setNexusDockerHTTP(Number(event.target.value))
                      if (props.team.integrations.nexus?.docker || false) {
                        props.team.integrations.nexus.docker.httpPort = Number(
                          event.target.value
                        )
                        props.setTeam(props.team)
                      }
                    }
                  }}
                />
              </>
            )}
        </>
      ) : (
        <></>
      )}
      <Typography
        variant="body2"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Gitlab
      </Typography>
      <UserInteractionSwitch
        name="gitlab"
        description="Создание ресурсов"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов в Gitlab для команды. Будeт создана группа репозиториев "${props.team.slug}".`}
        checked={props.team.integrations.gitlab.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              gitlab: {
                ...props.team.integrations.gitlab,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="gitlab-sync"
        description="Синхронизация пользователей"
        helperText={`Включить возможность синхронизации пользователей команды между группами Keycloak и Gitlab.`}
        checked={props.team.integrations.gitlab.syncMembers?.enabled || false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              gitlab: {
                ...props.team.integrations.gitlab,
                syncMembers: {
                  ...props.team.integrations.gitlab.syncMembers,
                  enabled: event.target.checked,
                },
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="gitlab-auto-sync"
        description="Автоматическая синхронизация"
        helperText={`Запускать синхронизацию пользователей команды между группами Keycloak и Gitlab автоматически раз в 5 минут.`}
        checked={props.team.integrations.gitlab.syncMembers?.auto || false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setTeam({
            ...props.team,
            integrations: {
              ...props.team.integrations,
              gitlab: {
                ...props.team.integrations.gitlab,
                syncMembers: {
                  ...props.team.integrations.gitlab.syncMembers,
                  auto: event.target.checked,
                },
              },
            },
          })
        }
      />
      <UserInteractionCustomSelect
        name="accessLevel"
        description="Уровень доступа"
        helperText="Уровень доступа, который будет назначен синхронизированным пользователям команды по умолчанию."
        options={Object.keys(GitlabAccessLevels).filter((key) =>
          isNaN(Number(key))
        )}
        selected={props.team.integrations.gitlab.syncMembers?.accessLevel || ''}
        setSelected={setGitlabAccessLevel}
      />
    </>
  )
}
export default Integrations
