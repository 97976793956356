import React from 'react'

import { Typography } from '@mui/material'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import { ClusterSpec } from '@edp/types'

export interface IntegrationsProps {
  disabled: boolean
  cluster: ClusterSpec
  setCluster: React.Dispatch<React.SetStateAction<ClusterSpec>>
}

export const Integrations = (props: IntegrationsProps) => {
  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Интеграции
      </Typography>
      <UserInteractionSwitch
        name="argocd"
        description="Argo CD"
        disabled={props.disabled}
        helperText={`Включить регистрацию кластера в Argo CD.`}
        checked={props.cluster.integrations.argocd?.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setCluster({
            ...props.cluster,
            integrations: {
              ...props.cluster.integrations,
              argocd: {
                ...props.cluster.integrations.argocd,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
    </>
  )
}
