import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { LinearProgress, Grid, CardContent, Card } from '@mui/material'
import { TechRadarData } from 'types/TechRadar'
import { ApiClient } from 'api/ApiClient'
import { NothingFoundPage } from '@edp/core-components-frontend'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { Entry } from './utils/types'
import TechRadar from './TechRadar/TechRadar'

export function TechRadarPage() {
  const [techRadarData, setTechRadarData] = useState<TechRadarData>()
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const ref = useRef<HTMLDivElement | null>(null)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
    setHeight(ref.current ? ref.current.offsetHeight : 0)
  }, [ref.current])

  const apiClient = new ApiClient()

  const getTechRadarData = async () => {
    try {
      setLoading(true)
      const data = await apiClient.getTechRadarData()
      setTechRadarData(data)
    } catch (e) {
      setFailed(true)

      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTechRadarData()
  }, [])

  const mapToEntries = (data: TechRadarData): Entry[] => {
    return data.entries.map((entry) => ({
      id: entry.key,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      quadrant: data.quadrants.find((q) => q.id === entry.quadrant)!,
      title: entry.title,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ring: data.rings.find((r) => r.id === entry.timeline[0].ringId)!,
      timeline: entry.timeline.map((e) => {
        return {
          date: e.date,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ring: data.rings.find((a) => a.id === e.ringId)!,
          description: e.description,
          moved: e.moved,
        }
      }),
      moved: entry.timeline[0].moved,
      description: entry.description || entry.timeline[0].description,
      url: entry.url,
      links: entry.links,
    }))
  }

  return (
    <div ref={ref} style={{ height: '90%', width: '100%' }}>
      {loading && <LinearProgress />}
      {!loading && !failed && techRadarData !== undefined && (
        <Card sx={{ border: 'none', boxShadow: 'none', padding: 0 }}>
          <CardContent className="TechRadar-RadarPage-OverflowXScroll">
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} sm={6} md={4}>
                <TechRadar
                  width={width}
                  height={height}
                  rings={techRadarData.rings}
                  quadrants={techRadarData.quadrants}
                  entries={mapToEntries(techRadarData)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {!loading && !failed && techRadarData === undefined && (
        <NothingFoundPage text="Попробуйте добавить ссылку на технологический радар в конфигурацию edp-backend: 'techradar.url'." />
      )}
      {!loading && failed && techRadarData === undefined && (
        <CustomSnackbarComponent
          opened={failed}
          setOpened={setFailed}
          message={errorMessage}
          severity="error"
        />
      )}
    </div>
  )
}

export default TechRadarPage
