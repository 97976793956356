import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { SonarqubeCard } from '@edp/plugin-sonarqube-card-frontend'
import { ComponentCard } from 'components/ComponentCard'
import DefectDojoCard from '../DefectDojoCard/DefectDojoCard'
import useFetchService from 'hooks/useFetchService'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const service = useFetchService(uuid)

  return (
    <>
      <ComponentCard
        name={service?.name}
        description={service?.description}
        fullPage
        options={[
          {
            name: 'Идентификатор',
            value: `${service?.slug}`,
          },
          {
            name: 'Владелец',
            value: service?.ownerRef?.name,
            isUrl: true,
            externalUrl: false,
            url: `/organization/teams/${service?.ownerRef?.uuid}`,
          },
          {
            name: 'Репозиторий',
            isUrl: true,
            value: service?.repositoryRef?.name,
            url: `/components/repositories/${service?.repositoryRef?.uuid}`,
            externalUrl: false,
          },
          {
            name: 'Репозиторий в Gitlab',
            isUrl: true,
            value: service?.repositoryRef?.slug,
            url: service?.repositoryRef?.url,
            externalUrl: true,
          },
          {
            name: 'Создан из шаблона',
            value: service?.templated ? 'да' : 'нет',
          },
          ...(service?.templated
            ? [
                {
                  name: 'Шаблон',
                  isUrl: true,
                  value: service?.templateRef?.name,
                  url: `/components/service-templates/${service?.templateRef?.uuid}`,
                  externalUrl: false,
                },
              ]
            : []),
        ]}
      />
      <Grid
        item
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'space-between',
          paddingTop: '10px',
          gap: '10px',
        }}
      >
        <SonarqubeCard />
        <DefectDojoCard />
      </Grid>
    </>
  )
}

export default DescriptionPanel
