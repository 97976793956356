import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const branch = {
  name: null,
  ref: null,
}

const fields = {
  name: {
    value: null,
    rules: 'required|string|between:3,64',
  },
  slug: {
    value: null,
    rules: 'required|string|between:3,64|regex:/^[a-z][a-z0-9-]*$/',
  },
  description: null,
  owner: {
    value: null,
    rules: 'required',
  },
  repository: null,
  repositoryUrl: null,
  templated: false,
  template: null,
  templateRepositoryVariables: [],
  additionalActionsVariables: [],
  templateActionsVariables: [],
  actionsVariables: [],
  artifactsRegistryEngine: null,
  artifactsRegistryName: null,
  artifactsRegistry: null,
  artifactsRegistryURL: null,
  artifactsName: '$EDP_SERVICE_SLUG',
  gitlabProjectLevelVariables: [],
  branches: {
    value: [],
    options: {
      structure: branch,
    },
  },
  vault: {
    secrets: [],
  },
  integrations: {
    grafana: {
      dashboard: null,
    },
    sonarqube: {
      enabled: false,
      project: null,
    },
    git: {
      enabled: true,
    },

    defectdojo: {
      enabled: false,
      product: null,
    },
  },
}

export default new MobxReactForm({ fields }, { plugins })
