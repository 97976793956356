import React from 'react'

import {
  Alert,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { SonarqubeInfo } from 'types/Managers/InfraServices'

export interface sonarqubeInfoCardProps {
  status: string
  error: string
  info: SonarqubeInfo
}

export const SonarqubeInfoCard = (props: sonarqubeInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <LinearProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}

      {props.status === 'success' && props.info.System !== undefined && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Version:</b> {props.info.System.Version}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Health:</b> {props.info.Health}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Server ID:</b> {props.info.System['Server ID']}
            </ListItemText>
          </ListItem>
          {props.info.System.Edition !== undefined && (
            <ListItem>
              <ListItemText>
                <b>Edition:</b> {props.info.System.Edition}
              </ListItemText>
            </ListItem>
          )}
          <ListItem>
            <ListItemText>
              <b>Docker:</b> {String(props.info.System.Docker)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>High Availability:</b>{' '}
              {String(props.info.System['High Availability'])}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Official Distribution:</b>{' '}
              {String(props.info.System['Official Distribution'])}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Force authentication:</b>{' '}
              {String(props.info.System['Force authentication'])}
            </ListItemText>
          </ListItem>
        </List>
      )}
    </Paper>
  )
}
