import { ApiClient } from 'api/ApiClient'
import { ComponentCard } from 'components/ComponentCard'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const [platformServiceTemplate, setPlatformServiceTemplate] =
    useState<PlatformServiceTemplateSpec>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getPlatformServiceTemplates = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getPlatformServiceTemplate(uuid)
          setPlatformServiceTemplate(data)
        } catch (e) {
          console.log(e)
        }
      }
    }

    getPlatformServiceTemplates()
  }, [uuid])

  return (
    <ComponentCard
      name={platformServiceTemplate?.name}
      description={platformServiceTemplate?.description}
      fullPage
      options={[
        {
          name: 'Владелец',
          value: `${platformServiceTemplate?.ownerRef?.name}`,
        },
        {
          name: 'Helm chart',
          value: `${platformServiceTemplate?.helmChart}`,
        },
        {
          name: 'Версия Helm chart',
          value: `${platformServiceTemplate?.helmVersion}`,
        },
      ]}
    />
  )
}

export default DescriptionPanel
