import { Typography } from '@mui/material'
import UserInteractionHighlightedInput from 'components/UserInteraction/UserInteractionHighlightedInput'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import Form from 'mobx-react-form'
import { observer } from 'mobx-react-lite'
import './styles.css'
import UserInteractionInfraServiceSelect from 'components/UserInteraction/UserInteractionInfraServiceSelect'
import { INFRA_SERVICE_MATTERMOST } from 'types/Managers/InfraServices'

interface MattermostPostActionProps {
  form: Form
}

export const MattermostPostAction = observer(
  (props: MattermostPostActionProps) => {
    return (
      <>
        <Typography
          variant="subtitle2"
          className="Typography-Subtitle2"
          paddingTop="15px"
        >
          Настройки сообщения в Mattermost
        </Typography>
        <UserInteractionInfraServiceSelect
          name="instance"
          type={INFRA_SERVICE_MATTERMOST}
          description="Инстанс Mattermost"
          helperText="Инстанс Mattermost в который будет отправлено сообщение."
          selected={props.form.$('instance').value}
          setSelected={(value) => props.form.$('instance').set(value)}
        />
        <UserInteractionTextField
          name="channel_id"
          description="Channel ID"
          helperText="Идентификатор канала в Mattermost в который будет отправлено сообщение."
          {...props.form.$('post.channel_id').bind()}
        />
        <UserInteractionHighlightedInput
          name="message"
          language="markdown"
          description="Сообщение"
          helperText="Сообщение, которое будет отправлено в Mattermost."
          minHeight={'150px'}
          value={props.form.$('post.message').value}
          setValue={(value) => props.form.$('post.message').set(value)}
        />
      </>
    )
  }
)

export default MattermostPostAction
