import React from 'react'

import { Typography } from '@mui/material'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'

export interface BasicInfoProps {
  platformServiceTemplate: PlatformServiceTemplateSpec
  setPlatformServiceTemplate: React.Dispatch<
    React.SetStateAction<PlatformServiceTemplateSpec>
  >
  disabled: boolean
}

const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setPlatformServiceTemplate((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setOwner: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setPlatformServiceTemplate((prevState) => ({
      ...prevState,
      owner: value instanceof Function ? value(prevState.owner) : value,
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название шаблона платформенного сервиса. Должно быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные и прописные буквы латинского алфавита, цифры и дефисы."
        value={props.platformServiceTemplate.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание шаблона платформенного сервиса."
        value={props.platformServiceTemplate.description}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая шаблоном платформенного сервиса."
        selected={props.platformServiceTemplate.owner}
        setSelected={setOwner}
      />
      <UserInteractionTextField
        name="helmRepo"
        description="Helm registry"
        helperText="Ссылка на Helm registry в котором хранится Helm chart, используемый в качестве шаблона."
        value={props.platformServiceTemplate.helmRepo}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="helmChart"
        description="Helm chart"
        helperText="Название Helm chart, используемого в качестве шаблона."
        value={props.platformServiceTemplate.helmChart}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="helmVersion"
        description="Версия Helm chart"
        helperText="Версия Helm chart, используемого в качестве шаблона."
        value={props.platformServiceTemplate.helmVersion}
        onChange={handleChange}
      />
    </>
  )
}

export default BasicInfo
