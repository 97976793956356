import React from 'react'

import {
  Alert,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { PrometheusInfo } from 'types/Managers/InfraServices'

export interface prometheusInfoCardProps {
  status: string
  error: string
  info: PrometheusInfo
}

export const PrometheusInfoCard = (props: prometheusInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <LinearProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}

      {props.status === 'success' && props.info.data !== undefined && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Version:</b> {props.info.data.version}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Revision:</b> {props.info.data.revision}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Branch:</b> {props.info.data.branch}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Build user:</b> {props.info.data.buildUser}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Build date:</b> {props.info.data.buildDate}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Go version:</b> {props.info.data.goVersion}
            </ListItemText>
          </ListItem>
        </List>
      )}
    </Paper>
  )
}
