import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardMedia, LinearProgress } from '@mui/material'
import { ApiClient } from 'api/ApiClient'

export const OpensearchPanel = () => {
  const { uuid } = useParams()
  const [dashboardUrl, setDashboardUrl] = useState<string>('')
  const [loading, setLoading] = useState(true)

  const handleLoad = () => {
    setLoading(false)
  }

  const apiClient = new ApiClient()
  const getEnvironmentOpensearchDashboardUrl = async () => {
    try {
      const data = await apiClient.getEnvironmentOpensearchDashboardUrl(
        uuid || ''
      )
      setDashboardUrl(data.url)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getEnvironmentOpensearchDashboardUrl()
  }, [])

  return (
    <Card>
      {loading && <LinearProgress />}
      <CardMedia
        component="iframe"
        height="800px"
        src={dashboardUrl}
        sx={{
          border: 'none',
          boxShadow: 'none',
          visibility: loading ? 'hidden' : 'visible',
        }}
        onLoad={handleLoad}
        sandbox="allow-forms allow-scripts allow-top-navigation allow-same-origin"
      />
    </Card>
  )
}
