import { useEffect, useState } from 'react'

import { ApiClient } from 'api/ApiClient'
import BasicInfo from './EditPlatformServiceTemplatePage/BasicInfo'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'
import UpdateComponentPage from 'components/UpdateComponentPage/UpdateComponentPage'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export const UpdatePlatformServiceTemplatePage = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()

  const [platformServiceTemplate, setPlatformServiceTemplate] =
    useState<PlatformServiceTemplateSpec>({
      uuid: '',
      name: '',
      owner: '',
      description: '',
      helmRepo: '',
      helmChart: '',
      helmVersion: '',
    })

  const [updated, setUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const apiClient = new ApiClient()

  const getPlatformServiceTemplate = async () => {
    try {
      const data = await apiClient.getPlatformServiceTemplate(uuid || '')
      setPlatformServiceTemplate(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPlatformServiceTemplate()
  }, [])

  const handleUpdate = async () => {
    setLoading(true)
    setFailed(false)
    setUpdated(false)
    setErrorMessage('')

    try {
      const spec: PlatformServiceTemplateSpec = {
        uuid: platformServiceTemplate.uuid,
        name: platformServiceTemplate.name,
        owner: platformServiceTemplate.owner,
        description: platformServiceTemplate.description,
        helmRepo: platformServiceTemplate.helmRepo,
        helmChart: platformServiceTemplate.helmChart,
        helmVersion: platformServiceTemplate.helmVersion,
      }

      await apiClient.updatePlatformServiceTemplate(spec)

      setUpdated(true)
      navigate('/platform-services/templates')
    } catch (e) {
      if (e instanceof Error) {
        setFailed(true)
        setErrorMessage(e.message)
      } else {
        setFailed(true)
        setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <UpdateComponentPage
      handleUpdate={handleUpdate}
      loading={loading}
      updated={updated}
      setUpdated={setUpdated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      updatedMessage={`Шаблон платформенного сервиса ${platformServiceTemplate.name} обновлен`}
    >
      <BasicInfo
        platformServiceTemplate={platformServiceTemplate}
        setPlatformServiceTemplate={setPlatformServiceTemplate}
        disabled={true}
      />
    </UpdateComponentPage>
  )
}

export default UpdatePlatformServiceTemplatePage
