import { HelpOutline } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Tooltip, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { ApiClient } from 'api/ApiClient'
import UserInteractionActionSelect from 'components/UserInteraction/UserInteractionActionSelect'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Action, ActionType, ActionVariables } from 'types/Managers/Actions'
import { RepositoryVariable } from 'types/Managers/Repositories'
import {
  CustomWebhook,
  CustomWebhookLogo,
} from './TemplateActions/CustomWebhook'
import {
  GitlabAction,
  GitlabMRActionLogo,
  GitlabPipelineActionLogo,
} from './TemplateActions/GitlabAction'
import {
  GitlabGroupLevelVariables,
  GitlabGroupLevelVariablesLogo,
} from './TemplateActions/GitlabGroupLevelVariables'
import { KafkaTopic, KafkaTopicLogo } from './TemplateActions/KafkaTopic'
import {
  KrakendEndpoint,
  KrakendEndpointLogo,
} from './TemplateActions/KrakendEndpoint'
import {
  MattermostPost,
  MattermostPostLogo,
} from './TemplateActions/MattermostPost'
import {
  MattermostWebhook,
  MattermostWebhookLogo,
} from './TemplateActions/MattermostWebhook'
import {
  VaultSecretAction,
  VaultSecretActionLogo,
} from './TemplateActions/VaultSecretAction'
import ServiceStore from './store'
import './styles.css'
export interface TemplateActionsProps {
  disabled: boolean
}

export const TemplateActions = observer((props: TemplateActionsProps) => {
  const apiClient = new ApiClient()
  const [templateActions, setTemplateActions] = useState<Action[]>([])
  const [additionalActions, setAdditionalActions] = useState<Action[]>([])
  const [additionalActionsUUIDs, setAdditionalActionsUUIDs] = useState<
    string[]
  >([])

  const handleChangeEnabled = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const a: ActionVariables[] = [...ServiceStore.$('actionsVariables').value]
      a[index].action.enabled = event.target.checked

      ServiceStore.$('actionsVariables').set(a)
    })
  }

  const fetchTemplateActions = async () => {
    try {
      if (
        ServiceStore.$('templated').value &&
        ServiceStore.$('template').value
      ) {
        const a: Action[] = []
        const template = await apiClient.getServiceTemplate(
          ServiceStore.$('template').value
        )

        if (template.actions) {
          for (const actionUUID of template.actions) {
            const action = await apiClient.getAction(actionUUID)
            a.push(action)
          }
        }
        setTemplateActions(a)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchTemplateActions()
  }, [ServiceStore.$('template').value])

  const fetchAdditionalActions = async () => {
    try {
      if (ServiceStore.$('templated').value) {
        const a: Action[] = []

        for (const actionUUID of additionalActionsUUIDs) {
          if (actionUUID) {
            const action = await apiClient.getAction(actionUUID)
            a.push(action)
          }
        }

        setAdditionalActions(a)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchAdditionalActions()
  }, [additionalActionsUUIDs])

  const fetchActionsVariables = async (actions: Action[]) => {
    const a: ActionVariables[] = []

    for (const action of actions) {
      try {
        if (action.type == ActionType.GitlabPipeline && action.repository) {
          const variables: RepositoryVariable[] =
            await apiClient.getRepositoryVariables(action.repository)
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.GitlabMR && action.repository) {
          const variables: RepositoryVariable[] =
            await apiClient.getRepositoryVariables(action.repository)
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.VaultSecret) {
          const variables = action.vault?.secret
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.KrakendEndpoint) {
          const variables = action.krakend?.endpoint
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.KafkaTopic) {
          const variables = action.kafka?.topic
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.GitlabGroupLevelVariables) {
          const variables = action.gitlab?.groupLevelVariables
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.MattermostPost) {
          const variables = action.mattermost?.post
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.MattermostWebhook) {
          const variables = action.mattermost?.webhook
          a.push({ action: action, variables: variables })
        }
        if (action.type == ActionType.CustomWebhook) {
          const variables = action.customWebhook
          a.push({ action: action, variables: variables })
        }
      } catch (e) {
        console.error('Ошибка при получении данных:', e)
      }
    }

    return a
  }

  const fetchTemplateActionsVariables = async () => {
    const a = await fetchActionsVariables(templateActions)
    ServiceStore.$('templateActionsVariables').set(a)
  }

  useEffect(() => {
    fetchTemplateActionsVariables()
  }, [templateActions])

  const fetchAdditionalActionsVariables = async () => {
    const a = await fetchActionsVariables(additionalActions)
    ServiceStore.$('additionalActionsVariables').set(a)
  }

  useEffect(() => {
    fetchAdditionalActionsVariables()
  }, [additionalActions])

  const setActionsVariables = async () => {
    const a = [
      ...ServiceStore.$('templateActionsVariables').value,
      ...ServiceStore.$('additionalActionsVariables').value,
    ]

    ServiceStore.$('actionsVariables').set(a)
  }

  useEffect(() => {
    setActionsVariables()
  }, [
    ServiceStore.$('templateActionsVariables').value,
    ServiceStore.$('additionalActionsVariables').value,
  ])

  const handleAddAdditionalAction = () => {
    runInAction(() => {
      let a: string[] = [...additionalActionsUUIDs]
      a = [...a, '']
      setAdditionalActionsUUIDs(a)
    })
  }

  const handleRemoveAdditionalAction = (index: number) => {
    runInAction(() => {
      const a = [...additionalActionsUUIDs]
      a.splice(index, 1)
      setAdditionalActionsUUIDs(a)
    })
  }

  const handleChangeAdditionalActions = (
    index: number,
    value: React.SetStateAction<string>
  ) => {
    runInAction(async () => {
      const a = [...additionalActionsUUIDs]
      a[index] = String(value)
      setAdditionalActionsUUIDs(a)
    })
  }

  return (
    <>
      {!props.disabled && ServiceStore.$('templated').value && (
        <>
          <Typography
            variant="subtitle2"
            paddingTop="30px"
            className="Typography-Subtitle2"
          >
            Actions
          </Typography>
          {ServiceStore.$('actionsVariables').value &&
            ServiceStore.$('actionsVariables').value.map(
              (actionVariables: ActionVariables, index: number) => (
                <Accordion
                  key={`accordion-${index}`}
                  elevation={0}
                  sx={{
                    width: '670px',
                    '&::before': {
                      visibility: 'hidden',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      padding: '0px',
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                  >
                    {actionVariables.action.type ===
                      ActionType.GitlabPipeline && <GitlabPipelineActionLogo />}
                    {actionVariables.action.type === ActionType.GitlabMR && (
                      <GitlabMRActionLogo />
                    )}
                    {actionVariables.action.type === ActionType.VaultSecret && (
                      <VaultSecretActionLogo />
                    )}
                    {actionVariables.action.type ===
                      ActionType.KrakendEndpoint && <KrakendEndpointLogo />}
                    {actionVariables.action.type === ActionType.KafkaTopic && (
                      <KafkaTopicLogo />
                    )}
                    {actionVariables.action.type ===
                      ActionType.GitlabGroupLevelVariables && (
                      <GitlabGroupLevelVariablesLogo />
                    )}
                    {actionVariables.action.type ===
                      ActionType.MattermostPost && <MattermostPostLogo />}
                    {actionVariables.action.type ===
                      ActionType.MattermostWebhook && <MattermostWebhookLogo />}
                    {actionVariables.action.type ===
                      ActionType.CustomWebhook && <CustomWebhookLogo />}
                    <Typography variant="subtitle2">
                      {actionVariables.action.name}
                    </Typography>
                    <Tooltip
                      arrow
                      title={actionVariables.action.description}
                      placement="top"
                    >
                      <HelpOutline className="Accordion-HelpIcon"></HelpOutline>
                    </Tooltip>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: '0px',
                      width: '800px',
                    }}
                  >
                    <UserInteractionSwitch
                      name={`enabled-${index}`}
                      helperText={'Запускать ли Action при создании сервиса'}
                      description={'Включить action'}
                      checked={actionVariables.action.enabled}
                      onChange={(event: any) =>
                        handleChangeEnabled(index, event)
                      }
                    />
                    {[ActionType.GitlabPipeline, ActionType.GitlabMR].includes(
                      actionVariables.action.type
                    ) && (
                      <GitlabAction
                        actionVariables={actionVariables}
                        index={index}
                        serviceSlug={ServiceStore.$('slug').value}
                      />
                    )}
                    {[ActionType.VaultSecret].includes(
                      actionVariables.action.type
                    ) && (
                      <VaultSecretAction
                        actionVariables={actionVariables}
                        index={index}
                      />
                    )}
                    {[ActionType.KrakendEndpoint].includes(
                      actionVariables.action.type
                    ) && (
                      <KrakendEndpoint
                        actionVariables={actionVariables}
                        index={index}
                      />
                    )}
                    {[ActionType.KafkaTopic].includes(
                      actionVariables.action.type
                    ) && (
                      <KafkaTopic
                        actionVariables={actionVariables}
                        index={index}
                      />
                    )}
                    {[ActionType.GitlabGroupLevelVariables].includes(
                      actionVariables.action.type
                    ) && (
                      <GitlabGroupLevelVariables
                        actionVariables={actionVariables}
                        index={index}
                      />
                    )}
                    {[ActionType.MattermostPost].includes(
                      actionVariables.action.type
                    ) && (
                      <MattermostPost
                        actionVariables={actionVariables}
                        index={index}
                      />
                    )}
                    {[ActionType.MattermostWebhook].includes(
                      actionVariables.action.type
                    ) && (
                      <MattermostWebhook
                        actionVariables={actionVariables}
                        index={index}
                      />
                    )}
                    {[ActionType.CustomWebhook].includes(
                      actionVariables.action.type
                    ) && (
                      <CustomWebhook
                        actionVariables={actionVariables}
                        index={index}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )
            )}
          <Typography
            variant="subtitle2"
            paddingTop="30px"
            className="Typography-Subtitle2"
          >
            Дополнительные actions
          </Typography>
          {additionalActionsUUIDs.map((action: string, index: number) => (
            <UserInteractionActionSelect
              name={`additional-action-${index + 1}`}
              description={`Дополнительный action ${index + 1}`}
              helperText={`Дополнительный action ${index + 1} для сервиса.`}
              key={`additional-action-${index + 1}`}
              removeButton
              handleRemove={() => handleRemoveAdditionalAction(index)}
              selected={action}
              setSelected={(value) =>
                handleChangeAdditionalActions(index, value)
              }
            />
          ))}
          <UserInteractionAddButton handleAdd={handleAddAdditionalAction} />
        </>
      )}
    </>
  )
})

export default TemplateActions
