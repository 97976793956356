import { observer } from 'mobx-react-lite'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import { Typography } from '@mui/material'
import ServiceTemplateStore from './store'
import UserInteractionRepositorySelect from 'components/UserInteraction/UserInteractionRepositorySelect'
import './styles.css'

const BasicInfo = observer(() => {
  return (
    <>
      <Typography variant="body1" className="Typography-Body1">
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название шаблона сервиса."
        error={!ServiceTemplateStore.$('name').isValid}
        {...ServiceTemplateStore.$('name').bind()}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор шаблона сервиса. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        error={!ServiceTemplateStore.$('slug').isValid}
        {...ServiceTemplateStore.$('slug').bind()}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание шаблона сервиса."
        multiline
        rows={6}
        height={'150px'}
        {...ServiceTemplateStore.$('description').bind()}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая шаблоном сервиса."
        error={!ServiceTemplateStore.$('owner').isValid}
        selected={ServiceTemplateStore.$('owner').value}
        setSelected={(value) => ServiceTemplateStore.$('owner').set(value)}
      />
      <UserInteractionRepositorySelect
        name="templateRepository"
        description="Шаблонный репозиторий"
        helperText="Репозиторий с шаблоном сервиса."
        template
        error={!ServiceTemplateStore.$('templateRepository').isValid}
        selected={ServiceTemplateStore.$('templateRepository').value}
        setSelected={(value) =>
          ServiceTemplateStore.$('templateRepository').set(value)
        }
      />
    </>
  )
})

export default BasicInfo
