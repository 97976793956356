import React from 'react'
import { Avatar, Tooltip } from '@mui/material'
import { GitlabContributorData } from 'types/Controllers/Gitlab'

type Props = {
  contributor: GitlabContributorData
}

export const Contributor = ({ contributor }: Props) => (
  <Tooltip
    style={{
      backgroundColor: 'white',
      border: '1px solid lightgrey',
      color: '#333',
    }}
    title={contributor.name}
  >
    <Avatar
      key={contributor.name}
      alt={contributor.name}
      src={contributor.avatar_url}
    />
  </Tooltip>
)
