import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import useFetchAction from 'hooks/useFetchAction'
import { ActionType } from 'types/Managers/Actions'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const action = useFetchAction(uuid)

  return (
    <ComponentCard
      name={action?.name}
      description={action?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: action?.slug,
        },
        {
          name: 'Владелец',
          value: action?.ownerRef?.name,
          isUrl: true,
          externalUrl: false,
          url: `/organization/teams/${action?.ownerRef?.uuid}`,
        },
        {
          name: 'Тип',
          value: action?.type,
        },
        ...(action?.type &&
        [ActionType.GitlabPipeline, ActionType.GitlabMR].includes(action?.type)
          ? [
              {
                name: 'Репозиторий',
                isUrl: true,
                value: action?.repositoryRef?.name,
                url: `/components/repositories/${action?.repositoryRef?.uuid}`,
                externalUrl: false,
              },
              {
                name: 'Репозиторий в Gitlab',
                isUrl: true,
                value: action?.repositoryRef?.slug,
                url: action?.repositoryRef?.url,
                externalUrl: true,
              },
            ]
          : []),
        ...(action?.type &&
        [ActionType.GitlabMR, ActionType.KrakendEndpoint].includes(action?.type)
          ? [
              {
                name: 'Целевой репозиторий',
                isUrl: true,
                value: action?.targetRepositoryRef?.name,
                url: `/components/repositories/${action?.targetRepositoryRef?.uuid}`,
                externalUrl: false,
              },
              {
                name: 'Целевой репозиторий в Gitlab',
                isUrl: true,
                value: action?.targetRepositoryRef?.slug,
                url: action?.targetRepositoryRef?.url,
                externalUrl: true,
              },
              {
                name: 'Ветка целевого репозитория',
                value: action?.targetBranch,
              },
            ]
          : []),
        ...(action?.type &&
        [ActionType.GitlabGroupLevelVariables].includes(action?.type)
          ? [
              {
                name: 'Целевая группа в Gitlab',
                isUrl: false,
                value: action?.gitlab?.groupLevelVariables?.group,
              },
            ]
          : []),
      ]}
    />
  )
}

export default DescriptionPanel
