import React from 'react'
import { WithLink } from '../utils/components'
import type { EntrySnapshot } from '../utils/types'
import { Typography } from '@mui/material'
import { TechRadarDescription } from '../TechRadarDescription/TechRadarDescription'

type TechRadarLegendLinkProps = {
  url?: string
  description?: string
  title?: string
  active?: boolean
  links: Array<{ url: string; title: string }>
  timeline: EntrySnapshot[]
}

export const TechRadarLegendLink = ({
  url,
  description,
  title,
  active,
  links,
  timeline,
}: TechRadarLegendLinkProps) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const toggle = () => {
    setOpen(!open)
  }

  if (description) {
    return (
      <>
        <Typography
          component="span"
          className="TechRadar-RadarLegend-EntryLink"
          onClick={handleClickOpen}
          role="button"
          tabIndex={0}
          onKeyPress={toggle}
        >
          <Typography
            component="span"
            className={
              active
                ? 'TechRadar-RadarLegend-ActiveEntry'
                : 'TechRadar-RadarLegend-Entry'
            }
          >
            {title}
          </Typography>
        </Typography>
        {open && (
          <TechRadarDescription
            open={open}
            onClose={handleClose}
            title={title ? title : 'no title'}
            url={url}
            description={description}
            timeline={timeline ? timeline : []}
            links={links}
          />
        )}
      </>
    )
  }
  return (
    <WithLink url={url} className="TechRadar-RadarLegend-EntryLink">
      <Typography
        component="span"
        className={
          active
            ? 'TechRadar-RadarLegend-ActiveEntry'
            : 'TechRadar-RadarLegend-Entry'
        }
      >
        {title}
      </Typography>
    </WithLink>
  )
}
