import { observer } from 'mobx-react-lite'

import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import BasicInfo from './BasicInfo'
import HelmChartStore from './store'
import { HelmChartSpec } from 'types/Managers/HelmCharts'
import { EditComponentPage } from 'components/EditComponentPage'
import { EntityAction } from 'types/entities'

export interface EditHelmChartPageProps {
  action: EntityAction
}

export const EditHelmChartPage = observer((props: EditHelmChartPageProps) => {
  const { uuid } = useParams()
  const navigate = useNavigate()

  const [edited, setEdited] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  useEffect(() => {
    HelmChartStore.reset()
  }, [])

  const fetchData = async () => {
    try {
      if (uuid) {
        const data = await apiClient.getHelmChart(uuid)
        HelmChartStore.set(data)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [uuid])

  const handleEdit = async () => {
    setEdited(false)
    setFailed(false)
    setErrorMessage('')
    setLoading(true)

    try {
      await HelmChartStore.validate().then(({ isValid }) => {
        if (!isValid) {
          setFailed(true)
          const e = new Error('Все обязательные поля должны быть заполнены')
          return Promise.reject(e)
        }
      })

      const spec: HelmChartSpec = {
        name: HelmChartStore.$('name').value,
        description: HelmChartStore.$('description').value,
        owner: HelmChartStore.$('owner').value,
        registryEngine: HelmChartStore.$('registryEngine').value,
        registryURL: HelmChartStore.$('registryURL').value,
        registry: HelmChartStore.$('registry').value,
      }

      if (props.action === EntityAction.Create) {
        await apiClient.createHelmChart(spec)
      }

      if (props.action === EntityAction.Update) {
        spec.uuid = uuid
        await apiClient.updateHelmChart(spec)
      }

      setEdited(true)
      navigate('/components/helm-charts')
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
    } finally {
      setLoading(false)
    }
  }

  let editedMessage

  if (props.action === EntityAction.Create) {
    editedMessage = 'Helm chart зарегистрирован'
  }

  if (props.action === EntityAction.Update) {
    editedMessage = 'Helm chart обновлен'
  }

  return (
    <>
      <EditComponentPage
        action={props.action}
        handleEdit={handleEdit}
        loading={loading}
        edited={edited}
        setEdited={setEdited}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        editedMessage={editedMessage}
      >
        <BasicInfo disabled={props.action === EntityAction.Update} />
      </EditComponentPage>
    </>
  )
})

export default EditHelmChartPage
