import {
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { NexusStatus } from 'types/Managers/InfraServices'

export interface nexusInfoCardProps {
  status: string
  error: string
  info: NexusStatus
}

export const NexusInfoCard = (props: nexusInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <CircularProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <>
          <Alert severity="success">Подключение установлено</Alert>
          <List>
            <ListItem>
              <ListItemText>
                <b>Available CPUs: </b>
                {props.info['Available CPUs']?.healthy ? `ОК` : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Azure Blob Store: </b>
                {props.info['Azure Blob Store']?.healthy
                  ? `ОК`
                  : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Coordinate Content Selectors: </b>
                {props.info['Coordinate Content Selectors']?.message}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Default Role Realm: </b>
                {props.info.DefaultRoleRealm?.message}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>File Blob Stores Path: </b>
                {props.info['File Blob Stores Path']?.healthy
                  ? `ОК`
                  : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>File Descriptors: </b>
                {props.info['File Descriptors']?.healthy
                  ? `ОК`
                  : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Lifecycle Phase: </b>
                {props.info['Lifecycle Phase']?.healthy
                  ? `ОК`
                  : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Read-Only Detector: </b>
                {props.info['Read-Only Detector']?.healthy
                  ? `ОК`
                  : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Scheduler: </b>
                {props.info['Scheduler']?.healthy ? `ОК` : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Scripting: </b>
                {props.info['Scripting']?.healthy ? `ОК` : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Thread Deadlock Detector: </b>
                {props.info['Thread Deadlock Detector']?.healthy
                  ? `ОК`
                  : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <b>Transactions: </b>
                {props.info['Transactions']?.healthy ? `ОК` : 'Есть проблемы'}
              </ListItemText>
            </ListItem>
          </List>
        </>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}
    </Paper>
  )
}
