import React, { forwardRef } from 'react'

import { Grid, TextField, Tooltip } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'

import './styles.css'
import { CHANGE_ME_PLACEHOLDER } from 'types/other'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'

export interface UserInteractionThreeTextFieldsProps {
  first: string
  second: any
  third: any
  index?: number
  handleChangeFirst?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeSecond?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeThird?: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  rows?: number
  height?: string
  firstDisabled?: boolean
  secondDisabled?: boolean
  thirdDisabled?: boolean
  firstLabel?: string
  secondLabel?: string
  thirdLabel?: string
  firstReadOnly?: boolean
  secondReadOnly?: boolean
  thirdReadOnly?: boolean
  type?: string
  helperText?: string
  removeButton?: boolean
  handleRemove?: () => void
}

const UserInteractionThreeTextFields = forwardRef(
  (props: UserInteractionThreeTextFieldsProps, _ref) => {
    return (
      <Grid className="UserInteraction-Grid-Remove">
        <Grid className="UserInteraction-Grid">
          <TextField
            key={`${props.index}-first`}
            name={props.first}
            className="UserInteraction-ThreeTextFields-First"
            label={props.firstLabel}
            value={props.first}
            type={props.type}
            error={props.first === CHANGE_ME_PLACEHOLDER}
            onChange={props.handleChangeFirst}
            required={props.required || false}
            disabled={props.firstDisabled}
            InputProps={{
              readOnly: props.firstReadOnly,
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                fontSize: '10px',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(14px, -5px) scale(0.75)',
                },
              },
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: `${props.height || '40px'}`,
              },
              '& .MuiOutlinedInput-root': {
                fontSize: '10px',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '15px',
                fontWeight: '700',
              },
            }}
          />
          <TextField
            key={`${props.index}-second`}
            name={props.second}
            className="UserInteraction-ThreeTextFields-Second"
            label={props.secondLabel}
            value={props.second}
            error={props.second === CHANGE_ME_PLACEHOLDER}
            type={props.type}
            onChange={props.handleChangeSecond}
            required={props.required || false}
            disabled={props.secondDisabled}
            InputProps={{
              readOnly: props.secondReadOnly,
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                fontSize: '10px',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(14px, -5px) scale(0.75)',
                },
              },
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: `${props.height || '40px'}`,
              },
              '& .MuiOutlinedInput-root': {
                fontSize: '10px',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '15px',
              },
            }}
          />
          <TextField
            key={`${props.index}-third`}
            name={props.third}
            className="UserInteraction-ThreeTextFields-Third"
            label={props.thirdLabel}
            value={props.third}
            error={props.third === CHANGE_ME_PLACEHOLDER}
            type={props.type}
            onChange={props.handleChangeThird}
            required={props.required || false}
            disabled={props.thirdDisabled}
            InputProps={{
              readOnly: props.thirdReadOnly,
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                fontSize: '10px',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(14px, -5px) scale(0.75)',
                },
              },
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: `${props.height || '40px'}`,
              },
              '& .MuiOutlinedInput-root': {
                fontSize: '10px',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '15px',
              },
            }}
          />
        </Grid>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-ThreeTextFields-HelpIcon"></HelpOutline>
        </Tooltip>
        {props.removeButton && (
          <UserInteractionRemoveButton handleRemove={props.handleRemove} />
        )}
      </Grid>
    )
  }
)

UserInteractionThreeTextFields.displayName = 'UserInteractionThreeTextFields'

export default UserInteractionThreeTextFields
