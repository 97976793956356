import React from 'react'

import { HelpOutline } from '@mui/icons-material'
import { Grid, Typography, Tooltip } from '@mui/material'
import './styles.css'
import {
  CustomSelectComponentUUIDOption,
  CustomSelectComponentUUID,
} from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'

export interface UserInteractionCustomSelectUUIDProps {
  name: string
  description: string
  options: CustomSelectComponentUUIDOption[]
  helperText: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  error?: boolean
  removeButton?: boolean
  handleRemove?: () => void
}

const UserInteractionCustomSelectUUID = (
  props: UserInteractionCustomSelectUUIDProps
) => {
  return (
    <Grid className="UserInteraction-Grid-Remove">
      <Grid className="UserInteraction-Grid">
        <Grid className="UserInteraction-Description">
          <Typography variant="body2" fontSize={'15px'}>
            {props.description}
          </Typography>
          <Tooltip arrow title={props.helperText} placement="top">
            <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
          </Tooltip>
        </Grid>
        <CustomSelectComponentUUID
          options={props.options}
          selected={props.selected}
          setSelected={props.setSelected}
          error={props.error}
        />
      </Grid>
      {props.removeButton && (
        <UserInteractionRemoveButton handleRemove={props.handleRemove} />
      )}
    </Grid>
  )
}

export default UserInteractionCustomSelectUUID
