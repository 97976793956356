import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Link } from '@mui/material'
import { V1Ingress, V1IngressRule } from '@kubernetes/client-node'
import ManifestButton from './ManifestButton'

export interface IngressesTableProps {
  ingresses: V1Ingress[]
}

export const IngressesTable = (props: IngressesTableProps) => {
  const columns: GridColDef[] = [
    {
      field: 'manifest',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return <ManifestButton manifest={params.row.ingress} />
      },
    },
    {
      field: 'name',
      headerName: 'Название',
      minWidth: 200,
    },
    {
      field: 'namespace',
      headerName: 'Namespace',
      minWidth: 230,
    },
    {
      field: 'rules',
      headerName: 'Hosts',
      minWidth: 600,
      renderCell: (params) => {
        return (
          <div>
            {params.row.rules.map((rule: V1IngressRule) => (
              <div key={rule.host}>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={`https://${rule.host}`}
                >
                  {rule.host}
                </Link>
                <> </>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={`http://${rule.host}`}
                >
                  (http)
                </Link>
              </div>
            ))}
          </div>
        )
      },
    },
  ]

  const rows = props.ingresses.map((ingress: V1Ingress) => {
    return {
      name: ingress.metadata?.name,
      namespace: ingress.metadata?.namespace,
      rules: ingress.spec?.rules,
      ingress: ingress,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.name}
      columns={columns}
      rows={rows}
      pageSize={8}
      rowsPerPageOptions={[8]}
      autoPageSize
      autoHeight={true}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      onCellClick={() => {}}
      getRowHeight={() => 'auto'}
    />
  )
}
