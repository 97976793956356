import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { HelmChartSpec } from 'types/Managers/HelmCharts'

const useFetchHelmChart = (uuid?: string) => {
  const [helmChart, setHelmChart] = useState<HelmChartSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchHelmChart = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getHelmChart(uuid)
          setHelmChart(data)
        } catch (e) {
          console.error('Ошибка при получении Helm chart:', e)
        }
      }
    }

    fetchHelmChart()
  }, [uuid])

  return helmChart
}

export default useFetchHelmChart
