import { useState } from 'react'
import { PieChart, Pie, Sector, Cell } from 'recharts'
import prettyBytes from 'pretty-bytes'

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    resource,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  let formattedValue = `${value}`

  if (['CPU', 'CPU (requests)', 'CPU (limits)'].includes(resource)) {
    formattedValue = `${value}m`
  }

  if (['RAM', 'RAM (requests)', 'RAM (limits)'].includes(resource)) {
    formattedValue = prettyBytes(value, {
      binary: true,
    })
  }

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={fill}
        fontSize="11px"
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}
        fontSize="14px"
      >
        {formattedValue}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fontSize="12px"
        fill="#999"
      >
        {`(${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  )
}

export interface CustomPieChartProps {
  resource?: string
  loading?: boolean
  data: {
    name: string
    value: number
    color: string
  }[]
}

export const CustomPieChart = (props: CustomPieChartProps) => {
  const { data } = props
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index)
  }

  const additionalProps = {
    resource: props.resource,
  }

  return (
    <>
      <PieChart width={500} height={290}>
        <Pie
          activeIndex={activeIndex}
          activeShape={(props: any) =>
            renderActiveShape({ ...props, ...additionalProps })
          }
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={90}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </>
  )
}

export default CustomPieChart
