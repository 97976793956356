import { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { IconButton, LinearProgress, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { PartitionMetadata } from 'kafkajs'
import { KafkaTopic } from 'types/Controllers/Kafka'
import EditKafkaTopic, { KafkaTopicActions } from './EditKafkaTopic'
import SettingsIcon from '@mui/icons-material/Settings'
import useFetchKafkaCluster from 'hooks/useFetchKafkaCluster'

export interface KafkaTopicsTableProps {
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface DeleteButtonProps {
  name: string
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface UpdateButtonProps {
  name: string
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export const KafkaTopicsTable = (props: KafkaTopicsTableProps) => {
  const { uuid } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [topics, setTopics] = useState<KafkaTopic[]>([])

  const kafkaCluster = useFetchKafkaCluster(uuid)

  const apiClient = new ApiClient()

  const DeleteButton = (props: DeleteButtonProps) => {
    const apiClient = new ApiClient()

    const deleteTopic = async () => {
      await apiClient.deleteKafkaTopic(uuid || '', props.name)
      getTopics()
    }

    return (
      <IconButton
        onClick={deleteTopic}
        disabled={!kafkaCluster?.allowTopicsDeletion}
      >
        <Tooltip arrow title={`Удалить топик "${props.name}"`} placement="top">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    )
  }

  const UpdateButton = (props: UpdateButtonProps) => {
    const [opened, setOpened] = useState<boolean>(false)

    const handleOpen = () => setOpened(true)

    return (
      <>
        <IconButton onClick={handleOpen}>
          <Tooltip
            arrow
            title={`Обновить топик "${props.name}"`}
            placement="top"
          >
            <SettingsIcon />
          </Tooltip>
        </IconButton>
        <EditKafkaTopic
          action={KafkaTopicActions.Update}
          name={props.name}
          opened={opened}
          disabled
          setOpened={setOpened}
          updated={props.updated}
          setUpdated={props.setUpdated}
          failed={props.failed}
          setFailed={props.setFailed}
          errorMessage={props.errorMessage}
          setErrorMessage={props.setErrorMessage}
          created={props.created}
          setCreated={props.setCreated}
        />
      </>
    )
  }

  const getTopics = async () => {
    try {
      setLoading(true)
      const data = await apiClient.getKafkaTopics(uuid || '')
      setTopics(data)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('Неизвестная ошибка')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTopics()
  }, [props.created, props.updated])

  const columns: GridColDef[] = [
    {
      field: 'update',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return (
          <UpdateButton
            name={params.row.name}
            created={props.created}
            setCreated={props.setCreated}
            updated={props.updated}
            setUpdated={props.setUpdated}
            failed={props.failed}
            setFailed={props.setFailed}
            errorMessage={props.errorMessage}
            setErrorMessage={props.setErrorMessage}
          />
        )
      },
    },
    {
      field: 'delete',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return (
          <DeleteButton
            name={params.row.name}
            failed={props.failed}
            setFailed={props.setFailed}
            errorMessage={props.errorMessage}
            setErrorMessage={props.setErrorMessage}
          />
        )
      },
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 300,
    },
    {
      field: 'partitions',
      headerName: 'Партиции',
      width: 500,
      renderCell: (params) => {
        return (
          <div>
            {params.row.partitions.map((partition: PartitionMetadata) => (
              <div>
                {`ID: ${partition.partitionId}, `}
                {`реплик: ${partition.replicas.length}, `}
                {`реплик offline: ${partition.offlineReplicas?.length}`}
              </div>
            ))}
          </div>
        )
      },
    },
  ]

  const rows = topics.map((topic: KafkaTopic) => {
    return {
      name: topic.name,
      partitions: topic.metadata.partitions,
    }
  })

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && (
        <DataGrid
          getRowId={() => uuidv4()}
          columns={columns}
          rows={rows}
          pageSize={12}
          rowsPerPageOptions={[12]}
          autoPageSize
          autoHeight={true}
          getRowHeight={() => 'auto'}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      )}
    </>
  )
}
