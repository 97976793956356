import {
  AppBar,
  Breadcrumbs,
  Divider,
  Toolbar,
  Typography,
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import colorConfigs from '../../configs/colorConfigs'
import sizeConfigs from '../../configs/sizeConfigs'
import { Link as RouterLink } from 'react-router-dom'
import appRoutes from '../../routes/routes'
import { RouteType } from 'routes/config'
import Link, { LinkProps } from '@mui/material/Link'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { ApiClient } from 'api/ApiClient'
import { useEffect, useMemo, useState } from 'react'

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />
}

async function findBreadcrumbs(
  routes: RouteType[],
  path: string,
  uuid?: string,
  name?: string
): Promise<{ pretty: string | undefined; accessible: boolean } | any> {
  let replaced = ''

  for (const route of routes) {
    let tmp = route.path

    if (route.topbarProps?.nameFromParams && uuid) {
      tmp = tmp.replace(/:uuid/g, uuid)
    }

    if (route.topbarProps?.nameFromParams && name) {
      tmp = tmp.replace(/:name/g, name)
    }

    if (tmp !== route.path) {
      replaced = tmp
    }

    if (route.path === path || replaced === path) {
      if (route.topbarProps?.nameFromParams) {
        if (route.path.endsWith(':name')) {
          return {
            pretty: name,
            accessible:
              route.topbarProps?.accessible === undefined
                ? true
                : route.topbarProps?.accessible,
          }
        }

        if (route.path.endsWith(':uuid')) {
          const apiClient = new ApiClient()
          if (route.topbarProps.entity === 'Service') {
            return {
              pretty: (await apiClient.getService(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'Environment') {
            return {
              pretty: (await apiClient.getEnvironment(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'Team') {
            return {
              pretty: (await apiClient.getTeam(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'Repository') {
            return {
              pretty: (await apiClient.getRepository(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'HelmChart') {
            return {
              pretty: (await apiClient.getHelmChart(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'Cluster') {
            return {
              pretty: (await apiClient.getCluster(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'PlatformServiceTemplate') {
            return {
              pretty: (await apiClient.getPlatformServiceTemplate(uuid || ''))
                .name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'PlatformServiceConfiguration') {
            return {
              pretty: (
                await apiClient.getPlatformServiceConfiguration(uuid || '')
              ).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'PlatformServiceInstance') {
            return {
              pretty: (await apiClient.getPlatformServiceInstance(uuid || ''))
                .name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'CustomEntity') {
            return {
              pretty: (await apiClient.getCustomEntity(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'KafkaCluster') {
            return {
              pretty: (await apiClient.getKafkaCluster(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'Action') {
            return {
              pretty: (await apiClient.getAction(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'ServiceTemplate') {
            return {
              pretty: (await apiClient.getServiceTemplate(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          if (route.topbarProps.entity === 'KubernetesNamespace') {
            return {
              pretty: (await apiClient.getKubernetesNamespace(uuid || '')).name,
              accessible:
                route.topbarProps?.accessible === undefined
                  ? true
                  : route.topbarProps?.accessible,
            }
          }

          return {
            pretty: uuid,
            accessible:
              route.topbarProps?.accessible === undefined
                ? true
                : route.topbarProps?.accessible,
          }
        }

        return {
          pretty: route.topbarProps?.breadcrumbs,
          accessible:
            route.topbarProps?.accessible === undefined
              ? true
              : route.topbarProps?.accessible,
        }
      }

      return route.topbarProps?.nameFromParams
        ? {
            pretty: uuid,
            accessible:
              route.topbarProps?.accessible === undefined
                ? true
                : route.topbarProps?.accessible,
          }
        : {
            pretty: route.topbarProps?.breadcrumbs,
            accessible:
              route.topbarProps?.accessible === undefined
                ? true
                : route.topbarProps?.accessible,
          }
    }
  }

  for (const route of routes) {
    if (route.child) {
      const found = await findBreadcrumbs(route.child, path, uuid)
      if (found) {
        return found
      } else {
        continue
      }
    }
  }

  return
}

interface Breadcrumbs {
  last: boolean
  to: string
  pretty?: string
  accessible: boolean
}

const Topbar = () => {
  const location = useLocation()
  const { uuid, name } = useParams()
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs[]>([])

  const pathnames = useMemo(
    () => location.pathname.split('/').filter((x) => x),
    [location.pathname]
  )

  const getBreadcrumbs = async () => {
    try {
      const promises = pathnames.map(async (_, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const { pretty, accessible } = await findBreadcrumbs(
          appRoutes,
          to,
          uuid,
          name
        )
        return { last: last, to: to, pretty: pretty, accessible: accessible }
      })
      setBreadcrumbs(await Promise.all(promises))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getBreadcrumbs()
  }, [appRoutes, pathnames, uuid, name])

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        height: sizeConfigs.topbar.height,
        ml: sizeConfigs.sidebar.width,
        boxShadow: 'unset',
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar
        sx={{
          display: 'grid',
        }}
        variant="dense"
      >
        <Breadcrumbs
          separator={<MdOutlineNavigateNext fontSize="13px" />}
          aria-label="breadcrumb"
        >
          <LinkRouter underline="hover" color="inherit" fontSize="13px" to="/">
            Экспресс.Платформа
          </LinkRouter>
          {breadcrumbs.map((breadcrumb) => {
            return breadcrumb.last ? (
              <Typography
                component={'span'}
                color="inherit"
                fontWeight={700}
                key={breadcrumb.to}
                fontSize="13px"
              >
                {breadcrumb.pretty}
              </Typography>
            ) : (
              <LinkRouter
                underline="hover"
                color="inherit"
                fontSize="13px"
                to={breadcrumb.to}
                sx={{
                  pointerEvents: breadcrumb.accessible ? '' : 'none',
                }}
                key={breadcrumb.to}
              >
                {breadcrumb.pretty}
              </LinkRouter>
            )
          })}
        </Breadcrumbs>
      </Toolbar>
      <Divider />
    </AppBar>
  )
}

export default Topbar
