import { Divider, Typography } from '@mui/material'
import UserInteractionActionSelect from 'components/UserInteraction/UserInteractionActionSelect'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import ServiceTemplateStore from './store'
import './styles.css'

const Actions = observer(() => {
  const handleAddAction = () => {
    let newActions: string[] = [...ServiceTemplateStore.$('actions').value]

    runInAction(() => {
      newActions = [...newActions, '']
      ServiceTemplateStore.$('actions').set(newActions)
    })
  }

  const handleRemoveAction = (index: number) => {
    runInAction(() => {
      const newActions: string[] = [...ServiceTemplateStore.$('actions').value]
      newActions.splice(index, 1)
      ServiceTemplateStore.$('actions').set(newActions)
    })
  }

  const handleChangeActions = (
    index: number,
    value: React.SetStateAction<string>
  ) => {
    runInAction(() => {
      const newActions = [...ServiceTemplateStore.$('actions').value]
      newActions[index] = value
      ServiceTemplateStore.$('actions').set(newActions)
    })
  }

  return (
    <div>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Actions
      </Typography>
      {ServiceTemplateStore.$('actions').value &&
        ServiceTemplateStore.$('actions').value.map(
          (action: string, index: number) => (
            <UserInteractionActionSelect
              name={`action-${index + 1}`}
              description={`Action ${index + 1}`}
              helperText={`Action ${index + 1} для шаблона сервиса.`}
              key={`action-${index + 1}`}
              removeButton
              handleRemove={() => handleRemoveAction(index)}
              selected={action}
              setSelected={(value) => handleChangeActions(index, value)}
            />
          )
        )}
      <UserInteractionAddButton handleAdd={handleAddAction} />
    </div>
  )
})

export default Actions
