import { TeamSpec } from '@edp/types'

export enum RepositoryMirroringStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  UNKNOWN = 'unknown',
}

export enum RepositoryMirroringType {
  PULL = 'pull',
}

export interface RepositoryVariables {
  [key: string]: any
}

export interface RepositoryVariable {
  key: string
  value: string
  description?: string
}

export interface RepositorySpec {
  uuid?: string
  name: string
  slug?: string
  owner: string
  description: string
  isTemplate: boolean
  url: string
  RepositoryMirroring: {
    enabled: boolean
    type?: RepositoryMirroringType
    credentials?: string
    syncFrequency?: number
    source?: string
    lastSync?: Date
    nextSync?: Date
    status?: RepositoryMirroringStatus
    remoteUrl?: string
  }
  ownerRef?: TeamSpec
}
