import { Entry, Quadrant, Ring } from '../utils/types'
import { Segments } from './types'

export const setupSegments = (entries: Entry[]) => {
  const segments: Segments = {}

  for (const entry of entries) {
    const quadrantIndex = entry.quadrant.index
    const ringIndex = entry.ring.index
    let quadrantData: { [k: number]: Entry[] } = {}
    if (quadrantIndex !== undefined) {
      if (segments[quadrantIndex] === undefined) {
        segments[quadrantIndex] = {}
      }

      quadrantData = segments[quadrantIndex]
    }

    let ringData = []
    if (ringIndex !== undefined) {
      if (quadrantData[ringIndex] === undefined) {
        quadrantData[ringIndex] = []
      }

      ringData = quadrantData[ringIndex]
    }

    ringData.push(entry)
  }

  return segments
}

export const getSegment = (
  segmented: Segments,
  quadrant: Quadrant,
  ring: Ring,
  ringOffset = 0
) => {
  const quadrantIndex = quadrant.index
  const ringIndex = ring.index
  const segmentedData =
    quadrantIndex === undefined ? {} : segmented[quadrantIndex] || {}
  return ringIndex === undefined
    ? []
    : segmentedData[ringIndex + ringOffset] || []
}
