import { useMemo, useRef, useState } from 'react'
import type { Entry, Quadrant, Ring } from '../utils/types'
import { adjustEntries, adjustQuadrants, adjustRings } from './utils'
import TechRadarPlot from '../TechRadarPlot/TechRadarPlot'

export type TechRadarProps = {
  width: number
  height: number
  quadrants: Quadrant[]
  rings: Ring[]
  entries: Entry[]
  svgProps?: object
}

const TechRadar = ({
  width,
  height,
  quadrants,
  rings,
  entries,
  ...props
}: TechRadarProps): JSX.Element => {
  const radius = Math.min(width, height) / 2

  // State
  const [activeEntry, setActiveEntry] = useState<Entry>()
  const node = useRef<SVGSVGElement>(null)

  // Adjusted props
  const adjustedQuadrants = useMemo(
    () => adjustQuadrants(quadrants, radius, width, height),
    [quadrants, radius, width, height]
  )
  const adjustedRings = useMemo(
    () => adjustRings(rings, radius),
    [radius, rings]
  )
  const adjustedEntries = useMemo(
    () =>
      adjustEntries(
        entries,
        adjustedQuadrants,
        adjustedRings,
        radius,
        activeEntry
      ),
    [entries, adjustedQuadrants, adjustedRings, radius, activeEntry]
  )

  return (
    <svg ref={node} width={width} height={height} {...props.svgProps}>
      <TechRadarPlot
        width={width}
        height={height}
        radius={radius}
        entries={adjustedEntries}
        quadrants={adjustedQuadrants}
        rings={adjustedRings}
        activeEntry={activeEntry}
        onEntryMouseEnter={(entry) => setActiveEntry(entry)}
        onEntryMouseLeave={() => setActiveEntry(undefined)}
      />
    </svg>
  )
}

export default TechRadar
