import { Card, CardContent } from '@mui/material'

import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'
import { PipelinesTable } from './PipelinesTable'

export const PipelinesCard = () => {
  return (
    <Card sx={{ boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Pipelines"
        description="Информация о пайплайнах сервиса"
        fullPage={false}
      />
      <CardContent sx={{ minHeight: '250px' }}>
        <PipelinesTable />
      </CardContent>
    </Card>
  )
}
