import { Card, CardContent } from '@mui/material'

import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'
import { TagsTable } from './TagsTable'

export const TagsCard = () => {
  return (
    <Card sx={{ boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Теги"
        description="Информация о тегах в репозитории сервиса"
        fullPage={false}
      />
      <CardContent sx={{ minHeight: '250px' }}>
        <TagsTable />
      </CardContent>
    </Card>
  )
}
