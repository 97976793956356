import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from 'components/CustomButton'
import { useEffect, useState } from 'react'
import { Role } from 'types/rbac/role'
import CloseIcon from '@mui/icons-material/Close'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionCustomSelectUUID from 'components/UserInteraction/UserInteractionCustomSelectUUID'
import { RoleBinding } from 'types/rbac/roleBinding'

import './styles.css'
import { CustomSelectComponentUUIDOption } from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'

export enum RoleBindingActions {
  Create,
  Update,
}

export interface EditRoleBindingProps {
  uuid?: string
  action: RoleBindingActions
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export const EditRoleBinding = (props: EditRoleBindingProps) => {
  const [name, setName] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [roles, setRoles] = useState<Role[]>([])

  const handleClose = () => props.setOpened(false)

  const [loading, setLoading] = useState<boolean>(false)

  const apiClient = new ApiClient()

  const getRoleBinding = async () => {
    try {
      if (
        props.action === RoleBindingActions.Update &&
        props.uuid &&
        props.opened
      ) {
        const data = await apiClient.getRoleBinding(props.uuid)
        setSubject(data.subject)
        setRole(data.role)
        setName(data.name)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRoleBinding()
  }, [props.opened])

  const getRoles = async () => {
    try {
      const data = await apiClient.getRoles()
      setRoles(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRoles()
  }, [])

  const handleCreate = async () => {
    setName('')
    setSubject('')
    setRole('')

    props.setCreated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    try {
      setLoading(true)

      const spec: RoleBinding = {
        name: name,
        subject: subject,
        role: role,
      }

      await apiClient.createRoleBinding(spec)
      props.setCreated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleUpdate = async () => {
    props.setUpdated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    try {
      setLoading(true)

      const spec: RoleBinding = {
        uuid: props.uuid,
        name: name,
        subject: subject,
        role: role,
      }

      await apiClient.updateRoleBinding(spec)

      props.setUpdated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const options: CustomSelectComponentUUIDOption[] =
    roles?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return (
    <>
      <Dialog
        PaperProps={{ sx: { maxWidth: '750px' } }}
        open={props.opened}
        onClose={handleClose}
      >
        <DialogTitle>
          {props.action === RoleBindingActions.Create &&
            'Создание привязки роли'}
          {props.action === RoleBindingActions.Update &&
            'Изменение привязки роли'}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Typography
            component={'span'}
            variant="caption"
            sx={{ paddingTop: '15px' }}
          >
            Привязка роли - это назначение роли субъекту доступа. Принадлежность
            пользователя к субъекту доступа определяется на основе команд (групп
            в Keycloak), в которых состоит данный пользователь.
          </Typography>
          <div style={{ paddingTop: '15px' }}>
            <UserInteractionTextField
              name="name"
              description="Название"
              helperText="Название привязки роли."
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <UserInteractionTeamSelect
              name="subject"
              description="Субъект доступа"
              helperText="Субъект доступа, к которому будут применяться политики доступа, указанные в роли."
              selected={subject}
              setSelected={setSubject}
            />
            <UserInteractionCustomSelectUUID
              name="role"
              description="Роль"
              helperText="Роль, содержащая набор политик доступа."
              options={options}
              selected={role}
              setSelected={setRole}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right', alignSelf: 'end' }}>
          {props.action === RoleBindingActions.Create && (
            <CustomButton onClick={handleCreate} loading={loading}>
              Создать
            </CustomButton>
          )}
          {props.action === RoleBindingActions.Update && (
            <CustomButton onClick={handleUpdate} loading={loading}>
              Обновить
            </CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditRoleBinding
