import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import { GitlabGroup } from 'types/index'

export async function getGitlabUrl(this: ApiClient): Promise<string> {
  const url = `${this.serverUrl}/gitlab/url`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getGitlabGroups(this: ApiClient): Promise<GitlabGroup[]> {
  const url = `${this.serverUrl}/gitlab/groups`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await this.handleResponse(response)
}
