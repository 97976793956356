import React from 'react'

import { ComponentPage } from 'components/ComponentPage'
import { HelmValuesPanel } from './HelmValuesPanel/HelmValuesPanel'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'

export const PlatformServiceInstancePage = () => {
  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Helm Values',
      index: 1,
      children: <HelmValuesPanel />,
    },
  ]

  return <ComponentPage panels={panels} />
}
export default PlatformServiceInstancePage
