import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import { ChangePasswordSpec, LoginSpec } from 'types/LocalAccounts'

export async function login(this: ApiClient, spec: LoginSpec): Promise<JSON> {
  const url = `${this.serverUrl}/accounts/login`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  })
  return await this.handleResponse(response)
}

export async function changePassword(
  this: ApiClient,
  spec: ChangePasswordSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/accounts/password`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })
  return await this.handleResponse(response)
}
