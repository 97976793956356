import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { HarborArtifactLabel } from 'types/Controllers/Harbor'

export async function getArtifactLabels(
  this: ApiClient,
  project: string,
  repository: string,
  reference: string
): Promise<HarborArtifactLabel[]> {
  const url = `${this.serverUrl}/artifacts/${project}/${repository}/${reference}/labels`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
