import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { ServiceTag } from 'types/Managers/Artifacts'
import { CustomSelectComponent } from 'components/CustomSelectComponent'

export interface ServiceVersionsSelectProps {
  uuid: string
  title?: string
  height?: string
  width?: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
  error?: boolean
}

export const ServiceVersionSelect = (props: ServiceVersionsSelectProps) => {
  const [serviceVersions, setServiceVersions] = useState<ServiceTag[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getServiceVersions = async () => {
      if (props.uuid === '') {
        return
      }

      try {
        const data: ServiceTag[] = await apiClient.getServiceTags(props.uuid)
        setServiceVersions(data)
      } catch (e) {
        console.log(e)
      }
    }

    getServiceVersions()
  }, [props.uuid])

  const options = serviceVersions?.map((version) => version.name)

  return <CustomSelectComponent options={options} {...props} />
}
