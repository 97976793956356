import React from 'react'

import { Typography } from '@mui/material'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import { CustomEntity } from 'types/Managers/CustomEntities'

export interface BasicInfoProps {
  customEntity: CustomEntity
  setCustomEntity: React.Dispatch<React.SetStateAction<CustomEntity>>
  disabled: boolean
}

const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setCustomEntity((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setOwner: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setCustomEntity((prevState) => ({
      ...prevState,
      owner: value instanceof Function ? value(prevState.owner) : value,
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название пользовательского компонента"
        value={props.customEntity.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор пользовательского компонента в инфраструктурных сервисах. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        value={props.customEntity.slug || ''}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание пользовательского компонента."
        value={props.customEntity.description || ''}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая пользовательским компонентом."
        selected={props.customEntity.owner}
        setSelected={setOwner}
      />
      <UserInteractionTextField
        name="type"
        description="Тип"
        helperText="Тип пользовательского компонента."
        value={props.customEntity.type || ''}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="url"
        description="URL"
        helperText="Ссылка на пользовательский компонент."
        value={props.customEntity.url || ''}
        onChange={handleChange}
      />
    </>
  )
}

export default BasicInfo
