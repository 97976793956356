import { useEffect, useState } from 'react'

import { LinearProgress, Typography } from '@mui/material'
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'
import { GitlabAccessLevels, GitlabUser } from 'types/Controllers/Gitlab'

export const DataGridTitle = () => {
  return (
    <Typography
      variant="body1"
      component="div"
      sx={{
        fontWeight: '600',
        textAlign: 'center',
      }}
    >
      Пользователи в Gitlab
    </Typography>
  )
}

export const GitlabGroupMembersTable = () => {
  const { uuid } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [members, setMembers] = useState<GitlabUser[]>([])

  useEffect(() => {
    const apiClient = new ApiClient()

    const getMembers = async () => {
      try {
        setLoading(true)
        const data = await apiClient.getGitlabGroupMembers(uuid || '')
        setMembers(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getMembers()
  }, [uuid])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', minWidth: 50 },
    { field: 'username', headerName: 'Username', minWidth: 150 },
    {
      field: 'name',
      headerName: 'Имя',
      minWidth: 300,
    },
    {
      field: 'access_level',
      headerName: 'Уровень доступа',
      minWidth: 150,
    },
    {
      field: 'state',
      headerName: 'Состояние',
      minWidth: 60,
    },
  ]

  const rows = members.map((member) => {
    return {
      id: member.id,
      username: member.username,
      name: member.name,
      state: member.state,
      access_level:
        Object.keys(GitlabAccessLevels)[
          Object.values(GitlabAccessLevels).indexOf(
            member.access_level as unknown as GitlabAccessLevels
          )
        ],
    }
  })

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <DataGrid
          getRowId={(row) => row.id}
          columns={columns}
          rows={rows}
          pageSize={5}
          rowsPerPageOptions={[5]}
          autoPageSize
          autoHeight={true}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{ Toolbar: DataGridTitle }}
        />
      )}
    </>
  )
}

export default GitlabGroupMembersTable
