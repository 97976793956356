import { SiGitkraken } from 'react-icons/si'
import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ActionVariables } from 'types/Managers/Actions'
import './styles.css'
import KrakendEndpointAction from 'pages/components/Actions/KrakendEndpointAction/KrakendEndpointAction'
import { CreateKrakendEndpointActionForm } from 'pages/components/Actions/KrakendEndpointAction/store'
import { reaction, runInAction } from 'mobx'
import ServiceStore from '../store'
import { KrakendEndpointSpec } from 'types/Controllers/Krakend'
import { useEffect } from 'react'

export interface KrakendEndpointProps {
  actionVariables: ActionVariables
  index: number
}

export const KrakendEndpointLogo = () => {
  return (
    <Tooltip
      arrow
      title={
        'KrakenD endpoint action генерирует спецификацию KrakenD endpoint и создает MR в репозиторий, в котором хранится конфигурация.'
      }
      placement="top"
    >
      <div>
        <SiGitkraken className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const KrakendEndpoint = observer((props: KrakendEndpointProps) => {
  const form = CreateKrakendEndpointActionForm()
  form.set(props.actionVariables.variables)

  const setActionsVariables = (index: number) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const endpoint: KrakendEndpointSpec = {
        endpoint: form.$('endpoint').value,
        method: form.$('method').value,
        output_encoding: form.$('output_encoding').value,
        timeout: form.$('timeout').value,
        cache_ttl: form.$('cache_ttl').value,
        extra_config: {
          'auth/validator': {
            alg: form.$('extra_config.auth/validator.alg').value,
            jwk_url: form.$('extra_config.auth/validator.jwk_url').value,
            disable_jwk_security: form.$(
              'extra_config.auth/validator.disable_jwk_security'
            ).value,
          },
        },
        input_headers: form.$('input_headers').value,
        backend: [
          {
            url_pattern: form.$('backend[0].url_pattern').value,
            encoding: form.$('backend[0].encoding').value,
            sd: form.$('backend[0].sd').value,
            method: form.$('method').value,
            host: form.$('backend[0].host').value,
            disable_host_sanitize: form.$('backend[0].disable_host_sanitize')
              .value,
          },
        ],
      }

      actionsVariables[index].variables = endpoint

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  useEffect(() => {
    const disposer = reaction(
      () => ({
        endpoint: form.$changed,
      }),
      () => {
        setActionsVariables(props.index)
      }
    )

    return () => disposer()
  }, [form, props.index])

  return <KrakendEndpointAction form={form} />
})
