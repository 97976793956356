import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { AlertColor } from '@mui/material'
import { Grid } from '@mui/material'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      sx={{ whiteSpace: 'pre-line' }}
      variant="filled"
      {...props}
    />
  )
})

export type CustomSnackbarProps = {
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  message?: string
  severity: AlertColor
}

export const CustomSnackbarComponent = (props: CustomSnackbarProps) => {
  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    props.setOpened(false)
  }

  return (
    <Grid
      sx={{
        width: '100%',
        marginTop: 0,
      }}
    >
      <Snackbar
        open={props.opened}
        autoHideDuration={3000}
        onClose={handleClose}
        sx={{ maxWidth: '40%' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={props.severity}>
          {props.message}
        </Alert>
      </Snackbar>
    </Grid>
  )
}
