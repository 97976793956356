export type KubernetesKind = string

export const kubernetesKindPod: KubernetesKind = 'Pod'
export const kubernetesKindConfigMap: KubernetesKind = 'ConfigMap'
export const kubernetesKindDeployment: KubernetesKind = 'Deployment'
export const kubernetesKindReplicaSet: KubernetesKind = 'ReplicaSet'
export const kubernetesKindHorizontalPodAutoscaler: KubernetesKind =
  'HorizontalPodAutoscaler'
export const kubernetesKindJob: KubernetesKind = 'Job'
export const kubernetesKindCronJob: KubernetesKind = 'CronJob'
export const kubernetesKindIngress: KubernetesKind = 'Ingress'
export const kubernetesKindStatefulSet: KubernetesKind = 'StatefulSet'
export const kubernetesKindDaemonSet: KubernetesKind = 'DaemonSet'
export const kubernetesKindNode: KubernetesKind = 'Node'
export const kubernetesKindResourceQuota: KubernetesKind = 'ResourceQuota'
export const kubernetesKindNamespace: KubernetesKind = 'Namespace'

export type PodPhase = string

export const PodPending: PodPhase = 'Pending'
export const PodRunning: PodPhase = 'Running'
export const PodSucceeded: PodPhase = 'Succeeded'
export const PodFailed: PodPhase = 'Failed'
export const PodUnknown: PodPhase = 'Unknown'

export interface KubernetesResource {
  kind: KubernetesKind
  nameApi: string
  kindItemsList: string
  group: string
  apiVersion: string
}

export const kubernetesPod: KubernetesResource = {
  kind: kubernetesKindPod,
  nameApi: 'pods',
  kindItemsList: 'PodList',
  group: '',
  apiVersion: 'v1',
}

export const kubernetesConfigMap: KubernetesResource = {
  kind: kubernetesKindConfigMap,
  nameApi: 'configmaps',
  kindItemsList: 'ConfigMapList',
  group: '',
  apiVersion: 'v1',
}

export const kubernetesDeployment: KubernetesResource = {
  kind: kubernetesKindDeployment,
  nameApi: 'deployments',
  kindItemsList: 'DeploymentList',
  group: 'apps',
  apiVersion: 'v1',
}

export const kubernetesReplicaSet: KubernetesResource = {
  kind: kubernetesKindReplicaSet,
  nameApi: 'replicasets',
  kindItemsList: 'ReplicaSetList',
  group: 'apps',
  apiVersion: 'v1',
}

export const kubernetesHorizontalPodAutoscaler: KubernetesResource = {
  kind: kubernetesKindHorizontalPodAutoscaler,
  nameApi: 'horizontalpodautoscalers',
  kindItemsList: 'HorizontalPodAutoscalerList',
  group: 'autoscaling',
  apiVersion: 'v1',
}

export const kubernetesJob: KubernetesResource = {
  kind: kubernetesKindJob,
  nameApi: 'jobs',
  kindItemsList: 'JobList',
  group: 'batch',
  apiVersion: 'v1',
}

export const kubernetesCronJob: KubernetesResource = {
  kind: kubernetesKindCronJob,
  nameApi: 'cronjobs',
  kindItemsList: 'CronJobList',
  group: 'batch',
  apiVersion: 'v1',
}

export const kubernetesIngress: KubernetesResource = {
  kind: kubernetesKindIngress,
  nameApi: 'ingresses',
  kindItemsList: 'IngressList',
  group: 'networking.k8s.io',
  apiVersion: 'v1',
}

export const kubernetesStatefulSet: KubernetesResource = {
  kind: kubernetesKindStatefulSet,
  nameApi: 'statefulsets',
  kindItemsList: 'StatefulSetList',
  group: 'apps',
  apiVersion: 'v1',
}

export const kubernetesDaemonset: KubernetesResource = {
  kind: kubernetesKindDaemonSet,
  nameApi: 'daemonsets',
  kindItemsList: 'DaemonSetList',
  group: 'apps',
  apiVersion: 'v1',
}

export const kubernetesNode: KubernetesResource = {
  kind: kubernetesKindNode,
  nameApi: 'nodes',
  kindItemsList: 'NodeList',
  group: '',
  apiVersion: 'v1',
}

export const kubernetesResourceQuota: KubernetesResource = {
  kind: kubernetesKindResourceQuota,
  nameApi: 'resourcequotas',
  kindItemsList: 'ResourceQuotaList',
  group: '',
  apiVersion: 'v1',
}

export const kubernetesNamespace: KubernetesResource = {
  kind: kubernetesKindNamespace,
  nameApi: 'namespaces',
  kindItemsList: 'NamespaceList',
  group: '',
  apiVersion: 'v1',
}

export interface LimitRange {
  type: string
  max?: LimitRangeResources
  min?: LimitRangeResources
  default?: LimitRangeResources
  defaultRequest?: LimitRangeResources
}

export interface LimitRangeResources {
  memory: string
  cpu: string
}

export interface PodLogs {
  podStatus: string
  logs: string
}
