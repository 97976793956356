import React from 'react'
import { WithLink } from '../utils/components'
import type { EntrySnapshot } from '../utils/types'
import { TechRadarDescription } from '../TechRadarDescription/TechRadarDescription'
import './styles.css'

export type TechRadarEntryProps = {
  x: number
  y: number
  value: number
  color: string
  url?: string
  links?: Array<{ title: string; url: string }>
  moved?: number
  description?: string
  timeline?: EntrySnapshot[]
  title?: string
  onMouseEnter?: (event: React.MouseEvent<SVGGElement, MouseEvent>) => void
  onMouseLeave?: (event: React.MouseEvent<SVGGElement, MouseEvent>) => void
  onClick?: (event: React.MouseEvent<SVGGElement, MouseEvent>) => void
}

const makeBlip = (color: string, moved?: number) => {
  const style = { fill: color }

  let blip = <circle r={9} style={style} />
  if (moved && moved > 0) {
    blip = <path d="M -11,5 11,5 0,-13 z" style={style} /> // triangle pointing up
  } else if (moved && moved < 0) {
    blip = <path d="M -11,-5 11,-5 0,13 z" style={style} /> // triangle pointing down
  }

  return blip
}

export const TechRadarEntry = (props: TechRadarEntryProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)

  const {
    moved,
    description,
    timeline,
    title,
    color,
    url,
    links,
    value,
    x,
    y,
    onMouseEnter,
    onMouseLeave,
    onClick,
  } = props

  const blip = makeBlip(color, moved)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      data-testid="radar-entry"
    >
      {' '}
      {open && (
        <TechRadarDescription
          open={open}
          onClose={handleClose}
          title={title ? title : 'no title'}
          description={description ? description : 'no description'}
          timeline={timeline ? timeline : []}
          url={url}
          links={links}
        />
      )}
      {description ? (
        <a
          className={'TechRadar-RadarEntry-Link'}
          onClick={handleClickOpen}
          role="button"
          href="#"
          tabIndex={0}
          onKeyPress={toggle}
        >
          {blip}
        </a>
      ) : (
        <WithLink url={url} className={'TechRadar-RadarEntry-Link'}>
          {blip}
        </WithLink>
      )}
      <text y={3} className={'TechRadar-RadarEntry-Text'}>
        {value}
      </text>
    </g>
  )
}

export default TechRadarEntry
