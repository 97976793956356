import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  instance: null,
  post: {
    channel_id: null,
    message: null,
  },
}

export const MattermostPostActionForm = () => {
  return new MobxReactForm({ fields }, { plugins })
}

export default new MobxReactForm({ fields }, { plugins })
