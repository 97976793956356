import Alert from '@mui/material/Alert'
import { useAsync } from 'react-use'
import { Chip, Tooltip, Card, CardContent, LinearProgress } from '@mui/material'
import { colors } from './colors'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'
import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'

export type Language = {
  [key: string]: number
}

export const LanguagesCard = () => {
  const apiClient = new ApiClient()
  const { uuid } = useParams()

  let barWidth = 0
  let languageTitle: string

  const { value, loading, error } = useAsync(async (): Promise<Language> => {
    const gitlabObj = await apiClient.getLanguagesSummary(uuid || '')
    const data = gitlabObj?.getLanguagesData
    return data
  })

  return (
    <Card>
      <CustomCardHeader
        height="80px"
        title="Языки программирования"
        description="Языки программирования, использующиеся при разработке сервиса"
        fullPage={false}
      />
      <CardContent sx={{ minHeight: '250px', boxShadow: '1' }}>
        {loading && <LinearProgress />}
        {!loading && error && (
          <Alert severity="error" style={{ marginTop: 3 }}>
            {error.message}
          </Alert>
        )}
        {!loading && !error && (
          <>
            <div
              style={{
                height: '16px',
                marginBottom: '10px',
                borderRadius: '4px',
                backgroundColor: 'transparent',
                overflow: 'hidden',
              }}
            >
              {Object.entries(value as Language).map(
                (language, index: number) => {
                  barWidth = barWidth + language[1]
                  languageTitle = language[0] + ' ' + language[1] + '%'
                  return (
                    <Tooltip
                      title={languageTitle}
                      placement="bottom-end"
                      key={language[0]}
                    >
                      <div
                        key={language[0]}
                        style={{
                          marginTop: index === 0 ? '0' : `-16px`,
                          zIndex: Object.keys(value as Language).length - index,
                          backgroundColor: colors[language[0]]?.color || '#333',
                          width: `${barWidth}%`,
                          height: '100%',
                          position: 'relative',
                        }}
                      />
                    </Tooltip>
                  )
                }
              )}
            </div>
            {Object.entries(value as Language).map((language) => (
              <Chip
                style={{ color: 'inherit', margin: '4px' }}
                label={
                  <>
                    <span
                      style={{
                        backgroundColor: colors[language[0]]?.color || '#333',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        marginRight: '8px',
                        display: 'inline-block',
                      }}
                    />
                    <b>{language[0]}</b> - {language[1]}%
                  </>
                }
                variant="outlined"
                key={language[0]}
              />
            ))}
          </>
        )}
      </CardContent>
    </Card>
  )
}
