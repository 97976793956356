import { useEffect, useState } from 'react'

import { ApiClient } from 'api/ApiClient'
import { HelmChartSpec } from 'types/Managers/HelmCharts'
import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
  CustomSelectProps,
} from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'

export interface HelmChartSelectProps extends CustomSelectProps {}

export const HelmChartSelect = (props: HelmChartSelectProps) => {
  const [helmCharts, setHelmCharts] = useState<HelmChartSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getHelmCharts = async () => {
      try {
        const data = await apiClient.getHelmCharts()
        setHelmCharts(data)
      } catch (e) {
        console.log(e)
      }
    }

    getHelmCharts()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    helmCharts?.map((o) => ({
      label: `${o.name}`,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default HelmChartSelect
