import React from 'react'
import { Link } from 'react-router-dom'

type WithLinkProps = {
  url?: string
  className: string
  children: React.ReactNode
}

export function isValidUrl(url: string | undefined): url is string {
  return Boolean(url && url !== '#' && url.length > 0)
}

export const WithLink = ({
  url,
  className,
  children,
}: WithLinkProps): JSX.Element =>
  isValidUrl(url) ? (
    <Link className={className} to={url}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
