import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from 'components/CustomButton'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { ConfigEntries, IResourceConfigEntry, ITopicConfig } from 'kafkajs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './styles.css'
import { kafkaTopicConfigParams } from './KafkaTopicConfigParams'

import UserInteractionCustomSelectUUID from 'components/UserInteraction/UserInteractionCustomSelectUUID'
import { blocks, environments } from './Fields'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'

export enum KafkaTopicActions {
  Create,
  Update,
}

export interface EditKafkaTopicProps {
  name: string
  action: KafkaTopicActions
  opened: boolean
  disabled?: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export type Option = {
  key: string
  value?: string
}

export const EditKafkaTopic = (props: EditKafkaTopicProps) => {
  const { uuid } = useParams()
  const [name, setName] = useState<string>('')
  const [numPartitions, setNumPartitions] = useState<number>(-1)
  const [replicationFactor, setReplicationFactor] = useState<number>(-1)
  const [configEntries, setConfigEntries] = useState<ConfigEntries[]>([])
  const [creationConfigEntries, setCreationConfigEntries] = useState<
    IResourceConfigEntry[]
  >([])

  const [loading, setLoading] = useState<boolean>(false)
  const [topicLoading, setTopicLoading] = useState<boolean>(false)

  const [block, setBlock] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [environment, setEnvironment] = useState<string>(environments[0])
  const [extendedFormat, setExtendedFormat] = useState<boolean>(false)

  const handleClose = () => props.setOpened(false)

  const apiClient = new ApiClient()

  const c: IResourceConfigEntry[] = []

  useEffect(() => {
    for (const param of kafkaTopicConfigParams) {
      c.push({ name: param.name, value: param.value || '' })
    }

    setCreationConfigEntries(c)
  }, [])

  const HandleChangeCreationConfigEntry = (index: number, value: string) => {
    if (index !== -1) {
      const updated = [...creationConfigEntries]
      updated[index].value = value
      setCreationConfigEntries(updated)
    }
  }

  const getKafkaTopic = async () => {
    try {
      if (props.action === KafkaTopicActions.Update && uuid && props.opened) {
        setTopicLoading(true)
        const topic = await apiClient.getKafkaTopic(uuid, props.name)

        setName(topic.name)
        setNumPartitions(topic.metadata.partitions.length)
        setReplicationFactor(topic.metadata.partitions[0].replicas.length)
        setConfigEntries(topic.configEntries)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setTopicLoading(false)
    }
  }

  useEffect(() => {
    getKafkaTopic()
  }, [props.opened])

  const handleCreate = async () => {
    props.setCreated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    try {
      setLoading(true)

      const spec: ITopicConfig = {
        topic: name,
        numPartitions: numPartitions,
        replicationFactor: replicationFactor,
        configEntries: creationConfigEntries,
      }

      await apiClient.createKafkaTopic(uuid || '', spec)
      props.setCreated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleUpdate = async () => {
    props.setUpdated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    try {
      setLoading(true)

      const spec: ITopicConfig = {
        topic: name,
      }

      await apiClient.updateKafkaTopic(uuid || '', spec)

      props.setUpdated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog
        PaperProps={{ sx: { minWidth: '750px' } }}
        open={props.opened}
        onClose={handleClose}
      >
        <DialogTitle>
          {props.action === KafkaTopicActions.Create && 'Создание топика'}
          {props.action === KafkaTopicActions.Update && 'Изменение топика'}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          {topicLoading && <LinearProgress />}
          {!topicLoading && (
            <div style={{ paddingTop: '15px' }}>
              <UserInteractionSwitch
                name="extendedFormat"
                description="Расширенный формат"
                helperText="Расширенный формат наименования топика: {Бизнес блок - заказчик}- {Описание продукта или функции}-{Публикуемая сущность}-{Формат сообщения}-{Тип топика}-{Версия}-{Окружение}-{Логическая метка [опционально]}"
                checked={extendedFormat}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setExtendedFormat(event.target.checked)
                }
              />
              <UserInteractionCustomSelectUUID
                name="block"
                description="Блок"
                helperText="Блок - заказчик топика."
                options={blocks}
                selected={block}
                setSelected={setBlock}
              />
              <UserInteractionTextField
                name="description"
                description="Описание"
                helperText="Описание продукта или продукта и функции топика."
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
              <UserInteractionCustomSelect
                name="environment"
                description="Environment"
                helperText="Наименование окружения."
                options={environments}
                selected={environment}
                setSelected={setEnvironment}
              />
              <UserInteractionTextField
                name="name"
                description="Название"
                helperText="Название топика."
                value={`${block}-${description}-${environment}`}
                onChange={(event) => setName(event.target.value)}
              />
              <UserInteractionTextField
                name="numPartitions"
                type="number"
                description="Количество партиций"
                helperText="Количество создаваемых партиций (разделов) в топике. Чем больше партиций, тем быстрее происходит чтение и запись в топике."
                disabled={props.disabled}
                value={numPartitions}
                onChange={(event) =>
                  setNumPartitions(Number(event.target.value))
                }
              />
              <UserInteractionTextField
                name="replicationFactor"
                type="number"
                description="Фактор репликации"
                helperText="Указывает, сколько копий партиций будет на разных узлах кластера. Не может быть большим, чем количество брокеров."
                disabled={props.disabled}
                value={replicationFactor}
                onChange={(event) =>
                  setReplicationFactor(Number(event.target.value))
                }
              />
              {props.action === KafkaTopicActions.Update &&
                configEntries.length > 0 && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        paddingTop: '30px',
                        paddingBottom: '15px',
                        fontWeight: '600',
                      }}
                    >
                      Конфигурация топика
                    </Typography>
                    {configEntries.map((entry) => (
                      <UserInteractionTextField
                        name={entry.configName}
                        disabled={props.disabled}
                        description={entry.configName}
                        helperText={
                          kafkaTopicConfigParams.find(
                            (param) => param.name === entry.configName
                          )?.description || ''
                        }
                        value={entry.configValue}
                        onChange={(event) =>
                          setReplicationFactor(Number(event.target.value))
                        }
                      />
                    ))}
                  </>
                )}
              {props.action === KafkaTopicActions.Create && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      paddingTop: '30px',
                      paddingBottom: '15px',
                      fontWeight: '600',
                    }}
                  >
                    Конфигурация топика
                  </Typography>
                  {creationConfigEntries.map((entry, index) => (
                    <UserInteractionTextField
                      key={index}
                      name={entry.name}
                      disabled={props.disabled}
                      description={entry.name}
                      helperText={
                        kafkaTopicConfigParams.find(
                          (param) => param.name === entry.name
                        )?.description || ''
                      }
                      value={entry.value}
                      onChange={(event) =>
                        HandleChangeCreationConfigEntry(
                          index,
                          event.target.value
                        )
                      }
                    />
                  ))}
                </>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right', alignSelf: 'end' }}>
          {props.action === KafkaTopicActions.Create && (
            <CustomButton onClick={handleCreate} loading={loading}>
              Создать
            </CustomButton>
          )}
          {props.action === KafkaTopicActions.Update && (
            <CustomButton
              disabled={props.disabled}
              onClick={handleUpdate}
              loading={loading}
            >
              Обновить
            </CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditKafkaTopic
