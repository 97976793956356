import React from 'react'
import { Typography } from '@mui/material'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionClusterSelect from 'components/UserInteraction/UserInteractionClusterSelect'
import { EnvironmentSpec } from 'types/Managers/Environments'

export interface BasicInfoProps {
  disabled: boolean
  environment: EnvironmentSpec
  setEnvironment: React.Dispatch<React.SetStateAction<EnvironmentSpec>>
}

export const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setEnvironment((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setOwner: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setEnvironment((prevState) => ({
      ...prevState,
      owner: value instanceof Function ? value(prevState.owner) : value,
    }))
  }

  const setCluster: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setEnvironment((prevState) => ({
      ...prevState,
      cluster: value instanceof Function ? value(prevState.cluster) : value,
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название окружения."
        value={props.environment.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор окружения в инфраструктурных сервисах. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        value={props.environment.slug || ''}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание окружения."
        value={props.environment.description}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая окружением."
        selected={props.environment.owner}
        setSelected={setOwner}
      />
      <UserInteractionClusterSelect
        name="cluster"
        description="Kubernetes кластер"
        disabled={props.disabled}
        helperText="Kubernetes кластер в котором будет создано окружение."
        selected={props.environment.cluster}
        setSelected={setCluster}
      />
    </>
  )
}

export default BasicInfo
