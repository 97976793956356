import { ApiClient } from 'api/ApiClient'
import { useState, useEffect } from 'react'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'

const useFetchPlatformServiceTemplate = (uuid?: string) => {
  const [platformServiceTemplate, setPlatformServiceTemplate] =
    useState<PlatformServiceTemplateSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchPlatformServiceTemplate = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getPlatformServiceTemplate(uuid)
          setPlatformServiceTemplate(data)
        } catch (e) {
          console.error('Ошибка при получении репозитория:', e)
        }
      }
    }

    fetchPlatformServiceTemplate()
  }, [uuid])

  return platformServiceTemplate
}

export default useFetchPlatformServiceTemplate
