import { useEffect, useState } from 'react'

import { ApiClient } from 'api/ApiClient'
import BasicInfo from './EditRepositoryPage/BasicInfo'
import {
  RepositoryMirroringStatus,
  RepositoryMirroringType,
  RepositorySpec,
} from 'types/Managers/Repositories'
import UpdateComponentPage from 'components/UpdateComponentPage/UpdateComponentPage'
import { useNavigate, useParams } from 'react-router-dom'

export const UpdateRepositoryPage = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()

  const [repository, setRepository] = useState<RepositorySpec>({
    uuid: '',
    name: '',
    slug: '',
    owner: '',
    description: '',
    url: '',
    isTemplate: false,
    RepositoryMirroring: {
      enabled: false,
      type: RepositoryMirroringType.PULL,
      credentials: '',
      syncFrequency: 60,
      source: '',
      status: RepositoryMirroringStatus.UNKNOWN,
      remoteUrl: '',
    },
  })

  const [updated, setUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const getRepository = async () => {
    const apiClient = new ApiClient()

    try {
      const data = await apiClient.getRepository(uuid || '')
      setRepository(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRepository()
  }, [])

  const handleUpdate = async () => {
    setUpdated(false)

    if (!repository.name || !repository.owner || !repository.url) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }

    setErrorMessage('')
    setLoading(true)

    try {
      const spec: RepositorySpec = {
        uuid: repository.uuid,
        name: repository.name,
        slug: repository.slug,
        description: repository.description,
        owner: repository.owner,
        isTemplate: repository.isTemplate,
        url: repository.url,
        RepositoryMirroring: {
          enabled: repository.RepositoryMirroring.enabled,
          type: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.type
            : undefined,
          credentials: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.credentials
            : undefined,
          syncFrequency: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.syncFrequency
            : undefined,
          source: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.source
            : undefined,
          remoteUrl: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.remoteUrl
            : undefined,
          status: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.status
            : undefined,
        },
      }

      await apiClient.updateRepository(spec)

      setUpdated(true)
      navigate('/components/repositories')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <UpdateComponentPage
      handleUpdate={handleUpdate}
      loading={loading}
      updated={updated}
      setUpdated={setUpdated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      updatedMessage={`Репозиторий ${repository.name} обновлен.`}
    >
      <BasicInfo
        repository={repository}
        setRepository={setRepository}
        disabled={true}
      />
    </UpdateComponentPage>
  )
}
export default UpdateRepositoryPage
