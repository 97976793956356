import { useState } from 'react'

import { ApiClient } from 'api/ApiClient'

import BasicInfo from './EditPlatformServiceInstancePage/BasicInfo'
import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import { PlatformServiceInstanceSpec } from 'types/Managers/PlatformServiceInstances'
import { useNavigate } from 'react-router-dom'

export const CreatePlatformServiceInstancePage = () => {
  const navigate = useNavigate()
  const [platformServiceInstance, setPlatformServiceInstance] =
    useState<PlatformServiceInstanceSpec>({
      uuid: '',
      name: '',
      owner: '',
      description: '',
      environment: '',
      cluster: '',
      platformServiceTemplate: '',
      platformServiceConfiguration: '',
    })

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const handleCreate = async () => {
    setCreated(false)
    if (
      !platformServiceInstance.name ||
      !platformServiceInstance.environment ||
      !platformServiceInstance.platformServiceTemplate ||
      !platformServiceInstance.platformServiceConfiguration
    ) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)
    try {
      const spec: PlatformServiceInstanceSpec = {
        uuid: platformServiceInstance.uuid,
        name: platformServiceInstance.name,
        description: platformServiceInstance.description,
        owner: platformServiceInstance.owner,
        environment: platformServiceInstance.environment,
        cluster: platformServiceInstance.cluster,
        platformServiceTemplate:
          platformServiceInstance.platformServiceTemplate,
        platformServiceConfiguration:
          platformServiceInstance.platformServiceConfiguration,
      }

      await apiClient.createPlatformServiceInstance(spec)
      setCreated(true)
      navigate('/platform-services/instances')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createdMessage={`Инстанс ${platformServiceInstance.name} создан`}
    >
      <BasicInfo
        platformServiceInstance={platformServiceInstance}
        setPlatformServiceInstance={setPlatformServiceInstance}
        disabled={false}
      />
    </CreateComponentPage>
  )
}

export default CreatePlatformServiceInstancePage
