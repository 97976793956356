import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  name: {
    value: null,
    rules: 'required',
  },
  description: null,
  owner: {
    value: null,
    rules: 'required',
  },
  registryURL: null,
  registryEngine: null,
  registryName: null,
  registry: null,
}

export default new MobxReactForm({ fields }, { plugins })
