import { Card, CardContent } from '@mui/material'

import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'
import { MergeRequestsTable } from './MergeRequestsTable'

export const MergeRequestsCard = () => {
  return (
    <Card sx={{ boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Merge Requests"
        description="Информация о MR сервиса"
        fullPage={false}
      />
      <CardContent sx={{ minHeight: '250px' }}>
        <MergeRequestsTable />
      </CardContent>
    </Card>
  )
}
