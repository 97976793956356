import { ApiClient } from 'api/ApiClient'
import { HarborArtifact } from 'types/Controllers/Harbor'
import {
  GitlabBranch,
  GitlabContributorsSummary,
  GitlabIssuesSummary,
  GitlabLanguagesSummary,
  GitlabMergeRequestsSummary,
  GitlabPipeline,
  GitlabPipelinesSummary,
  GitlabTag,
  GitlabTagRequest,
} from 'types/Controllers/Gitlab'
import { getAuthType, getJWT } from 'api/utils'
import { DefectDojoProduct } from 'types/Controllers/DefectDojo'
import { GrafanaDashboardPanelResponse } from 'common/grafana'
import { VaultEnvironmentSecret } from 'types/Controllers/Vault'
import {
  SonarqubeFindingSummary,
  SonarqubeFindingsWrapper,
  SonarqubeMetrics,
} from 'types/sonarqube'
import { SbomSpec } from 'types/Sbom'
import { ServiceInstanceInfoSpec } from 'types/service'
import { ServiceSpec } from 'types/Managers/Services'
import {
  ArtifactTag,
  DockerRegistryUrl,
  ServiceTag,
} from 'types/Managers/Artifacts'

export async function createService(
  this: ApiClient,
  spec: ServiceSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/services`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await this.handleResponse(response)
}

export async function getServices(this: ApiClient): Promise<ServiceSpec[]> {
  const url = `${this.serverUrl}/services`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getService(
  this: ApiClient,
  uuid: string
): Promise<ServiceSpec> {
  const url = `${this.serverUrl}/services/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updateService(
  this: ApiClient,
  spec: ServiceSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/services/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getArtifactsTags(
  this: ApiClient,
  uuid: string
): Promise<ArtifactTag[]> {
  const url = `${this.serverUrl}/services/${uuid}/artifacts/tags`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getArtifacts(
  this: ApiClient,
  uuid: string
): Promise<HarborArtifact[]> {
  const url = `${this.serverUrl}/services/${uuid}/artifacts`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getArtifactDockerRegistryUrl(
  this: ApiClient,
  uuid: string,
  registryID: number
): Promise<DockerRegistryUrl> {
  const url = `${this.serverUrl}/services/${uuid}/artifacts/docker-registry-url/${registryID}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceBranches(
  this: ApiClient,
  uuid: string
): Promise<GitlabBranch[]> {
  const url = `${this.serverUrl}/services/${uuid}/branches`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function startPipeline(
  this: ApiClient,
  uuid: string,
  spec: GitlabPipeline
): Promise<JSON> {
  const url = `${this.serverUrl}/services/${uuid}/pipelines`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await this.handleResponse(response)
}

export async function getPipelinesSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabPipelinesSummary> {
  const url = `${this.serverUrl}/services/${uuid}/pipelines`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getContributorsSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabContributorsSummary> {
  const url = `${this.serverUrl}/services/${uuid}/contributors`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getMergeRequestsSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabMergeRequestsSummary> {
  const url = `${this.serverUrl}/services/${uuid}/merge-requests`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getLanguagesSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabLanguagesSummary> {
  const url = `${this.serverUrl}/services/${uuid}/languages`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getIssuesSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabIssuesSummary> {
  const url = `${this.serverUrl}/services/${uuid}/issues`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getDefectDojoSummary(
  this: ApiClient,
  uuid: string
): Promise<DefectDojoProduct> {
  const url = `${this.serverUrl}/services/${uuid}/defectdojo/summary`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceGrafanaPanels(
  this: ApiClient,
  uuid: string
): Promise<GrafanaDashboardPanelResponse> {
  const url = `${this.serverUrl}/services/${uuid}/grafana/panels`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceSecrets(
  this: ApiClient,
  uuid: string
): Promise<VaultEnvironmentSecret[]> {
  const url = `${this.serverUrl}/services/${uuid}/secrets`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceInstancesInfo(
  this: ApiClient,
  uuid: string
): Promise<ServiceInstanceInfoSpec[]> {
  const url = `${this.serverUrl}/services/${uuid}/instances/info`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceSonarqubeFindingsSummary(
  this: ApiClient,
  uuid: string
): Promise<SonarqubeFindingSummary> {
  const url = `${this.serverUrl}/services/${uuid}/sonarqube/findings`

  const metrics: SonarqubeMetrics = {
    alert_status: undefined,
    bugs: undefined,
    reliability_rating: undefined,
    vulnerabilities: undefined,
    security_rating: undefined,
    security_hotspots_reviewed: undefined,
    security_review_rating: undefined,
    code_smells: undefined,
    sqale_rating: undefined,
    coverage: undefined,
    duplicated_lines_density: undefined,
  }

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const findings = (await this.handleResponse(
    result
  )) as unknown as SonarqubeFindingsWrapper

  findings.measures.forEach((m) => {
    metrics[m.metric] = m.value
  })

  return {
    lastAnalysis: findings.analysisDate,
    metrics,
  }
}

export async function deleteService(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/services/${uuid}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceSbom(
  this: ApiClient,
  uuid: string,
  branch: string
): Promise<SbomSpec> {
  const url = `${this.serverUrl}/services/${uuid}/sbom/${branch}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceApiSpecification(
  this: ApiClient,
  uuid: string,
  branch: string
): Promise<any> {
  const url = `${this.serverUrl}/services/${uuid}/api-specification/${branch}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getServiceReadme(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/services/${uuid}/readme`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function deleteSbom(this: ApiClient, id: number) {
  const url = `${this.serverUrl}/sbom?id=${id}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await this.handleResponse(response)
}

export async function getServiceTags(
  this: ApiClient,
  uuid: string
): Promise<ServiceTag[]> {
  const url = `${this.serverUrl}/services/${uuid}/artifacts/tags`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getServiceRepositoryTags(
  this: ApiClient,
  uuid: string
): Promise<GitlabTag[]> {
  const url = `${this.serverUrl}/services/${uuid}/repository/tags`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function createServiceRepositoryTag(
  this: ApiClient,
  uuid: string,
  spec: GitlabTagRequest
): Promise<JSON> {
  const url = `${this.serverUrl}/services/${uuid}/repository/tags`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await this.handleResponse(response)
}
