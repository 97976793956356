import { Outlet } from 'react-router-dom'
import { Box, Toolbar } from '@mui/material'
import colorConfigs from 'configs/colorConfigs'
import sizeConfigs from 'configs/sizeConfigs'
import Sidebar from 'components/Sidebar/Sidebar'
import Topbar from 'components/Topbar/Topbar'

const MainLayout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Topbar />
      <Box
        component="nav"
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          height: `calc(100vh - ${sizeConfigs.topbar.height})`,
          backgroundColor: colorConfigs.mainBg,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout
