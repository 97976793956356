export const blocks = [
  {
    label: 'Инфраструктура (ИТ)',
    uuid: 'i',
  },
  {
    label: 'ОПЕРУ',
    uuid: 'o',
  },
  {
    label: 'Маркетинг (Digital)',
    uuid: 'd',
  },
  {
    label: 'Розница',
    uuid: 'r',
  },
  {
    label: 'Корпоративный',
    uuid: 'c',
  },
  {
    label: 'Авиа',
    uuid: 'a',
  },
  {
    label: 'Медицина',
    uuid: 'm',
  },
  {
    label: 'Финансы',
    uuid: 'f',
  },
  {
    label: 'HR',
    uuid: 'h',
  },
]

export const environments = ['dev', 'test', 'demo', 'preprod', 'prod']
