export type InfraServiceName = string

export const INFRA_SERVICE_HARBOR: InfraServiceName = 'Harbor'
export const INFRA_SERVICE_GITLAB: InfraServiceName = 'GitLab'
export const INFRA_SERVICE_ARGOCD: InfraServiceName = 'ArgoCD'
export const INFRA_SERVICE_GRAFANA: InfraServiceName = 'Grafana'
export const INFRA_SERVICE_VAULT: InfraServiceName = 'Vault'
export const INFRA_SERVICE_DEFECTDOJO: InfraServiceName = 'DefectDojo'
export const INFRA_SERVICE_SONARQUBE: InfraServiceName = 'SonarQube'
export const INFRA_SERVICE_PROMETHEUS: InfraServiceName = 'Prometheus'
export const INFRA_SERVICE_OPENSEARCH: InfraServiceName = 'Opensearch'
export const INFRA_SERVICE_OPENSEARCH_DASHBOARDS: InfraServiceName =
  'OpensearchDashboards'
export const INFRA_SERVICE_STATUS_PAGE: InfraServiceName = 'StatusPage'
export const INFRA_SERVICE_POLARIS: InfraServiceName = 'Polaris'
export const INFRA_SERVICE_NEXUS: InfraServiceName = 'Nexus'
export const INFRA_SERVICE_CUSTOM: InfraServiceName = 'custom'
export const INFRA_SERVICE_MATTERMOST: InfraServiceName = 'Mattermost'

export interface InfraServiceSpec {
  id?: string
  name: string
  description?: string
  type: string
  url: string
  token?: string
  username?: string
  cluster?: string
  namespace?: string
}

export interface InfraServiceUrl {
  url: string
}

export interface HarborInfo {
  with_notary?: boolean
  harbor_version: string
  next_scan_all?: number
  auth_mode: string
  clair_vulnerability_status?: {
    overall_last_update?: number
    details?: [
      {
        namespace: string
        last_update: number
      }
    ]
  }
  with_clair?: boolean
  admiral_endpoint?: string
  with_admiral?: boolean
  external_url?: string
  project_creation_restriction?: string
  has_ca_root?: boolean
  self_registration?: boolean
  registry_url?: string
  read_only?: boolean
  current_time?: string
  authproxy_settings?: {
    server_certificate?: string
    tokenreivew_endpoint?: string
    endpoint?: string
    verify_cert?: boolean
    skip_search?: boolean
  }
  notification_enable?: boolean
  registry_storage_provider_name?: string
  with_chartmuseum?: boolean
}

export interface GitlabInfo {
  version?: string
  revision?: string
}

export interface ArgoCDInfo {
  Version: string
  BuildDate?: string
  GitCommit?: string
  GitTreeState?: string
  GoVersion?: string
  Compiler?: string
  Platform?: string
  KustomizeVersion?: string
  HelmVersion?: string
  KubectlVersion?: string
  JsonnetVersion?: string
}

export interface GrafanaInfo {
  appUrl: string
  buildInfo?: {
    buildstamp: number
    commit: string
    edition: string
    env: string
    hasUpdate: boolean
    hideVersion: boolean
    latestVersion: string
    version: string
  }
}

export interface VaultInfo {
  initialized: boolean
  sealed: boolean
  standby: boolean
  performance_standby: boolean
  replication_performance_mode: string
  replication_dr_mode: string
  server_time_utc: number
  version: string
  cluster_name: string
  cluster_id: string
}

export interface SonarqubeInfo {
  Health: string
  System: {
    'Server ID': string
    Version: string
    Edition: string
    'Lines of Code': number
    Docker: boolean
    'High Availability': boolean
    'Official Distribution': boolean
    'Force authentication': boolean
    'Home Dir': string
    'Data Dir': string
    'Temp Dir': string
    Processors: number
  }
}

export interface DefectDojoInfo {
  detail?: string
  info: {
    version: string
  }
}

export interface PrometheusInfo {
  status: string
  data?: {
    version: string
    revision: string
    branch: string
    buildUser: string
    buildDate: string
    goVersion: string
  }
}

export interface OpensearchInfo {
  name?: string
  cluster_name?: string
  cluster_uuid?: string
  version?: {
    number?: string
    distribution?: string
    build_type?: string
    build_hash?: string
    build_date?: string
    build_snapshot?: boolean
    lucene_version?: string
    minimum_wire_compatibility_version?: string
    minimum_index_compatibility_version?: string
  }
  tagline?: string
}

export interface OpensearchDashboardsInfo {
  user: string
  user_name: string
  has_api_access: boolean
  disabled_endpoints: any
}

export interface NexusStatusBase {
  healthy: boolean
  message: string
  error: string
  details: string
  time: number
  duration: number
  timestamp: string
}

export interface NexusStatus {
  'Available CPUs': NexusStatusBase
  'Azure Blob Store': NexusStatusBase
  'Blob Stores Quota': NexusStatusBase
  'Blob Stores Ready': NexusStatusBase
  'Coordinate Content Selectors': NexusStatusBase
  'Default Admin Credentials': NexusStatusBase
  DefaultRoleRealm: NexusStatusBase
  'File Blob Stores Path': NexusStatusBase
  'File Descriptors': NexusStatusBase
  'Lifecycle Phase': NexusStatusBase
  'NuGet V2 repositories': NexusStatusBase
  'Read-Only Detector': NexusStatusBase
  Scheduler: NexusStatusBase
  Scripting: NexusStatusBase
  'Thread Deadlock Detector': NexusStatusBase
  Transactions: NexusStatusBase
}
