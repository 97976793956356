export enum CredentialsType {
  LOGIN_AND_PASSWORD = 'логин и пароль',
  SSH = 'ssh ключ',
  SSH_WITH_PASSWORD = 'ssh ключ с паролем',
}

export interface CredentialsBaseSpec {
  uuid: string
  name: string
  owner: string
  description: string
  type: string
}

export interface CredentialsReqSpec {
  name: string
  owner: string
  description?: string
  type: string
  login?: string
  password?: string
  secretText?: string
}
