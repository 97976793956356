import React from 'react'

import { Card, CardContent } from '@mui/material'
import { CustomButton } from 'components/CustomButton'
import { CustomSnackbarComponent } from '../CustomSnackbarComponent'

type UpdateComponentPageProps = {
  handleUpdate: () => Promise<void>
  loading: boolean
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  updatedMessage?: string
  errorMessage?: string
  updateButtonText?: string
}

export const UpdateComponentPage = (props: UpdateComponentPageProps) => {
  let updateButtonText = ''
  if (props.updateButtonText) {
    updateButtonText = props.updateButtonText
  } else {
    updateButtonText = 'Обновить'
  }

  return (
    <Card
      sx={{
        border: 'none',
        boxShadow: 'none',
        minHeight: '100%',
        paddingTop: '0px',
      }}
    >
      <CardContent>
        {props.children}
        <CustomButton
          sx={{
            height: '40px',
            width: '220px',
            marginTop: '50px',
            marginBottom: '10px',
          }}
          onClick={props.handleUpdate}
          loading={props.loading}
        >
          {updateButtonText}
        </CustomButton>
      </CardContent>
      <CustomSnackbarComponent
        opened={props.updated}
        setOpened={props.setUpdated}
        message={props.updatedMessage}
        severity="success"
      />
      <CustomSnackbarComponent
        opened={props.failed}
        setOpened={props.setFailed}
        message={props.errorMessage}
        severity="error"
      />
    </Card>
  )
}

export default UpdateComponentPage
