import { ApiClient } from 'api/ApiClient'
import { ClusterSpec } from '@edp/types'
import {
  KubernetesListObject,
  KubernetesObject,
  V1NodeList,
  V1Pod,
} from '@kubernetes/client-node'
import { NodeResources } from 'types/resources'
import { getAuthType, getJWT } from 'api/utils'
import { KubernetesLabelSelector } from 'types/Controllers/Kubernetes'
import { PodLogs } from 'types/KubernetesResources'

export async function createCluster(
  this: ApiClient,
  cluster: ClusterSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/clusters`
  const body = JSON.stringify(cluster)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })
  return await this.handleResponse(response)
}

export async function getClusters(this: ApiClient): Promise<ClusterSpec[]> {
  const url = `${this.serverUrl}/clusters`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getCluster(
  this: ApiClient,
  uuid: string
): Promise<ClusterSpec> {
  const url = `${this.serverUrl}/clusters/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updateCluster(
  this: ApiClient,
  spec: ClusterSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/clusters/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getClusterInfo(
  this: ApiClient,
  address: string,
  token: string
): Promise<V1NodeList> {
  const url = `${this.serverUrl}/clusters/info/connect?address=${address}&token=${token}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function checkClusterHealth(
  this: ApiClient,
  address: string
): Promise<boolean> {
  const url = `${this.serverUrl}/clusters/check/health?address=${address}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getClusterResources(
  this: ApiClient,
  uuid: string
): Promise<NodeResources[]> {
  const url = `${this.serverUrl}/clusters/${uuid}/resources`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function deleteCluster(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/clusters/${uuid}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function getClusterObjects(
  this: ApiClient,
  uuid: string,
  labelSelectors: KubernetesLabelSelector[]
): Promise<KubernetesListObject<KubernetesObject>[]> {
  const url = `${this.serverUrl}/clusters/${uuid}/objects`
  const body = JSON.stringify(labelSelectors)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })
  return await this.handleResponse(response)
}

export async function getPodLogs(
  this: ApiClient,
  uuid: string,
  pod: V1Pod
): Promise<PodLogs> {
  const url = `${this.serverUrl}/clusters/${uuid}/pods/logs`

  const body = JSON.stringify(pod)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await this.handleResponse(response)
}

export async function deletePod(
  this: ApiClient,
  uuid: string,
  namespace: string,
  pod: string
) {
  const url = `${this.serverUrl}/clusters/${uuid}/pods/${namespace}/${pod}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
