import { ApiClient } from 'api/ApiClient'
import { EditComponentPage } from 'components/EditComponentPage'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ServiceSpec } from 'types/Managers/Services'
import { EntityAction } from 'types/entities'
import Artifacts from './Artifacts'
import BasicInfo from './BasicInfo'
import Grafana from './Grafana'
import Integrations from './Integrations'
import Secrets from './Secrets'
import { Template } from './Template'
import TemplateActions from './TemplateActions'
import ServiceStore from './store'

export interface EditServicePageProps {
  action: EntityAction
}

export const EditServicePage = observer((props: EditServicePageProps) => {
  const { uuid } = useParams()
  const navigate = useNavigate()

  const [edited, setEdited] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  useEffect(() => {
    ServiceStore.reset()
  }, [])

  const fetchData = async () => {
    try {
      if (uuid) {
        const data = await apiClient.getService(uuid)
        ServiceStore.set(data)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [uuid])

  const handleEdit = async () => {
    setEdited(false)
    setFailed(false)
    setErrorMessage('')
    setLoading(true)

    try {
      await ServiceStore.validate().then(({ isValid }) => {
        if (!isValid) {
          setFailed(true)
          const e = new Error('Все обязательные поля должны быть заполнены')
          return Promise.reject(e)
        }
      })

      const spec: ServiceSpec = {
        name: ServiceStore.$('name').value,
        slug: ServiceStore.$('slug').value,
        description: ServiceStore.$('description').value,
        owner: ServiceStore.$('owner').value,
        repository: ServiceStore.$('repository').value,
        repositoryUrl: ServiceStore.$('repositoryUrl').value,
        templated: ServiceStore.$('templated').value,
        template: ServiceStore.$('template').value,
        templateRepositoryVariables: ServiceStore.$(
          'templateRepositoryVariables'
        ).value,
        actionsVariables: ServiceStore.$('actionsVariables').value,
        artifactsName: ServiceStore.$('artifactsName').value,
        artifactsRegistryEngine: ServiceStore.$('artifactsRegistryEngine')
          .value,
        artifactsRegistry: ServiceStore.$('artifactsRegistry').value,
        artifactsRegistryURL: ServiceStore.$('artifactsRegistryURL').value,
        gitlabProjectLevelVariables: ServiceStore.$(
          'gitlabProjectLevelVariables'
        ).value,
        branches: ServiceStore.$('branches').value,
        vault: {
          secrets: ServiceStore.$('vault.secrets').value,
        },
        integrations: {
          grafana: {
            dashboard: ServiceStore.$('integrations.grafana.dashboard').value,
          },
          sonarqube: {
            enabled: ServiceStore.$('integrations.sonarqube.enabled').value,
            project: ServiceStore.$('slug').value,
          },
          defectdojo: {
            enabled: ServiceStore.$('integrations.defectdojo.enabled').value,
            product: ServiceStore.$('slug').value,
          },
        },
      }

      if (props.action === EntityAction.Create) {
        await apiClient.createService(spec)
      }

      if (props.action === EntityAction.Update) {
        spec.uuid = uuid
        await apiClient.updateService(spec)
      }

      setEdited(true)
      navigate('/components/services')
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
    } finally {
      setLoading(false)
    }
  }

  let editedMessage

  if (props.action === EntityAction.Create) {
    editedMessage = 'Сервис создан'
  }

  if (props.action === EntityAction.Update) {
    editedMessage = 'Сервис обновлен'
  }

  return (
    <>
      <EditComponentPage
        action={props.action}
        handleEdit={handleEdit}
        loading={loading}
        edited={edited}
        setEdited={setEdited}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        editedMessage={editedMessage}
      >
        <>
          <BasicInfo disabled={props.action === EntityAction.Update} />
          <Template
            disabled={props.action === EntityAction.Update}
            visible={props.action === EntityAction.Create}
          />
          <TemplateActions disabled={props.action === EntityAction.Update} />
          <Artifacts disabled={props.action === EntityAction.Update} />
          <Secrets disabled={props.action === EntityAction.Update} />
          <Integrations disabled={props.action === EntityAction.Update} />
          <Grafana />
        </>
      </EditComponentPage>
    </>
  )
})

export default EditServicePage
