import React, { useEffect } from 'react'

import { Grid, Typography } from '@mui/material'
import GitSecretsTable from './GitSecretsTable'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'
import {
  GitLeaksLockStatus,
  GitSecretSpec,
  GitSecretsReqSpec,
} from 'types/Security'
import { CustomButton } from 'components/CustomButton'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import { sleep } from 'api/utils'
import { FcOk, FcSynchronize, FcProcess, FcClock } from 'react-icons/fc'

export const GitSecretsPanel = () => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const [gitSecretsScanStatus, setGitSecretsScanStatus] = useState<string>('')
  const [secretsRepository, setSecretsRepository] = useState<
    Array<GitSecretSpec>
  >([])
  const [loadingScanButton, setLoadingScanButton] = useState<boolean>(false)
  const [runningUpdateStatus, setRunningUpdateStatus] = useState<boolean>(false)

  const [startScanSuccess, setStartScanSuccess] = useState<boolean>(false)
  const [startScanFailed, setStartScanFailed] = useState<boolean>(false)
  const [startScanError, setStartScanError] = useState<string>('')

  const [needUpdate, setNeedUpdate] = useState<boolean>(false)

  const getSecretsRepository = async () => {
    const result = await apiClient.getSecretsRepository(uuid || '')
    setSecretsRepository(result)
  }

  const getStatusScanGitSecrets = async () => {
    if (!runningUpdateStatus) {
      setRunningUpdateStatus(true)
      for (;;) {
        const result = await apiClient.getStatusScanGitSecrets(uuid || '')

        setGitSecretsScanStatus(result.status)

        if (result.status === GitLeaksLockStatus.NotFound) {
          getSecretsRepository()
          setRunningUpdateStatus(false)
          return
        } else {
          await sleep(2000)
        }
      }
    }
  }

  const handleStartScan = async () => {
    try {
      setLoadingScanButton(true)

      if (uuid) {
        const spec: GitSecretsReqSpec = {
          uuid: uuid,
          wait: false,
        }

        await apiClient
          .startScanGitSecrets(spec)
          .then(() => {
            setLoadingScanButton(false)
            setStartScanSuccess(true)
            getStatusScanGitSecrets()
          })
          .catch((err) => {
            setLoadingScanButton(false)
            setStartScanError(err)
            setStartScanFailed(true)
          })
      } else {
        setLoadingScanButton(false)
        setStartScanFailed(true)
        setStartScanError('repository uuid is undefined')
      }
    } catch (err) {
      console.log(err)
      if (err instanceof Error) {
        console.log(err)
      } else {
        console.log(err)
      }
    }
  }

  const handleDownloadReport = async () => {
    try {
      const formatted = []

      for (const secret of secretsRepository) {
        delete secret.id
        delete secret.repository

        formatted.push(secret)
      }

      const dataStr =
        'data:application/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(formatted))

      const download = document.createElement('a')

      download.setAttribute('href', dataStr)
      download.setAttribute('download', `${uuid}-secrets.json`)
      document.body.appendChild(download)
      download.click()
      download.remove()
    } catch (err) {
      console.log(err)
      if (err instanceof Error) {
        console.log(err)
      } else {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    getStatusScanGitSecrets()
    getSecretsRepository()
  }, [loadingScanButton])

  useEffect(() => {
    getSecretsRepository()
    setNeedUpdate(false)
  }, [needUpdate])

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ display: 'inline-flex' }}>
          <Grid container>
            <Typography style={{ paddingTop: '0.5em' }}>
              Статус сканирования:
            </Typography>
            {gitSecretsScanStatus === '' && (
              <>
                <div style={{ padding: '0.5em 0.5em 0 0.5em' }}>
                  <FcSynchronize size={20} />
                </div>
                <Typography style={{ padding: '0.5em 0.5em 0 0' }}>
                  определяем
                </Typography>
              </>
            )}
            {gitSecretsScanStatus === GitLeaksLockStatus.NotFound && (
              <>
                <div style={{ padding: '0.5em 0.5em 0 0.5em' }}>
                  <FcOk size={20} />
                </div>
                <Typography style={{ padding: '0.5em 0.5em 0 0' }}>
                  завершено
                </Typography>
              </>
            )}
            {gitSecretsScanStatus === GitLeaksLockStatus.Pending && (
              <>
                <div style={{ padding: '0.5em 0.5em 0 0.5em' }}>
                  <FcClock size={20} />
                </div>
                <Typography style={{ padding: '0.5em 0.5em 0 0' }}>
                  ожидание
                </Typography>
              </>
            )}
            {gitSecretsScanStatus === GitLeaksLockStatus.Running && (
              <>
                <div style={{ padding: '0.5em 0.5em 0 0.5em' }}>
                  <FcProcess size={20} />
                </div>
                <Typography style={{ padding: '0.5em 0.5em 0 0' }}>
                  сканируется
                </Typography>
              </>
            )}
            <CustomButton
              sx={{ width: '150px' }}
              onClick={handleStartScan}
              loading={loadingScanButton}
            >
              Запустить
            </CustomButton>
            <CustomButton
              sx={{ marginLeft: '1em', width: '150px' }}
              onClick={handleDownloadReport}
            >
              Скачать отчет
            </CustomButton>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '1em' }}>
          <GitSecretsTable
            secrets={secretsRepository}
            setNeedUpdate={setNeedUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={startScanSuccess}
            setOpened={setStartScanSuccess}
            message={
              'Сканирование репозитория на наличие секретов успешно запущено'
            }
            severity="success"
          />
          <CustomSnackbarComponent
            opened={startScanFailed}
            setOpened={setStartScanFailed}
            message={`При запуске сканирования репозитория на наличие секретов произошла ошибка. ${startScanError}`}
            severity="error"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default GitSecretsPanel
