import { useEffect, useState } from 'react'

import { ApiClient } from 'api/ApiClient'
import BasicInfo from './EditTeamPage/BasicInfo'
import Resources from './EditTeamPage/Resources'
import TeamApi from './EditTeamPage/TeamApi'
import Integrations from './EditTeamPage/Integrations'
import { TeamSpec } from '@edp/types'
import UpdateComponentPage from 'components/UpdateComponentPage/UpdateComponentPage'
import { useNavigate, useParams } from 'react-router-dom'

export const UpdateTeamPage = () => {
  const { uuid } = useParams()
  const navigate = useNavigate()

  const [team, setTeam] = useState<TeamSpec>({
    uuid: '',
    name: '',
    slug: '',
    parentTeam: '',
    description: '',
    focus: '',
    type: '',
    versioning: '',
    wiki: '',
    chatChannels: '',
    timeDailyMeeting: '',
    quotas: {
      kubernetes: {
        requestsCpu: 0,
        requestsMemory: 0,
        limitsCpu: 0,
        limitsMemory: 0,
      },
    },
    integrations: {
      keycloak: {
        enabled: true,
        group: '',
        realmRole: '',
      },
      argocd: {
        enabled: true,
        project: '',
      },
      gitlab: {
        enabled: true,
        group: '',
        syncMembers: {
          enabled: false,
          auto: false,
          accessLevel: undefined,
        },
      },
      harbor: {
        enabled: true,
        group: '',
        project: '',
      },
      nexus: {
        enabled: true,
        group: '',
        docker: {},
        kubernetes: {
          enabled: false,
        },
      },
      grafana: {
        enabled: true,
        org: '',
        dashboard: '',
      },
      vault: {
        enabled: true,
        secret: '',
        policy: '',
        group: '',
        groupAlias: '',
      },
      defectdojo: {
        enabled: true,
        group: {
          id: undefined,
          name: '',
          description: '',
        },
      },
    },
  })

  const [updated, setUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)

  const apiClient = new ApiClient()

  const getTeam = async () => {
    const apiClient = new ApiClient()

    try {
      const data = await apiClient.getTeam(uuid || '')
      setTeam(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getTeam()
  }, [])

  const handleUpdate = async () => {
    setUpdated(false)
    if (!team.name) {
      setLoading(false)
      setErrorMessage('поле "название" должно быть заполнено')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)

    try {
      const teamSpec: TeamSpec = {
        uuid: team.uuid,
        name: team.name,
        slug: team.slug,
        parentTeam: team.parentTeam,
        description: team.description,
        focus: team.focus,
        chatChannels: team.chatChannels,
        timeDailyMeeting: team.timeDailyMeeting,
        type: team.type,
        versioning: team.versioning,
        wiki: team.wiki,
        quotas: {
          kubernetes: {
            requestsCpu: team.quotas.kubernetes.limitsCpu,
            requestsMemory: team.quotas.kubernetes.limitsMemory,
            limitsCpu: team.quotas.kubernetes.limitsCpu,
            limitsMemory: team.quotas.kubernetes.limitsMemory,
          },
        },
        integrations: {
          keycloak: {
            enabled: team.integrations.keycloak.enabled,
            group: team.name,
            realmRole: team.name,
          },
          argocd: {
            enabled: team.integrations.argocd?.enabled,
            project: team.integrations.argocd?.enabled ? team.name : undefined,
          },
          gitlab: {
            enabled: team.integrations.gitlab.enabled,
            group: team.integrations.gitlab.enabled ? team.name : undefined,
            syncMembers: {
              enabled: team.integrations.gitlab.syncMembers?.enabled,
              auto: team.integrations.gitlab.syncMembers?.auto,
              accessLevel: team.integrations.gitlab.syncMembers?.accessLevel,
            },
          },
          harbor: {
            enabled: team.integrations.harbor.enabled,
            group: team.integrations.harbor.enabled ? team.name : undefined,
            project: team.integrations.harbor.enabled ? team.name : undefined,
          },
          nexus: {
            enabled: team.integrations.nexus?.enabled || false,
            group: team.integrations.nexus?.enabled ? team.name : undefined,
            kubernetes: {
              enabled:
                team.integrations.nexus?.enabled &&
                team.integrations.nexus?.kubernetes?.enabled,
            },
            docker: {
              name: team.integrations.nexus?.enabled
                ? `${team.name}-docker`
                : undefined,
              httpPort: team.integrations.nexus?.enabled
                ? team.integrations.nexus.docker?.httpPort
                : 0,
            },
          },
          grafana: {
            enabled: team.integrations.grafana.enabled,
            org: team.integrations.grafana.enabled ? team.name : undefined,
            dashboard: team.integrations.grafana.dashboard,
          },
          vault: {
            enabled: team.integrations.vault.enabled,
            secret: team.integrations.vault.enabled ? team.name : undefined,
            policy: team.integrations.vault.enabled ? team.name : undefined,
            group: team.integrations.vault.enabled ? team.name : undefined,
            groupAlias: team.integrations.vault.enabled ? team.name : undefined,
          },
          defectdojo: {
            enabled: team.integrations.defectdojo.enabled,
            group: {
              name: team.integrations.defectdojo.enabled
                ? team.name
                : undefined,
            },
          },
        },
      }

      await apiClient.updateTeam(teamSpec)
      setUpdated(true)
      navigate('/organization/teams')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <UpdateComponentPage
      handleUpdate={handleUpdate}
      loading={loading}
      updated={updated}
      setUpdated={setUpdated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      updatedMessage={`Команда ${team.name} обновлена`}
    >
      <>
        <BasicInfo team={team} setTeam={setTeam} disabled={true} />
        <TeamApi team={team} setTeam={setTeam} disabled={true} />
        <Integrations team={team} setTeam={setTeam} disabled={true} />
        <Resources team={team} setTeam={setTeam} disabled={true} />
      </>
    </UpdateComponentPage>
  )
}

export default UpdateTeamPage
