import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { Box, Card, CardContent } from '@mui/material'

export interface HelmValuesCardProps {
  helmValues: string
}

export const HelmValuesCard = (props: HelmValuesCardProps) => {
  return (
    <Card>
      <CardContent>
        <Box>
          <SyntaxHighlighter
            language="yaml"
            style={prism}
            showLineNumbers
            customStyle={{
              height: '100%',
              fontFamily: 'monospace',
              fontSize: '12px',
            }}
          >
            {props.helmValues}
          </SyntaxHighlighter>
        </Box>
      </CardContent>
    </Card>
  )
}

export default HelmValuesCard
