import { HelpOutline } from '@mui/icons-material'
import { Grid, Tooltip, Typography } from '@mui/material'
import { InfraServiceSelect } from '../InfraServiceSelect'

export interface UserInteractionInfraServiceSelectProps {
  name: string
  description: string
  helperText: string
  type: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
}

const UserInteractionInfraServiceSelect = (
  props: UserInteractionInfraServiceSelectProps
) => {
  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {props.description}
        </Typography>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <InfraServiceSelect
        type={props.type}
        selected={props.selected}
        setSelected={props.setSelected}
        disabled={props.disabled}
      />
    </Grid>
  )
}

export default UserInteractionInfraServiceSelect
