import { TechRadarLegendQuadrant } from './TechRadarLegendQuadrant'
import { TechRadarLegendProps } from './types'
import { setupSegments } from './utils'

const TechRadarLegend = ({
  quadrants,
  rings,
  entries,
  onEntryMouseEnter,
  onEntryMouseLeave,
}: TechRadarLegendProps): JSX.Element => {
  return (
    <g data-testid="radar-legend">
      {quadrants.map((quadrant) => (
        <TechRadarLegendQuadrant
          key={quadrant.id}
          segments={setupSegments(entries)}
          quadrant={quadrant}
          rings={rings}
          onEntryMouseEnter={onEntryMouseEnter}
          onEntryMouseLeave={onEntryMouseLeave}
        />
      ))}
    </g>
  )
}

export default TechRadarLegend
