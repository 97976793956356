import React from 'react'

import { Typography } from '@mui/material'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { TeamSpec } from '@edp/types'
export interface TeamApiProps {
  disabled: boolean
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

const TeamApi = (props: TeamApiProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setTeam((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Team API
      </Typography>
      <UserInteractionTextField
        name="focus"
        description="Фокус"
        helperText="Фокус команды: например, разработка, эксплуатация и т.д."
        value={props.team.focus}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="type"
        description="Тип"
        helperText="Тип команды: например, stream-aligned, enabling и т.д."
        value={props.team.type}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="versioning"
        description="Версионирование"
        helperText="Подход команды к версионированию: например, SemVer и т.д."
        value={props.team.versioning}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="wiki"
        description="Документация"
        helperText="Документация команды: ссылка на страницу с публичной документацией команды."
        value={props.team.wiki}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="chatChannels"
        description="Чат"
        helperText="Как связаться с командой: ссылка на чат команды."
        value={props.team.chatChannels}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="timeDailyMeeting"
        description="Митинги"
        helperText="Когда проходят встречи команды: ссылка на регулярные митинги и время их проведения."
        value={props.team.timeDailyMeeting}
        onChange={handleChange}
      />
    </>
  )
}

export default TeamApi
