import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton, Tooltip } from '@mui/material'

export interface UserInteractionRemoveButtonProps {
  handleRemove?: () => void
}

export const UserInteractionRemoveButton = (
  props: UserInteractionRemoveButtonProps
) => {
  return (
    <Tooltip arrow title={'Удалить'} placement="top">
      <IconButton
        aria-label="remove"
        onClick={props.handleRemove}
        sx={{
          marginTop: '7px',
        }}
      >
        <RemoveIcon />
      </IconButton>
    </Tooltip>
  )
}
