import { V1Pod } from '@kubernetes/client-node'
import { IconButton } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { Tooltip } from '@mui/material'

export interface DeleteButtonProps {
  pod: V1Pod
  cluster: string
}

export const DeleteButton = (props: DeleteButtonProps) => {
  const apiClient = new ApiClient()

  const deletePod = async () => {
    await apiClient.deletePod(
      props.cluster,
      props.pod.metadata?.namespace || '',
      props.pod.metadata?.name || ''
    )
  }

  return (
    <IconButton
      onClick={deletePod}
      sx={{
        ':hover': {
          bgcolor: 'transparent',
        },
      }}
    >
      <Tooltip
        arrow
        title={`Удалить pod "${props.pod.metadata?.name}"`}
        placement="top"
      >
        <div>
          <MdOutlineDeleteOutline />
        </div>
      </Tooltip>
    </IconButton>
  )
}
