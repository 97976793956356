import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionEnvironmentSelect from 'components/UserInteraction/UserInteractionEnvironmentSelect'
import UserInteractionPlatformServiceConfigurationSelect from 'components/UserInteraction/UserInteractionPlatformServiceConfigurationSelect copy'
import UserInteractionPlatformServiceTemplateSelect from 'components/UserInteraction/UserInteractionPlatformServiceTemplateSelect'
import { PlatformServiceInstanceSpec } from 'types/Managers/PlatformServiceInstances'

export interface BasicInfoProps {
  platformServiceInstance: PlatformServiceInstanceSpec
  setPlatformServiceInstance: React.Dispatch<
    React.SetStateAction<PlatformServiceInstanceSpec>
  >
  disabled: boolean
}

export const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setPlatformServiceInstance((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setOwner: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setPlatformServiceInstance((prevState) => ({
      ...prevState,
      owner: value instanceof Function ? value(prevState.owner) : value,
    }))
  }

  const setPlatformServiceTemplate: React.Dispatch<
    React.SetStateAction<string>
  > = (value) => {
    props.setPlatformServiceInstance((prevState) => ({
      ...prevState,
      platformServiceTemplate:
        value instanceof Function
          ? value(prevState.platformServiceTemplate)
          : value,
    }))
  }

  const setPlatformServiceConfiguration: React.Dispatch<
    React.SetStateAction<string>
  > = (value) => {
    props.setPlatformServiceInstance((prevState) => ({
      ...prevState,
      platformServiceConfiguration:
        value instanceof Function
          ? value(prevState.platformServiceConfiguration)
          : value,
    }))
  }

  const setEnvironment: React.Dispatch<React.SetStateAction<string>> = (
    value
  ) => {
    props.setPlatformServiceInstance((prevState) => ({
      ...prevState,
      environment:
        value instanceof Function ? value(prevState.environment) : value,
    }))
  }

  const setCluster: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setPlatformServiceInstance((prevState) => ({
      ...prevState,
      cluster: value instanceof Function ? value(prevState.cluster) : value,
    }))
  }

  useEffect(() => {
    const apiClient = new ApiClient()

    const getCluster = async () => {
      try {
        const data = await apiClient.getEnvironment(
          props.platformServiceInstance.environment
        )
        setCluster(data.cluster)
      } catch (e) {
        console.log(e)
      }
    }

    getCluster()
  }, [props.platformServiceInstance.environment])

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название инстанса платформенного сервиса."
        value={props.platformServiceInstance.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание инстанса платформенного сервиса."
        value={props.platformServiceInstance.description}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая инстансом платформенного сервиса."
        selected={props.platformServiceInstance.owner}
        setSelected={setOwner}
      />
      <UserInteractionPlatformServiceTemplateSelect
        name="platformServiceTemplate"
        description="Шаблон"
        helperText="Шаблон платформенного сервиса из которого будет развернут инстанс."
        selected={props.platformServiceInstance.platformServiceTemplate}
        setSelected={setPlatformServiceTemplate}
      />
      <UserInteractionPlatformServiceConfigurationSelect
        name="platformServiceConfiguration"
        description="Конфигурация"
        helperText="Конфигурация платформенного сервиса с использованием которой будет развернут инстанс."
        platformServiceTemplate={
          props.platformServiceInstance.platformServiceTemplate
        }
        selected={props.platformServiceInstance.platformServiceConfiguration}
        setSelected={setPlatformServiceConfiguration}
      />
      <UserInteractionEnvironmentSelect
        name="environment"
        description="Окружение"
        helperText="Окружение на котором будет развернут инстанс платформенного сервиса."
        selected={props.platformServiceInstance.environment}
        setSelected={setEnvironment}
      />
      <UserInteractionTextField
        name="cluster"
        description="Кластер"
        helperText="Kubernetes кластер в котором будет развернут инстанс платформенного сервиса. Выбирается автоматически на основе информации об окружении."
        value={props.platformServiceInstance.cluster}
        disabled
      />
    </>
  )
}

export default BasicInfo
