import { useEffect } from 'react'
import { useState } from 'react'

import randomColor from 'randomcolor'

import { Grid, Typography } from '@mui/material'

import { ApiClient } from 'api/ApiClient'
import useFetchTeam from 'hooks/useFetchTeam'
import { EnvironmentSpec } from 'types/Managers/Environments'
import { ResourcesChart } from './ResourcesChart'
import { ResourcesChartDropdown } from './ResourcesChartDropdown'
import { useParams } from 'react-router-dom'
import prettyBytes from 'pretty-bytes'
import './styles.css'

export const ResourcesPanel = () => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const [allocatedCpu, setAllocatedCpu] = useState<number>(0)
  const [allocatedMemory, setAllocatedMemory] = useState<number>(0)
  const [environments, setEnvironments] = useState<EnvironmentSpec[]>([])

  const team = useFetchTeam(uuid)

  const allMemory = team?.quotas.kubernetes.limitsMemory || 1
  const allCpu = team?.quotas.kubernetes.limitsCpu || 1

  /**
   * Получаем количество ресурсов, использованное командой (выделенное на другие окружения команды)
   */

  useEffect(() => {
    const getEnvironments = async () => {
      try {
        const data = await apiClient.getEnvironmentsBy('owner', uuid || '')
        setEnvironments(data)
      } catch (e) {
        console.log(e)
      }
    }
    getEnvironments()
  }, [team])

  useEffect(() => {
    let am: number = 0
    let ac: number = 0

    try {
      for (const environment of environments) {
        if (environment?.quotas.kubernetes.limitsMemory) {
          am += environment.quotas.kubernetes.limitsMemory
        }
        if (environment?.quotas.kubernetes.limitsCpu) {
          ac += environment.quotas.kubernetes.limitsCpu
        }
      }
    } catch (e) {
      console.log(e)
    }

    setAllocatedMemory(am)
    setAllocatedCpu(ac)
  }, [environments])

  const availableMemory = allMemory - allocatedMemory
  const availableCpu = allCpu - allocatedCpu

  const overallCpu = [
    {
      name: 'Доступно CPU',
      value: availableCpu,
      color: '#0CC078',
    },
  ]

  const overallMemory = [
    {
      name: 'Доступно RAM',
      value: availableMemory,
      color: '#0CC078',
    },
  ]

  if (environments) {
    for (const environment of environments) {
      const color = randomColor({ luminosity: 'light', hue: 'red' })
      overallCpu.push({
        name: `${environment.name}`,
        value: environment.quotas.kubernetes.limitsCpu || 0,
        color: color,
      })

      overallMemory.push({
        name: `${environment.name}`,
        value: environment.quotas.kubernetes.limitsMemory || 0,
        color: color,
      })
    }
  }

  return (
    <Grid>
      <Grid className="Resources-Grid">
        <Grid
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: '600',
              width: '500px',
            }}
          >
            CPU
          </Typography>
          <Typography
            component="div"
            variant="caption"
            sx={{
              width: '500px',
            }}
          >
            Всего у команды: {allCpu}m
          </Typography>
          <ResourcesChart
            resource="CPU"
            allocated={allocatedCpu}
            available={availableCpu}
          />
          <Typography
            variant="body2"
            sx={{
              paddingTop: '10px',
              fontWeight: '600',
              width: '500px',
            }}
          >
            Детализация по окружениям
          </Typography>
          <ResourcesChartDropdown resource="CPU" overall={overallCpu} />
        </Grid>
        <Grid
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: '600',
              width: '500px',
            }}
          >
            RAM
          </Typography>
          <Typography
            component="div"
            variant="caption"
            sx={{
              width: '500px',
            }}
          >
            Всего у команды: {prettyBytes(allMemory, { binary: true })}
          </Typography>
          <ResourcesChart
            resource="RAM"
            allocated={allocatedMemory}
            available={availableMemory}
          />
          <Typography
            variant="body2"
            sx={{
              paddingTop: '10px',
              fontWeight: '600',
              width: '500px',
            }}
          >
            Детализация по окружениям
          </Typography>
          <ResourcesChartDropdown resource="RAM" overall={overallMemory} />
        </Grid>
      </Grid>
    </Grid>
  )
}
