import { observer } from 'mobx-react-lite'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import { Typography } from '@mui/material'
import ServiceStore from './store'
import './styles.css'

export interface BasicInfoProps {
  disabled: boolean
}

const BasicInfo = observer((props: BasicInfoProps) => {
  return (
    <>
      <Typography variant="body1" className="Typography-Body1">
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название сервиса."
        error={!ServiceStore.$('name').isValid}
        {...ServiceStore.$('name').bind()}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор сервиса в инфраструктурных сервисах. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        disabled={props.disabled}
        error={!ServiceStore.$('slug').isValid}
        {...ServiceStore.$('slug').bind()}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание сервиса."
        multiline
        rows={6}
        height={'150px'}
        {...ServiceStore.$('description').bind()}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая сервисом."
        error={!ServiceStore.$('owner').isValid}
        selected={ServiceStore.$('owner').value}
        setSelected={(value) => ServiceStore.$('owner').set(value)}
      />
    </>
  )
})

export default BasicInfo
