import type { Ring } from '../utils/types'
import './styles.css'

export type TechRadarGridProps = {
  radius: number
  rings: Ring[]
}

// A component for the background grid of the radar, with axes, rings etc.  It will render around the origin, i.e.
// assume that (0, 0) is in the middle of the drawing.
const TechRadarGrid = (props: TechRadarGridProps) => {
  const { radius, rings } = props

  const makeRingNode = (ringIndex: number, ringRadius?: number) => [
    <circle
      key={`c${ringIndex}`}
      cx={0}
      cy={0}
      r={ringRadius}
      className={'TechRadar-RadarGrid-Ring'}
    />,
    <text
      key={`t${ringIndex}`}
      y={ringRadius !== undefined ? -ringRadius + 42 : undefined}
      textAnchor="middle"
      className={'TechRadar-RadarGrid-Text'}
      style={{ fill: rings[ringIndex].color }}
      data-testid="radar-ring-heading"
    >
      {rings[ringIndex].name}
    </text>,
  ]

  const axisNodes = [
    // X axis
    <line
      key="x"
      x1={0}
      y1={-radius}
      x2={0}
      y2={radius}
      className={'TechRadar-RadarGrid-Axis'}
      data-testid="radar-grid-x-line"
    />,
    // Y axis
    <line
      key="y"
      x1={-radius}
      y1={0}
      x2={radius}
      y2={0}
      className={'TechRadar-RadarGrid-Axis'}
      data-testid="radar-grid-y-line"
    />,
  ]

  const ringNodes = rings
    .map((r) => r.outerRadius)
    .map((ringRadius, ringIndex) => makeRingNode(ringIndex, ringRadius))

  return <>{axisNodes.concat(...ringNodes)}</>
}

export default TechRadarGrid
