import { SiGitlab } from 'react-icons/si'
import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ActionVariables } from 'types/Managers/Actions'
import { GitlabGroupLevelVariables as GroupLevelVariables } from 'types/Managers/Actions'

import './styles.css'
import GitlabGroupLevelVariablesAction from 'pages/components/Actions/GitlabGroupLevelVariablesAction/GitlabGroupLevelVariablesAction'
import { GitlabGroupLevelVariablesActionForm } from 'pages/components/Actions/GitlabGroupLevelVariablesAction/store'
import { reaction, runInAction } from 'mobx'
import ServiceStore from '../store'
import { useEffect } from 'react'

export interface GitlabGroupLevelVariablesProps {
  actionVariables: ActionVariables
  index: number
}

export const GitlabGroupLevelVariablesLogo = () => {
  return (
    <Tooltip
      arrow
      title={
        'Gitlab group-level variables action создает переменные в выбранной группе Gitlab.'
      }
      placement="top"
    >
      <div>
        <SiGitlab className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const GitlabGroupLevelVariables = observer(
  (props: GitlabGroupLevelVariablesProps) => {
    const form = GitlabGroupLevelVariablesActionForm()
    form.set(props.actionVariables.variables)

    const setActionsVariables = (index: number) => {
      runInAction(() => {
        const actionsVariables: ActionVariables[] = [
          ...ServiceStore.$('actionsVariables').value,
        ]

        const groupLevelVariables: GroupLevelVariables = {
          group: form.$('group').value,
          groupLevelVariables: form.$('groupLevelVariables').value,
        }

        actionsVariables[index].variables = groupLevelVariables

        ServiceStore.$('actionsVariables').set(actionsVariables)
      })
    }

    useEffect(() => {
      const disposer = reaction(
        () => ({
          topic: form.$changed,
        }),
        () => {
          setActionsVariables(props.index)
        }
      )

      return () => disposer()
    }, [form, props.index])

    return (
      <GitlabGroupLevelVariablesAction
        form={form}
        validate={true}
        disabled={true}
      />
    )
  }
)
