import SwaggerUI from 'swagger-ui-react'
import { useState, useEffect } from 'react'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'
import 'swagger-ui-react/swagger-ui.css'
import { NothingFoundPage } from '@edp/core-components-frontend'
import { Grid, LinearProgress } from '@mui/material'
import Branches from '../../../../components/ServiceBranchesSelect/ServiceBranchesSelect'
import './styles.css'

export const ApiPanel = () => {
  const { uuid } = useParams()
  const [apiSpec, setApiSpec] = useState<string>('')
  const [branch, setBranch] = useState<string>('main')

  const [loading, setLoading] = useState<boolean>(false)
  const apiClient = new ApiClient()

  useEffect(() => {
    setLoading(true)

    const fetchApiSpec = async () => {
      setApiSpec('')
      try {
        if (uuid && branch) {
          const body = await apiClient.getServiceApiSpecification(uuid, branch)
          setApiSpec(body)
        }
      } catch (e) {
        console.log('Ошибка при получении спецификации:', e)
      } finally {
        setLoading(false)
      }
    }

    fetchApiSpec()
  }, [uuid, branch])

  return (
    <>
      <Grid className="ApiPanel-Grid-Button">
        <Branches selected={branch} setSelected={setBranch} />
      </Grid>
      {loading && <LinearProgress />}
      {apiSpec && !loading && <SwaggerUI spec={apiSpec} />}
      {!apiSpec && !loading && (
        <NothingFoundPage text="Попробуйте выбрать другую ветку или добавить в репозиторий сервиса спецификацию API в формате, поддерживаемом Swagger (Swagger/OpenAPI). Файл должен называться openapi-schema.yml и находиться в корне репозитория." />
      )}
    </>
  )
}

export default ApiPanel
