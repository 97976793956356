import { Typography } from '@mui/material'
import UserInteractionHighlightedInput from 'components/UserInteraction/UserInteractionHighlightedInput'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import Form from 'mobx-react-form'
import { observer } from 'mobx-react-lite'
import './styles.css'

interface MattermostWebhookActionProps {
  form: Form
}

export const MattermostWebhookAction = observer(
  (props: MattermostWebhookActionProps) => {
    return (
      <>
        <Typography
          variant="subtitle2"
          className="Typography-Subtitle2"
          paddingTop="15px"
        >
          Настройки webhook в Mattermost
        </Typography>
        <UserInteractionTextField
          name="url"
          description="Webhook URL"
          helperText="URL, на который будет отправляться webhook."
          {...props.form.$('url').bind()}
        />
        <UserInteractionTextField
          name="channel"
          description="Channel"
          helperText="Название канала в Mattermost в который будет отправлено сообщение. Необходимо использовать channel’s name, не display name, например, 'town-square', а не 'Town Square'."
          {...props.form.$('webhook.channel').bind()}
        />
        <UserInteractionTextField
          name="username"
          description="Username"
          helperText="УЗ, от имени которой будет отправлено сообщение."
          {...props.form.$('webhook.username').bind()}
        />
        <UserInteractionHighlightedInput
          name="text"
          language="markdown"
          description="Сообщение"
          helperText="Сообщение, которое будет отправлено в Mattermost."
          minHeight={'150px'}
          value={props.form.$('webhook.text').value}
          setValue={(value) => props.form.$('webhook.text').set(value)}
        />
      </>
    )
  }
)

export default MattermostWebhookAction
