import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
  CustomSelectProps,
} from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'
import { ApiClient } from 'api/ApiClient'
import { CredentialsBaseSpec } from 'types/Credentials'

export interface CredentialsSelectProps extends CustomSelectProps {}

export const CredentialsSelect = (props: CredentialsSelectProps) => {
  const [credentials, setCredentials] = useState<CredentialsBaseSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getCredentials = async () => {
      try {
        const data = await apiClient.getCredentialsArray()
        setCredentials(data)
      } catch (e) {
        console.log(e)
      }
    }

    getCredentials()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    credentials?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default CredentialsSelect
