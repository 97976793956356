import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from 'components/CustomButton'
import UserInteractionCustomMultipleSelect from 'components/UserInteraction/UserInteractionCustomMultipleSelect'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import useFetchTeam from 'hooks/useFetchTeam'
import { useState } from 'react'
import { Actions, Objects, Permission } from 'types/rbac/permission'
import CloseIcon from '@mui/icons-material/Close'
import './styles.css'

export interface EditPermissionsProps {
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export const EditPermissions = (props: EditPermissionsProps) => {
  const actions = Object.values(Actions)
  const objects = Object.values(Objects)

  const handleClose = () => props.setOpened(false)

  const [owner, setOwner] = useState<string>('')
  const [selectedActions, setSelectedActions] = useState<string[]>([])
  const [selectedObjects, setSelectedObjects] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const apiClient = new ApiClient()

  const team = useFetchTeam(owner)

  const handleCreate = async () => {
    setOwner('')
    setSelectedActions([])
    setSelectedObjects([])

    props.setCreated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    if (selectedActions.length === 0) {
      props.setFailed(true)
      props.setErrorMessage('Выберите как минимум одно действие')
      return
    }

    if (selectedObjects.length === 0) {
      props.setFailed(true)
      props.setErrorMessage('Выберите как минимум один ресурс')
      return
    }

    try {
      setLoading(true)

      const spec: Permission[] = []

      selectedActions.forEach(async (action) => {
        selectedObjects.forEach(async (object) => {
          spec.push({
            name: owner
              ? `${action}-${object}-${team?.name}`
              : `${action}-${object}`,
            action: action,
            object: object,
            owner: owner,
          })
        })
      })

      await apiClient.createPermissions(spec)
      props.setCreated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog
        PaperProps={{ sx: { maxWidth: '750px' } }}
        open={props.opened}
        onClose={handleClose}
      >
        <DialogTitle>
          Создание политик
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Typography
            component={'span'}
            variant="caption"
            sx={{ paddingTop: '15px' }}
          >
            Политики доступа разрешают производить действия с ресурсами EDP:
            создание ресурсов (<b>create</b>), обновление ресурсов (
            <b>update</b>), просмотр ресурсов (<b>get</b>) или удаление ресурсов
            (<b>delete</b>
            ).
            <br />
            <br />
            Вы можете создать одну или несколько политик доступа. Для каждой
            политики необходимо указать действие и ресурс, к которому будет
            применяться данное действие. Если указано несколько действий или
            несколько ресурсов, то для каждой пары &quot;действие + ресурс&quot;
            будет создана отдельная политика.
            <br />
            <br />
            Выбор владельца ресурса позволяет ограничить действие политики
            только на ресурсы с данным владельцем. Если владелец не указан, то
            политика будет действовать на все ресурсы.
            <br />
            <br />
            Название политик доступа формируется автоматически по принципу
            &quot;действие-ресурс&quot; или
            &quot;действие-ресурс-владелец&quot;, в зависимости от того, указан
            владелец или нет.
          </Typography>
          <div style={{ paddingTop: '20px' }}>
            <UserInteractionCustomMultipleSelect
              name="action"
              description="Действие"
              helperText="Действие с выбранным ресурсом EDP. Доступно указание нескольких действий."
              options={actions}
              selected={selectedActions}
              setSelected={setSelectedActions}
            />
            <UserInteractionCustomMultipleSelect
              name="object"
              description="Ресурс"
              helperText="Ресурс EDP. Доступно указание нескольких ресурсов."
              options={objects}
              selected={selectedObjects}
              setSelected={setSelectedObjects}
            />
            <UserInteractionTeamSelect
              name="owner"
              description="Владелец ресурса"
              helperText="Владелец ресурса EDP."
              selected={owner}
              setSelected={setOwner}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right', alignSelf: 'end' }}>
          <CustomButton onClick={handleCreate} loading={loading}>
            Создать
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditPermissions
