import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Grid } from '@mui/material'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { CustomButton } from 'components/CustomButton'

import { GitlabTagRequest } from 'types/Controllers/Gitlab'
import { ApiClient } from 'api/ApiClient'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionServiceBranchesSelect from 'components/UserInteraction/UserInteractionServiceBranchesSelect'
import { observer } from 'mobx-react-lite'
import TagStore from './store'
import { FaTag } from 'react-icons/fa'

export const CreateTag = observer(() => {
  const { uuid } = useParams()

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)

  const apiClient = new ApiClient()

  const handleOpen = () => {
    TagStore.reset()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const createTag = async () => {
    setLoading(true)
    setErrorMessage('')

    if (uuid) {
      try {
        await TagStore.validate().then(({ isValid }) => {
          if (!isValid) {
            setFailed(true)
            const e = new Error('Все обязательные поля должны быть заполнены')
            return Promise.reject(e)
          }
        })

        const spec: GitlabTagRequest = {
          ref: TagStore.$('ref').value,
          tag_name: TagStore.$('name').value,
          message: TagStore.$('message').value,
        }

        await apiClient.createServiceRepositoryTag(uuid, spec)

        setCreated(true)
        setOpen(false)
      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.message)
          setFailed(true)
        } else {
          setErrorMessage('unknown')
          setFailed(true)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <CustomButton
        startIcon={<FaTag />}
        onClick={handleOpen}
        sx={{
          width: '145px',
        }}
      >
        Создать тег
      </CustomButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: '750px' } }}
        aria-labelledby="build-dialog"
        aria-describedby="build-dialog-description"
      >
        <DialogTitle>
          Создать тег
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Grid
            sx={{
              display: 'grid',
              paddingTop: '10px',
            }}
          >
            <UserInteractionServiceBranchesSelect
              name="ref"
              description="Ветка репозитория"
              helperText="Ветка репозитория для которой будет создан тег."
              error={!TagStore.$('ref').isValid}
              selected={TagStore.$('ref').value}
              setSelected={(value) => TagStore.$('ref').set(value)}
            />
            <UserInteractionTextField
              name="name"
              description="Название тега"
              helperText="Рекомендуется придерживаться подхода SemVer."
              error={!TagStore.$('name').isValid}
              {...TagStore.$('name').bind()}
            />
            <UserInteractionTextField
              name="message"
              description="Описание"
              helperText="Описание тега."
              multiline
              rows={6}
              height={'150px'}
              {...TagStore.$('message').bind()}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton loading={loading} onClick={createTag}>
            Создать тег
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomSnackbarComponent
        opened={created}
        setOpened={setCreated}
        message={'Тег создан'}
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
})

export default CreateTag
