import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { HelmValuesCard } from 'components/HelmValuesCard'
import { useParams } from 'react-router-dom'

export const HelmValuesPanel = () => {
  const { uuid } = useParams()
  const [helmValues, setHelmValues] = useState<string>('')

  useEffect(() => {
    const apiClient = new ApiClient()

    const getHelmValues = async () => {
      try {
        const data = await apiClient.getPlatformServiceConfiguration(uuid || '')
        setHelmValues(data.helmValues)
      } catch (e) {
        console.log(e)
      }
    }

    getHelmValues()
  }, [uuid])

  return <HelmValuesCard helmValues={helmValues} />
}
