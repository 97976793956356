import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import useFetchTeam from 'hooks/useFetchTeam'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const team = useFetchTeam(uuid)

  return (
    <ComponentCard
      name={team?.name}
      description={team?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: `${team?.slug}`,
        },
        {
          name: 'Входит в',
          value: `${team?.parentTeamRef?.name}`,
          disabled: !team?.parentTeam,
        },
        {
          name: 'Фокус',
          value: `${team?.focus}`,
          disabled: !team?.focus,
        },
        {
          name: 'Тип',
          value: `${team?.type}`,
          disabled: !team?.type,
        },
        {
          name: 'Версионирование',
          value: `${team?.versioning}`,
          disabled: !team?.versioning,
        },
        {
          name: 'Документация',
          value: `${team?.wiki}`,
          disabled: !team?.wiki,
        },
        {
          name: 'Чат',
          value: `${team?.chatChannels}`,
          disabled: !team?.chatChannels,
        },
        {
          name: 'Митинги',
          value: `${team?.timeDailyMeeting}`,
          disabled: !team?.timeDailyMeeting,
        },
      ]}
    />
  )
}

export default DescriptionPanel
