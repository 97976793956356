import { AllurePanel } from '@edp/plugin-allure-panel-frontend'
import { ComponentPage } from 'components/ComponentPage'
import { OperationsPanel } from 'components/OperationsPanel/OperationsPanel'
import { useParams } from 'react-router-dom'
import ApiPanel from './ApiPanel/ApiPanel'
import { ArtifactsPanel } from './ArtifactsPanel/ArtifactsPanel'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'
import EnvironmentsPanel from './EnvironmentsPanel/EnvironmentsPanel'
import { GitlabPanel } from './GitlabPanel/GitlabPanel'
import GrafanaPanel from './GrafanaPanel/GrafanaPanel'
import ReadmePanel from './ReadmePanel/ReadmePanel'
import SbomPanel from './SbomPanel/SbomPanel'
import { SecretsPanel } from './SecretsPanel/SecretsPanel'

export const ServicePage = () => {
  const { uuid } = useParams()

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'README',
      index: 1,
      children: <ReadmePanel uuid={uuid || ''} />,
    },
    {
      name: 'Окружения',
      index: 2,
      children: <EnvironmentsPanel />,
    },
    {
      name: 'CI/CD',
      index: 3,
      children: <GitlabPanel />,
    },
    {
      name: 'Артефакты',
      index: 4,
      children: <ArtifactsPanel />,
    },
    {
      name: 'Секреты',
      index: 5,
      children: <SecretsPanel />,
    },
    {
      name: 'Мониторинг',
      index: 6,
      children: <GrafanaPanel />,
      hidden: false,
    },
    {
      name: 'Allure',
      index: 7,
      children: <AllurePanel uuid={uuid || ''} />,
    },
    {
      name: 'SBOM',
      index: 8,
      children: <SbomPanel />,
    },
    {
      name: 'API',
      index: 9,
      children: <ApiPanel />,
    },
    {
      name: 'Операции',
      index: 10,
      children: <OperationsPanel entity={uuid || ''} />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default ServicePage
