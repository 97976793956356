import { SiMattermost } from 'react-icons/si'
import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import {
  ActionVariables,
  MattermostPostVariables,
} from 'types/Managers/Actions'

import './styles.css'
import MattermostPostAction from 'pages/components/Actions/MattermostPostAction/MattermostPostAction'
import { MattermostPostActionForm } from 'pages/components/Actions/MattermostPostAction/store'
import { reaction, runInAction } from 'mobx'
import ServiceStore from '../store'
import { useEffect } from 'react'

export interface MattermostPostProps {
  actionVariables: ActionVariables
  index: number
}

export const MattermostPostLogo = () => {
  return (
    <Tooltip
      arrow
      title={
        'Mattermost post action создает сообщение в выбранном канале Mattermost.'
      }
      placement="top"
    >
      <div>
        <SiMattermost className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const MattermostPost = observer((props: MattermostPostProps) => {
  const form = MattermostPostActionForm()
  form.set(props.actionVariables.variables)

  const setActionsVariables = (index: number) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const post: MattermostPostVariables = {
        instance: form.$('instance').value,
        post: {
          channel_id: form.$('post.channel_id').value,
          message: form.$('post.message').value,
        },
      }

      actionsVariables[index].variables = post

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  useEffect(() => {
    const disposer = reaction(
      () => ({
        post: form.$changed,
      }),
      () => {
        setActionsVariables(props.index)
      }
    )

    return () => disposer()
  }, [form, props.index])

  return <MattermostPostAction form={form} />
})
