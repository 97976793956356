import { CardMedia, LinearProgress } from '@mui/material'
import useFetchService from 'hooks/useFetchService'
import { NothingFoundPage } from '@edp/core-components-frontend'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import './styles.css'

export const GrafanaPanel = () => {
  const { uuid } = useParams()
  const service = useFetchService(uuid)

  const [loading, setLoading] = useState<boolean>(true)

  const handleLoad = () => {
    setLoading(false)
  }

  return (
    <>
      {loading && <LinearProgress />}
      {!service?.integrations.grafana?.dashboard && !loading && (
        <NothingFoundPage text="Grafana dashboard не найден. Попробуйте добавить его на странице редактирования сервиса" />
      )}
      <CardMedia
        className="Services-GrafanaPanel-CardMedia"
        component="iframe"
        height="100%"
        width="100%"
        src={service?.integrations.grafana?.dashboard}
        sx={{
          border: 'none',
          boxShadow: 'none',
          visibility: loading ? 'hidden' : 'visible',
        }}
        onLoad={handleLoad}
      />
    </>
  )
}

export default GrafanaPanel
