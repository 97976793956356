export const KrakendEndpointMethods = [
  'GET',
  'POST',
  'PUT',
  'HEAD',
  'DELETE',
  'CONNECT',
  'OPTIONS',
  'TRACE',
  'PATH',
]

export const KrakendEndpointOutputEncoindgs = [
  'no-op',
  'json',
  'fast-json',
  'json-collection',
  'xml',
  'string',
  'negotiate',
]

export const KrakendEndpointBackendEncodings = [
  'no-op',
  'json',
  'fast-json',
  'safe-json',
  'xml',
  'string',
  'rss',
]

export const KrakendEndpointExtraConfigAlgs = [
  'EdDSA',
  'HS256',
  'HS384',
  'HS512',
  'RS256',
  'RS384',
  'RS512',
  'ES256',
  'ES384',
  'ES512',
  'PS256',
  'PS384',
  'PS512',
]

export const KrakendEndpointSds = ['static', 'dns']

export interface KrakendBackend {
  url_pattern: string
  encoding: string
  sd: string
  method: string
  host: string[]
  disable_host_sanitize: boolean
}

export interface KrakendExtraConfig {
  'auth/validator': {
    alg: string
    jwk_url: string
    disable_jwk_security: boolean
  }
}

export interface KrakendEndpointSpec {
  endpoint: string
  method: string
  output_encoding: string
  backend: KrakendBackend[]
  extra_config: KrakendExtraConfig
  input_headers: string[]
  timeout: string
  cache_ttl: string
}
