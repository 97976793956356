import {
  INFRA_SERVICE_ARGOCD,
  INFRA_SERVICE_CUSTOM,
  INFRA_SERVICE_DEFECTDOJO,
  INFRA_SERVICE_GITLAB,
  INFRA_SERVICE_GRAFANA,
  INFRA_SERVICE_HARBOR,
  INFRA_SERVICE_MATTERMOST,
  INFRA_SERVICE_NEXUS,
  INFRA_SERVICE_OPENSEARCH,
  INFRA_SERVICE_OPENSEARCH_DASHBOARDS,
  INFRA_SERVICE_POLARIS,
  INFRA_SERVICE_PROMETHEUS,
  INFRA_SERVICE_SONARQUBE,
  INFRA_SERVICE_STATUS_PAGE,
  INFRA_SERVICE_VAULT,
} from 'types/Managers/InfraServices'
import {
  CustomSelectComponent,
  CustomSelectProps,
} from 'components/CustomSelectComponent/CustomSelectComponent'

export interface InfraServiceTypeSelectProps extends CustomSelectProps {
  filter?: string[]
}

export const InfraServiceTypeSelect = (props: InfraServiceTypeSelectProps) => {
  const services: string[] = [
    INFRA_SERVICE_HARBOR,
    INFRA_SERVICE_GITLAB,
    INFRA_SERVICE_ARGOCD,
    INFRA_SERVICE_GRAFANA,
    INFRA_SERVICE_VAULT,
    INFRA_SERVICE_DEFECTDOJO,
    INFRA_SERVICE_SONARQUBE,
    INFRA_SERVICE_PROMETHEUS,
    INFRA_SERVICE_OPENSEARCH,
    INFRA_SERVICE_OPENSEARCH_DASHBOARDS,
    INFRA_SERVICE_STATUS_PAGE,
    INFRA_SERVICE_POLARIS,
    INFRA_SERVICE_NEXUS,
    INFRA_SERVICE_CUSTOM,
    INFRA_SERVICE_MATTERMOST,
  ]

  let options: string[] = []

  if (props.filter && props.filter.length > 0) {
    options = services.filter((service) => props.filter?.includes(service))
  } else {
    options = services
  }

  return <CustomSelectComponent options={options} {...props} />
}

export default InfraServiceTypeSelect
