import React from 'react'
import Alert from '@mui/material/Alert'
import { Card, LinearProgress, CardContent } from '@mui/material'
import { useAsync } from 'react-use'
import { ContributorsList } from './components/ContributorsList'
import { GitlabContributorData } from 'types/Controllers/Gitlab'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'
import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'

export const ContributorsCard = () => {
  const apiClient = new ApiClient()
  const { uuid } = useParams()

  const { value, loading, error } =
    useAsync(async (): Promise<GitlabContributorData> => {
      const gitlabObj = await apiClient.getContributorsSummary(uuid || '')
      const data = gitlabObj?.getContributorsData
      const renderData: any = { data }

      return renderData
    }, [])

  return (
    <Card sx={{ boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Контрибьюторы"
        description="Пользователи, которые внесли вклад в кодовую базу сервиса"
        fullPage={false}
      />
      <CardContent sx={{ minHeight: '250px' }}>
        {loading && <LinearProgress />}
        {!loading && error && (
          <Alert severity="error" style={{ marginTop: 3 }}>
            {error.message}
          </Alert>
        )}
        {!loading && !error && (
          <ContributorsList contributorsObj={value || { data: [] }} />
        )}
      </CardContent>
    </Card>
  )
}
