import React from 'react'

import { HelpOutline } from '@mui/icons-material'
import { Grid, Typography, Tooltip } from '@mui/material'
import './styles.css'
import { CustomSelectComponent } from '../CustomSelectComponent'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'

export interface UserInteractionCustomSelectProps {
  name: string
  description: string
  options: string[]
  helperText: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  error?: boolean
  loading?: boolean
  disabled?: boolean
  removeButton?: boolean
  handleRemove?: () => void
}

const UserInteractionCustomSelect = (
  props: UserInteractionCustomSelectProps
) => {
  return (
    <Grid className="UserInteraction-Grid-Remove">
      <Grid className="UserInteraction-Grid">
        <Grid className="UserInteraction-Description">
          <Typography variant="body2" fontSize={'15px'}>
            {props.description}
          </Typography>
          <Tooltip arrow title={props.helperText} placement="top">
            <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
          </Tooltip>
        </Grid>
        <CustomSelectComponent
          options={props.options}
          selected={props.selected}
          setSelected={props.setSelected}
          error={props.error}
          loading={props.loading}
          disabled={props.disabled}
        />
      </Grid>
      {props.removeButton && (
        <UserInteractionRemoveButton handleRemove={props.handleRemove} />
      )}
    </Grid>
  )
}

export default UserInteractionCustomSelect
