import { OpensearchInfo } from 'types/Managers/InfraServices'
import {
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'

export interface opensearchInfoCardProps {
  status: string
  error: string
  info: OpensearchInfo
}

export const OpensearchInfoCard = (props: opensearchInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <CircularProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'warning' && (
        <Alert severity="warning">
          Подключение установлено, но токен некорректный
        </Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}
      {props.status === 'success' && props.info !== undefined && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Cluster name:</b> {props.info.cluster_name}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Cluster uuid:</b> {props.info.cluster_uuid}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Distribution:</b> {String(props.info.version?.distribution)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Version:</b> {String(props.info.version?.number)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Lucene version:</b>{' '}
              {String(props.info.version?.lucene_version)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Minimum wire compatibility version:</b>{' '}
              {String(props.info.version?.minimum_wire_compatibility_version)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Minimum index compatibility version:</b>{' '}
              {String(props.info.version?.minimum_index_compatibility_version)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Tagline:</b> {String(props.info.tagline)}
            </ListItemText>
          </ListItem>
        </List>
      )}
    </Paper>
  )
}
