import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 7
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export type MultipleSelectProps = {
  title?: string
  width?: string
  options: string[]
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
}

export const CustomMultipleSelectComponent = (props: MultipleSelectProps) => {
  const options = props.options

  const handleChange = (event: { target: { value: any } }) => {
    props.setSelected([...event.target.value])
  }

  return (
    <FormControl
      size="small"
      sx={{
        width: props.width || 360,
      }}
    >
      <InputLabel id="select-helper-label">{props.title}</InputLabel>
      <Select
        multiple
        value={props.selected}
        onChange={handleChange}
        label={props.title}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
            {props.selected.map((option: string) => (
              <Chip size="small" key={option} label={option} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
