import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  targetRepository: null,
  targetBranch: null,
  targetFile: null,
  endpoint: null,
  method: null,
  output_encoding: null,
  timeout: null,
  cache_ttl: null,
  extra_config: {
    'auth/validator': {
      alg: 'RS256',
      jwk_url: null,
      disable_jwk_security: true,
    },
  },
  input_headers: [],
  backend: [
    {
      url_pattern: null,
      encoding: null,
      sd: null,
      method: null,
      host: [null],
      disable_host_sanitize: false,
    },
  ],
}

export const CreateKrakendEndpointActionForm = () => {
  return new MobxReactForm({ fields }, { plugins })
}

export default new MobxReactForm({ fields }, { plugins })
