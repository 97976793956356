import React from 'react'

import {
  Alert,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { VaultInfo } from 'types/Managers/InfraServices'

export interface vaultInfoCardProps {
  status: string
  error: string
  info: VaultInfo
}

export const VaultInfoCard = (props: vaultInfoCardProps) => {
  return (
    <Paper>
      {props.status === 'loading' && <LinearProgress />}

      {props.status === 'info' && (
        <Alert severity="info">
          Для отображения информации проверьте подключение
        </Alert>
      )}

      {props.status === 'success' && (
        <Alert severity="success">Подключение установлено</Alert>
      )}

      {props.status === 'error' && (
        <Alert severity="error">
          Не удалось подключиться к сервису
          <br />
          <br />
          {props.error}
        </Alert>
      )}

      {props.status === 'success' && (
        <List>
          <ListItem>
            <ListItemText>
              <b>Version:</b> {props.info.version}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Initialized:</b> {String(props.info.initialized)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Sealed:</b> {String(props.info.sealed)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Standby:</b> {String(props.info.standby)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Performance standby:</b>{' '}
              {String(props.info.performance_standby)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Replication performance mode:</b>{' '}
              {props.info.replication_performance_mode}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Replication dr mode:</b> {props.info.replication_dr_mode}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Cluster name:</b> {props.info.cluster_name}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Cluster ID:</b> {props.info.cluster_id}
            </ListItemText>
          </ListItem>
        </List>
      )}
    </Paper>
  )
}
