import { User } from 'oidc-client-ts'
import { AuthType, ProfileSpec } from 'types/LocalAccounts'
import {
  checkExistOidcToken,
  getOIDCUserFromSessionStorage,
} from './OidcAccounts'
import { checkExistLocalUserToken } from './LocalAccounts'

export function getJWT() {
  const authType = getAuthType()

  if (authType === AuthType.OIDC) {
    const userInfo: User | null = getOIDCUserFromSessionStorage()

    if (userInfo === null) {
      return ''
    }

    return userInfo.id_token
  }

  if (authType === AuthType.Local) {
    return localStorage.getItem('local.user')
  }
}

export function getAuthType(): AuthType {
  const authType = localStorage.getItem('authType')

  switch (authType) {
    case AuthType.Local:
      return AuthType.Local

    case AuthType.OIDC:
      return AuthType.OIDC

    default:
      return AuthType.unknown
  }
}

export function checkAuth(): boolean {
  if (checkExistLocalUserToken() || checkExistOidcToken()) {
    return true
  } else {
    if (localStorage.getItem('auth') === 'true') {
      return true
    } else {
      return false
    }
  }
}

// Profile

export function setProfile(profile: ProfileSpec) {
  localStorage.setItem('profile', JSON.stringify(profile))
}

export function getProfile(): ProfileSpec {
  const result = localStorage.getItem('profile')

  if (result === null) {
    return {
      email_verified: false,
      name: 'unknown',
      groups: ['unknown'],
      preferred_username: 'unknown',
      given_name: 'unknown',
      family_name: 'unknown',
      email: 'unknown',
    }
  } else {
    return JSON.parse(result) as ProfileSpec
  }
}
export function deleteProfile() {
  localStorage.removeItem('profile')
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
