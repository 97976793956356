import React, { useEffect, useState } from 'react'

import { Card, LinearProgress } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { CustomButton } from 'components/CustomButton'
import { RevokeSignatureSpec, SignedSpec } from 'types/signature'
import useFetchService from 'hooks/useFetchService'
import useFetchTeam from 'hooks/useFetchTeam'
import { ServiceTag } from 'types/Managers/Artifacts'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'

interface ArtifactsPanelProps {
  tags: ServiceTag[]
  sign?: SignedSpec
  loading: boolean
  setNeedUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

export const ArtifactsTable = (props: ArtifactsPanelProps) => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const service = useFetchService(uuid || '')
  const name = service?.name
  const team = useFetchTeam(service?.owner || '')
  const owner = team?.name

  const [loadingSignedImage, setLoadingSignedImage] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>())

  const sign = async (spec: SignedSpec, tag: string) => {
    setLoadingSignedImage(loadingSignedImage.set(tag, true))

    await apiClient
      .createSignatureImage(spec)
      .then(() => {
        props.setNeedUpdate(true)
        setLoadingSignedImage(loadingSignedImage.set(tag, false))
      })
      .catch(() => {
        setLoadingSignedImage(loadingSignedImage.set(tag, false))
      })
  }

  const revoke = async (
    project: string,
    registryID: number,
    repository: string,
    tag: string
  ) => {
    setLoadingSignedImage(loadingSignedImage.set(tag, true))

    const spec: RevokeSignatureSpec = {
      project: project,
      repository: repository,
      registryID: registryID,
      artifact: tag,
    }

    await apiClient
      .deleteSignatureImage(spec)
      .then(() => {
        props.setNeedUpdate(true)
        setLoadingSignedImage(loadingSignedImage.set(tag, false))
      })
      .catch(() => {
        setLoadingSignedImage(loadingSignedImage.set(tag, false))
      })
  }

  useEffect(() => {
    props.tags.forEach((tag) => {
      setLoadingSignedImage(loadingSignedImage.set(tag.name, false))
    })
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'sign',
      headerName: '',
      minWidth: 130,
      renderCell: (params) => {
        return (
          <>
            {params.row.signed && (
              <CustomButton
                disabled
                size="small"
                sx={{
                  width: '110px',
                  height: '40px',
                }}
                onClick={() =>
                  revoke(
                    params.row.project,
                    params.row.sign.registryID,
                    params.row.repository,
                    params.row.tag
                  )
                }
                loading={loadingSignedImage.get(params.row.tag)}
              >
                Отозвать
              </CustomButton>
            )}
            {!params.row.signed && (
              <CustomButton
                disabled
                size="small"
                sx={{
                  width: '110px',
                  height: '40px',
                }}
                onClick={() => sign(params.row.sign, params.row.tag)}
                loading={loadingSignedImage.get(params.row.tag)}
              >
                Подписать
              </CustomButton>
            )}
          </>
        )
      },
    },
    { field: 'tag', headerName: 'Тег', minWidth: 250 },
    {
      field: 'pullCommand',
      headerName: 'Команда для загрузки образа',
      minWidth: 500,
    },
    {
      field: 'signed',
      headerName: 'Цифровая подпись',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.signed ? (
              <CheckCircleIcon
                style={{ color: 'green', verticalAlign: 'middle' }}
              />
            ) : (
              <CancelIcon style={{ color: 'red', verticalAlign: 'middle' }} />
            )}
          </div>
        )
      },
    },
  ]

  const rows = props.tags.map((tag) => {
    return {
      tag: tag.name,
      signed: tag.signed,
      pullCommand: `docker pull ${service?.artifactsRegistryURL}/${service?.artifactsName}:${tag.name}`,
      project: owner,
      repository: name,
      sign: {
        image: `${service?.artifactsRegistryURL}/${service?.artifactsName}/${tag.name}`,
        owner: `${owner}`,
        registryID: `${service?.artifactsRegistry}`,
      },
    }
  })

  return (
    <>
      {props.loading && <LinearProgress />}
      {!props.loading && (
        <DataGrid
          getRowId={(row) => row.tag}
          columns={columns}
          rows={rows}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight={true}
          autoPageSize
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      )}
    </>
  )
}

export const ArtifactsPanel = () => {
  const { uuid } = useParams()
  const [tags, setTags] = useState<ServiceTag[]>()
  const [loading, setLoading] = useState<boolean>(false)

  const [needUpdate, setNeedUpdate] = useState<boolean>(false)

  const apiClient = new ApiClient()

  useEffect(() => {
    const getTags = async () => {
      try {
        setLoading(true)
        const data = await apiClient.getServiceTags(uuid || '')
        setTags(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getTags()
    setNeedUpdate(false)
  }, [uuid, needUpdate])

  return (
    <Card style={{ minHeight: '200px' }}>
      <CustomCardHeader
        height="80px"
        title="Артефакты сборки"
        description="Docker образы сервиса в хранилище артефактов"
        fullPage={false}
      />
      <ArtifactsTable
        tags={tags || []}
        setNeedUpdate={setNeedUpdate}
        loading={loading}
      />
    </Card>
  )
}

export default ArtifactsPanel
