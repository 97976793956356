import { useEffect, useState } from 'react'

import { ApiClient } from 'api/ApiClient'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'
import { CustomSelectComponentUUID } from '../CustomSelectComponentUUID'
import {
  CustomSelectComponentUUIDOption,
  CustomSelectProps,
} from '../CustomSelectComponentUUID/CustomSelectComponentUUID'

export interface PlatformServiceTemplateSelectProps extends CustomSelectProps {}

export const PlatformServiceTemplateSelect = (
  props: PlatformServiceTemplateSelectProps
) => {
  const [platformServiceTemplates, setPlatformServiceTemplates] =
    useState<PlatformServiceTemplateSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getPlatformServiceTemplates = async () => {
      try {
        const data = await apiClient.getPlatformServiceTemplates()
        setPlatformServiceTemplates(data)
      } catch (e) {
        console.log(e)
      }
    }

    getPlatformServiceTemplates()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    platformServiceTemplates?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default PlatformServiceTemplateSelect
