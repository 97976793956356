import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import {
  RevokeSignatureSpec,
  SignaturePublicKey,
  SignedSpec,
} from 'types/signature'

export async function createSignatureImage(
  this: ApiClient,
  spec: SignedSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/signature/sign`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await this.handleResponse(response)
}

export async function getSignaturePublicKey(
  this: ApiClient,
  uuid: string
): Promise<SignaturePublicKey> {
  const url = `${this.serverUrl}/signature/publickey/${uuid}`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await this.handleResponse(response)
}

export async function updateSignaturePrivateKey(
  this: ApiClient,
  uuid: string
): Promise<boolean> {
  const url = `${this.serverUrl}/signature/privatekey/${uuid}/rotate`

  const response = await fetch(url, {
    method: 'PUT',
    headers: new Headers({
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await this.handleResponse(response)
}

export async function deleteSignatureImage(
  this: ApiClient,
  spec: RevokeSignatureSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/signature/revoke/${spec.project}/${spec.repository}/${spec.registryID}/${spec.artifact}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: new Headers({
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await this.handleResponse(response)
}
