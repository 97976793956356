import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectProps,
  CustomSelectComponentUUIDOption,
} from 'components/CustomSelectComponentUUID/CustomSelectComponentUUID'
import { ApiClient } from 'api/ApiClient'
import { KubernetesNamespaceSpec } from 'types/Managers/KubernetesNamespaces'

export interface KubernetesNamespaceSelectProps extends CustomSelectProps {}

export const KubernetesNamespaceSelect = (
  props: KubernetesNamespaceSelectProps
) => {
  const [kubernetesNamespaces, setKubernetesNamespaces] =
    useState<KubernetesNamespaceSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getKubernetesNamespaces = async () => {
      try {
        const data = await apiClient.getKubernetesNamespaces()
        setKubernetesNamespaces(data)
      } catch (e) {
        console.log(e)
      }
    }

    getKubernetesNamespaces()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    kubernetesNamespaces?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default KubernetesNamespaceSelect
