import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { Action } from 'types/Managers/Actions'
import { GitlabPipelinesSummary } from 'types/Controllers/Gitlab'

export async function getActions(this: ApiClient): Promise<Action[]> {
  const url = `${this.serverUrl}/actions`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function deleteAction(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/actions/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function createAction(
  this: ApiClient,
  spec: Action
): Promise<JSON> {
  const url = `${this.serverUrl}/actions`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getAction(
  this: ApiClient,
  uuid: string
): Promise<Action> {
  const url = `${this.serverUrl}/actions/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}

export async function updateAction(
  this: ApiClient,
  spec: Action
): Promise<JSON> {
  const url = `${this.serverUrl}/actions/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await this.handleResponse(response)
}

export async function getPipelinesSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabPipelinesSummary> {
  const url = `${this.serverUrl}/actions/${uuid}/pipelines`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await this.handleResponse(response)
}
