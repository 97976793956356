import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainLayout from './components/Layouts/MainLayout'
import { routes } from './routes'
import { useAuth, hasAuthParams } from 'react-oidc-context'
import { ThemeProvider } from '@mui/material/styles'
import { edp } from './theme/edp'
import { getAuthType } from 'api/utils'
import { AuthType } from 'types/LocalAccounts'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function App() {
  if (getAuthType() === AuthType.OIDC) {
    const auth = useAuth()

    useEffect(() => {
      if (
        !hasAuthParams() &&
        !auth.isAuthenticated &&
        !auth.activeNavigator &&
        !auth.isLoading
      ) {
        auth.signinRedirect()
      }
    }, [
      auth,
      auth.isAuthenticated,
      auth.activeNavigator,
      auth.isLoading,
      auth.signinRedirect,
    ])

    switch (auth.activeNavigator) {
      case 'signinSilent':
        return <div>Выполняется аутентификация...</div>
      case 'signoutRedirect':
        return <div>Выполняется выход...</div>
    }

    if (auth.activeNavigator) {
      return <div>Проверка предыдущих сессий...</div>
    }

    if (auth.isLoading) {
      return <div>Загрузка...</div>
    }

    if (auth.error) {
      return <div>Произошла ошибка аутентификации: {auth.error.message}</div>
    }

    if (auth.isAuthenticated) {
      return (
        <div>
          <BrowserRouter>
            <ThemeProvider theme={edp}>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  {routes}
                </Route>
              </Routes>
            </ThemeProvider>
          </BrowserRouter>
        </div>
      )
    }
    return <div>Невозможно произвести аутентификацию</div>
  } else {
    return (
      <div>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={edp}>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  {routes}
                </Route>
              </Routes>
            </ThemeProvider>
          </DndProvider>
        </BrowserRouter>
      </div>
    )
  }
}

export default App
