const sizeConfigs = {
  sidebar: {
    width: '250px',
    header: {
      height: '72px',
    },
    footer: {
      height: '30px',
    },
  },
  topbar: {
    height: '48px',
  },
}

export default sizeConfigs
