import { Divider, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionRepositorySelect from 'components/UserInteraction/UserInteractionRepositorySelect'
import UserInteractionServiceTemplateSelect from 'components/UserInteraction/UserInteractionServiceTemplateSelect'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionThreeTextFields from 'components/UserInteraction/UserInteractionThreeTextFields'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { RepositoryVariable } from 'types/Managers/Repositories'
import { ServiceTemplate } from 'types/Managers/ServiceTemplates'
import { GitlabBranchRequest, GitlabGroup, GitlabVariable } from 'types/index'
import ServiceStore from './store'
import './styles.css'

export interface TemplateProps {
  disabled: boolean
  visible: boolean
}

export const IMMUTABLE_VARIABLES = [
  'EDP_TEAM_SLUG',
  'EDP_SERVICE_UUID',
  'EDP_SERVICE_SLUG',
]

export const Template = observer((props: TemplateProps) => {
  const [gitlabUrl, setGitlabUrl] = useState<string>('')
  const [gitlabGroupFullPath, setGitlabGroupFullPath] = useState<string>('')

  const [gitlabGroups, setGitlabGroups] = useState<GitlabGroup[]>([])
  const [template, setTemplate] = useState<ServiceTemplate>()

  const [templateRepositoryUrl, setTemplateRepositoryUrl] = useState<string>('')
  const apiClient = new ApiClient()

  const getGitlabGroups = async () => {
    try {
      let templateGroups: GitlabGroup[] = []
      const groups = await apiClient.getGitlabGroups()

      if (template && template.groups && template.groups.length > 0) {
        templateGroups = groups.filter((group) =>
          template?.groups?.includes(group.full_path)
        )
      } else {
        templateGroups = groups
      }

      setGitlabGroups(templateGroups)
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    getGitlabGroups()
  }, [template])

  function transformRepositoryVariableValue(key: string, value: any): string {
    if (IMMUTABLE_VARIABLES.includes(key)) {
      return 'генерируется автоматически'
    } else {
      return value ? value : ''
    }
  }

  const handleAddGitlabProjectLevelVariables = () => {
    let t: GitlabVariable[] = [
      ...ServiceStore.$('gitlabProjectLevelVariables').value,
    ]

    runInAction(() => {
      t = [...t, { key: '', value: '' }]
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleRemoveGitlabProjectLevelVariables = (index: number) => {
    runInAction(() => {
      const t: GitlabVariable[] = [
        ...ServiceStore.$('gitlabProjectLevelVariables').value,
      ]
      t.splice(index, 1)
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeGitlabProjectLevelVariablesKey = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const t = [...ServiceStore.$('gitlabProjectLevelVariables').value]
      t[index].key = event.target.value
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeGitlabProjectLevelVariablesValue = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const t = [...ServiceStore.$('gitlabProjectLevelVariables').value]
    runInAction(() => {
      t[index].value = event.target.value
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeGitlabProjectLevelVariablesEnvironmentScope = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const t = [...ServiceStore.$('gitlabProjectLevelVariables').value]
    runInAction(() => {
      t[index].environment_scope = event.target.value
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeRepositoryVariables = (index: number, value: string) => {
    runInAction(() => {
      const v: RepositoryVariable[] = [
        ...ServiceStore.$('templateRepositoryVariables').value,
      ]
      v[index].value = value

      ServiceStore.$('templateRepositoryVariables').set(v)
    })
  }

  const handleAddBranch = () => {
    let b: GitlabBranchRequest[] = [...ServiceStore.$('branches').value]

    runInAction(() => {
      b = [...b, { branch: '', ref: '' }]
      ServiceStore.$('branches').set(b)
    })
  }

  const handleRemoveBranch = (index: number) => {
    runInAction(() => {
      const b: GitlabBranchRequest[] = [...ServiceStore.$('branches').value]
      b.splice(index, 1)
      ServiceStore.$('branches').set(b)
    })
  }

  const handleChangeBranch = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const b = [...ServiceStore.$('branches').value]
      b[index].branch = event.target.value
      ServiceStore.$('branches').set(b)
    })
  }

  const handleChangeBranchRef = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const b = [...ServiceStore.$('branches').value]
    runInAction(() => {
      b[index].ref = event.target.value
      ServiceStore.$('branches').set(b)
    })
  }

  useEffect(() => {
    const getGitlabUrl = async () => {
      try {
        const data = await apiClient.getGitlabUrl()
        setGitlabUrl(data)
      } catch (e) {
        console.error(e)
      }
    }

    getGitlabUrl()
  }, [])

  useEffect(() => {
    const setRepositoryUrl = async () => {
      try {
        if (ServiceStore.$('slug').value) {
          ServiceStore.$('repositoryUrl').set(
            `${gitlabUrl}/${gitlabGroupFullPath}/${
              ServiceStore.$('slug').value
            }.git`
          )
        } else {
          ServiceStore.$('repositoryUrl').set('')
        }
      } catch (e) {
        console.error(e)
      }
    }

    setRepositoryUrl()
  }, [ServiceStore.$('slug').value, gitlabUrl, gitlabGroupFullPath])

  useEffect(() => {
    const getTemplateRepositoryUrl = async () => {
      try {
        if (ServiceStore.$('templated').value) {
          const template = await apiClient.getServiceTemplate(
            ServiceStore.$('template').value
          )
          setTemplate(template)

          const repository = await apiClient.getRepository(
            template.templateRepository
          )
          setTemplateRepositoryUrl(repository.url)
        }
      } catch (e) {
        console.error(e)
      }
    }

    getTemplateRepositoryUrl()
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    const getRepositoryVariables = async () => {
      try {
        ServiceStore.$('templateRepositoryVariables').reset()

        if (ServiceStore.$('template').value) {
          const template = await apiClient.getServiceTemplate(
            ServiceStore.$('template').value
          )
          const templateRepository = await apiClient.getRepository(
            template.templateRepository
          )
          const variables = await apiClient.getRepositoryVariables(
            templateRepository.uuid || ''
          )

          const v: RepositoryVariable[] = []

          for (const variable of variables) {
            const transformedVariable: RepositoryVariable = {
              key: variable.key,
              value: transformRepositoryVariableValue(
                variable.key,
                variable.value
              ),
              description: variable.description,
            }

            v.push(transformedVariable)
          }

          ServiceStore.$('templateRepositoryVariables').set(v)
        }
      } catch (e) {
        console.error(e)
      }
    }

    getRepositoryVariables()
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    const getRepositoryProjectLevelVariables = async () => {
      try {
        ServiceStore.$('gitlabProjectLevelVariables').reset()

        if (ServiceStore.$('template').value) {
          const template = await apiClient.getServiceTemplate(
            ServiceStore.$('template').value
          )
          const templateRepository = await apiClient.getRepository(
            template.templateRepository
          )
          let variables = await apiClient.getRepositoryProjectLevelVariables(
            templateRepository.uuid || ''
          )

          if (variables.length == 0) {
            variables = []
          }

          ServiceStore.$('gitlabProjectLevelVariables').set(variables)
        }
      } catch (e) {
        console.error(e)
      }
    }

    getRepositoryProjectLevelVariables()
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    const getRepositoryVariablesBranches = async () => {
      try {
        ServiceStore.$('branches').reset()

        if (ServiceStore.$('template').value) {
          const template = await apiClient.getServiceTemplate(
            ServiceStore.$('template').value
          )
          const templateRepository = await apiClient.getRepository(
            template.templateRepository
          )
          let branches = await apiClient.getRepositoryVariablesBranches(
            templateRepository.uuid || ''
          )

          if (branches.length == 0) {
            branches = []
          }

          ServiceStore.$('branches').set(branches)
        }
      } catch (e) {
        console.error(e)
      }
    }

    getRepositoryVariablesBranches()
  }, [ServiceStore.$('template').value])

  return (
    <>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Шаблон
      </Typography>
      <UserInteractionSwitch
        name="templated"
        description="Создать сервис из шаблона"
        disabled={props.disabled}
        helperText={`Включить создание сервиса из шаблона.`}
        checked={Boolean(ServiceStore.$('templated').value)}
        onChange={(e) => {
          ServiceStore.$('templated').set(e.target.checked)
        }}
      />
      {ServiceStore.$('templated').value && (
        <>
          <UserInteractionServiceTemplateSelect
            name="template"
            description="Шаблон"
            disabled={props.disabled}
            helperText="Шаблон на основе которого будет создаваться репозиторий сервиса."
            selected={ServiceStore.$('template').value}
            setSelected={(value) => ServiceStore.$('template').set(value)}
          />
          {props.visible && (
            <>
              <UserInteractionCustomSelect
                name="gitlabGroup"
                description="Группа репозиториев"
                helperText="Группа репозиториев в Gitlab в которой будет создаваться репозиторий сервиса."
                options={gitlabGroups.map((group) => group.full_path)}
                selected={gitlabGroupFullPath}
                setSelected={setGitlabGroupFullPath}
              />
              <UserInteractionTextField
                name="templateRepositoryUrl"
                disabled
                description="Адрес шаблонного репозитория"
                helperText="Адрес шаблонного репозитория."
                value={templateRepositoryUrl}
              />
              <UserInteractionTextField
                name="repositoryUrl"
                disabled
                description="Адрес репозитория сервиса"
                helperText="Адрес репозитория сервиса. Генерируется автоматически. Должна быть выбрана группа репозиториев и указан идентификатор."
                value={ServiceStore.$('repositoryUrl').value}
              />
            </>
          )}
          {!props.disabled &&
            ServiceStore.$('templateRepositoryVariables').value && (
              <Typography
                variant="subtitle2"
                paddingTop="30px"
                className="Typography-Subtitle2"
              >
                Переменные шаблонного репозитория
              </Typography>
            )}
          {!props.disabled &&
            ServiceStore.$('templateRepositoryVariables').value &&
            ServiceStore.$('templateRepositoryVariables').value.map(
              (variable: RepositoryVariable, index: number) => (
                <UserInteractionTwoTextFields
                  key={`template-repository-variable-${index}`}
                  helperText={variable.description}
                  index={index}
                  first={variable.key}
                  second={transformRepositoryVariableValue(
                    variable.key,
                    variable.value
                  )}
                  firstLabel={'Название'}
                  secondLabel={'Значение'}
                  handleChangeSecond={(e) =>
                    handleChangeRepositoryVariables(index, e.target.value)
                  }
                  firstDisabled
                  // Users can't change some variables
                  secondDisabled={
                    IMMUTABLE_VARIABLES.includes(variable.key) || props.disabled
                  }
                />
              )
            )}
          {!props.disabled && (
            <>
              <Typography
                variant="subtitle2"
                paddingTop="30px"
                className="Typography-Subtitle2"
              >
                Project-level CI/CD переменные в Gitlab
              </Typography>
              {ServiceStore.$('gitlabProjectLevelVariables').value &&
                ServiceStore.$('gitlabProjectLevelVariables').value.map(
                  (variable: GitlabVariable, index: number) => (
                    <UserInteractionThreeTextFields
                      key={`gitlab-project-level-variable-${index}`}
                      index={index}
                      first={variable.key}
                      second={variable.value}
                      third={variable.environment_scope}
                      firstLabel={'Название'}
                      secondLabel={'Значение'}
                      thirdLabel={'Environment scope'}
                      helperText={variable.description}
                      removeButton
                      handleRemove={() =>
                        handleRemoveGitlabProjectLevelVariables(index)
                      }
                      handleChangeFirst={(event) =>
                        handleChangeGitlabProjectLevelVariablesKey(index, event)
                      }
                      handleChangeSecond={(event) =>
                        handleChangeGitlabProjectLevelVariablesValue(
                          index,
                          event
                        )
                      }
                      handleChangeThird={(event) =>
                        handleChangeGitlabProjectLevelVariablesEnvironmentScope(
                          index,
                          event
                        )
                      }
                    />
                  )
                )}
              <UserInteractionAddButton
                handleAdd={handleAddGitlabProjectLevelVariables}
              />
              <Typography
                variant="subtitle2"
                paddingTop="30px"
                className="Typography-Subtitle2"
              >
                Ветки
              </Typography>
              {ServiceStore.$('branches').value &&
                ServiceStore.$('branches').value.map(
                  (branch: GitlabBranchRequest, index: number) => (
                    <UserInteractionTwoTextFields
                      key={`branch-${index}`}
                      index={index}
                      first={branch.branch}
                      second={branch.ref}
                      firstLabel={'Название'}
                      secondLabel={'ref'}
                      removeButton
                      handleChangeFirst={(event) =>
                        handleChangeBranch(index, event)
                      }
                      handleChangeSecond={(event) =>
                        handleChangeBranchRef(index, event)
                      }
                      handleRemove={() => handleRemoveBranch(index)}
                    />
                  )
                )}
              <UserInteractionAddButton handleAdd={handleAddBranch} />
            </>
          )}
        </>
      )}
      {!ServiceStore.$('templated').value && (
        <>
          <UserInteractionRepositorySelect
            name="repository"
            description="Существующий репозиторий"
            disabled={props.disabled}
            helperText="Выберите существующий репозиторий сервиса."
            selected={ServiceStore.$('repository').value}
            setSelected={(value) => ServiceStore.$('repository').set(value)}
          />
        </>
      )}
    </>
  )
})

export default Template
