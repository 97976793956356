import { ComponentPage } from 'components/ComponentPage'
import { OperationsPanel } from 'components/OperationsPanel/OperationsPanel'
import { useParams } from 'react-router-dom'
import DescriptionPanel from './DescriptionsPanel/DescriptionPanel'
import ResourcesPanel from './ResourcesPanel/ResourcesPanel'

export const ClusterPage = () => {
  const { uuid } = useParams()

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Ресурсы',
      index: 1,
      children: <ResourcesPanel />,
    },
    {
      name: 'Операции',
      index: 2,
      children: <OperationsPanel entity={uuid || ''} />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default ClusterPage
