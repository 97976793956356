import { Link } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { VaultEnvironmentSecret } from 'types/Controllers/Vault'
import { v4 as uuidv4 } from 'uuid'

export interface SecretsTableProps {
  vaultEnvironmentSecrets: VaultEnvironmentSecret[]
}

export const SecretsTable = (props: SecretsTableProps) => {
  const columns: GridColDef[] = [
    {
      field: 'secret',
      headerName: 'Название секрета',
      minWidth: 200,
      renderCell: (params) =>
        params.row.url.substring(params.row.url.lastIndexOf('/') + 1),
    },
    {
      field: 'key',
      headerName: 'Название переменной',
      minWidth: 250,
    },
    { field: 'value', headerName: 'Значение переменной', minWidth: 250 },
    {
      field: 'url',
      headerName: 'Ссылка на секрет',
      minWidth: 700,
      renderCell: (params) => (
        <Link href={params.row.url} target="_blank">
          {params.row.url}
        </Link>
      ),
    },
  ]

  const rows = props.vaultEnvironmentSecrets.flatMap((secret) => {
    return secret.variables.map((variable) => {
      return {
        key: variable.key,
        value: variable.value,
        url: secret.url,
      }
    })
  })

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={() => uuidv4()}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoPageSize
      autoHeight={true}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  )
}

export default SecretsTable
