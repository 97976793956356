import { Typography } from '@mui/material'
import UserInteractionHighlightedInput from 'components/UserInteraction/UserInteractionHighlightedInput'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import Form from 'mobx-react-form'
import { observer } from 'mobx-react-lite'
import './styles.css'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import {
  CustomWebhookHeader,
  CustomWebhookMethods,
} from 'types/Managers/Actions'
import { runInAction } from 'mobx'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'

interface CustomWebhookActionProps {
  form: Form
}

export const CustomWebhookAction = observer(
  (props: CustomWebhookActionProps) => {
    const handleAddHeaders = () => {
      let h: CustomWebhookHeader[] = [...props.form.$('headers').value]

      runInAction(() => {
        h = [...h, { name: '', value: '' }]
        props.form.$('headers').set(h)
      })
    }

    const handleRemoveHeaders = (index: number) => {
      runInAction(() => {
        const h: CustomWebhookHeader[] = [...props.form.$('headers').value]
        h.splice(index, 1)
        props.form.$('headers').set(h)
      })
    }

    const handleChangeHeaderName = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      runInAction(() => {
        const h: CustomWebhookHeader[] = [...props.form.$('headers').value]
        h[index].name = event.target.value
        props.form.$('headers').set(h)
      })
    }

    const handleChangeHeaderValue = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      runInAction(() => {
        const h: CustomWebhookHeader[] = [...props.form.$('headers').value]
        h[index].value = event.target.value
        props.form.$('headers').set(h)
      })
    }

    return (
      <>
        <Typography
          variant="subtitle2"
          className="Typography-Subtitle2"
          paddingTop="15px"
        >
          Настройки custom webhook
        </Typography>
        <UserInteractionTextField
          name="url"
          description="URL"
          helperText="URL, на который будет отправляться webhook."
          {...props.form.$('url').bind()}
        />
        <UserInteractionCustomSelect
          name="method"
          options={CustomWebhookMethods}
          description="Метод"
          helperText="HTTP метод запроса."
          selected={props.form.$('method').value}
          setSelected={(value) => props.form.$('method').set(value)}
        />
        <UserInteractionHighlightedInput
          name="payload"
          language="json"
          description="Payload"
          helperText="Тело запроса (JSON)."
          minHeight={'150px'}
          value={props.form.$('payload').value}
          setValue={(value) => props.form.$('payload').set(value)}
        />
        <div>
          <Typography
            variant="subtitle2"
            paddingTop="15px"
            className="Typography-Subtitle2"
          >
            Headers
          </Typography>
          {props.form.$('headers').value &&
            props.form
              .$('headers')
              .value.map((header: CustomWebhookHeader, index: number) => (
                <UserInteractionTwoTextFields
                  key={`headers-${index}`}
                  first={header.name}
                  second={header.value}
                  firstLabel="Name"
                  secondLabel="Value"
                  helperText="Header запроса."
                  removeButton
                  handleRemove={() => handleRemoveHeaders(index)}
                  handleChangeFirst={(event) =>
                    handleChangeHeaderName(index, event)
                  }
                  handleChangeSecond={(event) =>
                    handleChangeHeaderValue(index, event)
                  }
                />
              ))}
          <UserInteractionAddButton handleAdd={handleAddHeaders} />
        </div>
      </>
    )
  }
)

export default CustomWebhookAction
