import { useEffect, useState } from 'react'

import { CustomEntity } from 'types/Managers/CustomEntities'
import { ApiClient } from 'api/ApiClient'
import UpdateComponentPage from 'components/UpdateComponentPage/UpdateComponentPage'
import BasicInfo from './EditCustomEntityPage/BasicInfo'
import { useNavigate, useParams } from 'react-router-dom'

export const UpdateCustomEntityPage = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()
  const [customEntity, setCustomEntity] = useState<CustomEntity>({
    uuid: '',
    name: '',
    slug: '',
    description: '',
    owner: '',
    type: '',
    url: '',
  })

  const [updated, setUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const getCustomEntity = async () => {
    const apiClient = new ApiClient()

    try {
      const data = await apiClient.getCustomEntity(uuid || '')
      setCustomEntity(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getCustomEntity()
  }, [])

  const handleUpdate = async () => {
    setUpdated(false)
    if (!customEntity.name || !customEntity.owner) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)

    try {
      const spec: CustomEntity = {
        uuid: customEntity.uuid,
        name: customEntity.name,
        description: customEntity.description,
        slug: customEntity.slug,
        type: customEntity.type,
        owner: customEntity.owner,
        url: customEntity.url,
      }

      await apiClient.updateCustomEntity(spec)

      setUpdated(true)
      navigate('/components/custom-entities')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <UpdateComponentPage
      handleUpdate={handleUpdate}
      loading={loading}
      updated={updated}
      setUpdated={setUpdated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      updatedMessage={`Пользовательский компонент ${customEntity.name} обновлен.`}
    >
      <BasicInfo
        customEntity={customEntity}
        setCustomEntity={setCustomEntity}
        disabled={true}
      />
    </UpdateComponentPage>
  )
}
export default UpdateCustomEntityPage
