import { ComponentPage } from 'components/ComponentPage'
import { AddCredentialsPanel } from './panels/AddCredentialsPanel'
import { ListCredentialsPanel } from './panels/ListCredentialsPanel'

const CredentialsPage = () => {
  const panels = [
    {
      name: 'Список учетных данных',
      index: 0,
      children: <ListCredentialsPanel />,
    },
    {
      name: 'Добавить учетные данные',
      index: 1,
      children: <AddCredentialsPanel />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default CredentialsPage
