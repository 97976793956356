import { Box, Grid } from '@mui/material'
import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from 'components/CustomButton'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from 'components/CustomSnackbarComponent'
import useFetchPlatformServiceConfiguration from 'hooks/useFetchPlatformServiceConfiguration'
import { NothingFoundPage } from '@edp/core-components-frontend'

export const PlatformServiceConfigurationsPage = () => {
  const [platformServiceConfigurations, setPlatformServiceConfigurations] =
    useState<PlatformServiceConfigurationSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [
    platformServiceConfigurationToDelete,
    setPlatformServiceConfigurationToDelete,
  ] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getPlatformServiceConfigurations = async () => {
    setLoading(true)
    try {
      if (owner && owner != '') {
        const data = await apiClient.getPlatformServiceConfigurationsBy(
          'owner',
          owner
        )
        setPlatformServiceConfigurations(data)
      } else if (owner) {
        const data = await apiClient.getPlatformServiceConfigurations()
        setPlatformServiceConfigurations(data)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPlatformServiceConfigurations()
  }, [owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setPlatformServiceConfigurationToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient
        .deletePlatformServiceConfiguration(
          platformServiceConfigurationToDelete
        )
        .then(() => {
          setDeleted(true)
          getPlatformServiceConfigurations()
          setDeletionWindowOpened(false)
        })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const platformServiceConfigurationToDeleteSpec =
    useFetchPlatformServiceConfiguration(platformServiceConfigurationToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/platform-services/configurations/create'}
          >
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {platformServiceConfigurations?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новую конфигурацию платформенного сервиса, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {platformServiceConfigurations?.map(
          (platformServiceConfiguration, index) => (
            <ComponentCard
              key={index}
              name={platformServiceConfiguration.name}
              uuid={platformServiceConfiguration.uuid}
              description={platformServiceConfiguration.description}
              options={[
                {
                  name: 'Владелец',
                  value: `${platformServiceConfiguration.ownerRef?.name}`,
                },
                {
                  name: 'Шаблон',
                  value: `${platformServiceConfiguration.platformServiceTemplateRef?.name}`,
                },
              ]}
              linkOpen={`/platform-services/configurations/${platformServiceConfiguration.uuid}`}
              linkUpdate={`/platform-services/configurations/update/${platformServiceConfiguration.uuid}`}
              handleDelete={handleOpenDeletionWindow}
            />
          )
        )}
        <DeletionWindow
          title={'Удаление конфигурации'}
          text={`Конфигурация платформенного сервиса ${platformServiceConfigurationToDeleteSpec?.name} будет удалена без возможности восстановления.`}
          spec={platformServiceConfigurationToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Конфигурация платформенного сервиса ${platformServiceConfigurationToDeleteSpec?.name} удалена.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении конфигурации платформенного сервиса ${platformServiceConfigurationToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}

export default PlatformServiceConfigurationsPage
