import { ComponentPage } from 'components/ComponentPage'
import { KafkaTopicsPanel } from './KafkaTopicsPanel/KafkaTopicsPanel'
import KafkaAclsPanel from './KafkaAclsPanel/KafkaAclsPanel'
import GrafanaPanel from './GrafanaPanel/GrafanaPanel'

export const KafkaClusterPage = () => {
  const panels = [
    {
      name: 'Топики',
      index: 0,
      children: <KafkaTopicsPanel />,
    },
    {
      name: 'ACL',
      index: 1,
      children: <KafkaAclsPanel />,
    },
    {
      name: 'Мониторинг',
      index: 2,
      children: <GrafanaPanel />,
    },
  ]

  return (
    <>
      <ComponentPage panels={panels} />
    </>
  )
}

export default KafkaClusterPage
