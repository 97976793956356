import { Divider, Typography } from '@mui/material'
import ServiceStore from './store'
import { observer } from 'mobx-react-lite'
import './styles.css'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionInfraServiceSelect from 'components/UserInteraction/UserInteractionInfraServiceSelect'
import UserInteractionInfraServiceTypeSelect from 'components/UserInteraction/UserInteractionInfraServiceTypeSelect'
import {
  INFRA_SERVICE_NEXUS,
  INFRA_SERVICE_HARBOR,
} from 'types/Managers/InfraServices'
import { ApiClient } from 'api/ApiClient'
import { useEffect } from 'react'

export interface ArtifactsProps {
  disabled: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Artifacts = observer((props: ArtifactsProps) => {
  const apiClient = new ApiClient()

  useEffect(() => {
    const getInfraService = async () => {
      try {
        if (
          ServiceStore.$('artifactsRegistryEngine').value &&
          ServiceStore.$('artifactsRegistryName').value
        ) {
          const data = await apiClient.getInfraService(
            ServiceStore.$('artifactsRegistryEngine').value,
            ServiceStore.$('artifactsRegistryName').value
          )

          ServiceStore.$('artifactsRegistry').set(data.id)
        }
      } catch (e) {
        console.error(e)
      }
    }
    getInfraService()
  }, [
    ServiceStore.$('artifactsRegistryEngine').value,
    ServiceStore.$('artifactsRegistryName').value,
  ])

  return (
    <>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Артефакты
      </Typography>
      <UserInteractionInfraServiceTypeSelect
        name="artifactsRegistryEngine"
        description="Тип хранилища артефактов"
        helperText="Тип хранилища артефактов в котором будут храниться артефакты (Docker образы) сервиса."
        filter={[INFRA_SERVICE_NEXUS, INFRA_SERVICE_HARBOR]}
        selected={ServiceStore.$('artifactsRegistryEngine').value}
        setSelected={(value) =>
          ServiceStore.$('artifactsRegistryEngine').set(value)
        }
      />
      <UserInteractionInfraServiceSelect
        name="artifactsRegistryName"
        type={ServiceStore.$('artifactsRegistryEngine').value}
        description="Хранилище артефактов"
        helperText="Инстанс хранилища артефактов в котором будут храниться артефакты (Docker образы) сервиса."
        selected={ServiceStore.$('artifactsRegistryName').value}
        setSelected={(value) =>
          ServiceStore.$('artifactsRegistryName').set(value)
        }
      />
      <UserInteractionTextField
        name="artifactsRegistryURL"
        description="Docker registry URL"
        helperText="URL Docker registry в котором будут храниться артефакты (Docker образы) сервиса без схемы (формат 'registry.example.com')."
        {...ServiceStore.$('artifactsRegistryURL').bind()}
      />
      <UserInteractionTextField
        name="artifactsName"
        description="Название Docker образов"
        helperText="Название Docker образов, которые будут собираться для сервиса. Рекомендуется указывать идентификатор сервиса."
        {...ServiceStore.$('artifactsName').bind()}
      />
    </>
  )
})

export default Artifacts
