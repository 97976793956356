import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Box, Tab, Tabs, Typography } from '@mui/material'

type panel = {
  name: string
  index: number
  children: React.ReactNode
  hidden?: boolean
}

type ComponentPageProps = {
  panels: panel[]
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  hidden?: boolean
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, hidden, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export const ComponentPage = (props: ComponentPageProps) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (_event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Card sx={{ border: 'none', boxShadow: 'none', minHeight: '100%' }}>
      <CardContent sx={{ paddingTop: 0 }}>
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="tab"
            >
              {props.panels.map((panel) => (
                <Tab
                  label={panel.name}
                  {...a11yProps(panel.index)}
                  key={panel.index}
                  sx={{ display: panel.hidden ? 'none' : undefined }}
                />
              ))}
            </Tabs>
          </Box>
          {props.panels.map((panel) => (
            <TabPanel
              value={value}
              index={panel.index}
              hidden={panel.hidden}
              key={panel.index}
            >
              {panel.children}
            </TabPanel>
          ))}
        </>
      </CardContent>
    </Card>
  )
}

export default ComponentPage
