import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { CustomSelectComponent } from 'components/CustomSelectComponent'
import { ApiClient } from 'api/ApiClient'
import { GitlabBranch } from 'types/Controllers/Gitlab'
import { CustomSelectProps } from 'components/CustomSelectComponent/CustomSelectComponent'

export interface ServiceBranchesSelectProps extends CustomSelectProps {}

export const ServiceBranchesSelect = (props: ServiceBranchesSelectProps) => {
  const { uuid } = useParams()

  const [branches, setBranches] = useState<GitlabBranch[]>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const apiClient = new ApiClient()

    const getBranches = async () => {
      if (uuid) {
        try {
          setLoading(true)
          const data = await apiClient.getServiceBranches(uuid)
          setBranches(data)
        } catch (e) {
          console.error(e)
        } finally {
          setLoading(false)
        }
      }
    }

    getBranches()
  }, [uuid])

  const options = branches?.map((b) => b.name) || []

  return (
    <CustomSelectComponent
      title={props.title}
      error={props.error}
      selected={props.selected}
      setSelected={props.setSelected}
      options={options}
      height={props.height || '55px'}
      width={props.width || '280px'}
      loading={loading}
    />
  )
}

export default ServiceBranchesSelect
