import { Card, Grid, LinearProgress } from '@mui/material'
import { EnvironmentSelect } from 'components/EnvironmentSelect'
import { useEffect, useState } from 'react'
import './styles.css'
import {
  KubernetesListObject,
  KubernetesObject,
  V1Ingress,
  V1Pod,
} from '@kubernetes/client-node'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'
import { EnvironmentSpec } from 'types/Managers/Environments'
import useFetchService from 'hooks/useFetchService'
import { KubernetesLabelSelector } from 'types/Controllers/Kubernetes'
import { kubernetesPod, kubernetesIngress } from 'types/KubernetesResources'
import { IngressesTable } from './IngressesTable'
import { PodsTable } from './PodsTable'
import { NothingFoundPage } from '@edp/core-components-frontend'
import CustomCardHeader from 'components/CustomCardHeader/CustomCardHeader'

export const EnvironmentsPanel = () => {
  const { uuid } = useParams()

  const [environmentUUID, setEnvironmentUUID] = useState<string>('')
  const [environment, setEnvironment] = useState<EnvironmentSpec>()

  const [kubernetesData, setKubernetesData] =
    useState<KubernetesListObject<KubernetesObject>[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const apiClient = new ApiClient()

  const service = useFetchService(uuid)

  useEffect(() => {
    const getEnvironment = async () => {
      if (environmentUUID) {
        try {
          const data = await apiClient.getEnvironment(environmentUUID)
          setEnvironment(data)
        } catch (e) {
          console.error(e)
        }
      }
    }

    getEnvironment()
  }, [environmentUUID])

  useEffect(() => {
    const getResources = async () => {
      if (service && environment && environment.slug) {
        setLoading(true)
        setError('')
        const labelSelectors: KubernetesLabelSelector[] = [
          {
            key: 'edp.express42.com/service',
            value: service.slug || '',
          },
          {
            key: 'edp.express42.com/environment',
            value: environment.slug,
          },
        ]

        try {
          const data = await apiClient.getClusterObjects(
            environment?.clusterRef?.uuid || '',
            labelSelectors
          )
          setKubernetesData(data)
        } catch (e) {
          console.log(e)
          if (e instanceof Error) {
            setError(e.message)
          } else {
            setError('unknown')
          }
        } finally {
          setLoading(false)
        }
      }
    }

    getResources()
  }, [environment])

  const data = new Map<string, KubernetesObject[]>()

  if (kubernetesData !== undefined) {
    for (const listObject of kubernetesData) {
      if (listObject.kind !== undefined) {
        data.set(listObject.kind, listObject.items)
      }
    }
  }

  return (
    <>
      <Grid container className="Grid-Panel">
        <Grid item>
          <EnvironmentSelect
            selected={environmentUUID}
            setSelected={setEnvironmentUUID}
            width={'280px'}
            title="Окружение"
          />
        </Grid>
      </Grid>
      {!loading && data.size == 0 && (
        <NothingFoundPage
          text={
            'Попробуйте выбрать одно из окружений, на которых развернут сервис'
          }
        />
      )}
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          {loading !== false ? <LinearProgress /> : <></>}
        </Grid>
        {data.get(kubernetesPod.kindItemsList) !== undefined ? (
          <Grid item xs={12}>
            <Card>
              <CustomCardHeader
                height="80px"
                title="Pods"
                description={`Pod'ы сервиса на окружении ${environment?.name}`}
                fullPage={false}
              />
              <PodsTable
                pods={data.get(kubernetesPod.kindItemsList) as V1Pod[]}
                cluster={environment?.clusterRef?.uuid || ''}
              />
            </Card>
          </Grid>
        ) : (
          <></>
        )}
        {data.get(kubernetesIngress.kindItemsList) !== undefined ? (
          <Grid item xs={12}>
            <Card>
              <CustomCardHeader
                height="80px"
                title="Ingresses"
                description={`Ingress'ы сервиса на окружении ${environment?.name}`}
                fullPage={false}
              />
              <IngressesTable
                ingresses={
                  data.get(kubernetesIngress.kindItemsList) as V1Ingress[]
                }
              />
            </Card>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  )
}

export default EnvironmentsPanel
